/**
 * Created by lt-68 on 3/11/17.
 */

import {AbstractControl} from '@angular/forms';
export class BlankSpaceValidator {
    public static validate(c:AbstractControl) {
        let blankSpace_REGEXP = /.*\S.*/;
        return blankSpace_REGEXP.test(c.value) ? null : {validateBlankSpace: {valid: false}};
    }
    public static validateCkEditor(val:AbstractControl) {
        let blankSpace_REGEXP = /.*\S.*/;
        if(val.value == null){
            return blankSpace_REGEXP.test(val.value) ? null : {validateBlankSpace: {valid: false}}; 
           
        }else{
            return blankSpace_REGEXP.test(val.value.replace(/(&nbsp;)|(<p>)|(<\/p>)/g, '')) ? null : {validateBlankSpace: {valid: false}};  
        }
    }
}
