import { Component, ViewContainerRef } from '@angular/core';
import * as $ from 'jquery';
import { GlobalState } from './global.state';
import { ToastrService } from "ngx-toastr";
import { Router, NavigationEnd, RouterModule } from "@angular/router";
import { UtilityHelper } from "./helpers/utilityHelper";
import { AbstractBaseComponent } from "./app.baseComponent";
import { MentoringEvents } from "./helpers/events";
import { environment } from "../environments/environment";
import { AuthenticationHelper } from "./helpers/authenticationHelper";
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MentoringServices } from './appServices/mentoring';
import { PopUpScreenComponent } from './theme/components/popUpScreen/popUpScreen.component';
declare var gtag: Function;
declare let window: any;
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';  // Import RouterOutlet
import { NgxPaginationModule } from 'ngx-pagination';
import { Pages } from './pages';


@Component({
    selector: 'app',
    styleUrls: ['./app.component.scss'],
    template: `
    <main [ngClass]="{ 'menu-collapsed': isMenuCollapsed }">
      <div class="additional-bg"></div>
      <app-pop-up-screen></app-pop-up-screen>
      <router-outlet></router-outlet>
    </main>
  `,
    standalone: true,
    imports:[PopUpScreenComponent, CommonModule,RouterOutlet,NgxPaginationModule, Pages, RouterModule]
})
export class App extends AbstractBaseComponent {
  isMenuCollapsed: boolean = false;

  constructor(
    private _state: GlobalState,
    private viewContainerRef: ViewContainerRef,
    private toastr: ToastrService,
    public router: Router,
    private utility: UtilityHelper,
    private authService: AuthenticationHelper,
    private mentoringEvents: MentoringEvents,
    private translate: TranslateService,
    private mentoringServices: MentoringServices
  ) {
    super();
    this.applyGA();
    this.setAppFavicon();
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.utility.myDomain !== this.LOCAL_DOMAIN) {
        this.sendDataToGA(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit() {
    const userId = this.utility.getItem('userId');
    if (!this.utility.getItem('currentLanguage')) {
      this.utility.setItem('currentLanguage', 'en');
    } else if (this.authService.isLoggedIn()) {
      const params: any = [`user_details=ethnicity`,].join(`&`);
      const ethincityURL: any = `${userId}?${params}`;
      this.mentoringServices.getMyAccountDetails(ethincityURL).subscribe(data => {
        if (data.data.attributes.language == 'dy') {
          this.utility.setItem('currentLanguage', 'wls');
          this.translate.use('wls');


        } else {
          this.utility.setItem('currentLanguage', 'en');
          this.translate.use('en');

        }
      });
    }
    this.translate.addLangs(['en', 'wls']);
    let currentCountry = this.utility.getItem('currentLanguage');
    this.translate.use(currentCountry);
    this.translate.setDefaultLang('en');
    if (this.utility.getItem('currentLanguage') === 'wls') {
      moment.locale('cy');
    }

    this.mentoringEvents.getProjectSwitchedContent().subscribe(item => {
      if (item) {
        if (this.router.url.includes('feeds') && !item.reload) {
          this.sendDataToGA(this.router.url);
        }
      }
    });
  }

  sendDataToGA(urlAfterRedirects) {
    gtag('set', 'page', urlAfterRedirects);
    if (this.utility.getItem('role') == 'mentee' || this.utility.getItem('role') == 'mentor'
      || this.utility.getItem('role') == 'coordinator') {
      gtag('set', { 'user_properties': { 'role': this.utility.getItem('role') } });
    } else {
      gtag('set', { 'user_properties': { 'role': 'guest' } });
    }
    if (this.utility.getItem('partnerName')) {
      gtag('set', { 'user_properties': { 'partner': this.utility.getItem('partnerName') } });
    }
    if (this.utility.getItem('projectName')) {
      gtag('set', { 'user_properties': { 'project_name': this.utility.getItem('projectName') } });
    }
    gtag('event', 'page_view', {
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_title: document.title
    });
  }


  ngAfterViewInit() {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

    this.mentoringEvents.getAnalyticsEvent().subscribe((item) => {
      if (item) {
        this.applyGA();
        gtag('event', 'page_events', item);
      }
    });
  }

  applyGA() {
    if (this.utility.myDomain !== this.LOCAL_DOMAIN) {
      const script = document.createElement('script');
      script.innerHTML = '';
      const appId = environment.appId;

      // Intercom setup
      script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');` +
        `ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];` +
        `i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');` +
        `s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appId}';` +
        `var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};` +
        `if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);` +
        `}else{w.addEventListener('load',l,false);}}})();`;

      // Google Analytics gtag setup
      script.innerHTML += `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
        `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
        `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
        `'https://www.googletagmanager.com/gtag/js?id=' + i;f.parentNode.insertBefore(j,f);` +
        `})(window,document,'script','dataLayer','${this.getGAId()}');`;

      script.innerHTML += `window.dataLayer = window.dataLayer || [];` +
        `function gtag(){dataLayer.push(arguments);}` +
        `gtag('js', new Date());` +
        `gtag('config', '${this.getGAId()}');`;

      const head = document.getElementsByTagName('head')[0];
      head.appendChild(script);
    }
  }

  getGAId() {
    return this.utility.myDomain === this.KNOWLEDGE_DOMAIN
      ? environment.UAIDForKnowledge
      : environment.UAIDForMentoring;
  }

  setAppFavicon() {
    let iconPath: any = './assets/img/mentoring.ico';
    if (this.utility.myDomain === this.KNOWLEDGE_DOMAIN) {
      iconPath = './assets/img/knowledge.ico';
      $('#navigation-tab-title').text('Bright Knowledge');
      $('#site-favicon').attr('href', iconPath);
    }
  }

  get currentUserId() {
    return this.utility.currentUserID;
  }

  set currentUserId(value) {
    this.utility.currentUserID = value;
  }

  get userIdFromUtility() {
    return this.utility.getItem('userId') ? this.utility.getItem('userId') : '';
  }
}
