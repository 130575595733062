import {Injectable} from "@angular/core";
import {AppConstant} from "../app.constant";
import * as linkifyHtml from "linkifyjs/html";
import * as linkify from "linkifyjs";
import * as _ from 'lodash';
import * as youtubeUrlParser from "js-video-url-parser";
import * as youtubeThumbnail from "youtube-thumbnail";
import * as xss from 'xss';

@Injectable()
export class TextHelper {
    myxss: any;
    constructor() {
        let xssOptions: any = {};
        this.myxss = new xss.FilterXSS(xssOptions);
    }

    linkifyText(text) {
        let originalText: any = text;
        let foundLinkMatches: any = this.findLinkMatches(text);
        if (foundLinkMatches && foundLinkMatches.length) {
            foundLinkMatches.forEach((linkMatch) => {
                if ((linkMatch.includes('_') || linkMatch.includes('*')) &&
                    (!linkMatch.includes('/review_activities/'))) {
                    const rgx: any = new RegExp(`${linkMatch}`, 'gmi');
                    let hyperlink: any =
                        "<a href=\"" + this.sanitizeMatchFromUnderscores(linkMatch) +
                        "\" target=\"_blank\">" + linkMatch + "</a>";
                    hyperlink = this.addLinkProtocols(hyperlink);
                    text = text.replace(rgx, hyperlink);
                }
            });
        }
        if (this.doesTextContainsAnyLinks(originalText)) {
            return (linkifyHtml(this.myxss.process(text), { defaultProtocol: 'https' }));
        } else {
            return this.myxss.process(originalText);
        }
    }

    sanitizeMatchFromUnderscores(match) {
        let firstChar: any = match[match.length - 1];
        if (firstChar.includes('_') || firstChar.includes('*')) {
            match = match.substring(0, match.length - 1);
        }
        return match;
    }

    replaceWithLink(group1, group2, group3, group4) {
        if (typeof (group4) == "undefined") {
            return "<a href=\"" + group3 + "\" target=\"_blank\">" + group2 + "</a>";
        } else {
            return "<a href=\"" + group4 + "\" target=\"_blank\">" + group4 + "</a>";
        }
    }

    addLinkProtocols(text) {
        let regex = /(href=")(?!https?:\/\/)/g;
        return text.replace(regex, "href=\"https://");
    }


    breakifyText(text) {
        const brakifyRegex = new RegExp(AppConstant.BREAKIFY_TEXT_REGEX, 'gmi');
        return text.replace(brakifyRegex, '<br />');
    }

    /**
     * Removes the angle braces (< >) from the links of the message body
     * After removing angle braces bold or italics the text
     * @param messageBody
     */
    checkAndBoldOrItalicText(text, isThreadPost?: boolean) {
        if (text && text.length) {
            let reviewActivityText = this.checkForReviewActivity(text);
            if (reviewActivityText) {
                text = reviewActivityText;
            } else {
                if (!isThreadPost) {
                    text = this.removeAngleBraces(text);
                }
                //If the text contains underscore as first character then it first bolds and then italics
                if (text[0] == '_') {
                    text = this.boldText(text);
                    text = this.italicText(text);
                } else {
                    text = this.italicText(text);
                    text = this.boldText(text);
                }
            }
            return text;
        } else {
            return '';
        }
    }

    boldText(text): string {
        const searchAsterisksRegex: any = new RegExp(AppConstant.SEARCH_ASTERISKS_REGEX, 'gmi');
        let asterisksMatches = text.match(searchAsterisksRegex);
        if (asterisksMatches && asterisksMatches.length) {
            asterisksMatches.forEach((match) => {
                if (!this.excludeFromBoldOrItalic(text, match)) {
                    text = text.replace(match, '<b>' + this.removeFirstAndLastChracterFromMatch(match) + '</b>');
                }
            });
        }
        return text;
    }

    italicText(text): string {
        const searchUnderScoreRegex: any = new RegExp(AppConstant.SEARCH_UNDERSCORES_REGEX, 'gmi');
        let underscoreMatches = text.match(searchUnderScoreRegex);
        if (underscoreMatches && underscoreMatches.length) {
            underscoreMatches.forEach((match) => {
                if (!this.excludeFromBoldOrItalic(text, match)) {
                    text = text.replace(match, '<i>' + this.removeFirstAndLastChracterFromMatch(match) + '</i>');
                }
            });
        }
        return text;
    }

    removeFirstAndLastChracterFromMatch(str): string {
        return str.substring(1, str.length - 1);
    }

    removeAngleBraces(text): string {
        const removeAngleBracesRegex = new RegExp(AppConstant.REMOVE_ANGLE_BRACES_FROM_LINK_REGEX, 'gmi');
        return text.replace(removeAngleBracesRegex, '$1');
    }

    checkForReviewActivity(text) {
        let markdown_regex = new RegExp(AppConstant.ACTIVITY_LINK_REGEX, 'g');
        let isReviewActivityLink: boolean = false;
        text = text.replace(markdown_regex, ($1, $2, $3, $4) => {
            if ($2 === AppConstant.ACTIVITY_REVIEW_IT_NOW_TEXT) {
                isReviewActivityLink = true;
                return this.replaceWithLink($1, $2, $3, $4);
            } else {
                return '';
            }
        });
        return isReviewActivityLink ? this.addLinkProtocols(text) : '';
    }

    /**
     * Extract all the links from the given text
     * @param text
     */
    extractLinks(text) {
        let links: any = [];
        let extractedLinks: any = linkify.find(text);
        if (extractedLinks && extractedLinks.length) {
            extractedLinks.forEach((item) => {
                if (item.hasOwnProperty('href') && item.href) {
                    links.push(item.href);
                }
            });
        }
        return links;
    }

    /**
     * Checks if to exclude the found match from the text from being bold or italic
     * @param text
     * @param match
     */
    excludeFromBoldOrItalic(text, match): boolean {
        match = match.substring(1, match.length - 1);
        let exclude: any = false;
        let extractedLinks: any = this.extractLinks(text);
        let foundLinkMatches: any = this.findLinkMatches(text);
        extractedLinks = _.union(extractedLinks, foundLinkMatches);
        if (extractedLinks && extractedLinks.length) {
            extractedLinks.some((link) => {
                if (link.includes(match)) {
                    exclude = true;
                    return true;
                }

                if (linkify.find(match).length) {
                    exclude = true;
                    return true;
                }
            });
        }
        return exclude;
    }

    findLinkMatches(text) {
        text = this.removeAngleBraces(text);
        let linksRegex = new RegExp(AppConstant.FIND_LINKS_REGEX, 'gmi');
        let matches: any = [];
        if (text && text.length) {
            matches = text.match(linksRegex);
        }
        return matches;
    }

    extractYoutubeUrls(text) {
        let extractedYoutubeUrls: any = [];
        const baseYoutubeEmbededLink: string = 'https://www.youtube.com/embed';
        if (text && text.length) {
            let extractedLinks: any = this.extractLinksFromString(text);
            if (extractedLinks && extractedLinks.length) {
                extractedLinks.forEach((link) => {
                    const parsedUrl: any = this.parseYoutubeUrl(baseYoutubeEmbededLink, link.value);
                    if (parsedUrl && parsedUrl.length) {
                        extractedYoutubeUrls.push(parsedUrl);
                    }
                });
            }
        }
        return extractedYoutubeUrls;
    }

    extractYoutubeThumbnails(youtubeUrl, imageQuality = 'medium') {
        let thumbnail = '';
        const availableQualities = ['default', 'medium', 'high'];
        // Validates the passed image quality and set a default value
        if (!availableQualities.includes(imageQuality)) {
            imageQuality = 'medium';
        }
        // Retrieve youtube thumbnails
        thumbnail = youtubeThumbnail(youtubeUrl);
        if (thumbnail && thumbnail.hasOwnProperty(imageQuality)) {
            thumbnail = thumbnail[imageQuality]['url'];
        }
        return thumbnail;
    }

    extractLinksFromString(text) {
        if (text && text.length) {
            return linkify.find(text);
        }
        return [];
    }

    parseYoutubeUrl(baseYoutubeLink, url) {
        if (url && url.length) {
            let parsedInfo: any = youtubeUrlParser.parse(url);
            if (parsedInfo && parsedInfo.hasOwnProperty("provider") && parsedInfo.provider === 'youtube' &&
                parsedInfo.hasOwnProperty("mediaType") && parsedInfo.mediaType === 'video' &&
                parsedInfo.hasOwnProperty("id") && parsedInfo.id) {
                return `${baseYoutubeLink}/${parsedInfo.id}`;
            }
        }
        return "";
    }

    doesTextContainsAnyLinks(text) {
        let extractedLinks: any = this.extractLinks(this.removeAngleBraces(text));
        const foundLinkMatches: any = this.findLinkMatches(text);
        extractedLinks = _.union(extractedLinks, foundLinkMatches);
        return extractedLinks && extractedLinks.length;
    }

    regextToCheckVideoWordContains(msg) {
        const regexToCheckWordVideo = /scheduled_at|video_timestamp/i;
        if (regexToCheckWordVideo.test(msg)) {
            return true;
        } else {
            return false;
        }
    }

}
