import {Component} from '@angular/core';
import { Router } from '@angular/router';
import {AbstractBaseComponent} from "../../../app.baseComponent";
import {DateAndTime} from "../../../helpers/dateAndTime";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgaModule } from 'app/theme/nga.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'knowledgeFooterComponent',
    templateUrl: './knowledgeFooter.html',
    styleUrls: ['./knowledgeFooter.scss'],
    standalone: true,
    imports: [CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgaModule,
        TranslateModule,]
})
export class KnowledgeFooter extends AbstractBaseComponent {
    currentYear: string = '';
    constructor(private _router: Router,
                private dateAndTime: DateAndTime) {
        super();
    }

    ngOnInit() {
        this.currentYear = this.dateAndTime.defaultDateAndTimeFormat(new Date(),'YYYY');
    }

    routeTermsPage(){
        this._router.navigate(['/terms']);
    }

    routePrivacyPage(){
        this._router.navigate(['/privacy']);
    }

    routeHomePage(){
        this._router.navigate(['']);
    }
}
