import {Injectable} from "@angular/core";
import {AbstractBaseComponent} from "../app.baseComponent";
import {CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {UtilityHelper} from "../helpers/utilityHelper";
import {ActivatedRouteSnapshot} from "@angular/router";

@Injectable()
export class ChannelAccessGuard extends AbstractBaseComponent implements CanActivate {
    isChannelAccess: boolean = true;
    isArchive: string = '';

    constructor(private authService: AuthenticationHelper, private utility: UtilityHelper,
                private route: Router) {
        super();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn()) {
            let hasAccess = this.utility.getItem('isChannelAccess');
            let isTourPending = this.utility.getItem('isTourPending');
            this.isArchive = this.utility.getItem('isProjectUserArchived');
            if(state.url.includes("message_id")) {
                let channelId = state.url.match(/message_id=(.+)/);
                if(channelId){
                this.utility.setItem("messageId",channelId[1])
                }
            }
            if (([this.LOCAL_DOMAIN, this.MENTORING_DOMAIN].includes(this.utility.myDomain) && hasAccess == 'True')
                || isTourPending == 'true'  || this.isArchive === 'true') {
                return true;
            } else if ([this.ADMIN_DOMAIN, this.BRILLIANTCLUB_DOMAIN].includes(this.utility.myDomain)) {
                return true;
            } else {
                this.route.navigate(['']);
                return false;
            }
        } else {
            if (state.url) {
                let channelId = state.url.match(/channels\/(.+)/);
                if (channelId && channelId.length >= 2) {
                    this.route.navigate(['sign-in'], { queryParams : { 'channel_id': channelId[1] } });
                } else {
                    this.route.navigate([''])
                }
            } else {
                this.route.navigate(['']);
            }
        }
    }
}
