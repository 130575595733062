import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private authService: AuthenticationHelper, private route: Router) {

    }

    canActivate() {
        if (this.authService.isLoggedIn()) {
            return true;
        } else {
            this.route.navigate(['']);
            return false;
        }
    }
}