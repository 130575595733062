import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import * as _ from 'lodash';
import {take} from "rxjs/operators";

@Injectable()
export class CurrentUserStateService {

  private userStateSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  currentUserState$: Observable<any> = this.userStateSubject.asObservable();

  private isMatchedSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  isMatchedState$: Observable<any> = this.isMatchedSubject.asObservable();

  private isSuperAdminSubject = new BehaviorSubject<any>({});
  isSuperAdminState$: Observable<any> = this.isSuperAdminSubject.asObservable();

  private userRoleSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  userRoleState$: Observable<any> = this.userRoleSubject.asObservable();

  private supportChannelIdSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  supportChannelIdState$: Observable<any> = this.supportChannelIdSubject.asObservable();

  constructor() {
  }

  setCurrentUserState(userState: any) {
    let userStateKey: string = Object.keys(userState)[0];
    let currentUserStates = this.cloneUserState();
    currentUserStates[userStateKey] = userState[userStateKey];
    this.notifyState(currentUserStates);
  }

  setUserMatchedState(userState: any) {
    let matchStateKey: string = Object.keys(userState)[0];
    let currentmMtchState = this.cloneMatchState();
    currentmMtchState[matchStateKey] = userState[matchStateKey];
    this.notifyMatchState(currentmMtchState);
  }

  setSuperAdminState(userState: any) {
    let adminStateKey: string = Object.keys(userState)[0];
    let currentAdminState = this.cloneSuperAdminState();
    currentAdminState[adminStateKey] = userState[adminStateKey];
    this.notifySuperAdminState(currentAdminState);
  }

  setUserRoleState(userState: any) {
    let roleStateKey: string = Object.keys(userState)[0];
    let currentRoleState = this.cloneUserRoleState();
    currentRoleState[roleStateKey] = userState[roleStateKey];
    this.notifyUserRoleState(currentRoleState);
  }

  setSupportChannelIdState(userState: any) {
    let supportChannelIdKey: string = Object.keys(userState)[0];
    let currentSupportChannelIdState = this.cloneSupportChannelIdState();
    currentSupportChannelIdState[supportChannelIdKey] = userState[supportChannelIdKey];
    this.notifySupportChannelIdState(currentSupportChannelIdState);
  }

  private cloneSupportChannelIdState() {
    return _.clone(this.supportChannelIdSubject.getValue());
  }

  private notifySupportChannelIdState(userStates: any) {
    this.supportChannelIdSubject.next(userStates);
  }

  private cloneUserState() {
    return _.clone(this.userStateSubject.getValue());
  }

  private notifyState(userStates: any) {
    this.userStateSubject.next(userStates);
  }

  private cloneMatchState() {
    return _.clone(this.isMatchedSubject.getValue());
  }

  private notifyMatchState(userStates: any) {
    this.isMatchedSubject.next(userStates);
  }

  private cloneSuperAdminState() {
    return _.clone(this.isSuperAdminSubject.getValue());
  }

  private notifySuperAdminState(userStates: any) {
    this.isSuperAdminSubject.next(userStates);
  }

  private cloneUserRoleState() {
    return _.clone(this.userRoleSubject.getValue());
  }

  private notifyUserRoleState(userStates: any) {
    this.userRoleSubject.next(userStates);
  }

  isSuperAdmin() {
    let superAdminCheck: any = this.isSuperAdminSubject.getValue();
    return superAdminCheck.isSuperAdmin;
  }

  role() {
    let role : any = this.userRoleSubject.getValue();
    return role.role;
  }
}
