import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {UtilityHelper} from "../helpers/utilityHelper";
import {CurrentUserStateService} from "../appServices/current-user-state.service";
import {Observable, Subscription} from "rxjs";

@Injectable()
export class SuperAdminGuard implements CanActivate {
    isSuperAdmin$: Observable<any>;
    adminSub: Subscription;
    constructor(private authService: AuthenticationHelper, private utility: UtilityHelper, private route: Router,
                private currentUserStateService: CurrentUserStateService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                router: RouterStateSnapshot): | any
        | UrlTree
        | Promise<any | UrlTree>
        | Observable<any | UrlTree> {
        let bypass: boolean = false;
        this.isSuperAdmin$ = this.currentUserStateService.isSuperAdminState$;
        this.adminSub = this.isSuperAdmin$.subscribe(item => {
            if (item && item.isSuperAdmin) {
                if (this.authService.isLoggedIn() && item.isSuperAdmin) {
                    bypass =  true;
                } else {
                    this.route.navigate(['']);
                    bypass = false;
                }
            } else {
                this.route.navigate(['']);
                bypass = false;
            }
        });
        return bypass;
    }
}
