import { Injectable } from "@angular/core";
import { MentoringServices } from "app/appServices/mentoring";
import { ToastsManager } from "app/helpers/toastsManager";
import { UtilityHelper } from "app/helpers/utilityHelper";
import { BehaviorSubject, Subject } from "rxjs";
import * as Twilio from "twilio-video";
import { ConnectOptions } from "twilio-video";

@Injectable({
  providedIn: "root",
})
export class VideoChatService {
  private functionCallSource = new Subject<void>();
  functionCalled$ = this.functionCallSource.asObservable();


  private functionEditMeetScrren = new Subject<void>();
  functionEditMeetScrren$ = this.functionEditMeetScrren.asObservable();

  private functionCallSourceNew = new Subject<void>();
  functionCalledNew$ = this.functionCallSourceNew.asObservable();

  private functionCallSourceforEndCall = new Subject<void>();
  functionCallSourceforEndCall$ = this.functionCallSourceforEndCall.asObservable();

  private functionCallSourceforFetchChannelsMsg = new Subject<void>();
  functionCallSourceforFetchChannelsMsg$ = this.functionCallSourceforFetchChannelsMsg.asObservable();

  private functionCallSourcefortoggleLiveVideo = new Subject<void>();
  functionCallSourcefortoggleLiveVideo$ = this.functionCallSourcefortoggleLiveVideo.asObservable();

  private calenderCurrentValueSource = new BehaviorSubject<boolean>(false);
  calenderCurrentValue = this.calenderCurrentValueSource.asObservable();

  private videoIconCurrentValueSource = new BehaviorSubject<boolean>(false);
  videoIconCurrentValue = this.videoIconCurrentValueSource.asObservable();

  private ChatIconWhileCallCurrentValueSource = new BehaviorSubject<boolean>(false);
  chatIconWhileCallCurrentValue = this.ChatIconWhileCallCurrentValueSource.asObservable();

  private videoIconWhileCallCurrentValueSource = new BehaviorSubject<boolean>(false);
  videoIconWhileCallCurrentValue = this.videoIconWhileCallCurrentValueSource.asObservable();

  public showVideoModalPopUp = new BehaviorSubject<boolean>(false);
  showVideoModalPopUp$ = this.showVideoModalPopUp.asObservable();

  private channelNameSource = new BehaviorSubject<string>("");
  channelNameValue = this.channelNameSource.asObservable();
  isMobileDevice: boolean;
  videoHeight: number;
  videoWidth: number;
  liveRoomId: any; 

  constructor(
    private mentoringService: MentoringServices,
    private toastr: ToastsManager,private utility: UtilityHelper, 
  ) {}

  getCalenderCurrentValue(value: boolean) {
    this.calenderCurrentValueSource.next(value);
  }

  getChatIconWhileCallCurrentValue(value: boolean) {
    this.ChatIconWhileCallCurrentValueSource.next(value);
  }

  getVideoIconWhileCallCurrentValue(value: boolean) {
    this.videoIconWhileCallCurrentValueSource.next(value);
  }

  getVideoIconCurrentValue(value: boolean) {
    this.videoIconCurrentValueSource.next(value);
  }

  getChannelNameVakue(value: string) {
    this.channelNameSource.next(value);
  }

  geVideoModalDataToShow(value: boolean) {
    this.showVideoModalPopUp.next(value);
  } 

  callChannelsTopShowVideoCallIcon() {
    this.functionCallSource.next();
  }

  callChannelMethodAfterDisconnect() {
    this.functionCallSourceNew.next();
  }

  callAFterEndCall() {
    this.functionCallSourceforEndCall.next();
  }

  editMeetScreen() {
    this.functionEditMeetScrren.next();
  }

  FetchChannelsMsg() {
    this.functionCallSourceforFetchChannelsMsg.next();
  }

  toggleMethodWhileLiveCall() {
    this.functionCallSourcefortoggleLiveVideo.next();
  }


  async getAuthToken(payload) {
    try {
      const response = await this.mentoringService.getTwilioVideoAuth(payload).toPromise();
      return response;
    } catch (error) {
      this.getTwilioVideoAuthFail(error);
    }
  }
  
  async joinOrCreateRoom(data) {
    this.isMobileDevice = this.utility.checkForMobile(navigator.userAgent);
    if(this.isMobileDevice){
      this.videoHeight = 480
      this.videoWidth = 640 
    }else{
      this.videoHeight = 720
      this.videoWidth = 1280
    }
    let liveRoom: Twilio.Room = null;

    try {
      const auth = await this.getAuthToken(data);
      this.utility.setItem('schedule_call_id', auth.data.attributes.schedule_call_id);
      const authToken = auth.data.attributes.access_token;
      const roomSID = auth.data.attributes.room_sid;
      const roomName = auth.data.attributes.access_name;
      this.liveRoomId = auth.data.attributes.room_id
      liveRoom = await Twilio.connect(authToken, {
        dominantSpeaker: true,
        name: roomName,
        audio: true,
        maxAudioBitrate: 16000,
        video: { height: this.videoHeight, frameRate: 24, width: this.videoWidth},
        aspectRatio: 16/9,
        // bandwidthProfile: {
        //   video: {
        //     mode: "grid",
        //     maxSubscriptionBitrate: 2500000,
        //   },
        // },
        // networkQuality: { local: 1, remote: 1 },
      } as ConnectOptions);
    } catch (error) {
      this.toastr.error(`Error connecting to Room: ${error}`);
    }

    return liveRoom;
  }
  
  getTwilioVideoAuthFail(error) {}
}
