import { Injectable } from '@angular/core';
import * as Phoenix from './../../assets/javascript/phoenix.js';
import {UtilityHelper} from "../helpers/utilityHelper";
import {AppConstant} from "../app.constant";
import {MentoringServices} from "../appServices/mentoring";
import {MentoringEvents} from "../helpers/events";

@Injectable()
export class NotificationClient {
    window: any;
    socket: any;
    state: any;
    userNotifications: any;
    surveyNotifications: any;

    constructor(private utilityHelper: UtilityHelper, private mentoringEvents: MentoringEvents) {

    }

    ngOnInit() {
        this.fetchData();
    }

    fetchData() {
        let sessionToken: any = this.utilityHelper.getItem('token');
        if (!sessionToken) {
            return;
        } else {
            this.connect(sessionToken);
        }
    }

    connect(sessionToken) {
        let socketParams = {
            params: {
                session_token: sessionToken,
                project_id: this.utilityHelper.getItem('projectId')
            }
        };
        if (!this.socket) {
            let socketURL = AppConstant.MSG_SERVICE_URL;
            this.socket = new Phoenix.Socket(socketURL, socketParams);
            this.socket.connect();
        }
        this.join(sessionToken);
        this.render();
    }

    join(sessionToken) {
        let channel = this.socket.channel("notification:" + sessionToken, {});
        let presences = {};

        channel.on("presence_state", function (state) {
            Phoenix.Presence.syncState(presences, state);
        });

        channel.on("presence_diff", function (diff) {
            Phoenix.Presence.syncDiff(presences, diff);
        });

        if (!(channel.state === 'joined' || channel.state === "joining")) {
            channel.join().receive("ok", (function (_this) {
                return function (resp) {
                    return this.channel = channel;
                };
            })(this)).receive("error", (function (_this) {
                return function (resp) {
                };
            })(this));
        }

        channel.on("update", (function(_this) {
            return function(payload) {
                _this.state = payload;
                return _this.render();
            };
        })(this));

        channel.on('user_notification', (function(_this) {
            return function(payload) {
                _this.userNotifications = payload;
                return _this.renderUserNotificationCount();
            };
        })(this));

        channel.on('survey_notification', (function(_this) {
            return function(payload) {
                _this.surveyNotifications = payload;
                return _this.renderSurveyNotificationCount();
            };
        })(this));

        channel.on('matching_notification', (function(_this) {
            return function(payload) {
                return _this.updateMatchingNotification(payload);
            };
        })(this));

        channel.on('announcement_notification', (function(_this) {
            return function(payload) {
                return _this.updateAnnouncementNotification(payload);
            };
        })(this));
    }

    resetChannel(channel_id) {
        if (!this.state) {
            return false;
        } else {
            this.state[channel_id] = 0;
            this.render();
        }
    }

    render() {
        if (!this.state){
            return false;
        } else {
            let payload = this.state;
            if (payload) {
                this.mentoringEvents.setNotifications.emit( { notificationPayload: payload });
            }

            let any_messages = false;
            $.each(payload, function(channel_id: any, count) {
                let channel: any, notifcation_badge: any;
                any_messages = any_messages || count > 0;
                channel = $("#channels").find("li[data-channel-id='" + channel_id + "']");
                notifcation_badge = channel.find(".badge__pin--notifications");
                if (count > 0) {
                    notifcation_badge.html(count);
                    return notifcation_badge.css({
                        opacity: 1
                    });
                } else {
                    return notifcation_badge.css({
                        opacity: 0
                    });
                }
            });
            if (any_messages) {
                return $('body').addClass('state--unread-messages');
            } else {
                return $('body').removeClass('state--unread-messages');
            }
        }
    }

    renderUserNotificationCount() {
        if (!this.userNotifications) {
            return false;
        } else {
            if (this.userNotifications) {
                this.mentoringEvents.setUserNotifications.emit(this.userNotifications);
            }
        }
    }

    updateMatchingNotification(matchingEnabled) {
        this.mentoringEvents.sendMatchingotifications.emit(matchingEnabled);
    }

    updateAnnouncementNotification(announcementEvent) {
        this.mentoringEvents.sendAnnouncementNotification.emit(announcementEvent);
    }

    renderSurveyNotificationCount() {
        if (!this.surveyNotifications) {
            return false;
        } else {
            if (this.surveyNotifications) {
                this.mentoringEvents.setSurveyNotifications.emit(this.surveyNotifications);
            }
        }
    }

    disconnectSocket() {
        if(this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
            this.state = [];
        }
    }
}
