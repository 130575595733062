import {Component} from '@angular/core';
import {AbstractBaseComponent} from "../../../app.baseComponent";
import {ActivatedRoute, Router} from '@angular/router';
import {MentoringEvents} from "../../../helpers/events";
import {KnowledgeServices} from "../../../appServices/knowledgeService";
import * as _ from "lodash";
import {UtilityHelper} from "../../../helpers/utilityHelper";
import {ToastsManager} from "../../../helpers/toastsManager";
import {Observable} from "rxjs";
import {CurrentUserStateService} from "../../../appServices/current-user-state.service";
import { NgStyle, NgIf, NgClass, NgFor, AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'knowledgeModalPopUp',
    templateUrl: './knowledgeModalPopUp.component.html',
    styleUrls: ['./knowledgeModalPopUp.component.scss'],
    standalone: true,
    imports: [NgStyle, NgIf, NgClass, NgFor, AsyncPipe, DatePipe]
})

export class KnowledgeModalPopUpComponent extends AbstractBaseComponent {

    getCurrentNewsArticleDetails: any = {};
    serviceEvent: any;
    subscriptions: any = [];
    articles: any = [];
    displayFooter: boolean = true;
    displayArticleOrNewsArticle: boolean = false;
    displayCategories: boolean = false;
    modalPopUpTitle: any = '';
    categoryData: any = [];
    perPage: any = 13;
    pageNumber: any = 1;
    relatedArticlesData: any = [];
    newsArticlesData: any = [];
    subcategoryData: any = [];
    newsCategoryData: any = [];
    categoryId: any = '';
    isLoaded: boolean = false;
    isSubCategory: boolean = false;
    totalRelatedArticles: any = '';
    displaySeeMore: boolean = false;
    currentType: any = '';
    attachedLinks: any = [];
    debounceTimeOut: any = 200;
    debounceTimeOutForGAEvents: any = 500;
    isConnectionLandingPage: boolean = true;
    currentState$: Observable<any>;

    private debouncedAttachLink = _.debounce((event) => this.attachLink(event),
        this.debounceTimeOut, {});

    private debouncedArticlesLink = _.debounce((event) => this.openRelatedArticle(event),
        this.debounceTimeOut, {});

    private debouncedCategoriesLink = _.debounce((event, isSubCategory) => this.sendCategoriesDetails(event, isSubCategory),
        this.debounceTimeOut, {});

    constructor(private mentoringEvents: MentoringEvents,
                public router: Router,
                private knowledgeService: KnowledgeServices,
                private _toaster: ToastsManager,
                private routes: ActivatedRoute,
                private utility: UtilityHelper,
                private currentUserStateService: CurrentUserStateService) {
        super();
    }

    ngOnInit() {
        this.currentState$ = this.currentUserStateService.currentUserState$;
        $(window).on('resize orientationchange', () => {
            if (this.isLoaded) {
                this.setModalPopUpStyle();
            }
        });
    }

    setModalPopUpStyle() {
        const clientWidth: any = (document.body && document.body.clientWidth) ? document.body.clientWidth : 0;
        this.displayFooter = !(clientWidth < 768);
        const modalContentHeight: any = $(".modal-content-container").innerHeight() ? $(".modal-content-container").innerHeight() : 0;
        const modalHeaderHeight: any = $(".modal-header-container").outerHeight() ? $(".modal-header-container").outerHeight() : 0;
        const modalFooterHeight: any = $(".footer-container").outerHeight() ? $(".footer-container").outerHeight() : 0;
        if (modalContentHeight) {
            const newModalBodyHeight: any = (modalContentHeight - (modalHeaderHeight + modalFooterHeight)) + 'px';
            $(".modal-body-container").css('max-height', newModalBodyHeight);
        }

        if (!this.displayFooter) {
            const currentWindowHeight: any = document.body.clientHeight;
            $(".modal-content-container").outerHeight(0.916 * currentWindowHeight);
        }
    }

    ngAfterViewInit() {
        this.mentoringEvents.getDataToModal().subscribe(item => {
            if (item) {
                this.isConnectionLandingPage = !this.router.url.includes('channels');
                this.currentType = item.type;
                if ((item.type === 'news_articles'
                    || item.type === 'articles') && item.id) {
                    this.resetArticles();
                    this.categoryData = '';
                    this.getCurrentNewsArticleDetails = item;
                    this.modalPopUpTitle = this.getCurrentNewsArticleDetails.attributes.title;
                    this.articles = this.getCurrentNewsArticleDetails.relatedArticles;
                    this.displayCategories = false;
                    this.displayArticleOrNewsArticle = true;
                    this.isLoaded = true;
                } else if (item.type === 'category_data') {
                    this.resetCategories();
                    this.categoryData = item.categoryData;
                    this.relatedArticlesData = item.relatedArticleData;
                    this.newsArticlesData = item.newsArticleData;
                    this.subcategoryData = item.subcategoryData;
                    this.newsCategoryData = item.newsCategoryData;
                    this.totalRelatedArticles = item.totalRelatedArticles;
                    this.displayCategories = true;
                    this.displayArticleOrNewsArticle = false;
                    if (this.relatedArticlesData.length < this.totalRelatedArticles) {
                        this.displaySeeMore = true;
                    } else {
                        this.displaySeeMore = false;
                    }
                    if (item.parentCategoryName) {
                        this.isSubCategory = true;
                        this.modalPopUpTitle = `${item.parentCategoryName}: ${this.categoryData.attributes.title}`;
                    } else {
                        this.isSubCategory = false;
                        this.modalPopUpTitle = this.categoryData.attributes.title;
                    }
                    this.isLoaded = true;
                }

                if (this.pageNumber == 1) {
                    $('.modal-body').scrollTop(0);
                }
                this.setModalPopUpStyle();
            }
            this.checkAttachedLinksInChatInput();
        });

        this.mentoringEvents.getSyncedAttachedLinks().subscribe(item => {
            this.attachedLinks = item;
        });

        $('#showModalPopUp').on('hidden.bs.modal', () => {
            this.resetArticles();
            this.resetCategories();
        });
    }

    ngOnDestroy() {
        this.dispose();
    }

    dispose() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    resetCategories() {
        this.isLoaded = false;
        this.categoryData = '';
        this.relatedArticlesData = '';
        this.newsArticlesData = [];
        this.subcategoryData = [];
        this.articles = [];
        this.newsCategoryData = '';
        this.displayCategories = false;
        this.modalPopUpTitle = '';
        this.isSubCategory = false;
    }

    resetArticles() {
        this.isLoaded = false;
        this.getCurrentNewsArticleDetails = '';
        this.modalPopUpTitle = '';
        this.displayArticleOrNewsArticle = false;
        this.isSubCategory = false;
        this.pageNumber = 1;
        this.articles = [];
    }

    sendDataForLikeUnlikeNewsArticle(articleDetails) {
        if (articleDetails && articleDetails.id && articleDetails.type) {
            this.likeUnlikeArticle(articleDetails);
        }
    }

    openRelatedArticle(article) {
        const gaEvents: object = {
            eventCategory: 'Brightknowledge hits',
            eventAction: 'Open Article',
            transport: 'beacon'
        };
        _.debounce(() => {this.mentoringEvents.sendAnalyticsEvents(gaEvents)}, this.debounceTimeOutForGAEvents);
        this.isLoaded = false;
        this.mentoringEvents.sendSpecificDataToSidePanel(article);
    }

    openNewsArticle(newsArticlesData) {
        this.isLoaded = false;
        this.mentoringEvents.sendSpecificDataToSidePanel(newsArticlesData);
    }

    sendCategoriesDetails(category, isSubCategory) {
        const gaEvents: object = {
            eventCategory: 'Brightknowledge hits',
            eventAction: 'Open Category',
            transport: 'beacon'
        };
        _.debounce(() => {this.mentoringEvents.sendAnalyticsEvents(gaEvents)}, this.debounceTimeOutForGAEvents);
        this.isLoaded = false;
        if (isSubCategory) {
            category.parentCategoryName = this.categoryData.attributes.title;
        }
        this.mentoringEvents.sendSpecificDataToSidePanel(category);
    }

    showMoreArticle(category) {
        this.pageNumber++;
        category.relatedArticleData = this.relatedArticlesData;
        category.pageNumber = this.pageNumber;
        this.sendCategoriesDetails(category, this.isSubCategory);
    }

    attachLink(event) {
        if (this.getCurrentNewsArticleDetails &&
            this.getCurrentNewsArticleDetails.attributes &&
            this.getCurrentNewsArticleDetails.attributes.knowledge_url) {
            const knowledgeUrl: any = this.getCurrentNewsArticleDetails.attributes.knowledge_url;
            const chatInputBtn: any = $('#chat__input');
            const chatSendBtn: any = $('#chat__send-message');
            event.stopPropagation();
            if (this.attachedLinks.includes(knowledgeUrl)) {
                const replaceWith: any = `Read this article: ${knowledgeUrl.toString()}`;
                if ($('#chat__input') && $('#chat__input').length) {
                    $('#chat__input')[0].innerText =
                        $('#chat__input')[0].innerText.replace(replaceWith, '');
                }
                this.attachedLinks.splice(this.attachedLinks.indexOf(knowledgeUrl), 1);
                this._toaster.success('Link removed from your channel');
                const gaEvents: object ={
                    eventCategory: 'Copy article link',
                    eventAction: 'Remove Article',
                    transport: 'beacon'
                };
                this.mentoringEvents.sendAnalyticsEvents(gaEvents);
            } else {
                this.attachedLinks.push(knowledgeUrl);
                this.mentoringEvents.sendAttachedUrl(knowledgeUrl);
                this._toaster.success('Link copied to your channel');
                const gaEvents: object ={
                    eventCategory: 'Copy article link',
                    eventAction: 'Copy Article',
                    transport: 'beacon'
                };
                this.mentoringEvents.sendAnalyticsEvents(gaEvents);
            }

            if (chatInputBtn && chatInputBtn.length) {
                if (chatInputBtn[0].innerText.trim().length) {
                    chatSendBtn.prop('disabled', false);
                    chatSendBtn.removeClass('btn--disabled');
                } else {
                    chatInputBtn[0].innerText = '';
                    chatSendBtn.prop('disabled', true).removeClass('btn--disabled').addClass('btn--disabled');
                }
            }
        }
        this.mentoringEvents.syncAttachedLinks(this.attachedLinks);
        return false;
    }

    checkAttachedLinksInChatInput() {
        const chatInput: any = $('#chat__input');
        if (chatInput[0] && chatInput[0].innerText &&
            this.attachedLinks && this.attachedLinks.length) {
            this.attachedLinks.forEach((url, index) => {
                if (!chatInput[0].innerText.includes(url)) {
                    this.attachedLinks.splice(index, 1);
                }
            });
        } else if (this.attachedLinks && this.attachedLinks.length) {
            this.attachedLinks = [];
        }
    }

    likeUnlikeArticle(articleDetails) {
        const payload: any = {
            article_id: articleDetails.id,
            like_status: articleDetails.attributes.like_status ? 'unlike' : 'like',
            like_count: articleDetails.attributes.like_count,
        };

        let likeStatus: string = '';
        if(payload.like_status == 'like') {
            likeStatus = 'Like Article'
        } else if(payload.like_status == 'unlike') {
            likeStatus = 'Unlike Article'
        }

        const gaEvents: object = {
            eventCategory: 'Like article',
            eventAction: likeStatus,
            transport: 'beacon'
        };
        this.mentoringEvents.sendAnalyticsEvents(gaEvents);
        const url: any = `${articleDetails.type}/${payload.article_id}/${payload.like_status}`;
        payload.type = articleDetails.type;
        this.knowledgeService.sendLikeAndUnlikeArticle(url).subscribe(
            (articleData) => this.sendLikeAndUnlikeArticleSuccess(articleData, payload),
            error => this.sendLikeAndUnlikeArticleFail(error),
        );
    }

    sendLikeAndUnlikeArticleSuccess(articleData, payload) {
        if (payload && payload.like_status) {
            this.updateLikeButton(payload);
            this.mentoringEvents.syncLikeAndUnlike(payload);
        }
    }

    sendLikeAndUnlikeArticleFail(error) {

    }

    updateLikeButton(data) {
        const articleId = data.article_id;
        const likeStatus = data.like_status;
        const articlesData: any = this.getCurrentNewsArticleDetails;
        if (articleId == articlesData.id) {
            if (likeStatus === 'unlike') {
                articlesData.attributes.like_status = false;
                articlesData.attributes.like_count--;
            } else {
                articlesData.attributes.like_status = true;
                articlesData.attributes.like_count++;
            }
            return true;
        }
    }

    closeModal() {
        let showModalPopUp: any = $('#showModalPopUp');
        showModalPopUp.modal('hide');
    }
}
