import {Component, HostListener, ElementRef, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {UtilityHelper} from "../../../helpers/utilityHelper";
import {Router, RouterModule} from "@angular/router";
import {GlobalState} from "../../../global.state";
import {layoutSizes} from '../../../theme';
import {ApplicationServices} from "../../../appServices/application";
import {BaThemeSpinner} from "../../services/baThemeSpinner/baThemeSpinner.service";
import {MentoringEvents} from "../../../helpers/events";
import {MentoringServices} from "../../../appServices/mentoring";
import {AbstractBaseComponent} from "../../../app.baseComponent";
import {JoyrideModule, JoyrideService} from "ngx-joyride";
import {ToastsManager} from "../../../helpers/toastsManager";
import {Observable, Subscription} from "rxjs";
import {CurrentUserStateService} from "../../../appServices/current-user-state.service";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule, NgClass, NgIf, NgStyle } from '@angular/common';
import { ChannelListComponent } from 'app/pages/channelList/channelList.component';
import { ProjectSwitcher } from '../projectSwitcher';

@Component({
    selector: 'left-side-menu',
    styleUrls: ['leftSideMenu.scss', './../../../../assets/css/unreadMessages.css'],
    templateUrl: 'leftSideMenu.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, TranslateModule, NgStyle, NgClass, RouterModule, CommonModule, ChannelListComponent,JoyrideModule,ProjectSwitcher]
})
export class LeftSideMenu extends AbstractBaseComponent {

    role: string = '';
    firstName: string = '';
    lastName: string = '';
    communityPrimaryBgColor: string = '';
    communityProjectName: string = '';
    askTheGraduate: string = '';
    assignmentsEnabled: boolean = false;
    communityEnabled: boolean = false;
    externalMaterialsEnabled: boolean = false;
    activitiesEnabled: boolean = false;
    isAdmin: boolean = false;
    public menuHeight:number;
    public isMenuCollapsed:boolean = false;
    public isMenuShouldCollapsed:boolean = false;
    selectedMenu: any = {};
    isSuperAdmin: any;
    knowledgeUrl: any = '';
    deviceWidth: any;
    isBrilliantClub: any = false;
    surveyData: any = [];
    isSurvey: boolean = false;
    isChannelAccess: boolean = false;
    projectId: any = '';
    tutorId: any;
    userId: any = '';
    adminURL: any = '';
    isProjectSwitcherOpen: boolean = false;
    changeProjectNameFontSize: boolean = false;
    logoId: any = '';
    partnerDetails: string = '';
    partnerName: string = '';
    isMobile: boolean = false;
    isMatched: boolean = false;
    subscriptions: any = [];
    isArchive: boolean = false;
    observableSubscription: Subscription;
    superAdminSub: Subscription;
    roleSub: Subscription;
    currentState$: Observable<any>;
    resizeSub = null;
    isSuperAdmin$: Observable<any>;
    role$: Observable<any>;
    isSurveyCompleted: boolean = false;
    ToasterMessage: any=[];
    isVideoLive: boolean;
    mesaageToasterWhileClickOnSignOutButton: any;

    constructor(private applicationService: ApplicationServices, public utility: UtilityHelper, private router: Router,
                private _elementRef:ElementRef, private _state:GlobalState, public joyrideService: JoyrideService,
                private _spinner: BaThemeSpinner, private mentoringEvents: MentoringEvents,
                private toastr: ToastsManager, private mentoringService: MentoringServices,
                private cd: ChangeDetectorRef, private currentUserStateService: CurrentUserStateService,
                private translate: TranslateService,) {
        super();
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
        this.projectId = this.utility.getItem('projectId');
        this.userId = this.utility.getItem('userId');
        this.isBrilliantClub = this.utility.myDomain == this.BRILLIANTCLUB_DOMAIN;
        this.isSuperAdmin$ = this.currentUserStateService.isSuperAdminState$;
        this.role$ = this.currentUserStateService.userRoleState$;
        // this.roleSub = this.role$.subscribe(item => {
        //     if (item && item.role) {
        //        this.getData();
        //    }
        // });
        let url = this.utility.getItem('mentoringUrl') ? this.utility.getItem('mentoringUrl') : this.MENTORING_URL;
        let knowledgeBaseUrl = url.substring(0, url.length-1);
        this.knowledgeUrl = this.BRIGHT_KNOWLEDGE_URL + 'sign-in?partner_url='+ knowledgeBaseUrl;
        this.resizeSub = $(window).resize(() => {
            this.getDeviceWidth();
        });
        this.getDeviceWidth();
        this.logoId = '';
        this.utility.openAdmin = this.utility.getItem('open_admin') ? true : false;
    }
    
    getDeviceWidth(){
        this.deviceWidth = $(window).width();
        if(this.deviceWidth < 768) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }

    public toggleCellMenu() {
        let isMenuCollapsed = true;
        if (this.deviceWidth < 1200 && $(".display-icon").css('display') == 'block') {
            this._state.notifyDataChanged('menu.isCollapsed', isMenuCollapsed);
        }
    }

    public ngOnInit(): void {
        if (this._shouldMenuCollapse()) {
            this.menuCollapse();
        }
        this.checkAndChangeFontSizeOfProjectName();
        this.eventSubscribtions();
        let sub: any = this.mentoringEvents.getMentorMatchedData().subscribe(item => {
            if (item) {
                this.isMatched = true;
            } else {
                this.isMatched = false;
            }
        });
        this.subscriptions.push(sub);
        this.translate.get('leftSideMenu').subscribe((value: any) => {
            this.ToasterMessage = value
        });

        this.translate.get('baPageTop.MesaageToasterWhileClickOnSignOutButton').subscribe((value: any) => {
            this.mesaageToasterWhileClickOnSignOutButton = value
        });
    }

    ngOnDestroy(){
        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach((sub) => {
                sub.unsubscribe();
            });
        }
        if (this.observableSubscription) {
            this.observableSubscription.unsubscribe();
        }

        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }

        if (this.superAdminSub) {
            this.superAdminSub.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.mentoringEvents.getOpenVideoContainer().subscribe( item =>{
            if (item) {
                this.isVideoLive = true;
            } else {
                this.isVideoLive = false;
            }
        })
        this.roleSub = this.role$.subscribe(item => {
            if (item && item.role) {
               this.getData();
           }
        });
        this.getPartnerLogo();
        this.cd.detectChanges();
    }

    eventSubscribtions() {
        this.mentoringEvents.getOpenProjectSwitcher().subscribe(item => {
            if (item) {
                this.isProjectSwitcherOpen = true;
            } else {
                this.isProjectSwitcherOpen = false;
            }
        });

        this.mentoringEvents.getChangedProfileData().subscribe(item => {
            if (item) {
                this.firstName = '';
                this.lastName = ''
                this.firstName = this.utility.getItem('firstName');
                this.lastName = this.utility.getItem('lastName');
            }
        });

        this.mentoringEvents.getSelectedMenu().subscribe(item => {
            if(item) {
                this.selectedMenu = {};
                this.selectedMenu[item] = true;
            }
        });

        this.mentoringEvents.getRemovedMenu().subscribe(item => {
            if(item) {
                this.selectedMenu = {};
            }
        });

        this.mentoringEvents.getProjectSwitchedContent().subscribe( item => {
            if(item) {
                this.checkAndChangeFontSizeOfProjectName();
                this.roleSub = this.role$.subscribe(item => {
                    if(item && item.role){
                        this.getData();
                    }
                });
                if(!item.reload) {
                    this.utility.openAdmin = false;
                    this.utility.removeItem('open_admin');
                }
                this.getPartnerLogo();
            }
        });
    }

    checkAndChangeFontSizeOfProjectName() {
        this.changeProjectNameFontSize = false;
        if (this.utility.getItem('projectName')) {
            this.utility.getItem('projectName').split(" ").some( (name) => {
                if (name.length > 12) {
                    this.changeProjectNameFontSize = true;
                    return true;
                }
            } );
        }
    }

    @HostListener('window:resize')
    public onWindowResize():void {

        var isMenuShouldCollapsed = this._shouldMenuCollapse();

        if (this.isMenuShouldCollapsed !== isMenuShouldCollapsed) {
            this.menuCollapseStateChange(isMenuShouldCollapsed);
        }
        this.isMenuShouldCollapsed = isMenuShouldCollapsed;
        this.updateSidebarHeight();
    }

    public menuExpand():void {
        this.menuCollapseStateChange(false);
    }

    public menuCollapse():void {
        this.menuCollapseStateChange(true);
    }

    public menuCollapseStateChange(isCollapsed:boolean):void {
        this.isMenuCollapsed = isCollapsed;
        this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    }

    public updateSidebarHeight(): void {
        // TODO: get rid of magic 84 constant
        if (this._elementRef && this._elementRef.nativeElement &&
            this._elementRef.nativeElement.childNodes &&
            this._elementRef.nativeElement.childNodes.length) {
            this.menuHeight = this._elementRef.nativeElement.childNodes[0].clientHeight - 84;
        }
    }

    private _shouldMenuCollapse():boolean {
        return window.innerWidth <= layoutSizes.resWidthCollapseSidebar;
    }

    getJWT_Token(){
        let body = {
            "data":{
                "type":"sso_tokens",
                "attributes":{}
            }
        };
        this.applicationService.getsso_tokens(body).subscribe(
            data => this.getsso_tokensSuccess(data),
            error => this.getsso_tokensFail(error)
        );
    }

    getsso_tokensSuccess(data){
        let token;
        if(data && data.data.attributes.token) {
            token = data.data.attributes.token;
            this.utility.setItem('jwtToken', token);
            this.knowledgeUrl = this.BRIGHT_KNOWLEDGE_URL + 'session?jwt=' + token;
            if (this.isSuperAdmin || this.isAdmin) {
                let project_id = this.utility.getItem('projectId');
                this.adminURL = '';
                this.adminURL = this.ADMIN_DASHBOARD_URL + 'session?jwt=' + token + '&current_project_id=' + project_id;
            }
        }
    }

    getsso_tokensFail(error){
    }

    getData() {
        this.isSuperAdmin = this.currentUserStateService.isSuperAdmin();
        this.role = this.currentUserStateService.role();
        if (this.role == 'admin' || this.role == 'coordinator') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        this.projectId = this.utility.getItem('projectId');
        this.getCurrentSurveyInformation();
        this.role = '';
        this.firstName = '';
        this.communityPrimaryBgColor = '';
        this.communityProjectName = '';
        this.askTheGraduate = '';
        this.userId = '';
        if (this.isBrilliantClub) {
            // if(this.utility.getItem('role') == 'mentor')
            //     this.role = 'Tutor';
            // if(this.utility.getItem('role') == 'mentee')
            //     this.role = 'Tutee';
            // if(this.utility.getItem('role') == 'coordinator')
            //     this.role = 'coordinator';
            // if(this.utility.getItem('projectName')) {
            //     this.communityProjectName = this.utility.getItem('projectName').replace(/\d+/g, '');
            // }
        } else {
            this.communityProjectName = this.utility.getItem('projectName');
        }
        this.firstName = this.utility.getItem('firstName');
        this.communityPrimaryBgColor = this.utility.getItem('primaryBgColor');
        this.askTheGraduate = this.utility.getItem('graduateUrl');
        this.userId = this.utility.getItem('userId');
        this.communityEnabled = this.utility.getItem('community_enabled') ? true : false;
        this.externalMaterialsEnabled = this.utility.getItem('external_materials_enabled') ? true : false;
        this.activitiesEnabled = this.utility.getItem('activities_enabled') ? true : false;
        if (this.isBrilliantClub && this.role == 'Tutee') {
            this.getTutorDetailsForTutee(true);
        }
        this.currentState$ = this.currentUserStateService.currentUserState$;
        this.observableSubscription = this.currentState$.subscribe(item => {
            this.isArchive = item.isArchive;
        })
        this.getJWT_Token();
    }

    navigateToChannels() {
        if((this.utility.getItem('isChannelAccess') == 'True') || this.isArchive) {
            this.router.navigate(['channels']);
        } else if(!this.isArchive) {
            this.toastr.error(this.ToasterMessage.youWillNotBeAbleToAccessYourMessagingChannel)
        }
    }

    navigateToFinalAssignments() {
        if(!this.isArchive) {
            this.toastr.error(this.ToasterMessage.youWillNotBeAbleToAccessFinalAssignment)
        }
    }

    navigateAssignments() {
        if((this.utility.getItem('isChannelAccess') == 'True') || this.isArchive) {
            this.router.navigate(['assignments']);
        } else if (!this.isArchive) {
            this.toastr.error(this.ToasterMessage.youWillNotBeAbleToAccessAssignments)
        }
    }

    navigateToMyTutor() {
        this.tutorId = this.utility.getItem('tutorId');
        if (this.utility.getItem('projectUserId') && this.tutorId) {
            this.router.navigate(['/project_users/'+ this.tutorId]);
        } else if (this.isBrilliantClub && this.role == 'Tutee') {
            this.getTutorDetailsForTutee();
        }
    }

    getTutorDetailsForTutee(isDefault?) {
        if (this.utility.getItem('projectUserId')) {
            let userDeatilsUrl = this.utility.getItem('projectUserId') + "?include=user,external";
            this.mentoringService.getNotificationStatus(userDeatilsUrl).subscribe(
                data => this.getTutorDetailsForTuteeSuccess(data, isDefault),
                error => this.getTutorDetailsForTuteeFail(error)
            );
        }
    }

    getTutorDetailsForTuteeSuccess(data, isDefault?) {

        if (data.data.attributes.matches.length) {
            this.utility.setItem('tutorId', data.data.attributes.matches[0].mentor_id.toString());
            this.tutorId = data.data.attributes.matches[0].mentor_id.toString();
        }

        if (!isDefault) {
            if (this.tutorId){
                this.navigateToMyTutor();
            }
        }
    }

    getTutorDetailsForTuteeFail(error) {
        this.router.navigate(['/channels']);
    }

    getCurrentSurveyInformation() {
        let surveyPageUrl = '?filter[project_id]=' + this.projectId;
        this.mentoringService.getSurvey(surveyPageUrl).subscribe(
            data => this.getCurrentSurveyInformationSuccess(data),
            error => this.getCurrentSurveyInformationFail(error)
    );
        
        // let data;
        // this.mentoringEvents.getProjectUsersData().subscribe(item => {
        //     data = item;
        //     if (data && data.data.length) {
        //         if (data.data[0].attributes.is_archived) {
        //             this.utility.setItem('isProjectUserArchived', true);
        //         } else {
        //             this.utility.setItem('isProjectUserArchived', false);
        //         }
        //         this.surveyData = data.data[0];
        //         if (this.surveyData.attributes["survey_state"] == 'unstarted' && this.surveyData.attributes["survey_compulsory?"] && this.utility.getItem('role') == 'mentee') {
        //             this.isSurveyCompleted = false;
        //         }else{
        //             this.isSurveyCompleted = true;
        //         }
        //         if (this.surveyData.attributes["survey_state"] == 'unstarted' && this.surveyData.attributes["has_survey?"] && this.surveyData.attributes["survey_compulsory?"] && this.role =='mentee') {
        //             this.isChannelAccess = false;
        //             this.isSurvey = true;
        //         } else if (this.surveyData.attributes["survey_state"] == 'unstarted' && this.surveyData.attributes["has_survey?"] && this.role =='mentee') {
        //             this.isSurvey = true;
        //             this.isChannelAccess = true;
        //         } else if (this.surveyData.attributes["survey_state"] == 'completed' && this.surveyData.attributes["has_survey?"] && this.role =='mentee'){
        //             this.isSurvey = false;
        //             this.isChannelAccess = true;
        //         } else {
        //             this.isSurvey = false;
        //             this.isChannelAccess = true;
        //         }
        //         let hasAccess = this.isChannelAccess ? 'True' : 'False';
        //         this.utility.setItem('isChannelAccess', hasAccess);
        //         this.utility.setItem('isTourDisplay', this.isSurveyCompleted)
        //     }
        // });
    }

    getCurrentSurveyInformationSuccess(data) {
        if (data && data.data.length) {
            if (data.data[0].attributes.is_archived) {
                this.utility.setItem('isProjectUserArchived', true);
            } else {
                this.utility.setItem('isProjectUserArchived', false);
            }
            this.surveyData = data.data[0];
            if (this.surveyData.attributes["survey_state"] == 'unstarted' && this.surveyData.attributes["survey_compulsory?"] && this.utility.getItem('role') == 'mentee') {
                this.isSurveyCompleted = false;
            }else{
                this.isSurveyCompleted = true;
            }
            if (this.surveyData.attributes["survey_state"] == 'unstarted' && this.surveyData.attributes["has_survey?"] && this.surveyData.attributes["survey_compulsory?"] && this.role =='mentee') {
                this.isChannelAccess = false;
                this.isSurvey = true;
            } else if (this.surveyData.attributes["survey_state"] == 'unstarted' && this.surveyData.attributes["has_survey?"] && this.role =='mentee') {
                this.isSurvey = true;
                this.isChannelAccess = true;
            } else if (this.surveyData.attributes["survey_state"] == 'completed' && this.surveyData.attributes["has_survey?"] && this.role =='mentee'){
                this.isSurvey = false;
                this.isChannelAccess = true;
            } else {
                this.isSurvey = false;
                this.isChannelAccess = true;
            }
            let hasAccess = this.isChannelAccess ? 'True' : 'False';
            this.utility.setItem('isChannelAccess', hasAccess);
            this.utility.setItem('isTourDisplay', this.isSurveyCompleted)
        }
    }

    getCurrentSurveyInformationFail(error) {
        this._spinner.hide();
    }

    switchCurrentProject() {
        if (this.isVideoLive) {
            this.toastr.warning("Please complete your meeting to switch project.")
        } else {
            if (this.isProjectSwitcherOpen) {
                this.mentoringEvents.setOpenProjectSwitcher(false);
            } else {
                this.mentoringEvents.setOpenProjectSwitcher(true);
            }
        }
    }

    displayEndTourPopup() {
        this.router.navigate(['/channels'])
    }

    getPartnerLogo() {
        const partnerLogo: any = this.utility.getItem('partnerLogoId');
        const partnerName: string = this.utility.getItem('partnerName');
        this.logoId = partnerLogo ? this.IMG_SERVER_CDN + partnerLogo : '';
        this.partnerName = partnerName ? partnerName : '';
    }

    closeTour() {
        this.utility.isTourStarted = false;
        if (this.utility.triggerTour) {
            this.utility.triggerTour = false;
        } else {
            this.utility.triggerTour = false;
            this.router.navigate(['/channels']);
        }
    }
    signOutSubmit() {
        if(this.isVideoLive) {
            this.toastr.warning(this.mesaageToasterWhileClickOnSignOutButton)
        } else {
            this.router.navigate(['/signout']);
        }
    }
 }
