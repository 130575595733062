import {environment} from '../environments/environment';

export abstract class AbstractBaseComponent {

    ADMIN_DOMAIN: string = 'admin';
    BRILLIANTCLUB_DOMAIN: string = 'brilliantclub';
    KNOWLEDGE_DOMAIN: string = 'knowledge';
    LOCAL_DOMAIN: string = 'localhost';
    MENTORING_DOMAIN: string = 'mentoring';
    REACHING_WIDER_DOMAIN = 'reachingwider'
    ADMIN_DASHBOARD_URL: string = environment.adminDashboardUrl;
    BRIGHT_KNOWLEDGE_URL: string = environment.brightKnowledgeUrl;
    MENTORING_URL: string = environment.mentoringUrl;
    BRIGHTSIDE_URL: string = environment.brightsideUrl;
    BASE_URL_FOR_IMG_UPLOAD: string = environment.baseUrlForImgUpload;
    IMG_SERVER_CDN: string = environment.imgServerCDN;
    ALGOLIA_ARTICLE_OPTIONS = environment.algoliaArticleOptions;
    ALGOLIA_CATEGORY_OPTIONS = environment.algoliaCategoryOptions;
    ALGOLIA_NEWS_ARTICLE_OPTIONS = environment.algoliaNewsArticleOptions;
    KNOWLEDGE_PROJECT_CODE: string = environment.knowledgeProjectCode;
    TYPEFORM_SURVEY_URL: string = environment.typeFormSurveyLink;
    ENCRYPT_DECRYPT_CODE: string = environment.encyptDecryptKey;
}
