import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { errorHandlerFactory, HttpLoaderFactory } from './app/app.module';
import {environment} from "./environments/environment";
import { App } from './app/app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CookieModule } from 'ngx-cookie';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { JoyrideModule } from 'ngx-joyride';
import { routes } from './app/pages/pages.routing';
import { NgaModule } from './app/theme/nga.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideRouter, RouterModule } from '@angular/router';
import { withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { DynamicURLHelper } from './app/helpers/dynamicURLHelper';
import { DraftMessageTextHelper } from './app/helpers/draftMessageTextHelper';
import { SignupHelper } from './app/helpers/signupHelper';
import { CurrentUserStateService } from './app/appServices/current-user-state.service';
import { ToastsManager } from './app/helpers/toastsManager';
import { CertificateHelper } from './app/helpers/certificateHelper';
import { MenteeAccessGuard } from './app/routingGuards/menteeAccess.guard';
import { ImageHelper } from './app/helpers/imageHelper';
import { TextHelper } from './app/helpers/textHelper';
import { DateAndTime } from './app/helpers/dateAndTime';
import { MentoringSocketService } from './app/messageServices/mentoringSocketService';
import { MentoringMessagingComponent } from './app/messageServices/mentoringMessagingComponent';
import { AssignmentGuard } from './app/routingGuards/assignment.guard';
import { NavigationGuard } from './app/routingGuards/navigation.guard';
import { AdminRedirectionGuard } from './app/routingGuards/adminRedirection.guard';
import { KnowledgeGuard } from './app/routingGuards/knowledge.guard';
import { KnowledgeServices } from './app/appServices/knowledgeService';
import { BrilliantGuard } from './app/routingGuards/brilliant.guard';
import { MentoringEvents } from './app/helpers/events';
import { AdminServices } from './app/appServices/adminService';
import { ReportHelper } from './app/helpers/reportHelper';
import { UtilityHelper } from './app/helpers/utilityHelper';
import { MentoringServices } from './app/appServices/mentoring';
import { ApplicationServices } from './app/appServices/application';
import { HttpClientHelper } from './app/appServices/apiClient';
import { AuthenticationHelper } from './app/helpers/authenticationHelper';
import { ChannelAccessGuard } from './app/routingGuards/channelAccess.guard';
import { Attachments } from './app/messageServices/attachment';
import { NotificationClient } from './app/messageServices/notificationClient';
import { MessageComponent } from './app/messageServices/components';
import { SocketService } from './app/messageServices/socketService';
import { MentoringGuard } from './app/routingGuards/mentoring.guard';
import { SuperAdminGuard } from './app/routingGuards/superAdmin.guard';
import { AdminGuard } from './app/routingGuards/admin.guard';
import { AdminMentoringGuard } from './app/routingGuards/adminMentoring.guard';
import { PreLoginGuard } from './app/routingGuards/preLogIn.guard';
import { LoginGuard } from './app/routingGuards/logIn.guard';
import { GlobalState } from './app/global.state';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { BarController, Color, Legend } from 'chart.js';
import { QuillModule } from 'ngx-quill';

const APP_PROVIDERS = [
    GlobalState,
    LoginGuard,
    PreLoginGuard,
    AdminMentoringGuard,
    AdminGuard,
    SuperAdminGuard,
    MentoringGuard,
    SocketService,
    MessageComponent,
    NotificationClient,
    Attachments,
    ChannelAccessGuard,
    AuthenticationHelper,
    HttpClientHelper,
    ApplicationServices,
    MentoringServices,
    UtilityHelper,
    ReportHelper,
    AdminServices,
    MentoringEvents,
    BrilliantGuard,
    KnowledgeServices,
    KnowledgeGuard,
    AdminRedirectionGuard,
    NavigationGuard,
    AssignmentGuard,
    MentoringMessagingComponent,
    MentoringSocketService,
    DateAndTime,
    TextHelper,
    ImageHelper,
    MenteeAccessGuard,
    CertificateHelper,
    ToastsManager,
    CurrentUserStateService,
    SignupHelper,
    DraftMessageTextHelper,
    DynamicURLHelper,
];



if (environment.production) {
    enableProdMode();
}


bootstrapApplication(App, {
    providers: [
        provideRouter(routes),
        importProvidersFrom(
        BrowserModule, FormsModule, ReactiveFormsModule, NgaModule.forRoot(), JoyrideModule.forRoot(), JoyrideModule.forChild(), ToastNoAnimationModule.forRoot(), CookieModule.forRoot(),QuillModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })),
        APP_PROVIDERS,
        [{ provide: APP_BASE_HREF, useValue: '/' }],
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        provideCharts(withDefaultRegisterables()),
        provideCharts({ registerables: [BarController, Legend] }),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ],  
});
