import {Component, ElementRef, Input, OnInit, SimpleChanges} from '@angular/core';
import instantsearch from 'instantsearch.js/dist-es5-module/src/lib/main';
import {connectHits} from 'instantsearch.js/es/connectors/index';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractBaseComponent} from "../../../app.baseComponent";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgStyle, NgFor, AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { NgaModule } from 'app/theme/nga.module';

@Component({
    selector: 'knowledge-search',
    templateUrl: './knowledgeSearch.component.html',
    styleUrls: ['./knowledgeSearch.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, FormsModule, NgFor, AsyncPipe,CommonModule,
        ReactiveFormsModule,NgaModule,NgFor,NgIf]
})

export class KnowledgeSearchComponent extends AbstractBaseComponent implements OnInit {

    articleSearch: any;
    categorySearch: any;
    newsArticleSearch: any;
    articleHits = new BehaviorSubject(null);
    categoryHits = new BehaviorSubject(null);
    newsArticleHits = new BehaviorSubject(null);
    arrowKeyLocation: any = -1;
    routerEvents: any;
    @Input() isHomeForSearch: boolean = true;
    searchString: any = '';
    searchedQuery: any = '';
    searchStarted: boolean = false;
    instantsearch: any;

    constructor(private router: Router, private elRef: ElementRef, private routes: ActivatedRoute) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.isHomeForSearch = changes.isHomeForSearch.currentValue;
    }

    ngOnInit() {
        if (this.routes.snapshot.queryParams['q']) {
            this.searchString = this.routes.snapshot.queryParams['q'];
        }
        this.configureSearch();
    }

    configureSearch() {

        this.articleSearch = instantsearch(this.ALGOLIA_ARTICLE_OPTIONS);
        this.categorySearch = instantsearch(this.ALGOLIA_CATEGORY_OPTIONS);
        this.newsArticleSearch = instantsearch(this.ALGOLIA_NEWS_ARTICLE_OPTIONS);

        let searchBoxConfig: any = instantsearch.widgets.searchBox({
            container: '.searchForum',
            reset: false,
            magnifier: false,
            wrapInput: true,
            autofocus: true,
        });

        let searchConfig: any = instantsearch.widgets.configure({
            highlightPreTag: '<strong>',
            highlightPostTag: '</strong>'
        });

        // Container for the search box
        this.articleSearch.addWidget(searchBoxConfig);
        this.categorySearch.addWidget(searchBoxConfig);
        this.newsArticleSearch.addWidget(searchBoxConfig);

        // Configuration for adding the strong tag in place of the em tags for highlighting results
        this.articleSearch.addWidget(searchConfig);
        this.categorySearch.addWidget(searchConfig);
        this.newsArticleSearch.addWidget(searchConfig);

        // To store the searched query
        this.articleSearch.addWidget(
            instantsearch.widgets.analytics({
                pushFunction: (query, state, results) => {
                    this.searchedQuery = results.query;
                },
                delay: 1000
            })
        );

        // initialize custom hits widget
        let customHits = connectHits(this.renderFn);
        this.articleSearch.addWidget(
            customHits({
                subject: this.articleHits
            })
        );

        this.categorySearch.addWidget(
            customHits({
                subject: this.categoryHits
            })
        );

        this.newsArticleSearch.addWidget(
            customHits({
                subject: this.newsArticleHits
            })
        );

        // On every search resets the arrow location
        this.articleSearch.on('render', function (response) {
            this.arrowKeyLocation = 0;
        });

    }

    startSearch() {
        this.articleSearch.start();
        this.categorySearch.start();
        this.newsArticleSearch.start();
    }

    renderFn(HitsRenderingOptions) {
        HitsRenderingOptions.widgetParams.subject.next(HitsRenderingOptions.hits);
    }

    keyDown(event: any) {

        let isArrowUpOrArrowDown: boolean = false;

        if (!this.searchStarted) {
            this.startSearch();
            this.searchStarted = true;
        }

        if (this.searchString) {
            let resultsDropDownElement = this.elRef.nativeElement.querySelector('.search-results-container');
            let articleResultsLength: any = 0;
            let categoryeResultsLength: any = 0;
            let newsArticleResultsLength: any = 0;
            let searchResultsLength: any = 0;

            if (this.articleHits['_value']) {
                articleResultsLength = this.articleHits['_value'].length;
            }
            if (this.categoryHits['_value']) {
                categoryeResultsLength = this.categoryHits['_value'].length;
            }
            if (this.newsArticleHits['_value']) {
                newsArticleResultsLength = this.newsArticleHits['_value'].length;
            }


            searchResultsLength = articleResultsLength + categoryeResultsLength + newsArticleResultsLength;

            if (searchResultsLength) {
                switch (event.keyCode) {
                    case 38: // this is the ascii of arrow up
                        if (this.arrowKeyLocation >= 0) {
                            this.arrowKeyLocation--;
                        } else {
                            this.arrowKeyLocation = (searchResultsLength) - 1;
                        }
                        isArrowUpOrArrowDown = true;
                        break;
                    case 40: // this is the ascii of arrow down
                        if (this.arrowKeyLocation < (searchResultsLength) - 1) {
                            this.arrowKeyLocation++;
                        } else {
                            this.arrowKeyLocation = -1;
                        }
                        isArrowUpOrArrowDown = true;
                        break;
                    // if backspace or delete button is pressed then reset the arrow location
                    case 8:
                        this.arrowKeyLocation = -1;
                        this.searchedQuery = '';
                        break;
                    case 46:
                        this.arrowKeyLocation = -1;
                        this.searchedQuery = '';
                        break;
                }

                let searchResults: any = [];
                let articleHits: any = this.articleHits['_value'];
                let categoryHits: any = this.categoryHits['_value'];
                let newsArticleHits: any = this.newsArticleHits['_value'];

                if (articleHits[0] && !categoryHits[0] && !newsArticleHits[0]) {
                    searchResults = articleHits;
                } else if (categoryHits[0] && !articleHits[0] && !newsArticleHits) {
                    searchResults = categoryHits;
                } else if (newsArticleHits[0] && !articleHits[0] && !categoryHits[0]) {
                    searchResults = newsArticleHits;
                } else {
                    searchResults = (articleHits.concat(categoryHits)).concat(newsArticleHits);
                }

                if (isArrowUpOrArrowDown) {
                    if (searchResults[0] && this.arrowKeyLocation >= 0 && this.arrowKeyLocation < searchResultsLength) {
                        this.searchString = searchResults[this.arrowKeyLocation].title;
                    } else if (this.arrowKeyLocation == -1 && this.searchedQuery && this.searchString !== this.searchedQuery) {
                        this.searchString = this.searchedQuery;
                    }
                }

            }

            if (this.searchString && resultsDropDownElement) {
                if (resultsDropDownElement.style.display == 'none') {
                    resultsDropDownElement.style.display = 'block';
                }
            }
        }
    }

    navigateToSearchPage(queryTitle) {
        if (queryTitle) {
            this.router.navigate(['/articles/search'], {queryParams: {'q': queryTitle}});
            this.arrowKeyLocation = -1;
            this.searchString = queryTitle;
            this.closeResultsDropDown();
        }
    }

    search() {
        this.searchString = this.searchString.trim();
        if (this.searchString) {
            this.router.navigate(['/articles/search'], {queryParams: {'q': this.searchString}});
        }
        this.arrowKeyLocation = -1;
        this.closeResultsDropDown();
    }

    currentValue($event) {
        if ($event.target.value) {
            this.searchString = $event.target.value;
        }
    }

    /* closeResults dropdown function has been delayed because if any searched result is clicked from drop down
    * then navigateToSearchPage function executes after closeResultsDropDown function which removes the resultDropDown
    * element from the page before navigation function gets the element's title.
    * */

    closeResultsDropDown() {
        let resultsDropDownElement = this.elRef.nativeElement.querySelector('.search-results-container');
        if (resultsDropDownElement && resultsDropDownElement.style.display == 'block') {
            setTimeout(() => {
                resultsDropDownElement.style.display = 'none';
            }, 200);
        }
        this.arrowKeyLocation = -1;
    }

    selectElementOnMouseOver(index, type) {

        if (index >= 0 && type == 'article') {
            this.arrowKeyLocation = index;
        } else if (index >= 0 && type == 'category') {
            if (this.articleHits['_value'].length) {
                this.arrowKeyLocation = this.articleHits['_value'].length + index;
            } else {
                this.arrowKeyLocation = index;
            }
        } else if (index >= 0 && type == 'newsArticle') {
            if (this.articleHits['_value'].length && !this.categoryHits['_value'].length) {
                this.arrowKeyLocation = this.articleHits['_value'].length + index;
            } else if (!this.articleHits['_value'].length && this.categoryHits['_value'].length) {
                this.arrowKeyLocation = this.categoryHits['_value'].length + index;
            } else if (this.articleHits['_value'].length && this.categoryHits['_value'].length) {
                this.arrowKeyLocation = (this.categoryHits['_value'].length + this.articleHits['_value'].length) + index;
            } else {
                this.arrowKeyLocation = index;
            }
        } else {
            this.arrowKeyLocation = -1;
        }

        if (this.searchedQuery) {
            this.searchString = this.searchedQuery;
        }

    }

}
