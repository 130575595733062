import {Injectable} from '@angular/core';

@Injectable()
export class SignupHelper {
     projectRole: string;

     constructor(){}

     set userRole(role) {
        this.projectRole = role;
     }

     get userRole() {
        return this.projectRole;
     }
}
