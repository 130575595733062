import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Router} from "@angular/router";
import {UtilityHelper} from "../helpers/utilityHelper";
import {HttpClientHelper} from "./apiClient";
import {throwError} from "rxjs";
import { catchError, pluck } from 'rxjs/operators';


@Injectable()
export class KnowledgeServices {

    private httpClient: HttpClientHelper;
    private getArticle = 'articles';
    private getNewsArticle = 'news_articles';
    private getNewsCategories = 'categories';
    private getSavedArticle = 'saved_articles';
    private getSavedNewsArticle = 'saved_news_articles';

    constructor(httpClient: HttpClientHelper, private router: Router, private utility: UtilityHelper) {
        this.httpClient = httpClient;
    }

    // To get the articles details the for home page and article page
    getArticles(url): Observable<any> {
        return this.httpClient.get(this.getArticle + url)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get the articles for home page
    getNewsArticles(url): Observable<any> {
        return this.httpClient.get(this.getNewsArticle + url)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get the articles for home page
    getCategories(url): Observable<any> {
        return this.httpClient.get(this.getNewsCategories + url)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get the articles for home page
    getSavedArticles(url): Observable<any> {
        return this.httpClient.get(this.getSavedArticle + url)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To save article as read later
    saveArticleAsReadLater(payload): Observable<any> {
        return this.httpClient.post(this.getSavedArticle, payload)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get the saved news articles
    getSavedNewsArticles(url): Observable<any> {
        return this.httpClient.get(this.getSavedNewsArticle + url)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To save news article as read later
    saveNewsArticleAsReadLater(payload): Observable<any> {
        return this.httpClient.post(this.getSavedNewsArticle, payload)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To remove saved article
    removeSavedArticle(data): Observable<any> {
        return this.httpClient.delete(this.getSavedArticle + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To remove saved article
    removeSavedNewsArticle(data): Observable<any> {
        return this.httpClient.delete(this.getSavedNewsArticle + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    sendLikeAndUnlikeArticle(url): Observable<any> {
        return this.httpClient.post(url, {})
            .pipe(catchError(this.handleError.bind(this)));
    }

    private extractResponse(res) { return res; }

    private handleError(error) {
        if (error.error == "Unauthorized") {
            this.utility.clear();
            this.router.navigate([''])
        } else {
            return throwError(error.error);
        }
    }
}
