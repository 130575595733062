import {Injectable} from "@angular/core";

@Injectable()
export class DraftMessageTextHelper {

    currentChannelState: Object = {};
    doneTypingInterval = 100;

    constructor() {
    }

    /**
     * Saves the passed text from the message input box
     * @param channelData
     */
    setCurrentChannelDraftedText(channelData) {
        let channelId = channelData.channelId;
        let text = channelData.text;
        if (channelId) {
            if (!this.currentChannelState.hasOwnProperty(channelId)) {
                this.currentChannelState[channelId] = {text: '', attachment: ''};
            }
            this.currentChannelState[channelId].text = text;
        }
    }

    /**
     * Saves the attached file to the message input box
     * @param channelData
     */
    setCurrentChannelDraftedAttachment(channelData) {
        let channelId = channelData.channelId;
        let attachment = channelData.attachment;
        if (channelId) {
            if (!this.currentChannelState.hasOwnProperty(channelId)) {
                this.currentChannelState[channelId] = {text: '', attachment: ''};
            }
            this.currentChannelState[channelId].attachment = attachment;
        }
    }

    /**
     * Retrieves the drafted text for a channel
     * @param channelId
     */
    getCurrentChannelDraftedText(channelId) {
        if (this.currentChannelState.hasOwnProperty(channelId)) {
            return this.currentChannelState[channelId].text;
        } else {
            return '';
        }
    }

    /**
     * Retrieves the drafted attachment details for the passed channel
     * @param channelId
     */
    getCurrentChannelDraftedAttachment(channelId) {
        if (this.currentChannelState.hasOwnProperty(channelId)) {
            return this.currentChannelState[channelId].attachment;
        } else {
            return '';
        }
    }

    draftMessageInputText(channelId) {
        if (channelId && $('#chat__input') && $('#chat__input').length) {
            this.setCurrentChannelDraftedText({
                channelId: channelId,
                text: $('#chat__input')[0].innerHTML
            });
        }
    }

    /**
     * Helps to preserve the entered text in the chat input box
     * @param channelId
     */
    preserveMessageInputBoxText(channelId) {
        if (channelId) {
            let typingTimer;
            let chatInput: any = $('#chat__input');
            chatInput.on('keyup', () => {
                clearTimeout(typingTimer);
                if (chatInput && chatInput.length) {
                    typingTimer = setTimeout(() => {
                        this.draftMessageInputText(channelId);
                    }, this.doneTypingInterval);
                }
            });
        }
    }

    /**
     * Removes the drafted message text
     * @param channelId
     */
    clearChannelDraftedMessage(channelId) {
        if (channelId && this.currentChannelState.hasOwnProperty(channelId)) {
            this.currentChannelState[channelId].text = '';
        }
    }

    /**
     * Removes the drafted channel details
     * @param channelId
     */
    clearChannelDraftedAttachment(channelId) {
        if (channelId && this.currentChannelState.hasOwnProperty(channelId)) {
            this.currentChannelState[channelId].attachment = '';
        }
    }

    clearSavedMessagesAndAttachments() {
        this.currentChannelState = {};
    }
}
