import {Injectable} from "@angular/core";

@Injectable()
export class DynamicURLHelper {
    constructor(){}

    createDynamicURL(params: string[]) {
        const url: string = params.join('&');
        const invitationReqURL: any = `?${url}`;
        return invitationReqURL;
    }
}
