import {Component, HostListener, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {GlobalState} from '../../../global.state';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, RouterLink } from '@angular/router';
import {UtilityHelper} from "../../../helpers/utilityHelper";
import {MentoringEvents} from "../../../helpers/events";
import {AbstractBaseComponent} from "../../../app.baseComponent";
import {MentoringServices} from "../../../appServices/mentoring";
import * as _ from "lodash";
import {DateAndTime} from "../../../helpers/dateAndTime";
import {CertificateHelper} from "../../../helpers/certificateHelper";
import {AuthenticationHelper} from "../../../helpers/authenticationHelper";
import {Observable, Subscription} from "rxjs";
import {CurrentUserStateService} from "../../../appServices/current-user-state.service";
import {ToastsManager} from "../../../helpers/toastsManager";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MentoringMessagingComponent } from 'app/messageServices/mentoringMessagingComponent';
import { BaThemeSpinner } from 'app/theme/services/baThemeSpinner/baThemeSpinner.service';
import { VideoChatService } from 'app/messageServices/video-chat-service';
import { filter } from 'rxjs/operators';
import { NgClass, NgIf, NgStyle, NgFor, AsyncPipe, CommonModule } from '@angular/common';
import { BaScrollPosition } from '../../directives/baScrollPosition/baScrollPosition.directive';
import { LeftSideMenu } from '../leftSideMenu';

@Component({
    selector: 'ba-page-top',
    templateUrl: './baPageTop.html',
    styleUrls: ['./baPageTop.scss'],
    standalone: true,
    imports: [
        BaScrollPosition,
        NgClass,
        NgIf,
        RouterLink,
        NgStyle,
        NgFor,
        AsyncPipe,
        TranslateModule,
        CommonModule,
        LeftSideMenu
    ],
})
export class BaPageTop extends AbstractBaseComponent {

    public isScrolled: boolean = false;
    public isMenuCollapsed: boolean = false;
    communitySecondaryBgColor: string = '';
    avatar: string = '';
    isAdmin: boolean = false;
    isProjectSwitcherOpen: boolean = false;
    displayInfoToolTip: boolean = false;
    isMobile: boolean = false
    isMobileDevice: boolean = false;
    displayNotificationToolTip: boolean = false;
    projectId: string = '';
    notificationList: any = [];
    isDataloaded: boolean = false;
    notificationCount: number = 0;
    pageNumber: any = 1;
    currentNotificationCount: number = 0;
    displayMore: boolean = false;
    showSpinner: boolean = false;
    isDeleted: boolean = false;
    removedNotification: number = 0;
    scrollLock: boolean = false;
    displayDropDownList: boolean = false;
    showCertificateIcon: boolean = false;
    missionAccomplished: boolean = false;
    badges: any;
    isMissionAccomplishedBadgeEarned: boolean = false;
    isArchive: boolean = false;
    supportChannelId$: Observable<any>;
    currentState$: Observable<any>;
    observableSubscription: Subscription;
    countryList: any[] = [
        { name: 'wls' },
        { name: 'en' }];
    currentCountry: any;
    selectedLanguage: any;
    MesaageToaster: any;
    MesaageToasterWhileClickOnLanguageButton: any;
    monthList: any = [
        {name: 'Jan',value: 'Ion'},
        {name: 'Feb',value: 'Chwe'},
        {name: 'Mar',value: 'Maw'},
        {name: 'Apr' ,value: 'Ebr'},
        {name: 'May',value: 'Mai'},
        {name: 'Jun',value: 'Meh'},
        {name: 'Jul',value: 'Gorff'},
        {name: 'Aug',value: 'Awst'},
        {name: 'Sep' ,value: 'Medi'},
        {name: 'Oct',value: 'Hyd'},
        {name: 'Nov',value: 'Tach'},
        {name: 'Dec',value: 'Rhag'},
    ];
    
    isVideoLive: boolean;
    showVideModalPopUp:any;
    constructor(private _state: GlobalState, public router: Router,
                public utility: UtilityHelper, private mentoringEvents: MentoringEvents,
                private mentoringServices: MentoringServices, private dateAndTime: DateAndTime,
                private cd: ChangeDetectorRef, private certificate: CertificateHelper,
                public authService: AuthenticationHelper,private currentUserStateService: CurrentUserStateService,
                private translate:TranslateService,
                private badgesForWls :MentoringMessagingComponent,
                private _spinner: BaThemeSpinner,
                private toaster: ToastsManager,private videoChatService:VideoChatService) {
        super();
    }

    ngOnInit(){
        this.selectedLanguage = this.utility.getItem('currentLanguage');
        this.translate.get('baPageTop.MesaageToaster').subscribe((value:any)=> {
            this.MesaageToaster=value
        });
        this.translate.get('baPageTop.MesaageToasterWhileClickOnLanguageButton').subscribe((value:any)=> {
            this.MesaageToasterWhileClickOnLanguageButton=value
        });
        this.subscribeUserNotification();
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
        this.communitySecondaryBgColor = this.utility.getItem('secondaryBgColor');
        this.avatar = this.utility.getAvatarId;
        if (this.utility.getItem('role') == 'admin' || this.utility.getItem('role') == 'coordinator') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        this.isMobileDevice = this.utility.checkForMobile(navigator.userAgent);
        this.mentoringEvents.getProjectChangedEvent().subscribe(item => {
            setTimeout(() => {
                if (item) {
                    this.displayNotificationToolTip = false;
                    this.projectId = this.utility.getItem('projectId');
                    this.userNotification(false);
                    this.showCertificateIcon = false;
                    this.getBadges();
                }
            }, 400)
        });

        this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          if (event && this.displayNotificationToolTip) {
            this.displayNotificationToolTip = false;
          }
        });
        this.getDeviceWidth();
        this.mentoringEvents.getCertificateDownloaded().subscribe(item => {
            if (item) {
                this.isMissionAccomplishedBadgeEarned = false;
            }
        })
        this.supportChannelId$ = this.currentUserStateService.supportChannelIdState$;
        this.currentState$ = this.currentUserStateService.currentUserState$;
        this.observableSubscription = this.currentState$.subscribe(item => {
            this.isArchive = item.isArchive;
        })
    }
    activeCountry(): void {
        if(this.isVideoLive) {
            this.toaster.warning(this.MesaageToasterWhileClickOnLanguageButton)
        } else {
            this._spinner.show();
            let lang = this.utility.getItem('currentLanguage');
            let userId = this.utility.getItem('userId'); 
            if (lang == 'en') {
                this.utility.setItem('currentLanguage', 'wls');
                let data = {
                    data: {
                        id: userId,
                        type: 'users',
                        attributes: {
                            language:'dy',
                        },
                    }
                };
                this.updateLanguage(userId,data);
                this.selectedLanguage = this.utility.getItem('currentLanguage');          
            } else {
                this.utility.setItem('currentLanguage', 'en');
                let data = {
                    data: {
                        id: userId,
                        type: 'users',
                        attributes: {
                            language:'en',
                        },
                    }
                };
                this.updateLanguage(userId,data);
                this.selectedLanguage = this.utility.getItem('currentLanguage');             
            }
        }
      }

      updateLanguage(userId, data) {
            this.mentoringServices.updateMyAccount(userId, data).subscribe(data => {
                window.location.reload();});
        }
    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if ((!this.isMenuCollapsed && $(window).width() < 768) &&
            ($('#mySidenavR').length) &&
            ($('.collapse-menu-link').length)) {
            let elementToCheckIfClicksAreInsideOfLeftSideMenu: any = document.querySelector('#mySidenavR');
            let myElementToCheckIfClicksAreInsideOfMenuIcon: any = document.querySelector('.collapse-menu-link');
            if ((elementToCheckIfClicksAreInsideOfLeftSideMenu &&
                elementToCheckIfClicksAreInsideOfLeftSideMenu.contains(targetElement)) ||
                (myElementToCheckIfClicksAreInsideOfMenuIcon &&
                 myElementToCheckIfClicksAreInsideOfMenuIcon.contains(targetElement)) ){
            } else {
                this._state.notifyDataChanged('menu.isCollapsed', true);
            }
        }

        if (this.displayNotificationToolTip && !this.isMobileDevice) {
            const notificationIconElement = document.querySelector('.notification-icon');
            const notificationPanelElement = document.querySelector('#notification-menu-id');
            if (!notificationIconElement.contains(targetElement) &&
                !notificationPanelElement.contains(targetElement)) {
                this.displayNotificationToolTip = false;
                this.reset();
            }
        }

        if(this.displayDropDownList) {
            const dropdownIconElement = document.querySelector('.menu-icon-for-mobile-container');
            const dropdownListElement = document.querySelector('.dropdown-mobile-menu');
            if ((!dropdownIconElement.contains(targetElement) && !dropdownListElement.contains(targetElement))) {
                this.displayDropDownList = false;
            }
        }
        if (this.displayInfoToolTip) {
            const retakeTourIconElement = document.querySelector('.retake-tour-icon');
            if (!retakeTourIconElement.contains(targetElement)) {
                this.displayInfoToolTip = false;
            }
        }
    }

    public toggleMenu() {
        if (this.isProjectSwitcherOpen) {
            this.mentoringEvents.setOpenProjectSwitcher(false);
            this.isMenuCollapsed = false;
        } else {
            if (!this.isMenuCollapsed) {
                this.mentoringEvents.setOpenProjectSwitcher(false);
            }
            this.isMenuCollapsed = !this.isMenuCollapsed;
        }
        this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
        return false;
    }

    public scrolledChanged(isScrolled) {
        this.isScrolled = isScrolled;
    }

    navigateToDashboard() {
        this.router.navigate(['feeds']);
    }

    public ngAfterViewInit(): void {
        this.communitySecondaryBgColor = this.utility.getItem('secondaryBgColor');
        this.avatar = this.utility.getAvatarId;
        if (this.avatar && this.avatar.indexOf('brightside-assets') > -1) {
            this.avatar = '';
            this.avatar = this.IMG_SERVER_CDN + 'fill/60x60/' + this.utility.getAvatarId;
        }else{
            this.avatar = `../../../../assets/img/anonymous_user.png`;
        }
        this.mentoringEvents.getChangedProfileData().subscribe(item => {
            if (item) {
                this.avatar = this.utility.getAvatarId;
                if (this.avatar && this.avatar.indexOf('brightside-assets') > -1) {
                    this.avatar = '';
                    this.avatar = this.IMG_SERVER_CDN + 'fill/60x60/' + this.utility.getAvatarId;
                }else{
                    this.avatar = `../../../../assets/img/anonymous_user.png`;
                }
            }
        });

        this.mentoringEvents.getOpenProjectSwitcher().subscribe(item => {
            if (item) {
                this.isProjectSwitcherOpen = true;
            } else {
                this.isProjectSwitcherOpen = false;
            }
        });
        this.mentoringEvents.getOpenVideoContainer().subscribe( item =>{
            if (item) {
                this.isVideoLive = true;
            } else {
                this.isVideoLive = false;
            }
        })
        this.userNotification(false);
        this.cd.detectChanges();
    }

    ngDoCheck() {
        if (!this.avatar) {
            this.avatar = this.utility.getAvatarId;
            if (this.avatar && this.avatar.indexOf('brightside-assets') > -1) {
                this.avatar = '';
                this.avatar = this.IMG_SERVER_CDN + this.utility.getAvatarId;
            }else{
                this.avatar = `../../../../assets/img/anonymous_user.png`;
            }
        }
        if (!this.communitySecondaryBgColor) {
            this.communitySecondaryBgColor = this.utility.getItem('secondaryBgColor');
        }
        this.projectId = this.utility.getItem('projectId');
    }

    openProjectSwitcher() {
        this.mentoringEvents.setOpenProjectSwitcher(!this.isProjectSwitcherOpen);
        this._state.notifyDataChanged('menu.isCollapsed', true);
    }

    openNotificationMenu(event) {
        this.reset();
        this.showSpinner = true;
        this.displayNotificationToolTip = !this.displayNotificationToolTip;
        if (this.displayNotificationToolTip) {
            this.userNotification(true, true);
        }
        if(this.isMobile || this.isMobileDevice){
            this.displayDropDownList = !this.displayDropDownList;
        }
    }

    userNotification(response: boolean, isFirstTime?: boolean) {
        const params: any = [
            `filter[project_id]=${this.projectId}`,
            `filter[is_read]=false`,
            `page[number]=${this.pageNumber}`,
            `page[size]=${10}`,
        ].join('&');
        const notificationUrl: any = `?${params}`;
        this.mentoringServices.getUserNotification(notificationUrl).subscribe(
            data => this.getUserNotificationSuccess(data, response, isFirstTime),
            error => this.getUserNotificationFail(error)
        )
    }

    subscribeUserNotification() {
        this.mentoringEvents.fetchUserNotifications().subscribe( notificationItem => {
            if (notificationItem) {
                this.notificationCount = notificationItem['record_count'];
            }
        } );
    }

    getUserNotificationSuccess(data, response, isFirstTime) {
        if (isFirstTime) {
            this.reset();
        }

        if (data && data.data &&
            data.data.length && response) {
            this.isDataloaded = true;
            let existingListIds = this.notificationList.map((item) => {
                return item.id
            });
            data.data.forEach((item) => {
                if (item && item.attributes &&
                    item.attributes.created_at) {
                        if(this.selectedLanguage == 'wls'){
                            item.attributes.created_at = this.formatData(item.attributes.created_at);
                            
                         }else{
                            item.attributes.created_at = this.dateAndTime.formatData(item.attributes.created_at, 'at');
                         }
                    if (item.attributes.notification_type == 'earned_badge') {
                        let badge = null;
                        badge = this.getBadgeData(item.attributes.message);
                        if (badge) {
                            item.attributes.badge = badge;
                        }
                    }
                    if (!existingListIds.includes(item.id)) {
                        this.notificationList.push(item);
                    }
                }
            });
            this.showSpinner = false;
        } else {
            this.isDataloaded = false;
        }
        this.scrollLock = false;
        this.currentNotificationCount = this.notificationList.length;
        this.displayMore = (this.currentNotificationCount !== this.notificationCount);
    }

    getUserNotificationFail(error) {
        this.scrollLock = false;
    }

    removeNotification(notification) {
        const payload: any = {
            data: {
                id: notification.id,
                type: 'user_notifications',
                attributes: {
                    is_read: true,
                }
            }
        };
        this.mentoringServices.updateReadUserNotification(notification.id, payload).subscribe(
            data => this.updateReadUserNotificationSuccess(data),
            error => this.updateReadUserNotificationFail(error)
        )
    }

    updateReadUserNotificationSuccess(data) {
        if (data) {
            this.isDeleted = true;
            this.notificationCount--;
            this.notificationList = this.notificationList.filter((item) => item.id !== data.data.id);
            this.userNotification(true);
        }
    }

    updateReadUserNotificationFail(error) {
    }

    scrollHandler(event) {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();
        let scrollHeight: any = document.getElementsByClassName('notification-message-container')[0].scrollHeight;
        let innerHeight: any = $($('.notification-message-container')[0]).innerHeight();
        let maxScrollValue: any = Math.round(scrollHeight - innerHeight);
        let currentScrollPosition: any = $($('.notification-message-container')[0]).scrollTop();
        if (currentScrollPosition >= maxScrollValue && this.displayMore && !this.scrollLock) {
            this.scrollLock = true;
            this.showSpinner = true;
            _.debounce(() => {
                this.fetchNewNotifications();
            }, 800)();
        }
    }

    fetchNewNotifications() {
        if (this.isDeleted) {
            this.pageNumber;
        } else {
            this.pageNumber++;
        }
        this.isDeleted = false;
        this.removedNotification = 0;
        this.userNotification(true);
    }

    reset() {
        this.currentNotificationCount = 0;
        this.notificationList = [];
        this.pageNumber = 1;
        this.isDeleted = false;
        this.removedNotification = 0;
    }

    openAccountMenu(event) {
        this.displayDropDownList = !this.displayDropDownList;
    }

    closeNotificationList() {
        this.displayNotificationToolTip = false;
        if(this.isMobile || this.isMobileDevice){
        this.displayDropDownList = !this.displayDropDownList;
        }
    }
    retakeTour(){
        this.utility.setItem('isTourPending', true);
        this.utility.startFeatureTour();
        $('#mySidenavR').scrollTop(0);
    }

    openToolTipMenu(){
        this.displayInfoToolTip = !this.displayInfoToolTip;
    }

    getDeviceWidth(){
        if($(window).width() < 768) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }

    downloadCertificate() {
        this.certificate.getCertificate();
        if(this.isMobile || this.isMobileDevice){
            this.displayDropDownList = !this.displayDropDownList;
            }
    }

    getBadges() {
        const url: any = `?filter[all_badges]=${this.utility.getItem('projectUserId')}&include=project_user_badges`;
        const projectUserChannelsSub: any = this.mentoringServices.getBadgesData(url).subscribe(
            data => this.getBadgesSuccess(data),
            error => this.getBadgesFail(error),
        );
    }

    getBadgesSuccess(data) {
        let badges = [];
        if (data && data.data && data.data.length) {
            data.data.forEach(item => {
                if(this.selectedLanguage == 'wls'){
                    this.badgesForWls.badgesWls.forEach(element => {
                        if(element.id==item.id)
                        badges.push({
                        id: item.id,
                        title: element.title,
                    });})
                    
                }else{
                badges.push({
                    id: item.id,
                    title: item.attributes.title,
                });}
            });

            this.badges = badges;
        }
        if (data && data.included && data.included.length) {
            let badgeIds = [];
            data.included.forEach(item => {
                badgeIds.push(item.attributes.badge_id)
                if (item.attributes.badge_id == 10
                    && this.dateAndTime.momentDate(item.attributes.updated_at).isSame(this.dateAndTime.momentDate(item.attributes.created_at))) {
                    this.isMissionAccomplishedBadgeEarned = true
                }
            });
            if (badgeIds.includes(10)) {
                this.showCertificateIcon = true;
            } else {
                this.showCertificateIcon = false;
            }
        }
    }

    getBadgesFail(errors) {
    }

    getBadgeData(message) {
        let badge = null;
        const match = message.match(/Congratulations you have earned the badge (.+)/);
        if (match && match.length && match.length > 1) {
            this.badges.forEach(item => {
                if (item.id === match[1]) {
                    badge = item;
                }
            });
        }
        return badge;
    }

    intercomNavigation() {
        this.translate.get('baPageTop.MesaageToaster').subscribe((value:any)=> {

            this.MesaageToaster=value
        });
        if (this.isArchive) {
            this.toaster.error('<p>Please email us on <a href="mailto:support@brightsidementoring.org">support@brightsidementoring.org </a>if you have any queries</p>', { timeOut: 10000, extendedTimeOut: 10000, enableHtml: true });
        } else {
            this.toaster.success(this.MesaageToaster, { timeOut: 10000, extendedTimeOut: 10000 });
        }
    }
    formatData(date): string {
        const dataObject: any = new Date(Date.parse(date));
        const currentDate: any = moment(moment().toDate());
        const createdDate: any = moment(dataObject);
        const toDay = currentDate.clone().startOf('day');
        const yesterDay = currentDate.clone().subtract(1, 'days').startOf('day');
        
        if (createdDate.isSame(toDay, 'd')) {
            return `Heddiw am ${createdDate.format('LT')}`;
        } else if (createdDate.isSame(yesterDay, 'd')) {
            return `Ddoe ${createdDate.format('LT')}`;
        } else {
            return this.dateAndTime.defaultDateAndTimeFormat(createdDate,'lll');
        }
    }
    // toggleVideoCall() {
    //     this.showVideModalPopUp = $('#showVideModalPopUp');
    //     this.showVideModalPopUp.modal('toggle');
    // }
}
