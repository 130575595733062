import {Component} from '@angular/core';
import {UtilityHelper} from "../helpers/utilityHelper";
import {NotificationClient} from "../messageServices/notificationClient";
import {AdminServices} from "../appServices/adminService";
import {MentoringEvents} from "../helpers/events";
import {MentoringServices} from "../appServices/mentoring";
import {Router, Event as RouterEvent, NavigationEnd, RoutesRecognized, RouterModule} from "@angular/router";
import {BaThemeSpinner} from "../theme/services/baThemeSpinner/baThemeSpinner.service";
import {AbstractBaseComponent} from "../app.baseComponent";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {SocketService} from "../messageServices/socketService";
import {ApplicationServices} from "../appServices/application";
import {MentoringSocketService} from "../messageServices/mentoringSocketService";
import { ToastsManager } from "app/helpers/toastsManager";
import {CurrentUserStateService} from "../appServices/current-user-state.service";
import {Observable, Subscription} from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgClass, NgIf } from '@angular/common';
import { KnowledgeModalPopUpComponent } from 'app/theme/components/knowledgeModalPopUp/knowledgeModalPopUp.component';
import { VideoCallModalComponent } from 'app/theme/components/video-call-modal/video-call-modal.component';
import { BaPageTop, LeftSideMenu } from 'app/theme/components';
import { KnowledgeHeader } from 'app/theme/components/knowledgeHeader';
import { KnowledgeFooter } from './knowledge/knowledgeFooter';
import { KnowledgeLeftSideMenuComponent } from 'app/theme/components/knowledgeLeftSideMenu/knowledgeLeftSideMenu.component';
import { routes } from 'app/app.routing';

@Component({
    selector: 'pages',
    templateUrl: './pages.html',
    styleUrls: ['knowledge/knowledgeHome/knowledgeHome.scss'],
    standalone: true,
    imports: [NgIf,
        KnowledgeModalPopUpComponent,
        VideoCallModalComponent,  
        RouterModule,KnowledgeHeader,
        BaPageTop,
        KnowledgeFooter,
        NgClass,
        KnowledgeLeftSideMenuComponent,
        LeftSideMenu]
})
export class Pages extends AbstractBaseComponent {
    public currentYear;
    graduateUrl: string = '';
    isMenu: boolean = false;
    subscriptions : any = [];
    routerSubscription: any;
    isProjectArchive: boolean = false;
    projectDetails: any;
    toasterMessage: any;
    isVideoLive: boolean;
    videoCallTrack: any;
    isPopupVisible: true;
    showVideModalPopUp : any = $('#showVideModalPopUp')

    constructor(public utility: UtilityHelper, private notification: NotificationClient,
                private adminServices: AdminServices, private mentoringEvents: MentoringEvents,
                private mentoringService: MentoringServices, private spinner: BaThemeSpinner,
                private router: Router, private authService: AuthenticationHelper,
                private toaster: ToastsManager, private socketService: SocketService,
                private mentoringSocketService: MentoringSocketService,
                private appService: ApplicationServices, private currentUserStateService: CurrentUserStateService,
                private translate:TranslateService) {
        super();
        this.currentYear = new Date().getFullYear();
        if (this.utility.getItem('token') && this.utility.myDomain != this.KNOWLEDGE_DOMAIN) {
            this.notification.connect(this.utility.getItem('token'));
        }

        if (utility.myDomain !== this.KNOWLEDGE_DOMAIN) {
            this.getProjectDetails();
        }

        this.routerSubscription = router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });
        
        this.showVideModalPopUp = $('#showVideModalPopUp');
        this.showVideModalPopUp.modal('hide');
    }

    ngOnInit() {
        if( this.utility.getItem('currentLanguage')==='wls'){
            moment.locale('cy');
        }
        this.translate.get('Home').subscribe((value:any)=> {
            this.toasterMessage=value;
           });
    }

    ngOnDestroy(){
        this.disposeSub();
        this.routerSubscription.unsubscribe();
    }

    disposeSub() {
        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach((sub)=>{
                sub.unsubscribe();
            });
        }
    }

    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationEnd) {
            if (this.utility.getItem('token')) {
                this.utility.updateLastActiveSession(this.router.url);
                if (this.utility.myDomain == this.MENTORING_DOMAIN || this.utility.myDomain == this.BRILLIANTCLUB_DOMAIN || this.utility.myDomain == this.LOCAL_DOMAIN ) {
                    this.checkProjectAccess();
                    setTimeout(() => {
                        this.mentoringEvents.projectChanged(true);
                    }, 400);
                }
            }
        }
    }

    checkProjectAccess() {
        const projectId = this.utility.getItem('projectId');
        const userId = this.utility.getItem('userId');

        if (this.utility.lastAccessedProjectId !=  projectId) {
            this.authService.updateLastAccessedProject(projectId);
        }

        if (projectId && userId) {
            let pageUrl = '?filter[project_id]=' + projectId + '&filter[user_id]=' + userId +
                '&include=project,project.ask_graduate&fields[project_users]=is_archived,is_authorized,user_id,project_id' +
                '&fields[projects]=is_archived,name,ask_graduate_enabled,gamification_enabled,mission_accomplished&fields[ask_graduates]=replacement_text_enabled,replacement_text';

            this.mentoringService.getProjectUserPermissions(pageUrl).subscribe(
                data => this.getProjectAccessSuccess(data),
                error => this.getProjectAccessFail(error)
            );
        } else {
            this.signOutUser();
        }
    }

    /* *
     * 1-Checks for user's authorization and logs out unauthorized users
     * 2-The project is archived or not is checked for only mentor and mentee
     * 3-Authorized and Archived project's mentor and mentee gets logged out from the platform
     * */

    getProjectAccessSuccess(data) {
        let projectId = this.utility.getItem('projectId');
        let projectUserId = this.utility.getItem('projectUserId');
        let askGraduateEnabled = false;
        if (data && data.data.length) {
            data.data.some(item => {
                if (item.id == projectUserId){
                    this.utility.isUserArchived = item.attributes.is_archived;
                    if (!item.attributes.is_authorized) {
                        this.toaster.error(this.toasterMessage.sorrYouAreNotAuthorizedToAccess + this.utility.getItem('projectName') + this.toasterMessage.projectPleaseContactYourCoordinator);
                        this.signOutUser();
                    } else if (this.currentUserStateService.isSuperAdmin() ||
                        this.utility.getItem('role') !== 'coordinator') {
                        data.included.some(projectData => {
                            if (projectData.type == 'projects' && projectData.id == projectId) {
                                this.utility.isProjectArchived = projectData.attributes.is_archived;
                                askGraduateEnabled = projectData.attributes.ask_graduate_enabled;
                                this.utility.setItem('gamification_enabled',
                                    projectData.attributes.gamification_enabled);
                                return true;
                            }
                        });
                        if (askGraduateEnabled) {
                            data.included.some(askGraduateData => {
                                if (askGraduateData.type == 'ask_graduates') {
                                    if (askGraduateData.attributes && askGraduateData.attributes.replacement_text_enabled) {
                                        this.utility.setItem('graduateUrl', 'Ask the ' + askGraduateData.attributes.replacement_text);
                                    } else {
                                        this.utility.setItem('graduateUrl', 'Ask the Graduate');
                                    }
                                    return true
                                }
                            });
                        } else {
                            this.utility.removeItem('graduateUrl');
                         }
                    }
                    return true;
                }
            });
        } else {
            this.signOutUser();
        }
    }

    getProjectAccessFail(error) {
        this.signOutUser();
    }

    getProjectDetails() {
        let projectId= this.utility.getItem('projectId');
        this.adminServices.getSpecificProject(projectId).subscribe(
            data => this.getSpecificProjectSuccess(data),
            error => this.getSpecificProjectFail(error)
        );
    }

    getSpecificProjectSuccess(data) {
        this.mentoringEvents.setVideoCallEnabled(data.data.attributes.video_call_enabled)
        this.utility.setItem("video_call_enabled",data.data.attributes.video_call_enabled)
        this.projectDetails = data;
        if (data) {
            this.utility.mentorsAvailable = data.data.attributes.matching_enabled;
            this.utility.setItem('is_connection_project', data.data.attributes.is_connection_project);
            this.isProjectArchive = data.data.attributes.is_archived;
            if (this.isProjectArchive) {
                const archiveObject = {
                    'isArchive': this.isProjectArchive,
                };
                this.currentUserStateService.setCurrentUserState(archiveObject);
            }
            let isConnectionEnabled: boolean = false;
            let isTypeformEnabled: boolean = false;
            if (data.data &&
                data.data.attributes &&
                data.data.attributes.is_connection_project) {
                isConnectionEnabled = true;
            }

            if (data.data &&
                data.data.attributes &&
                data.data.attributes.typeform_enabled) {
                isTypeformEnabled = true;
            }
            this.utility.connectionEnabled = isConnectionEnabled;
            this.utility.typeformEnabled = isTypeformEnabled;

            if (data.included.length && data.included[0]) {
                data.included.forEach(item => {
                    if(item.type == "partners") {
                        this.utility.setPartnerDetails = item;
                        this.utility.setItem('primaryBgColor', item.attributes.primary_color);
                        this.utility.setItem('secondaryBgColor', item.attributes.secondary_color);
                        this.utility.setItem('mentoringUrl', item.attributes.url + '/');
                    }
                    if(item.type == "ask_graduates") {
                        if (item.attributes && item.attributes.replacement_text_enabled) {
                            this.graduateUrl = 'Ask the ' + item.attributes.replacement_text;
                        } else {
                            this.graduateUrl = 'Ask the Graduate';
                        }
                    }
                })

            }

            if(data.included){
                this.utility.partnerDetails = data.included;
            }

            if (data.data) {
                this.utility.setItem('projectName', data.data.attributes.name);
                this.utility.setItem('moderationType', data.data.attributes.moderation_type);
                this.utility.setItem('projectId', data.data.id);
                this.utility.isProjectArchived = data.data.attributes.is_archived;
                this.utility.setMenus(data.data.attributes, this.graduateUrl);
                this.mentoringService.getUserProjectRole(data.data.id).subscribe(
                    data => this.getUserProjectRoleSuccess(data),
                    error => this.getUserProjectRoleFail(error)
                )
            }
        }
    }

    getSpecificProjectFail(error) {
        console.log(error);
    }

    getUserProjectRoleSuccess(data) {
        if (data) {
            this.mentoringEvents.setProjectUsersData(data);
            if (data.data[0]) {
                this.utility.setItem('userId', data.data[0].relationships.user.data.id);
                this.utility.setItem('projectUserId', data.data[0].id);
                this.utility.setItem('communication_pereference', data.included[0].attributes.communication_preference)
                this.utility.mentorTypeformId = data.data[0].attributes.typeform_link;
                if (!this.isProjectArchive) {
                    const archiveObject = {
                        'isArchive': data.data[0].attributes.is_archived,
                    };
                    this.currentUserStateService.setCurrentUserState(archiveObject);
                }
            }
            if (data.included[0]) {
                this.utility.setAvatarId = data.included[0].attributes.avatar_id;
                this.utility.setItem('firstName', data.included[0].attributes.first_name);
                this.utility.setItem('lastName', data.included[0].attributes.last_name);
                this.utility.setItem('autoPrompt', data.included[0].attributes.auto_prompt);
                this.utility.setItem('promptDisplayed', data.included[0].attributes.prompt_displayed_at);
                const superAdminObj = {
                    'isSuperAdmin': data.included[0].attributes.super_admin,
                };
                this.currentUserStateService.setSuperAdminState(superAdminObj);
            }
            if (data.included[1]) {
                this.utility.setItem('role', data.included[1].attributes.name);
            }
            const roleObj = {
                'role': data.included[1].attributes.name,
            };
            this.currentUserStateService.setUserRoleState(roleObj);
            let reload = true;
            // this.mentoringEvents.setProjectSwitchedContent(data, reload);
        }
    }

    getUserProjectRoleFail(error) {
        console.log(error);
    }

    ngAfterViewInit() {
        this.mentoringEvents.getOpenVideoContainer().subscribe( item =>{
            if (item) {
                this.isVideoLive = true;
            } else {
                this.isVideoLive = false;
            }
        })
        this.mentoringEvents.isKnowledgeMenu.subscribe(item => {
            this.isMenu = item;
        });

        this.mentoringEvents.isProjectSwitched.subscribe(item => {
            if (item) {
                if (this.utility.myDomain == this.MENTORING_DOMAIN || this.utility.myDomain == this.BRILLIANTCLUB_DOMAIN || this.utility.myDomain == this.LOCAL_DOMAIN ) {
                    this.checkProjectAccess();
                }
            }
            const sessionToken = this.utility.getItem('token');
            if (sessionToken) {
                this.notification.disconnectSocket();
                this.notification.connect(sessionToken);
            }

        });
    }

    signOutUser() {
        this.disposeSub();
        this.socketService.disconnectSocket();
        this.mentoringSocketService.disconnectSocket();
        this.notification.disconnectSocket();
        this.utility.clear();
        this.router.navigate(['']);
    }

    
}
