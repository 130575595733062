import {Injectable} from "@angular/core";;
import {UtilityHelper} from "./utilityHelper";
import {MentoringServices} from "../appServices/mentoring";
import {BaThemeSpinner} from "../theme/services/baThemeSpinner/baThemeSpinner.service";
import {ToastsManager} from "../helpers/toastsManager";
import {MentoringEvents} from "./events";

@Injectable()
export class CertificateHelper {
    constructor(public utility: UtilityHelper, private mentoringServices: MentoringServices,
                private _spinner: BaThemeSpinner, private toastr: ToastsManager,
                private events: MentoringEvents) {
    }

    getCertificate() {
        this._spinner.show();
        let payload = {
            'data': {
                'type': 'gamification_certificates',
                'attributes': {
                    'project_id': this.utility.getItem('projectId'),
                    'project_user_id': this.utility.getItem('projectUserId')
                },
            },
        };
        const earnedBadges: any = this.mentoringServices.getCertificateData(payload).subscribe(
            data => this.getCertificateSuccess(data),
            error => this.getCertificateError(error),
        );
    }

    getCertificateSuccess(data) {
        if (data && data.data && data.data.attributes) {
            const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
                const byteCharacters = atob(b64Data);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, {type: contentType});
                return blob;
            };

            let theFile;
            const certificateData = data.data.attributes.certificate_pdf;
            if (certificateData && certificateData.length) {
                const blobFile = b64toBlob(certificateData, 'application/pdf');
                theFile = new Blob([blobFile], { type: 'application/pdf' });
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    (window.navigator as any).msSaveOrOpenBlob(theFile, 'mentoring_journey_completion_certificate.pdf');
                } else {
                    const linkSource = `data:application/pdf;base64,${certificateData.replace(/(\r\n|\n|\r)/gm, '')}`;
                    const downloadLink = document.createElement('a');
                    downloadLink.href = linkSource;
                    downloadLink.download = 'mentoring_journey_completion_certificate.pdf';
                    downloadLink.click();
                    this.events.certificateDownloaded(true);
                }
            } else {
                this.toastr.error('Unable to download certificate');
            }
        }
        this._spinner.hide();
    }

    getCertificateError(error) {
        this._spinner.hide();
        if (error && error.errors && error.errors.length) {
            this.toastr.error(error.errors[0].title);
        }
    }
}
