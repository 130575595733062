import {NgModule, ModuleWithProviders}      from '@angular/core';
import {CommonModule}  from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {
    BaPageTop,
    ProjectSwitcher,
} from './components';

import {
    BaScrollPosition,
    BaSlimScroll,
} from './directives';

import {
    BaThemePreloader,
    BaThemeSpinner
} from './services';

import {
    EmailValidator,
    EqualPasswordsValidator
} from './validators';
import { KnowledgeModalPopUpComponent } from './components/knowledgeModalPopUp/knowledgeModalPopUp.component';
import{PopUpScreenComponent} from './components/popUpScreen/popUpScreen.component'
import { TranslateModule } from '@ngx-translate/core';
import { VideoCallModalComponent } from './components/video-call-modal/video-call-modal.component'

const NGA_COMPONENTS = [
    BaPageTop,
    ProjectSwitcher,
    KnowledgeModalPopUpComponent,
    PopUpScreenComponent,
    // VideoCallModalComponent,
];

const NGA_DIRECTIVES = [
    BaScrollPosition,
    BaSlimScroll,
];

const NGA_SERVICES = [
    BaThemePreloader,
    BaThemeSpinner,
];

const NGA_VALIDATORS = [
    EmailValidator,
    EqualPasswordsValidator
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ...NGA_DIRECTIVES,
        ...NGA_COMPONENTS,
    ],
    exports: [
        ...NGA_DIRECTIVES,
        ...NGA_COMPONENTS
    ]
})
export class NgaModule {
    static forRoot(): ModuleWithProviders<RouterModule>{
        return <ModuleWithProviders<RouterModule>> {
            ngModule: NgaModule,
            providers: [
                ...NGA_VALIDATORS,
                ...NGA_SERVICES,
            ],
        };
    }
}
