import {Injectable} from "@angular/core";
import {AbstractBaseComponent} from "../app.baseComponent";
import {CanActivate, Router} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {UtilityHelper} from "../helpers/utilityHelper";

@Injectable()
export class KnowledgeGuard extends AbstractBaseComponent implements CanActivate {
    constructor(private authService: AuthenticationHelper, private utility: UtilityHelper, private route: Router) {
        super();
    }

    canActivate() {
        if ( this.utility.myDomain == this.KNOWLEDGE_DOMAIN ) {
            return true
        }else {
            this.route.navigate(['']);
            return false
        }
    }
}