import {Component, OnInit} from '@angular/core';
import {UtilityHelper} from '../../helpers/utilityHelper';
import {MentoringServices} from '../../appServices/mentoring';
import {BaThemeSpinner} from '../../theme/services/baThemeSpinner';
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from '@angular/router';
import {MentoringEvents} from "../../helpers/events";
import * as _ from "lodash";
import {NotificationClient} from "../../messageServices/notificationClient";
import {JoyrideModule, JoyrideService} from "ngx-joyride";
import {MessagingHelper} from "../../helpers/messagingHelper";
import {AppConstant} from "../../app.constant";
import {ToastsManager} from "../../helpers/toastsManager";
import {Observable, Subscription} from "rxjs";
import {CurrentUserStateService} from "../../appServices/current-user-state.service";
import { filter } from 'rxjs/operators';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgaModule } from 'app/theme/nga.module';

@Component({
    selector: 'channelList',
    templateUrl: './channelList.component.html',
    styleUrls: ['./channelList.component.scss'],
    standalone: true,
    imports:[NgIf, CommonModule, TranslateModule, RouterModule,JoyrideModule,NgFor,NgClass]
})
export class ChannelListComponent implements OnInit {
    isLoaded: boolean = false;
    projectId: any = '';
    channelData = [];
    channelType: any = '';
    isChannelArchived: boolean = false;
    currentChannelID: any = '';
    channelsToBeNotified: any = [];
    subscriptions: any = [];
    displayChannelList: boolean = false;
    currentChannelsList: any = [];
    notificationPayloads: any;
    isMatched: boolean = false;
    isArchive: boolean = false;
    observableSubscription: Subscription;
    currentState$: Observable<any>;

    constructor(public utility: UtilityHelper, private mentoringService: MentoringServices,
                private _spinner: BaThemeSpinner, private router: Router,
                private routes: ActivatedRoute, private mentoringEvents: MentoringEvents,
                private notificationClient: NotificationClient, public joyrideService: JoyrideService,
                private toastr: ToastsManager, public messagingHelper: MessagingHelper,
                private currentUserStateService: CurrentUserStateService) {
        this.subscribeToNotificationClient();
    }

    ngOnInit() {
        this.getProjectUserChannel();
        this.navigationInterceptor();
    }

    ngAfterViewInit() {
        this.mentoringEvents.isProjectSwitched.subscribe(item => {
            if (item) {
                this.getProjectUserChannel();
            }
        });
    }

    subscribeToNotificationClient() {
        this.mentoringEvents.fetchNotifications().subscribe( notificationItem => {
            if (notificationItem && notificationItem.notificationPayload) {
                this.notificationPayloads = notificationItem.notificationPayload;
                this.checkAndDisplayNotificationIcon();
            }
        } );
    }


    checkAndDisplayNotificationIcon() {
        let notifyingChannels:any = [];
        if (this.notificationPayloads) {
            _.forOwn(this.notificationPayloads, (value, key) => {
                if (key) {
                    notifyingChannels.push(Number(key));
                }
            } );
        }
        if (notifyingChannels && notifyingChannels.length &&
            this.currentChannelsList && this.currentChannelsList.length) {
            _.forOwn(this.notificationPayloads, (value, key) => {
                this.removeExistingChannelToBeNotified(Number(key));
                if (value) {
                    this.channelsToBeNotified.push(Number(key));
                }
            } );
        }
    }

    removeExistingChannelToBeNotified(key) {
        if (this.channelsToBeNotified && this.channelsToBeNotified.length
            && this.channelsToBeNotified.includes(key)) {
            const itemIndex = this.channelsToBeNotified.indexOf(key);
            this.channelsToBeNotified.splice(itemIndex, 1);
        }
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
        this.notificationClient.disconnectSocket();
    }

    unsubscribeSubscriptions() {
        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach( (currentSubscription) => {
                currentSubscription.unsubscribe();
            } );
        }
    }

    navigationInterceptor() {
        const routeSubscription: any = this.router.events.pipe(filter((event => event instanceof NavigationEnd)))
            .subscribe(event => {
                if (event) {
                    this.getProjectUserChannel();
                    const currentUrl = event['url'];
                    if (currentUrl && currentUrl.split('/').length) {
                        let channelUrl = currentUrl.split('/');
                        if (channelUrl == "/channels") {

                        } else if (channelUrl[1] == 'channels' &&
                            channelUrl[2]) {
                            this.currentChannelID = channelUrl[2];
                        } else {
                            this.currentChannelID = '';
                        }
                    }
                }
            });
        this.subscriptions.push(routeSubscription);
    }

    getProjectUserChannel() {
        this.isChannelArchived = false;
        this.projectId = this.utility.getItem('projectId');
        let data = '?filter[project_id]=' + this.projectId;
        const projectUserChannelSubscription: any =
                                this.mentoringService.getProjectUserChannel(data).subscribe(
                                    data => this.getProjectUserChannelsSuccess(data),
                                    error => this.getProjectUserChannelsFail(error)
                                );
        this.subscriptions.push(projectUserChannelSubscription);
    }

    capitalizeFirstLetter(str: any) {
        if (str && str.length) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        } else {
            return str;
        }
    }

    getProjectUserChannelsSuccess(data) {
        this.isMatched = false;
        if (data && data.data) {
            let userChannelData: any = data.data;
            let includedChannelData: any = data.included;
            userChannelData.map((item) => {
                includedChannelData.some(includedData => {
                    if (item.attributes.channel_id == includedData.id) {
                        item.attributes['channelType'] = includedData.attributes.channel_type;
                        return true;
                    }
                });
            });
            this.channelData = [];
            this.currentChannelsList = [];
            userChannelData.forEach((channelData) => {
                if (channelData.attributes.role === 'mentor') {
                    this.utility.mentorUserProjectId = channelData.attributes.project_user_id;
                }
                const channelType: any = channelData.attributes.channelType;
                const channelDataObject: any = {};
                channelDataObject['channelId'] = channelData.attributes.channel_id;
                this.currentChannelsList.push(channelDataObject.channelId);
                channelDataObject['channelType'] = channelType;
                if (channelType == 'mentoring') {
                    channelDataObject['channelName'] = this.capitalizeFirstLetter(channelData.attributes.display_name) + ' ' + `(${this.capitalizeFirstLetter(channelData.attributes.role)})`;
                } else if (channelType == 'group') {
                    channelDataObject['channelName'] = this.capitalizeFirstLetter(channelData.attributes.group_name) + ' ' + ` ${this.utility.getItem('currentLanguage') === 'en' ? '(Group Chat)':'(Sgwrs Grŵp)'}`;
                } else if (channelType == 'support') {
                    const supportChannelObj = {
                        'channel_id': channelData.attributes.channel_id,
                    };
                    this.currentUserStateService.setSupportChannelIdState(supportChannelObj);
                    channelDataObject['channelName'] = 'Support Team' ;
                } else {
                    channelDataObject['channelName'] = 'Unknown channel';
                }
                this.channelData.push(channelDataObject);
                this.channelData.forEach(mentoringChannel => {
                    if (mentoringChannel.channelType === 'mentoring') {
                        this.isMatched = true;
                    }
                });
                this.mentoringEvents.sendMentorMatchedData(this.isMatched);
            });
            if (this.channelData.length) {
                this.channelData = this.sortChannelsData();
                this.checkAndHighlightChannel();
            }
            this.displayChannelList = true;
        }
        const matchedObject = {
            'isMatched': this.isMatched,
        };
        this.currentUserStateService.setUserMatchedState(matchedObject);
        this.currentState$ = this.currentUserStateService.currentUserState$;
        this.observableSubscription = this.currentState$.subscribe(item => {
            this.isArchive = item.isArchive;
        })
        this.checkAndDisplayNotificationIcon();
    }

    getProjectUserChannelsFail(error) {
        this.isLoaded = true;
    }

    sortChannelsData() {
        return this.messagingHelper.sortChannelsData(this.channelData);
    }

    checkChannelAccess() {
        if (this.utility.getItem('isChannelAccess') !== 'True' && !this.isArchive) {
            this.toastr.error(AppConstant.RESTRICT_CHANNEL_ACCESS_DUE_TO_SURVEY);
        }
    }

    checkAndHighlightChannel() {
        if (this.router.url == '/channels') {
            if (this.channelData && this.channelData.length) {
                this.currentChannelID = this.channelData[0].channelId;
            }
        }else if (this.router.url.includes('/channels')) {
            if (this.channelData && this.channelData.length) {
                const urlParts = this.router.url.split('/');
                this.currentChannelID = +urlParts[urlParts.length - 1];
            }
        } 

    }
}
