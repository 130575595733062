declare let window: any;

export class Attachments {
    attachButton: any;
    constructor() {
        this.attachments();
    }

    attachments() {
        this.attachButton = $('.input--file-attachment');
        $(document).on("upload:start", "input",() => {
            return (e) => {
                let file;
                console.log('[Attachments] upload start');
                $("body").removeClass('state--file-upload-success');
                this.attachButton.hide();
                file = e.currentTarget.files[0];
                this.filePreviewElement().find(".file-preview__title:first").html(file.name);
                this.filePreviewElement().find(".file-preview__info:first").html((file.size));
                return $("body").addClass('state--uploaded-files');
            };
        });

        $(document).on("upload:success", "input", () => {
            return (e) =>{
                let upload;
                console.log("[Attachments] upload:success");
                upload = JSON.parse(e.detail);
                this.renderSuccessPreview();
                $("#attachment").val(JSON.stringify({
                    id: upload.id,
                    filename: upload.filename,
                    content_type: upload.format,
                    size: upload.size
                }));
                return $("body").addClass('state--file-upload-success');
            };
        });

        $(document).on("upload:failure", "input", (() => {
            return (e) => {
                console.log("[Attachments] upload:failure");
                return this.renderErrorPreview();
            };
        }));

        $(document).on("upload:progress", "input", () => {
            return (e) => {
                console.log("[Attachments] upload:progress");
                return console.log(e.detail.percent);
            };
        });

    }

    filePreviewElement() {
        return $("#attachment-preview");
    };

    renderSuccessPreview () {
        this.filePreviewElement().find('.file-preview__state').hide();
        this.renderPreview('uploading', 'destroyable');
        return this.filePreviewElement().find('*[class*="__action"]').show();
    };

    renderErrorPreview () {
        this.filePreviewElement().find('.file-preview__state.ui-state--uploading').hide();
        this.filePreviewElement().find('*[class*="__action"]').hide();
        this.renderPreview('uploading', 'error');
        return this.resetAttachments();
    };

    renderPreview = function(updated_state, original_state) {
        if (original_state == null) {
            original_state = "uploading";
        }
        return true;

    };

    destroyAttachment () {
        this.clearAttachmentField();
        this.renderPreview('uploading', 'destroyable');
        this.filePreviewElement().find('*[class*="__action"]').show();
        return this.resetAttachments();
    };

    clearAttachmentField () {
        $('.input--file-attachment').find('input').val('');
        return $("body").removeClass('state--uploaded-files');
    };

    uploadInProgress () {
        return this.attachButton.find("#attachment-file").hasClass('uploading');
    };

    resetAttachments () {
        $("body").removeClass('state--uploaded-files');
        $("body").removeClass('state--file-upload-success');
        this.clearAttachmentField();
        this.renderPreview('uploading', 'destroyable');
        this.renderPreview('uploading', 'error');
        this.filePreviewElement().find('.file-preview__state').hide();
        this.filePreviewElement().find('*[class*="__action"]').hide();
        this.filePreviewElement().find('.file-preview__state.ui-state--uploading').show();
        return this.attachButton.show();
    };
}

