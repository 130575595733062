import {Component, OnInit} from '@angular/core';
import {AuthenticationHelper} from "../../../helpers/authenticationHelper";
import {UtilityHelper} from "../../../helpers/utilityHelper";
import {ApplicationServices} from "../../../appServices/application";
import {MentoringEvents} from "../../../helpers/events";
import {AbstractBaseComponent} from "../../../app.baseComponent";
import {Observable} from "rxjs";
import {CurrentUserStateService} from "../../../appServices/current-user-state.service";
import { RouterLink } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'knowledge-left-side-menu',
    templateUrl: './knowledgeLeftSideMenu.component.html',
    styleUrls: ['./knowledgeLeftSideMenu.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, AsyncPipe]
})
export class KnowledgeLeftSideMenuComponent extends AbstractBaseComponent {
    role: string = '';
    isLoggedIn: boolean = false;
    firstName: any;
    adminURL: any = '';
    isSuperAdmin: any;
    isAdmin: boolean = false;
    isSuperAdmin$: Observable<any>;
    role$: Observable<any>;
    subscriptions: any = [];
    constructor(private authService: AuthenticationHelper, private utility: UtilityHelper,
                private applicationService: ApplicationServices, private mentoringEvent: MentoringEvents,
                private currentUserStateService: CurrentUserStateService) {
        super();
        this.getData();
    }

    getData() {
        this.isSuperAdmin$ = this.currentUserStateService.isSuperAdminState$;
        this.role$ = this.currentUserStateService.userRoleState$;
        if (this.authService.isLoggedIn()) {
            this.isLoggedIn = true;
        }
        let superAdminSub = this.isSuperAdmin$.subscribe(item => {
            this.isSuperAdmin = item ? true : false;
        });
        this.subscriptions.push(superAdminSub);
        let roleSub = this.role$.subscribe(item => {
            this.role = item;
            if(item == 'admin' || item == 'coordinator') {
                this.isAdmin = true;
                this.getJWT_Token();
            } else {
                this.isAdmin = false;
            }
        });
        this.subscriptions.push(roleSub);
        this.firstName = this.utility.getItem('firstName');

    }

    ngOnDestroy() {
        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }

    getJWT_Token(){
        let body = {
            "data":{
                "type":"sso_tokens",
                "attributes":{}
            }
        };
        this.applicationService.getsso_tokens(body).subscribe(
            data => this.getsso_tokensSuccess(data),
            error => this.getsso_tokensFail(error)
        );
    }

    getsso_tokensSuccess(data) {
        let token;
        if (data && data.data.attributes.token) {
            token = data.data.attributes.token;
            let project_id = this.utility.getItem('projectId');
            this.adminURL = '';
            this.adminURL = this.ADMIN_DASHBOARD_URL + 'session?jwt=' + token + '&current_project_id=' + project_id;
        }
    }

    getsso_tokensFail(error) {
        console.log("JWT error in Brightknowledge",error);
    }

    closeMenu() {
        this.mentoringEvent.isKnowledgeMenu.emit(false);
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.scrollIntoSection();
        }, 500 );
    }

    scrollIntoSection() {
        let element = document.querySelector('#categories');
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }
}
