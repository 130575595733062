import { Injectable } from '@angular/core';
import { UtilityHelper } from './utilityHelper';
import { MentoringServices } from '../appServices/mentoring';
@Injectable()
export class AuthenticationHelper {
    constructor(private utility: UtilityHelper, private mentoringService: MentoringServices) {
    }

    getToken() {
        return this.utility.getItem('token');
    }

    isLoggedIn() {
        let token = this.getToken();
        if (token && token.length > 0) {
            return true;
        }
        return false;
    }

    updateLastAccessedProject(currentProjectId) {
        const userId = this.utility.getItem('userId');

        const userPayload = {
            data: {
                type: 'users',
                id: userId,
                attributes: {
                    last_accessed_project_id: currentProjectId,
                },
            },
        };

        this.mentoringService.updateLastAccessedProject(userId, userPayload).subscribe(
            data => this.updateLastAccessedProjectSuccess(data),
            error => this.updateLastAccessedProjectFail(error),
        );
    }

    updateLastAccessedProjectSuccess(data) {}

    updateLastAccessedProjectFail(error) {}
}
