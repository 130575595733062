import {environment} from "../environments/environment";

// This service initializes Bugsnag and makes it available to the root module.
// Below are examples of some possible configurations, but only the api apiKey is required to get started.

export const bugsnagClientConfiguration: any = {
    apiKey: environment.bugsnagApikey,
    // if you track deploys or use source maps, make sure to set the correct version.
    appVersion: environment.appVersion,
    // defines the release stage for all events that occur in this app.
    releaseStage: environment.releaseStage,
    //  defines which release stages bugsnag should report. e.g. ignore staging errors.
    notifyReleaseStages: [ 'development', 'production'],
    // add any custom attributes relevant to your app.
    // Note that metadata can be added here, in a specific notify (example bleow) or in a beforeSend.
    metaData: {
        company: {
            name: 'Brightside',
            country: 'uk'
        }
    },
}
