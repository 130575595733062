import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import { Router } from "@angular/router";
import { UtilityHelper } from "../helpers/utilityHelper";
import { HttpClientHelper } from "./apiClient";
import { SocketService } from "../messageServices/socketService";
import { NotificationClient } from "../messageServices/notificationClient";
import { MentoringSocketService } from "../messageServices/mentoringSocketService";
import { forkJoin } from "rxjs/observable/forkJoin";
import { throwError } from "rxjs";
import { catchError,map  } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class AdminServices {

    private getUsersDetailsUrl = 'users';
    private getAdminUsersDetailsUrl = 'admin/users';
    private projectUrl = 'projects';
    private getNotificationsDetailsUrl = 'notifications';
    private getSessionDetailsUrl = 'sessions';
    private getPartnersDetailsUrl: string = 'partners';
    private moderationRequestsUrl: string = 'admin/moderation_requests';
    private filterAllModerationDataUrl: string = 'admin/channel_users';
    private getModerationProjectUrl: string = 'v2/projects';
    private projectCodesUrl = 'project_codes';
    private getProjectUsersDetailsUrl = 'project_users';
    private getProjectAnnouncementsDetailsUrl = 'project_user_announcements';
    private joinProjectUrl = 'admin/join_projects';
    private projectGroupUrl = 'project_groups';
    private getTagCategoriesUrl = 'tag_categories';
    private getEditGroupDetailsUsersUrl = 'v2/project_users/all';
    private partnerActivitiesUrl = 'attached_activities';
    private externalAssignmentsUrl = 'attached_external_materials';
    private getActivitiesCatagoriesUrl = 'categories';
    private getActivitiesUrl = 'activities';
    private getExternalMaterialsUrl = 'external_materials';
    private getProjectTagsUrl = 'tags';
    private getStopWordListUrl = 'admin/stop_words';
    private removeAvatarUrl = 'users';
    private landingPageUrl = 'landing_pages';
    private sendAnnouncementsUrl = 'announcements';
    private getProjectInvitationsUrl = 'project_invitations';
    private getProjectListUrl = 'v2/projects';
    private sendProjectUserTagsUrl = 'project_user_tags';
    private getAvatarModerationListUrl = 'avatar_moderations';
    private updateGraduate = 'ask_graduates';
    private graduatesUrl = 'graduates';
    private matchUserUrl = 'project_matches';
    private getPostModerationListUrl = 'admin/post_moderations';
    private getActivityModerationListUrl = '/admin/activity_moderations';
    private approveDeclineAllModerationUrl = 'moderation_requests';
    private getPendingModerationRequestUrl = 'admin/projects';
    private getSupportRequestsUrl = 'support_requests';
    private getStopWordExceptionsListUrl = 'admin/stop_word_exceptions';
    private getAdminSessionUrl = '/admin/sessions';
    private findStopWordUrl = '/admin/stop_words';
    private bypassStopWordExceptionUrl = 'stop_word_exceptions';
    private createStopWordUrl = 'stop_words';
    private getProjectRolesUrl = '/project_roles';
    private reportRequestsUrl = 'report_requests';
    private getPlatformMetricsGraphUrl = 'platform_metric_queries';
    private invitationsUploadsUrl = 'project_invitation_uploads';
    private joinSupportRequestUrl = 'channel_users';
    private getNewsArticlesUrl = 'news_articles';
    private httpClient: HttpClientHelper;
    private activityQuestionUrl = 'activity_questions';
    private getArticlesUrl = 'articles';
    private postArticlesSectionUrl = 'article_sections';
    private attachedActivityUrl = 'attached_activities';
    private connectionLandingUrl = 'connection_landing_pages';
    private approveOrRejectArticlesUrl = 'admin/news_article_moderations';
    private pendingModerationNotification = 'user_notifications';
    private mentorProfileModerationRequestsUrl = 'admin/mentor_profile_moderations';
    private reviewMessageUrl = 'admin/review_messages';
    private videoCallFeedbackReportingUrl = 'admin/video_call_feedback_reports';
    private exportvideocallfeedbackUrl = 'admin/video_call_feedback_reports/export_csv';
    private messageReportRequestUrl = 'message_report_requests';
    private getReviewMessageReportingDataUrl = 'admin/review_messages';
    private mentorProfileRequestUrl = 'mentor_profile_report_requests';
    private directMessageUrl = 'send_message_to_users';
    private saveReportingProfile = 'reporting_profiles';
    private mentorProfilesUrl = 'mentor_profiles';
    private projectInvitationUrl = 'bulk_project_invitations';
    private bulkArchiveInactiveUrl = 'bulk_inactive_archives';
    private exportAnnouncementInvitationListURL = 'project_announcement_report_requests';
    private exportInvitationListUrl = 'project_invitation_report_requests';
    private getEngagementDataURL = 'engagement_metrics';
    private userDataDeletionUrl = 'user_deletion_requests';
    private projectUserDataDeletionUrl = 'project_users_deletion_requests';
    private getCoordinatorsURL = 'brightside_coordinators';
    private getEthnicityURL = 'ethnicities';
    private projectInvitationTemplateurl = 'project_invitation_templates';
    private projectInvitationTemplatePreviewUrl = 'project_invitation_previews';
    private invitationsTagUploadsUrl = 'bulk_tag_assigns';
    private bulkArchiveTagsUrl = 'bulk_support_request_archives';
    private videoCallModerationRequestsUrl = 'admin/room_recording_moderations';
    private approveDeclineAllModerationUrlVideo = 'admin/room_recording_moderations';

    constructor(httpClient: HttpClientHelper, private router: Router,
        private utility: UtilityHelper, private socketService: SocketService,
        private notification: NotificationClient, private mentoringSocketService: MentoringSocketService) {
        this.httpClient = httpClient;
    }

    // To get users data
    getUsersDetails(data): Observable<any> {
        return this.httpClient.get(this.getUsersDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getAdminUsersDetails(data): Observable<any> {
        return this.httpClient.get(this.getAdminUsersDetailsUrl + data)
            .pipe(map(this.extractResponse),
            catchError(this.handleError.bind(this)))
    }

    // To get user data using ID
    getUserDetailUsingId(data): Observable<any> {
        return this.httpClient.get(this.getUsersDetailsUrl + '/' + data.id)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get users details using Id
    getUsersDetailsUsingId(data): Observable<any> {
        return this.httpClient.get(this.getUsersDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get tags details using project user Id
    getProjectDetailsUsingId(data): Observable<any> {
        return this.httpClient.get(this.getProjectUsersDetailsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }
    getProjectAnnouncements(data): Observable<any> {
        return this.httpClient.get(this.getProjectAnnouncementsDetailsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get notifications details using user Id
    getNotificationsDetailsUsingId(data): Observable<any> {
        return this.httpClient.get(this.getNotificationsDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Session details using user Id
    getSessionDetailsUsingId(data): Observable<any> {
        return this.httpClient.get(this.getSessionDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Projects details
    getProjectsDetails(data): Observable<any> {
        return this.httpClient.get(this.projectUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Project Details
    getProjectDetails(data): Observable<any> {
        return this.httpClient.get(this.projectUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // Update Project Details
    updateProjectDetails(id, data): Observable<any> {
        return this.httpClient.patch(this.projectUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // Update Project Status
    updateProjectStatus(id, data): Observable<any> {
        return this.httpClient.put(this.projectUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // Update user info using ID
    updateUserInfo(id, data): Observable<any> {
        return this.httpClient.put(this.getUsersDetailsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Partners Details
    getPartnersDetails(data): Observable<any> {
        return this.httpClient.get(this.getPartnersDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get all moderation data
    getAllModerationData(data): Observable<any> {
        return this.httpClient.get(this.moderationRequestsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Mentee codes
    getMenteeCodes(data): Observable<any> {
        return this.httpClient.get(this.projectCodesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To filter all moderation data
    filterAllModerationData(data): Observable<any> {
        return this.httpClient.get(this.filterAllModerationDataUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To project moderation data
    getModerationProject(data): Observable<any> {
        return this.httpClient.get(this.getModerationProjectUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getModerationRequest(data): Observable<any> {
        return this.httpClient.get(this.moderationRequestsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getReviewMessageReportingData(data): Observable<any> {
        return this.httpClient.get(this.getReviewMessageReportingDataUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }


    // To get Mentor codes
    getMentorCodes(data): Observable<any> {
        return this.httpClient.get(this.projectCodesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To generate Mentee Code
    generateMenteeCode(data): Observable<any> {
        return this.httpClient.post(this.projectCodesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To generate Mentor Code
    generateMentorCode(data): Observable<any> {
        return this.httpClient.post(this.projectCodesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get project Groups Details
    getProjectGroupDetails(data): Observable<any> {
        return this.httpClient.get(this.projectGroupUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get project Users Details
    getProjectUsersDetails(data): Observable<any> {
        return this.httpClient.get(this.getProjectUsersDetailsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get tag categories

    getTagCategories(data): Observable<any> {
        return this.httpClient.get(this.getTagCategoriesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get tag categories
    getProjectTags(data): Observable<any> {
        return this.httpClient.get(this.getProjectTagsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }


    // To get tag categories
    getEditGroupDetails(data): Observable<any> {
        return this.httpClient.get(this.projectGroupUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get tag categories
    getEditGroupDetailsUsers(data): Observable<any> {
        return this.httpClient.get(this.getEditGroupDetailsUsersUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete Project Group
    deleteProjectGroup(id, data): Observable<any> {
        return this.httpClient.put(this.projectGroupUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update Partner deatils
    updatePartner(url, data): Observable<any> {
        return this.httpClient.put(this.getPartnersDetailsUrl + url, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create Partner deatils
    createPartner(url, data): Observable<any> {
        return this.httpClient.post(this.getPartnersDetailsUrl + url, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get partner Activities
    getPartnerActivities(data): Observable<any> {
        return this.httpClient.get(this.partnerActivitiesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get partner Activities
    getPartnerExternalMaterials(data): Observable<any> {
        return this.httpClient.get(this.externalAssignmentsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create Project Group

    createProjectGroup(data): Observable<any> {
        return this.httpClient.post(this.projectGroupUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To edit Project Group
    editProjectGroup(id, data): Observable<any> {
        return this.httpClient.put(this.projectGroupUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get activities categories
    getActivitiesCatagories(data): Observable<any> {
        return this.httpClient.get(this.getActivitiesCatagoriesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get activities
    getActivities(data): Observable<any> {
        return this.httpClient.get(this.getActivitiesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get External Materials
    getExternalMaterials(data): Observable<any> {
        return this.httpClient.get(this.getExternalMaterialsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To assign external materials for Partner

    attachPartnerExternalMaterials(data): Observable<any> {
        return this.httpClient.post(this.externalAssignmentsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To assign Activities for Partner

    attachPartnerActivities(data): Observable<any> {
        return this.httpClient.post(this.partnerActivitiesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update attached activity

    updateAttachedActivity(id, data): Observable<any> {
        return this.httpClient.put(`${this.attachedActivityUrl}/${id}`, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete project user tag

    deleteProjectTag(data): Observable<any> {
        return this.httpClient.delete(this.getProjectTagsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create Project Tag

    createTagForProject(data): Observable<any> {
        return this.httpClient.post(this.getProjectTagsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get stop words list

    getStopWordList(data): Observable<any> {
        return this.httpClient.get(this.getStopWordListUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update Project Tag

    updateTagForProject(id, data): Observable<any> {
        return this.httpClient.put(this.getProjectTagsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create Project
    createProject(data): Observable<any> {
        return this.httpClient.post(this.projectUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To remove avatar
    removeAvatar(data, id): Observable<any> {
        return this.httpClient.patch(this.removeAvatarUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get landing page details
    getLandingPageDetails(data): Observable<any> {
        return this.httpClient.get(this.landingPageUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To send announcement

    sendAnnouncements(data): Observable<any> {
        return this.httpClient.post(this.sendAnnouncementsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To fetch project invitations

    getProjectInvitations(data): Observable<any> {
        return this.httpClient.get(this.getProjectInvitationsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To send invitations

    sendInvitations(data): Observable<any> {
        return this.httpClient.post(this.getProjectInvitationsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // to get project list

    getUsersProjectList(data): Observable<any> {
        return this.httpClient.get(this.getProjectListUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update Landing Page details
    updateLandingPage(url, data): Observable<any> {
        return this.httpClient.put(this.landingPageUrl + url, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get mentor and mentee id for landing page
    getLandingPageId(data): Observable<any> {
        return this.httpClient.post(this.landingPageUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To resend invitations

    reSendInvitations(id, data): Observable<any> {
        return this.httpClient.patch(this.getProjectInvitationsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // to get specific project details
    getSpecificProject(data): Observable<any> {
        return this.httpClient.get(this.getProjectListUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To attach tags to the users.

    attachTagsToUsers(data): Observable<any> {
        return this.httpClient.post(this.sendProjectUserTagsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // Attach multiple tags to users
    attachMultipleTagsToUsers(payloads): Observable<any[]> {
        let responses = [];
        if (payloads && payloads.length) {
            payloads.forEach((payload) => {
                responses.push(this.httpClient.post(this.sendProjectUserTagsUrl, payload)
                    .pipe(
catchError(e => Observable.of('Error in attaching tags to the users'))));
            });
        }
        return forkJoin(responses);
    }

    // to get tag delete id list

    getTagIdToDelete(data): Observable<any> {
        return this.httpClient.get(this.sendProjectUserTagsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete user tag from details

    deleteProjectTagFromUser(data): Observable<any> {
        return this.httpClient.delete(this.sendProjectUserTagsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update Notifications avatar
    updateNotificationInUserTag(data, id): Observable<any> {
        return this.httpClient.patch(this.getProjectUsersDetailsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get avatar moderation list

    getAvatarModerationList(data): Observable<any> {
        return this.httpClient.get(this.getAvatarModerationListUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To approve avatar

    approveAvatar(id, data): Observable<any> {
        return this.httpClient.patch(this.getAvatarModerationListUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get all the graduate profiles
    getGraduates(data): Observable<any> {
        return this.httpClient.get(this.updateGraduate + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update the ask the graduate's details
    updateGraduateDetails(url, data): Observable<any> {
        return this.httpClient.patch(this.updateGraduate + url, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create a new graduate
    postGraduateDetails(data): Observable<any> {
        return this.httpClient.post(this.graduatesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update the existing graduates details

    updateEditedGraduate(graduateId, data): Observable<any> {
        return this.httpClient.patch(this.graduatesUrl + '/' + graduateId, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To match mentee or mentor

    matchUser(data): Observable<any> {
        return this.httpClient.post(this.matchUserUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get post moderation list

    getPostModerationList(data): Observable<any> {
        return this.httpClient.get(this.getPostModerationListUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To unmatch mentee or mentor

    unmatchUser(data): Observable<any> {
        return this.httpClient.delete(this.matchUserUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get specific post moderation details

    getSpecificPostModeration(data): Observable<any> {
        return this.httpClient.get(this.getPostModerationListUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To approve decline post moderation

    approveDeclinePostModeration(id, data): Observable<any> {
        return this.httpClient.patch(this.getPostModerationListUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get activity moderation list
    getActivityModerationList(data): Observable<any> {
        return this.httpClient.get(this.getActivityModerationListUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete graduate profile
    deleteGraduate(data): Observable<any> {
        return this.httpClient.delete(this.graduatesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get specific activity moderation details
    getSpecificActivityModeration(data): Observable<any> {
        return this.httpClient.get(this.getActivityModerationListUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get graduate question and answers
    getGraduatesResponses(data): Observable<any> {
        return this.httpClient.get(this.graduatesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To approve decline activity moderation
    approveDeclineActivityModeration(id, data): Observable<any> {
        return this.httpClient.patch(this.getActivityModerationListUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create a new graduate answer
    postGraduateAnswer(url, data): Observable<any> {
        return this.httpClient.post(this.graduatesUrl + url, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    approveOrRejectArticles(id, data): Observable<any> {
        return this.httpClient.patch(this.approveOrRejectArticlesUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getModerationArticles(data): Observable<any> {
        return this.httpClient.get(this.approveOrRejectArticlesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getSpecificModerationArticles(id): Observable<any> {
        return this.httpClient.get(this.approveOrRejectArticlesUrl + '/' + id)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getMentorProfileModerationRequests(data): Observable<any> {
        return this.httpClient.get(this.mentorProfileModerationRequestsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getMentorProfileModerationRequest(id): Observable<any> {
        return this.httpClient.get(`${this.mentorProfileModerationRequestsUrl}/${id}`)
            .pipe(catchError(this.handleError.bind(this)));
    }

    processMentorProfileModerationRequest(id, data): Observable<any> {
        return this.httpClient.patch(`${this.mentorProfileModerationRequestsUrl}/${id}`, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get support requests

    getSupportRequests(data): Observable<any> {
        return this.httpClient.get(this.getSupportRequestsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get pending moderation list

    getPendingModerationRequest(data): Observable<any> {
        return this.httpClient.get(this.getPendingModerationRequestUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get support requests

    getSupportUsersTag(data): Observable<any> {
        return this.httpClient.get(this.getUsersDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get project pending moderation list

    getProjectPendingModerationList(data): Observable<any> {
        return this.httpClient.get(this.moderationRequestsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To approve decline pending moderation
    approveDeclinePendingModeration(id, data): Observable<any> {
        return this.httpClient.patch(this.approveDeclineAllModerationUrl + '/' + id, data, 'MSG_SERVICE_URL_FOR_MODERATION')
        .pipe(catchError(this.handleError.bind(this)));
    }

    // To approve decline video call modertion
    approveDeclineVideoCallModeration(id, data): Observable<any> {
        return this.httpClient.patch(this.approveDeclineAllModerationUrlVideo + '/' + id, data)
            .pipe(map(this.extractResponse))
            .pipe(map(this.handleError.bind(this)))
    }
    // To get adminable projects

    getAdminableProjects(data): Observable<any> {
        return this.httpClient.get(this.projectUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get stop word exceptions list

    getStopWordExceptionsList(data): Observable<any> {
        return this.httpClient.get(this.getStopWordExceptionsListUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get admin session

    getAdminSession(token, data): Observable<any> {
        return this.httpClient.get(this.getAdminSessionUrl + '/' + token + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To find stop word

    findStopWord(data): Observable<any> {
        return this.httpClient.get(this.findStopWordUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To archive support request

    archiveSupportRequest(data, id): Observable<any> {
        return this.httpClient.patch(this.getSupportRequestsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
     * To request for the user report
     * */
    requestUserReport(data): Observable<any> {
        return this.httpClient.post(this.reportRequestsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * To request for the mentor profile report
     */

    requestMentorProfileReport(data): Observable<any> {
        return this.httpClient.post(this.mentorProfileRequestUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
     * To get the partners data
     * */
    getPartnersData(data): Observable<any> {
        return this.httpClient.get(data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To bypass stopWord exception
    bypassStopWordException(data): Observable<any> {
        return this.httpClient.post(this.bypassStopWordExceptionUrl, data, 'MSG_SERVICE_URL_FOR_MODERATION')
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To remove bypass stopword exception

    removeBypassStopWordException(id): Observable<any> {
        return this.httpClient.delete(this.bypassStopWordExceptionUrl + '/' + id, 'MSG_SERVICE_URL_FOR_MODERATION')
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create stopword

    createStopWord(data): Observable<any> {
        return this.httpClient.post(this.createStopWordUrl, data, 'MSG_SERVICE_URL_FOR_MODERATION')
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get stop word details

    getStopWord(data): Observable<any> {
        return this.httpClient.get(this.createStopWordUrl + '/' + data, 'MSG_SERVICE_URL_FOR_MODERATION')
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update stopword

    editStopWord(id, data): Observable<any> {
        return this.httpClient.patch(this.createStopWordUrl + '/' + id, data, 'MSG_SERVICE_URL_FOR_MODERATION')
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete stopword

    deleteStopWord(id): Observable<any> {
        return this.httpClient.delete(this.createStopWordUrl + '/' + id, 'MSG_SERVICE_URL_FOR_MODERATION')
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get the roles

    getRoles(data): Observable<any> {
        return this.httpClient.get(this.getProjectRolesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    //To get the report file
    getReportFile(id): Observable<any> {
        return this.httpClient.get(this.reportRequestsUrl + '/' + id)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To add survey to projects
    addSurvey(id, data): Observable<any> {
        return this.httpClient.patch(this.projectUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Platform Metrics Graph

    getPlatformMetricsGraph(data): Observable<any> {
        return this.httpClient.post(this.getPlatformMetricsGraphUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create a new activity
    createNewActivity(data): Observable<any> {
        return this.httpClient.post(this.getActivitiesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update  a  activity
    updateActivity(activityId, data): Observable<any> {
        return this.httpClient.patch(this.getActivitiesUrl + '/' + activityId, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To post the ativity question
    postNewActivityQuestions(data): Observable<any> {
        return this.httpClient.post(this.activityQuestionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete a activity question
    deleteActivityQuestion(id): Observable<any> {
        return this.httpClient.delete(this.activityQuestionUrl + '/' + id)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Platform Metrics Graph
    bulkUploadUsersOfInvitations(data): Observable<any> {
        return this.httpClient.post(this.invitationsUploadsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }
    bulkTagUploadUsersOfInvitations(data): Observable<any> {
        return this.httpClient.post(this.invitationsTagUploadsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get project user id
    getProjectUserId(data): Observable<any> {
        return this.httpClient.get(this.getProjectUsersDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To join project
    joinProject(data): Observable<any> {
        return this.httpClient.post(this.joinProjectUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To join support request

    joinSupportRequest(data): Observable<any> {
        return this.httpClient.post(this.joinSupportRequestUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get news articles
    getNewsArticles(data): Observable<any> {
        return this.httpClient.get(this.getNewsArticlesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete news article

    deleteNewsArticle(id): Observable<any> {
        return this.httpClient.delete(this.getNewsArticlesUrl + '/' + id)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get news articles

    getNewsCategories(data): Observable<any> {
        return this.httpClient.get(this.getActivitiesCatagoriesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To publish and save to drafts news article

    publishNewsArticle(data): Observable<any> {
        return this.httpClient.post(this.getNewsArticlesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get details of one news article

    getNewsArticle(id): Observable<any> {
        return this.httpClient.get(this.getNewsArticlesUrl + id)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get  articles
    getArticles(data): Observable<any> {
        return this.httpClient.get(this.getArticlesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create a new article
    createNewArticle(data): Observable<any> {
        return this.httpClient.post(this.getArticlesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create a new article
    postNewArticlesections(data): Observable<any> {
        return this.httpClient.post(this.postArticlesSectionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update the news article

    updateNewsArticle(id, data): Observable<any> {
        return this.httpClient.patch(this.getNewsArticlesUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Categories

    getCategories(data): Observable<any> {
        return this.httpClient.get(this.getActivitiesCatagoriesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get Category details

    getCategoryDetails(data): Observable<any> {
        return this.httpClient.get(this.getActivitiesCatagoriesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create new category

    createCategory(data): Observable<any> {
        return this.httpClient.post(this.getActivitiesCatagoriesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update the news article

    updateCategory(id, data): Observable<any> {
        return this.httpClient.patch(this.getActivitiesCatagoriesUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete category

    deleteCategory(id): Observable<any> {
        return this.httpClient.delete(this.getActivitiesCatagoriesUrl + '/' + id)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create a new article
    updateArticle(articleId, data): Observable<any> {
        return this.httpClient.patch(this.getArticlesUrl + '/' + articleId, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update article section
    updateArticleSection(articleSectionId, data): Observable<any> {
        return this.httpClient.patch(this.postArticlesSectionUrl + '/' + articleSectionId, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To delete a article section
    deleteSection(id): Observable<any> {
        return this.httpClient.delete(this.postArticlesSectionUrl + '/' + id)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create new External Material

    createExternalMaterial(data): Observable<any> {
        return this.httpClient.post(this.getExternalMaterialsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get external material

    getExternalMaterialDetails(data): Observable<any> {
        return this.httpClient.get(this.getExternalMaterialsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update the external material

    updateExternalMaterial(id, data): Observable<any> {
        return this.httpClient.patch(this.getExternalMaterialsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get brightside coordinators

    getCoordinators(data): Observable<any> {
        return this.httpClient.get(this.getProjectUsersDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To archive unarchive project users

    archiveProjectUser(projectUserId, data): Observable<any> {
        return this.httpClient.patch(this.getProjectUsersDetailsUrl + '/' + projectUserId, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To bulk archive unarchive project users

    bulkArchiveInactiveProjectUser(projectUserId, data): Observable<any> {
        return this.httpClient.post(this.bulkArchiveInactiveUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    bulkArchiveTags(supportRequestId, data): Observable<any> {
        return this.httpClient.post(this.bulkArchiveTagsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }


    // To remove a specific activity
    removeActivity(activityId): Observable<any> {
        return this.httpClient.delete(this.getActivitiesUrl + '/' + activityId)
            .pipe(catchError(this.handleError.bind(this)));
    }

    sendConnectionLandingPageDetails(url, data): Observable<any> {
        return this.httpClient.put(this.connectionLandingUrl + url, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getConnectionDetails(data): Observable<any> {
        return this.httpClient.get(this.connectionLandingUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    directMessage(data): Observable<any> {
        return this.httpClient.post(this.directMessageUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    createPendingModerationNotification(data): Observable<any> {
        return this.httpClient.post(this.pendingModerationNotification, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    createReportingProfile(data): Observable<any> {
        return this.httpClient.post(this.saveReportingProfile, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    updateReportingProfile(profileId, data): Observable<any> {
        return this.httpClient.patch(this.saveReportingProfile + `/${profileId}`, data)
            .pipe(catchError(this.handleError.bind(this)));
    }


    fetchSavedProfiles(data): Observable<any> {
        return this.httpClient.get(this.saveReportingProfile + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To remove a specific activity
    removeReportingProfile(profileId): Observable<any> {
        return this.httpClient.delete(this.saveReportingProfile + `/${profileId}`)
            .pipe(catchError(this.handleError.bind(this)));
    }

    reviewMessageReporting(data): Observable<any> {
        return this.httpClient.get(this.reviewMessageUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }
    videocallfeedbackReporting(data): Observable<any> {
        return this.httpClient.get(this.videoCallFeedbackReportingUrl + data)
            .pipe(map(this.extractResponse),
            catchError(this.handleError.bind(this)))
    }

    getvideocallfeedbackReporting(data): Observable<any> {
        return this.httpClient.get(this.videoCallFeedbackReportingUrl + '/' + data)
            .pipe(map(this.extractResponse),
            catchError(this.handleError.bind(this)))
    }

    exportvideocallfeedback(data): Observable<any> {
        return this.httpClient.get(this. exportvideocallfeedbackUrl + data)
            .pipe(map(this.extractResponse),
            catchError(this.handleError.bind(this)))
    }
    exportMessages(data): Observable<any> {
        return this.httpClient.post(this.messageReportRequestUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getMessageReportCSVData(id): Observable<any> {
        return this.httpClient.get(this.messageReportRequestUrl + '/' + id)
    }

    sendAvaibility(id, data): Observable<any> {
        return this.httpClient.patch(`${this.mentorProfilesUrl}/${id}`, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To resend bulk project invitations
    resendBulkProjectInvitations(body): Observable<any> {
        return this.httpClient.post(this.projectInvitationUrl, body)
            .pipe(catchError(this.handleError.bind(this)));
    }

    //export invitation list
    exportInvitationList(data): Observable<any> {
        return this.httpClient.post(this.exportInvitationListUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    //delete invitation list
    deleteInvitationList(invitationIds): Observable<any> {
        return this.httpClient.post(this.projectInvitationUrl, invitationIds)
            .pipe(catchError(this.handleError.bind(this)));
    }

    exportAnnouncementInvitationList(body): Observable<any> {
        return this.httpClient.post(this.exportAnnouncementInvitationListURL, body)
            .pipe(catchError(this.handleError.bind(this)));
    }

    fetchEngagementData(data): Observable<any> {
        return this.httpClient.post(this.getEngagementDataURL, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    deleteUserData(data): Observable<any> {
        return this.httpClient.post(this.userDataDeletionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    deleteProjectUserData(data): Observable<any> {
        return this.httpClient.post(this.projectUserDataDeletionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getEthnicityDetails(data): Observable<any> {
        return this.httpClient.get(this.getEthnicityURL + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getUserDetail(data): Observable<any> {
        return this.httpClient.get(this.getUsersDetailsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }
    updateModerationMessages(id, data): Observable<any> {
        return this.httpClient.patch(this.approveDeclineAllModerationUrl + '/' + id, data, 'MSG_SERVICE_URL_FOR_MODERATION')
        .pipe(catchError(this.handleError.bind(this)));
    }

    getBrightsideCoordinators(): Observable<any> {
        return this.httpClient.get(this.getCoordinatorsURL)
            .pipe(catchError(this.handleError.bind(this)));
    }

    addProjectInvitationTemplate(data): Observable<any> {
        return this.httpClient.post(this.projectInvitationTemplateurl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getProjectInvitationsDetails(partnerId): Observable<any> {
        return this.httpClient.get('partners/' + partnerId + '?include=project_invitation_templates')
            .pipe(catchError(this.handleError.bind(this)));
    }

    updateProjectInvitationTemplate(data): Observable<any> {
        return this.httpClient.put(this.projectInvitationTemplateurl + '/' + data.data.id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    addProjectInvitationTemplatePreview(data): Observable<any> {
        return this.httpClient.post(this.projectInvitationTemplatePreviewUrl, data)
        .pipe(catchError(this.handleError.bind(this)));
    }
    approveAccount(id, data): Observable<any> {
        return this.httpClient.post(this.userDataDeletionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }
    // To get all video call moderation data
    getAllVideoCallModerationData(data): Observable<any> {
        return this.httpClient.get(this.videoCallModerationRequestsUrl + data)
            .pipe(map(this.extractResponse),
            catchError(this.handleError.bind(this)))
    }
    getVideoModerationRequest(data): Observable<any> {
        return this.httpClient.get(this.videoCallModerationRequestsUrl + '/' + data)
            .pipe(map(this.extractResponse),
            catchError(this.handleError.bind(this)))
    }
    
    private extractResponse(res) { return res; }

    private handleError(error) {
        if (error.error == "Unauthorized") {
            this.socketService.disconnectSocket();
            this.mentoringSocketService.disconnectSocket();
            this.notification.disconnectSocket();
            this.utility.clear();
            this.router.navigate([''])
        } else {
            return throwError(error.error);
        }
    }
}
