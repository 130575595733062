// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  baseUrl: 'https://dev.brightsideapi.tudip.uk/',
  mentoringUrl: 'https://dev.angular.brightsidementoring.tudip.uk/',
  adminDashboardUrl: 'https://dev.angular.brightsideadmin.tudip.uk/',
  brightKnowledgeUrl: 'https://dev.angular.brightsideknowledge.tudip.uk/',
  baseUrlForImgUpload: 'https://dev.brightsideimage.tudip.uk/brightside-assets/upload',
  imgServerCDN: 'https://d3v0z1a6j4y9wy.cloudfront.net/',
  recordedVideoServerCdn: 'https://brightside-video-calls.s3.eu-west-1.amazonaws.com/upload/',
  youtubeBaseUrl: "https://www.youtube.com/embed/",
  vimeoBaseUrl: "https://player.vimeo.com/video/",
  brightsideUrl: 'dev.angular.brightsidementoring.tudip.uk',
  messageServiceUrlForModeration: "https://dev.message.brightside.tudip.uk/",
  messageServiceUrl: "wss://dev.message.brightside.tudip.uk/socket",
  UAIDForMentoring: 'UA-134589454-1',
  UAIDForKnowledge: 'UA-134589454-2',
  knowledgeProjectCode: 'LEBQEF',
  typeFormSurveyLink: 'https://brightsidesurveys.typeform.com/to/SRXT8o',
  algoliaArticleOptions: {
    appId: '8758NK3Q5J',
    apiKey: 'f84034bf25273389f6560536d334c327',
    indexName: 'articles_development',
    searchParameters: {
        hitsPerPage: 5,
    },
    algolia_search_key: '97f263c3e9e14433b736ca7cd2b032c5',
    highlight: true,
  },
  algoliaCategoryOptions: {
    appId: '8758NK3Q5J',
    apiKey: 'f84034bf25273389f6560536d334c327',
    indexName: 'categories_development',
    searchParameters: {
        hitsPerPage: 5,
    },
    algolia_search_key: '97f263c3e9e14433b736ca7cd2b032c5',
    highlight: true,
  },
  algoliaNewsArticleOptions: {
    appId: '8758NK3Q5J',
    apiKey: 'f84034bf25273389f6560536d334c327',
    indexName: 'news_articles_development',
    searchParameters: {
        hitsPerPage: 5,
    },
    algolia_search_key: '97f263c3e9e14433b736ca7cd2b032c5',
    highlight: true,
  },
  appId: 'ze9dtma8',
  typeformURL: '',
  typeFormAPIToken: 'BKdV6V6fUy4aiBgtA9o3adEp82re26K1bS2udyrn3WmS',
  appDownloadLink: 'https://dev.brightsidelinks.tudip.uk/app',
  s3BucketOriginUrl: 'https://brightside-assets.s3-eu-west-1.amazonaws.com',
  releaseStage: 'development',
  appVersion: '1.0.0',
  bugsnagApikey: '881d78051d04d1e7c4414e974144b943',
  version: '1.0.0',
  redirectFileName: 'redirects.dev.csv',
  encyptDecryptKey: 'ff9a1f0fbf82ac3cac0eabca7946f6f4'
};