import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {UtilityHelper} from '../helpers/utilityHelper';
import {AppConstant} from '../app.constant';
import {AbstractBaseComponent} from '../app.baseComponent';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class HttpClientHelper extends AbstractBaseComponent {

    constructor(private http: HttpClient, private utility: UtilityHelper) {
        super();
    }

    createAuthorizationHeader(type?: string): HttpHeaders {
        if (type) {
           return new HttpHeaders(
                {
                    'Content-Type': 'application/vnd.api+json',
                    'AUTHORIZATION': `Token token="${this.utility.getItem('token')}"`
                });
        } else {
            return new HttpHeaders(
                {
                    'Content-Type': 'application/vnd.api+json',
                    'AUTHORIZATION': `Token token="${this.utility.getItem('token')}"`,
                    'project-id' : `${this.utility.getItem('projectId')}`,
                });
        }
    }

    getBaseUrl(type) {
        if (type == 'MSG_SERVICE_URL_FOR_MODERATION') {
            return AppConstant.MSG_SERVICE_URL_FOR_MODERATION;
        } else {
            return AppConstant.BASE_URL;
        }
    }

    get(url, type?: string): Observable<any> {
        url = this.getBaseUrl(type) + url;
        const headers: HttpHeaders = this.createAuthorizationHeader(type);
        return this.http.get(url, { headers })
    }

    post(url, data, type?: string): Observable<any> {
        url = this.getBaseUrl(type) + url;
        const body: any = JSON.stringify(data);
        const headers: HttpHeaders = this.createAuthorizationHeader(type);
        return this.http.post(url, body, { headers })
    }

    patch(url, data, type?: string): Observable<any> {
        url = this.getBaseUrl(type) + url;
        const body: any = JSON.stringify(data);
        const headers: HttpHeaders = this.createAuthorizationHeader(type);
        return this.http.patch(url, body, { headers })
    }

    delete(url: string, type?: string): Observable<any> {
        url = this.getBaseUrl(type) + url;
        const headers: HttpHeaders = this.createAuthorizationHeader(type);
        return this.http.delete(url, { headers })
    }

    put(url, data, type?: string): Observable<any> {
        const body: any = JSON.stringify(data);
        const headers: HttpHeaders = this.createAuthorizationHeader(type);
        url = this.getBaseUrl(type) + url;
        return this.http.put(url, body, { headers })
    }

    imgPost(url, data, options): Observable<any> {
        url = this.BASE_URL_FOR_IMG_UPLOAD;
        return this.http.post(url, data, options)
    }

    getTypeForm(url): Observable<any> {
        url = `https://api.typeform.com/forms/${url}`;
        let typeFormHeader: HttpHeaders = new HttpHeaders();
        const token: string = `bearer ${AppConstant.TYPEFORM_API_TOKEN}`;
        typeFormHeader.append('Content-Type', 'application/json');
        typeFormHeader.append('AUTHORIZATION', token);
        return this.http.get(url, { headers: typeFormHeader })
    }
}

