import {Injectable} from "@angular/core";
import {AbstractBaseComponent} from "../app.baseComponent";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {UtilityHelper} from "../helpers/utilityHelper";
import {CurrentUserStateService} from "../appServices/current-user-state.service";

@Injectable()
export class NavigationGuard extends AbstractBaseComponent implements CanActivate {
    constructor(private authService: AuthenticationHelper, private utility: UtilityHelper,
                private router: Router, private currentUserStateService: CurrentUserStateService) {
        super();
    }

    canActivate(routes: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn() && this.utility.myDomain == this.MENTORING_DOMAIN) {
            if (this.utility.getItem('role') == 'coordinator' || this.currentUserStateService.isSuperAdmin()) {
                return true;
            } else {
                this.router.navigate(['/']);
                return false;
            }

        } else if (this.utility.myDomain == this.MENTORING_DOMAIN) {
            if (state.root.queryParams['navigation_url']) {
                window.location.assign(this.MENTORING_URL + 'sign-in?admin_redirect_url=' + encodeURIComponent(state.root.queryParams['navigation_url']));
            } else {
                this.router.navigate(['/']);
            }
            return false;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}
