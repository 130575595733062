import {Injectable} from "@angular/core";
import * as EXIF from "exif-js";

@Injectable()
export class ImageHelper {

    constructor() {
    }

    resetOrientation(srcBase64, callback) {
        const img = new Image();

        img.onload = (event: any) => {
            const width = img.width, height = img.height;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            let srcOrientation;

            EXIF.getData(img.toString(), function() {
                const allMetaData = EXIF.getAllTags(this);
                srcOrientation = allMetaData.Orientation;
            });

            if (4 < srcOrientation && srcOrientation < 9) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }

            switch (srcOrientation) {
                case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
                case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
                case 7: ctx.transform(0, -1, -1, 0, height , width); break;
                case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                default: break;
            }

            ctx.drawImage(img, 0, 0);

            callback(canvas.toDataURL());
        };

        img.src = srcBase64;
    }
}
