import {Injectable} from "@angular/core";
import {AbstractBaseComponent} from "../app.baseComponent";
import {CanActivate, Router} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {UtilityHelper} from "../helpers/utilityHelper";

@Injectable()
export class PreLoginGuard extends AbstractBaseComponent implements CanActivate {
    constructor(private authService: AuthenticationHelper, private utility: UtilityHelper, private route: Router) {
        super();
    }

    canActivate() {
        if (!this.authService.isLoggedIn()) {
            if (this.utility.myDomain == this.LOCAL_DOMAIN || this.utility.myDomain == this.BRILLIANTCLUB_DOMAIN || this.utility.myDomain == this.KNOWLEDGE_DOMAIN) {
                return true;
            } else if (this.utility.myDomain != this.MENTORING_DOMAIN) {
                this.utility.navigateToMentoringDomain();
            } else {
                return true;
            }
        } else {
            if (this.authService.isLoggedIn() && this.utility.myDomain == this.KNOWLEDGE_DOMAIN) {
                return true;
            } else if (this.authService.isLoggedIn()) {
                this.redirectToUser();
            }
        }
    }

    redirectToUser() {
        if (this.utility.myDomain == this.LOCAL_DOMAIN) {
            if (this.utility.getItem('open_admin')) {
                this.route.navigate(['/projects']);
            } else {
                this.route.navigate(['/feeds']);
            }
        } else if (this.utility.myDomain == this.MENTORING_DOMAIN) {
            this.route.navigate(['/feeds']);
        } else if (this.utility.myDomain == this.ADMIN_DOMAIN) {
            this.route.navigate(['/projects']);
        } else if (this.utility.myDomain == this.BRILLIANTCLUB_DOMAIN) {
            this.route.navigate(['/channels']);
        } else {
            return true;
        }
    }
}