// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.state--unread-messages .nav-item--channels:after {
    content: '';
    border-radius: 50%;
    width: 0.375em;
    /*10px / 16px */
    height: 0.375em;
    /*10px / 16px */
    margin-bottom: 0.1875em;
    display: inline-block;
}


/* line 240, /var/www/html/brightside/frontend/app/assets/stylesheets/common/extends.scss */
.state--unread-messages .nav-item--channels:after {
    margin-left: 0.5em;
}


/* line 129, /var/www/html/brightside/frontend/app/assets/stylesheets/common/mixins.scss */
.state--unread-messages .nav-item--channels:after {
    background-color: #0078af;
}

/* line 338, /var/www/html/brightside/frontend/app/assets/stylesheets/common/extends.scss */
.state--unread-messages .nav-item--channels:after {
    display: block;
    right: 0;
    margin: 0;
    transform: translateY(-50%);
}

/* line 347, /var/www/html/brightside/frontend/app/assets/stylesheets/common/extends.scss */
.state--unread-messages .nav-item--channels:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/unreadMessages.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IAGX,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,eAAe;IACf,eAAe;IACf,uBAAuB;IACvB,qBAAqB;AACzB;;;AAGA,2FAA2F;AAC3F;IACI,kBAAkB;AACtB;;;AAGA,0FAA0F;AAC1F;IACI,yBAAyB;AAC7B;;AAEA,2FAA2F;AAC3F;IACI,cAAc;IACd,QAAQ;IACR,SAAS;IAIT,2BAA2B;AAC/B;;AAEA,2FAA2F;AAC3F;IACI,kBAAkB;IAClB,QAAQ;IAIR,2BAA2B;AAC/B","sourcesContent":[".state--unread-messages .nav-item--channels:after {\n    content: '';\n    -moz-border-radius: 50%;\n    -webkit-border-radius: 50%;\n    border-radius: 50%;\n    width: 0.375em;\n    /*10px / 16px */\n    height: 0.375em;\n    /*10px / 16px */\n    margin-bottom: 0.1875em;\n    display: inline-block;\n}\n\n\n/* line 240, /var/www/html/brightside/frontend/app/assets/stylesheets/common/extends.scss */\n.state--unread-messages .nav-item--channels:after {\n    margin-left: 0.5em;\n}\n\n\n/* line 129, /var/www/html/brightside/frontend/app/assets/stylesheets/common/mixins.scss */\n.state--unread-messages .nav-item--channels:after {\n    background-color: #0078af;\n}\n\n/* line 338, /var/www/html/brightside/frontend/app/assets/stylesheets/common/extends.scss */\n.state--unread-messages .nav-item--channels:after {\n    display: block;\n    right: 0;\n    margin: 0;\n    -webkit-transform: translateY(-50%);\n    -moz-transform: translateY(-50%);\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n}\n\n/* line 347, /var/www/html/brightside/frontend/app/assets/stylesheets/common/extends.scss */\n.state--unread-messages .nav-item--channels:after {\n    position: absolute;\n    top: 50%;\n    -webkit-transform: translateY(-50%);\n    -moz-transform: translateY(-50%);\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
