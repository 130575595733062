import {Injectable} from "@angular/core";
import {AdminServices} from "../appServices/adminService";
import * as moment from "moment";

@Injectable()
export class DateAndTime {

    constructor() {
    }

    formatData(date, timeFormat): string {
        const dataObject: any = new Date(Date.parse(date));
        const currentDate: any = moment(moment().toDate());
        const createdDate: any = moment(dataObject);
        const toDay = currentDate.clone().startOf('day');
        const yesterDay = currentDate.clone().subtract(1, 'days').startOf('day');

        if (createdDate.isSame(toDay, 'd')) {
            return `Today ${createdDate.format(`[${timeFormat}] HH:mm`)}`;
        } else if (createdDate.isSame(yesterDay, 'd')) {
            return `Yesterday ${createdDate.format(`[${timeFormat}] HH:mm`)}`;
        } else {
            return createdDate.format(`Do MMM YYYY [${timeFormat}] HH:mm`);
        }
    }

    defaultDateAndTimeFormat(date, dateFormat = 'dddd, MMMM Do YYYY [at] LT') {
        return moment(new Date(Date.parse(date))).format(dateFormat);
    }

    momentDate(date, format?) {
        if (format) {
            return moment(date, format)
        } else {
            return moment(date)
        }
    }
}
