// import { TranslateService } from '@ngx-translate/core';
import {environment} from '../environments/environment';

export class AppConstant {

    public static BASE_URL: string = environment.baseUrl;
    public static MSG_SERVICE_URL_FOR_MODERATION: string = environment.messageServiceUrlForModeration;
    public static MSG_SERVICE_URL: string = environment.messageServiceUrl;
    public static YOUTUBE_BASE_URL: string = environment.youtubeBaseUrl;
    public static VIMEO_BASE_URL: string = environment.vimeoBaseUrl;
    public static UA_ID_FOR_MENTORING: string = environment.UAIDForMentoring;
    public static UA_ID_FOR_KNOWLEDGE: string = environment.UAIDForKnowledge;
    public static SEARCH_ASTERISKS_REGEX: any = "(\\*[^\\s\\_]+\\*)|(\\*([^\\s\\_][^\\*]+?[^\\s\\_])\\*)|(\\*([^\\s \\*][^\\_]*?[^\\s\\*])\\*)";
    public static SEARCH_UNDERSCORES_REGEX: any = "(\\_[^\\s\\*]+\\_)|(\\_([^\\s\\*][^\\_]+?[^\\s\\*])\\_)|(\\_([^\\s][^\\_]+?[^\\s])\\_)";
    public static REMOVE_ANGLE_BRACES_FROM_LINK_REGEX: any = /\<([^\s][^\<\>]+?[^\s])\>/;
    public static BREAKIFY_TEXT_REGEX: any = /(?:\r\n|\r|\n)/;
    public static CHANNEL_TYPES: any = ['mentoring', 'group', 'support'];
    public static FILE_SIZE_UNITS: any = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    public static ACTIVITY_LINK_REGEX: any = /\(([^|]+)\|<(\S+)>\)|<(\S+)>/;
    public static FIND_LINKS_REGEX: any = '(?:(?:https?|ftp|file):\\/\\/|www\\.|ftp\\.)(?:\\([-A-Z0-9+&@#\\/%=~_|$?!:,.]*\\)|[-A-Z0-9+&@#\\/%=~_|$?!:,.])*(?:\\([-A-Z0-9+&@#\\/%=~_|$?!:,.]*\\)|[A-Z0-9+&@#\\/%=~_|$])';

    // Messages related to the Messaging
    public static EMPTY_CHANNEL_TITLE: string = 'This is the start of your mentoring experience with';
    public static EMPTY_CHANNEL_INFORMATION: string = 'Messages sent here will be kept private between you two';
    public static EMPTY_GROUP_CHANNEL_TITLE: string = 'This is a group channel';
    public static EMPTY_GROUP_CHANNEL_INFORMATION: string = 'Messages sent here will be seen by everyone in the group';
    public static EMPTY_SUPPORT_CHANNEL_TITLE: string = 'This is your support channel';
    public static EMPTY_SUPPORT_CHANNEL_INFORMATION: string = 'Messages sent here will be sent to your coordinator';
    public static UPLOAD_ATTACHMENT_FAILURE_MESSAGE: string = 'Attachment upload failed';
    // Messages related to the Survey
    public static RESTRICT_CHANNEL_ACCESS_DUE_TO_SURVEY: string = 'You will not be able to access your messaging channel until you complete the survey';
    // Messages related to the Attachments
    public static FILE_VALIDATION_ERROR_MESSAGE: string = 'Please attach valid files only';
    public static FILE_SIZE_VALIDATION_MESSAGE: string = 'Please attach file size upto 10 MB';
    //Articles and News articles
    public static NEWS_ARTICLE_BODY_VALIDATION: string = 'Body can\'t be blank';
    public static NEWS_ARTICLE_TITLE_VALIDATION: string = 'Title can\'t be blank';
    public static NEWS_ARTICLE_INTRO_VALIDATION: string = 'Intro can\'t be blank';
    public static REMOVE_IMAGE_TOASTR_MSG: string = 'Image removed successfully';
    public static ATTACH_IMAGE_TOASTR_MSG: string = 'Image attached successfully';
    public static REMOVE_IMAGE_FAIL_TOASTR_MSG: string = '"Sorry failed to remove the article image"';
    public static IMAGE_FORMAT_VALIDATION_TOASTR_MSG: string = 'Please attach JPG, JPEG, PNG, GIF files only';
    public static IMAGE_SIZE_VALIDATION_TOASTR_MSG: string = 'Please attach image size upto 10 MB';
    public static NEWS_ARTICLE_SUBMIT_SUCCESS_TOASTR_MSG: string = 'News Article submitted successfully';
    public static NEWS_ARTICLE_SUBMIT_WARNING_TOASTR_MSG: string = 'Your article needs to be approved before it will be published';
    public static PENDING_STATE: string = 'Pending';
    public static APPROVED_STATE: string = 'Approved';
    public static DECLINED_STATE: string = 'Declined';
    // Communities
    public static TOPIC_NAME_REQUIRED_VALIDATION: string = 'Topic Name is required';
    public static TOPIC_DESCRIPTION_REQUIRED_VALIDATION: string = 'Topic Description is required';
    public static THREAD_TITLE_REQUIRED_VALIDATION: string = 'Thread Title is required';
    public static POST_REQUIRED_VALIDATION: string = 'Post can\'t be blank';
    public static BLANK_VALIDATION: string = 'cant be blank';
    public static ACTIVITY_REVIEW_IT_NOW_TEXT = 'Review it now';
    public static TAG_ADDED_SUCCESSFULLY: string = 'Tag added successfully';
    public static TAGS_ADDED_SUCCESSFULLY: string = 'Tags added successfully';
    public static TOPICS_HEADER_TEXT: string = 'Topics';
    public static THREADS_HEADER_TEXT: string = 'Threads';
    public static ARCHIVED_THREADS_HEADER_TEXT: string = 'Archived Threads';
    public static ADD_NEW_THREAD_HEADER_TEXT: string = 'Add New Thread';
    public static ADD_NEW_TOPIC_HEADER_TEXT: string = 'Add New Topic';
    public static SELECT_TOPICS_DROPDOWN_TEXT: string = 'Select topic(s)';
    public static SELECT_ALL: string = 'Select All';
    public static UNSELECT_ALL: string = 'UnSelect All';
    // Typeform
    public static TYPEFORM_API_TOKEN: string = environment.typeFormAPIToken;
    public static TYPEFORM_SUBMISSION: string = 'Typeform submitted successfully';
    public static TYPEFORM_MATCHING_FAIL: string = 'Unable to find matching mentors';
    public static INVALID_URL: string = "can't be blank and should have proper URL format";
    // Admin Categories
    public static CATEGORY_COLOR_REQURIED_VALIDATION: string = 'can\'t be blank and should have proper color format';
    public static BOX_COLORS = ['#0033CC', '#428BCA', '#44AD8E', '#A8D695', '#5CB85C', '#69D100',
        '#004E00', '#34495E', '#7F8C8D', '#A295D6', '#5843AD', '#8E44AD',
        '#FFECDB', '#AD4363', '#D10069', '#CC0033', '#FF0101', '#D9534F',
        '#D1D100', '#F0AD4E', '#AD8D43'];
    public static SUPPORTED_VIDEO_TYPES: any = ['video/mp4', 'video/ogg', 'video/webm'];
    public static SUPPORTED_IMAGE_TYPES: any = ['JPG', 'JPEG', 'PNG', 'GIF'];
    public static MODERATION_IMAGE_TYPES: string[] = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
    // deeplinking link
    public static APP_DOWNLOAD_LINK: string = environment.appDownloadLink;
    public static ENGAGEMENT_MESSAGE_SENT_COLOUR = '#F39200';
    public static ENGAGEMENT_MESSAGE_RECEIVE_COLOUR = '#0E016C';
    public static GENDER_TOOLTIP_TEXT = 'genderToolTipText';
    public static ETHNICITY_TOOLTIP_TEXT = 'ethnicityToolTipText';
    public static REPORTING_TOOLTIP_TEXT = ' Please do not download special category data unless absolutely necessary for your reporting requirements.'
    public static NOTIFICATION_TOOLTIP_TEXT = 'notificationToolTipText'
}
