import {Injectable} from "@angular/core";
import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class ToastsManager {
    constructor(private toastr: ToastrService) {}
    success (message: string = '', ...options: any) {
        if (options.length && options.length === 1) {
            this.toastr.success(message, null, options[0]);
        } else if (options.length && options.length === 2) {
            this.toastr.success(message, options[0], options[1]);
        } else {
            this.toastr.success(message);
        }
    }

    error(message: string, ...options: any) {
        if (options.length && options.length === 1) {
            this.toastr.error(message, null, options[0]);
        } else if (options.length && options.length === 2) {
            this.toastr.error(message, options[0], options[1]);
        } else {
            this.toastr.error(message);
        }
    }

    info(message: string, ...options: any) {
        if (options.length && options.length === 1) {
            this.toastr.info(message, null, options[0]);
        } else if (options.length && options.length === 2) {
            this.toastr.info(message, options[0], options[1]);
        } else {
            this.toastr.info(message);
        }
    }

    warning(message: string, ...options: any) {
        if (options.length && options.length === 1) {
            this.toastr.warning(message, null, options[0]);
        } else if (options.length && options.length === 2) {
            this.toastr.warning(message, options[0], options[1]);
        } else {
            this.toastr.warning(message);
        }
    }
}
