import {Routes, RouterModule, provideRouter} from '@angular/router';
import {Pages} from './pages.component';
import {ModuleWithProviders} from '@angular/core';
import {PreLoginGuard} from '../routingGuards/preLogIn.guard';
import {LoginGuard} from '../routingGuards/logIn.guard';
import {AdminGuard} from '../routingGuards/admin.guard';
import {AdminMentoringGuard} from '../routingGuards/adminMentoring.guard';
import {MentoringGuard} from '../routingGuards/mentoring.guard';
import {ChannelAccessGuard} from '../routingGuards/channelAccess.guard';
import {BrilliantGuard} from '../routingGuards/brilliant.guard';
import {KnowledgeGuard} from '../routingGuards/knowledge.guard';
import {AdminRedirectionGuard} from '../routingGuards/adminRedirection.guard';
import {NavigationGuard} from '../routingGuards/navigation.guard';
import {MenteeAccessGuard} from "../routingGuards/menteeAccess.guard";

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('app/pages/home/home.component').then(m => m.Home),
        canActivate: [PreLoginGuard, BrilliantGuard]
    },
    {
        path: 'sign-in',
        loadComponent: () => import('app/pages/sign-in/sign-in.component').then(m => m.SignIn),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'sign-up',
        loadComponent: () => import('app/pages/sign-up/sign-up.component').then(m => m.SignUp),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'project-code-sign-up',
        loadComponent: () => import('app/pages/projectCodeSignUp/projectCodeSignUp.component').then(m => m.ProjectCodeSignUp),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'tutee-sign-up',
        loadComponent: () => import('app/pages/vle/tuteeSignUp/tuteeSignUp.component').then(m => m.TuteeSignUp),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'password/new',
        loadComponent: () => import('app/pages/forgotPassword/forgotPassword.component').then(m => m.ForgotPasswordComponent),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'terms',
        loadComponent: () => import('app/pages/terms/terms.component').then(m => m.TermsComponent)
    },
    {
        path: 'app-terms',
        loadComponent: () => import('app/pages/termsForMobile/termsForMobile.component').then(m => m.TermsForMobileComponent)
    },
    {
        path: 'invitations/:id',
        loadComponent: () => import('app/pages/invitations/invitations.component').then(m => m.Invitations)
    },
    {
        path: 'passwords',
        loadComponent: () => import('app/pages/verificationCode/verificationCode.component').then(m => m.VerificationCodeComponent),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'passwords/:id',
        loadComponent: () => import('app/pages/verificationCode/verificationCode.component').then(m => m.VerificationCodeComponent),
        canActivate: [PreLoginGuard]

    },
    {
        path: 'privacy',
        loadComponent: () => import('./privacy/privacy.component').then(m => m.PrivacyComponent)
    },
    {
        path: 'app-privacy',
        loadComponent: () => import('./privacyForMobile/privacyForMobile.component').then(m => m.PrivacyForMobileComponent)
    },
    {
        path: 'gdpr-faqs',
        loadComponent: () => import('./gdprFaqs/gdprFaqs.component').then(m => m.GdprFaqsComponent)
    },
    {
        path: 'projects-list',
        loadComponent: () => import('./projectsSwitcherList/projectsSwitcherList.component').then(m => m.ProjectSwitcherList),
        canActivate: [LoginGuard]
    },
    {
        path: 'session',
        loadComponent: () => import('./domainSwitch/domainSwitch.component').then(m => m.DomainSwitch),
    },
    {
        path: 'auth/jwt/callback',
        loadComponent: () => import('./domainSwitch/domainSwitch.component').then(m => m.DomainSwitch),
    },
    {
        path: 'auth/jwt',
        loadComponent: () => import('./knowledgeRedirection/knowledgeRedirection.component').then(m => m.KnowledgeRedirection),
        canActivate: [LoginGuard]
    },
    {
        path: 'emails/:verification_code',
        loadComponent: () => import('app/pages/emailVerification/emailVerification.component').then(m => m.EmailVerification),
        canActivate: [PreLoginGuard]
    },
    {
        path: '',
        component: Pages,
        children: [
            /*
             * Unused dashboard route
             * */
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {
                path: 'signout',
                loadComponent: () => import('./signout/signout.component').then(m => m.SignOut),
                canActivate: [LoginGuard]
            },
            {
                path: 'communities',
                loadComponent: () => import('./communities/communities.component').then(m => m.Communities),
                canActivate: [AdminMentoringGuard]
            },
            {
                path: 'add-thread',
                loadComponent: () => import('./communities/addThread/addThread.component').then(m => m.AddThread),
                canActivate: [MentoringGuard]
            },
            {
                path: 'thread-post',
                loadComponent: () => import('./communities/threadsPost/threadsPost.component').then(m => m.ThreadsPost),
                canActivate: [MentoringGuard]
            },
            {
                path: 'add-topic',
                loadComponent: () => import('./communities/addTopic/addTopic.component').then(m => m.AddTopic),
                canActivate: [MentoringGuard]
            },
            {
                path: 'edit-topic',
                loadComponent: () => import('./communities/editTopic/editTopic.component').then(m => m.EditTopic),
                canActivate: [MentoringGuard]
            },
            {
                path: 'view-threads',
                loadComponent: () => import('./communities/topicsThread/topicsThread.component').then(m => m.TopicsThread),
                canActivate: [MentoringGuard]
            },
            {
                path: 'feeds',
                loadComponent: () => import('./feeds/feeds.component').then(m => m.FeedsComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'matches',
                loadComponent: () => import('./matches/matches.component').then(m => m.MatchesComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'mentors/:id',
                loadComponent: () => import('./mentors/mentors.component').then(m => m.MentorsComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'submit-article',
                loadComponent: () => import('./createArticleForConnectionLandingPage/createArticleForConnectionLandingPage.component').then(m => m.CreateArticleForConnectionLandingPageComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'view-articles',
                loadComponent: () => import('./viewSubmittedArticles/viewSubmittedArticles.component').then(m => m.ViewSubmittedArticlesComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'edit-articles/:id',
                loadComponent: () => import('./editSubmittedArticles/editSubmittedArticles.component').then(m => m.EditSubmittedArticlesComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'navigation',
                loadComponent: () => import('./adminRedirection/adminRedirection.component').then(m => m.AdminRedirectionComponent),
                canActivate: [NavigationGuard]
            },
            {
                path: 'users/:id/edit',
                loadComponent: () => import('./myAccount/myAccount.component').then(m => m.MyAccountComponent),
                canActivate: [AdminMentoringGuard]
            },
            {
                path: 'users/:id',
                loadComponent: () => import('./editUser/editUser.component').then(m => m.EditUserComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'badges',
                loadComponent: () => import('./myAchievements/myAchievements.component').then(m => m.MyAchievementsComponent),
                canActivate: [MenteeAccessGuard]
            },
            // {
            //     path: 'message-streak',
            //     loadChildren: () => import('./myAchievements/myAchievements.module').then(m => m.MyAchievementsModule),
            //     canActivate: [MenteeAccessGuard]
            // },
            {
                path: 'projects/:project_id/external_materials/new',
                loadComponent: () => import('./admin/projects/manageProject/projectResources/assignProjectResources/assignProjectResources.component').then(m => m.AssignProjectResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/activities/new',
                loadComponent: () => import('./admin/projects/manageProject/projectActivities/assignProjectActivities/assignProjectActivities.component').then(m => m.AssignProjectActivitiesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'project_users/:project_user_id/edit',
                loadComponent: () => import('./myAccount/myAccount.component').then(m => m.MyAccountComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'settings/:user_id/edit',
                loadComponent: () => import('./myAccount/myAccount.component').then(m => m.MyAccountComponent),
                canActivate: [AdminMentoringGuard]
            },
            {
                path: 'mentor_profile/:mentor_profile_id/edit',
                loadComponent: () => import('./mentorProfile/mentorProfile.component').then(m => m.MentorProfileComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'project_users/:project_user_id',
                loadComponent: () => import('./myAccount/myAccount.component').then(m => m.MyAccountComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'users',
                loadComponent: () => import('./admin/users/users.component').then(m => m.Users),
                canActivate: [AdminGuard]
            },
            {
                path: 'ask_graduate',
                loadComponent: () => import('./askGraduate/askGraduate.component').then(m => m.AskGraduateComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'graduates',
                loadComponent: () => import('./askGraduate/askGraduate.component').then(m => m.AskGraduateComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'graduates/:graduate_id',
                loadComponent: () => import('./askGraduate/askGraduate.component').then(m => m.AskGraduateComponent),
                canActivate: [MentoringGuard]
            },


            //done guard
            {
                path: 'projects',
                loadComponent: () => import('./admin/projects/projects.component').then(m => m.ProjectsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/engagement-dashboard/new',
                loadComponent: () => import('./admin/projects/manageProject/engagementDashboard//engagementDashboard.component').then(m => m.EngagementDashboardComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/engagement-dashboard/messages',
                loadComponent: () => import('./admin/projects/manageProject/engagementDashboard/messageEngagement/messageEngagement.component').then(m => m.MessageEngagementComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/new',
                loadComponent: () => import('./admin/projects/manageProject/createProject/createProject.component').then(m => m.CreateProject),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id',
                loadComponent: () => import('./admin/projects/manageProject/manageProject.component').then(m => m.ManageProjectComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'project_resources',
                loadComponent: () => import('./projectResources/projectResources.component').then(m => m.ProjectResources),
                canActivate: [LoginGuard]
            },
            {
                path: 'projects/:project_id/tags/new',
                loadComponent: () => import('./admin/projects/manageProject/createTag/createTag.component').then(m => m.CreateTagComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/announcements/new',
                loadComponent: () => import('./admin/projects/manageProject/sendAnnouncements/sendAnnouncements.component').then(m => m.SendAnnouncementsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/ask_graduate/graduates',
                loadComponent: () => import('./admin/projects/manageProject/adminAskTheGraduate/graduatesList/graduatesList.component').then(m => m.GraduatesListComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/groups/:group_id',
                loadComponent: () => import('./admin/projects/viewGroupChat/viewGroupChat.component').then(m => m.ViewGroupChat),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/:tab/:user_id',
                loadComponent: () => import('./admin/projects/manageProject/manageProject.component').then(m => m.ManageProjectComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/users/:project_user_id/channels',
                loadComponent: () => import('./admin/projects/conversation/conversation.component').then(m => m.Conversation),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/users/:project_user_id/channels/:channel_id',
                loadComponent: () => import('./admin/projects/conversation/conversation.component').then(m => m.Conversation),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/tags/:tag_id/edit',
                loadComponent: () => import('./admin/projects/manageProject/editTag/editTag.component').then(m => m.EditTagComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/edit',
                loadComponent: () => import('./admin/projects/manageProject/editProject/editProject.component').then(m => m.EditProject),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/ask_graduate/graduates/:graduate_id/edit',
                loadComponent: () => import('./admin/projects/manageProject/adminAskTheGraduate/editGraduateProfile/editGraduateProfile.component').then(m => m.EditGraduateProfileComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/ask_graduate/graduates/:graduate_id/answer/edit',
                loadComponent: () => import('./admin/projects/manageProject/adminAskTheGraduate/editGraduateAnswer/editGraduateAnswer.component').then(m => m.EditGraduateAnswerComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/ask_graduate/graduates/:graduate_id/answer/new',
                loadComponent: () => import('./admin/projects/manageProject/adminAskTheGraduate/newGraduateAnswer/newGraduateAnswer.component').then(m => m.NewGraduateAnswerComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/ask_graduate/graduates/:graduate_id/answer',
                loadComponent: () => import('./admin/projects/manageProject/adminAskTheGraduate/showGraduateAnswer/showGraduateAnswer.component').then(m => m.ShowGraduateAnswerComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/ask_graduate/graduates/new',
                loadComponent: () => import('./admin/projects/manageProject/adminAskTheGraduate/newGraduateProfile/newGraduateProfile.component').then(m => m.NewGraduateProfileComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/ask_graduate/graduates/:graduate_id',
                loadComponent: () => import('./admin/projects/manageProject/adminAskTheGraduate/showGraduateProfile/showGraduateProfile.component').then(m => m.ShowGraduateProfileComponent),
                canActivate: [AdminRedirectionGuard]
            },

            {
                path: 'projects/:project_id/:tab/:group_id/:action',
                loadComponent: () => import('./admin/projects/manageProject/manageProject.component').then(m => m.ManageProjectComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id/:tab',
                loadComponent: () => import('./admin/projects/manageProject/manageProject.component').then(m => m.ManageProjectComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'projects/:project_id',
                loadComponent: () => import('./admin/projects/manageProject/editProject/editProject.component').then(m => m.EditProject),
                canActivate: [AdminGuard]
            },
            {
                path: 'moderation_requests',
                loadComponent: () => import('./admin/moderation/moderationRequests/moderationRequests.component').then(m => m.ModerationRequestsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'moderation_requests/pending',
                loadComponent: () => import('./admin/moderation/pendingModeration/pendingModeration.component').then(m => m.PendingModeration),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'moderation_requests/:userId',
                loadComponent: () => import('./admin/moderation/moderationViews/moderationViews.component').then(m => m.ModerationViewsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'myMentor',
                loadComponent: () => import('./myMentor/myMentor.component').then(m => m.MyMentorComponent),
                canActivate: [MentoringGuard]
            },
            {
                path: 'activities/new',
                loadComponent: () => import('./admin/activities/manageActivities/newAdminActivities/newAdminActivities.component').then(m => m.NewAdminActivitiesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'activities/:activityId/edit',
                loadComponent: () => import('./admin/activities/manageActivities/editAdminActivities/editAdminActivities.component').then(m => m.EditAdminActivitiesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'activities',
                loadComponent: () => import('./activities/activities.component').then(m => m.Activities),
                canActivate: [AdminMentoringGuard]
            },
            {
                path: 'activities/:id',
                loadComponent: () => import('./activities/viewActivities/viewActivities.component').then(m => m.ViewActivities),
                canActivate: [AdminMentoringGuard]
            },
            {
                path: 'user_account_deletion',
                loadComponent: () => import('./admin/moderation/userAccountDeletion/userAccountDeletion.component').then(m => m.UserAccountDeletionComponent),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'video_calls',
                loadComponent: () => import('./admin/moderation/video-call-moderation/video-call-moderation.component').then(m => m.VideoCallModerationComponent),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'video_calls/:userId',
                loadComponent: () => import('./admin/moderation/video-call-views/video-call-views.component').then(m => m.VideoCallViewsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'partners',
                loadComponent: () => import('./admin/partners/partners.component').then(m => m.PartnersComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'partners/new',
                loadComponent: () => import('./admin/partners/editPartner/editPartner.component').then(m => m.EditPartnerComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'partners/:partner_id',
                loadComponent: () => import('./admin/partners/managePartner/managePartner.component').then(m => m.ManagePartnerComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'partners/:partner_id/edit',
                loadComponent: () => import('./admin/partners/editPartner/editPartner.component').then(m => m.EditPartnerComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'partners/:partner_id/:tab',
                loadComponent: () => import('./admin/partners/managePartner/managePartner.component').then(m => m.ManagePartnerComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'partners/:partner_id/activities/new',
                loadComponent: () => import('./admin/partners/managePartner/activities/newPartnerActivities/newPartnerActivities.component').then(m => m.NewPartnerActivitiesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'partners/:partner_id/external_materials/new',
                loadComponent: () => import('./admin/partners/managePartner/externalMaterials/assignPartnerExternalMaterials/assignPartnerExternalMaterials.component').then(m => m.AssignPartnerExternalMaterialsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'review_activities',
                loadComponent: () => import('./activities/reviewActivities/reviewActivities.component').then(m => m.ReviewActivities),
                canActivate: [MentoringGuard]
            },
            {
                path: 'review_activities/:id',
                loadComponent: () => import('./activities/reviewActivities/reviewActivity/reviewActivity.component').then(m => m.ReviewActivity),
                canActivate: [AdminMentoringGuard]
            },
            {
                path: 'stop_words',
                loadComponent: () => import('./admin/moderation/stopWords/stopWords.component').then(m => m.StopWords),
                canActivate: [AdminGuard]
            },
            {
                path: 'stop_words/new',
                loadComponent: () => import('./admin/moderation/stopWords/createStopWord/createStopWord.component').then(m => m.CreateStopWord),
                canActivate: [AdminGuard]
            },
            {
                path: 'stop_words/:stop_words_id/edit',
                loadComponent: () => import('./admin/moderation/stopWords/editStopWord/editStopWord.component').then(m => m.EditStopWord),
                canActivate: [AdminGuard]
            },
            {
                path: 'avatar_moderations',
                loadComponent: () => import('./admin/moderation/avatarModeration/avatarModeration.component').then(m => m.AvatarModeration),
                canActivate: [AdminGuard]
            },
            {
                path: 'post_moderations',
                loadComponent: () => import('./admin/moderation/postModeration/postModeration.component').then(m => m.PostModeration),
                canActivate: [AdminGuard]
            },
            {
                path: 'article_moderation',
                loadComponent: () => import('./admin/moderation/articleModeration/articleModeration.component').then(m => m.ArticleModerationComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'article_moderation/:id',
                loadComponent: () => import('./admin/moderation/submittedArticlesModeration/submittedArticlesModeration.component').then(m => m.SubmittedArticlesModerationComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'post_moderations/:id',
                loadComponent: () => import('./admin/moderation/postModeration/viewPostModeration/viewPostModeration.component').then(m => m.ViewPostModeration),
                canActivate: [AdminGuard]
            },
            {
                path: 'activity_moderations',
                loadComponent: () => import('./admin/moderation/activityModeration/activityModeration.component').then(m => m.ActivityModeration),
                canActivate: [AdminGuard]
            },
            {
                path: 'activity_moderations/:id',
                loadComponent: () => import('./admin/moderation/activityModeration/viewActivityModeration/viewActivityModeration.component').then(m => m.ViewActivityModeration),
                canActivate: [AdminGuard]
            },
            {
                path: 'mentor_profile_moderations',
                loadComponent: () => import('./admin/moderation/mentorProfileModeration/mentorProfileModeration.component').then(m => m.MentorProfileModerationComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'mentor_profile_moderations/:id',
                loadComponent: () => import('./admin/moderation/mentorProfileModeration/viewMentorProfileModeration/viewMentorProfileModeration.component').then(m => m.ViewMentorProfileModerationComponent),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'support_requests',
                loadComponent: () => import('./admin/supportRequests/supportRequests.component').then(m => m.SupportRequestsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'support_requests/:support_requests_id',
                loadComponent: () => import('./admin/supportRequests/supportRequestsChannels/supportRequestsChannels.component').then(m => m.SupportRequestsChannels),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'channels',
                loadComponent: () => import('./channels/channels.component').then(m => m.ChannelsComponent),
                canActivate: [ChannelAccessGuard]
            },
            {
                path: 'channels/:channel_id',
                loadComponent: () => import('./channels/channels.component').then(m => m.ChannelsComponent),
                canActivate: [ChannelAccessGuard]
            },
            {
                path: 'stop_words_exceptions',
                loadComponent: () => import('./admin/moderation/stopWordExceptions/stopWordExceptions.component').then(m => m.StopWordExceptions),
                canActivate: [AdminGuard]
            },
            {
                path: 'stop_words_exceptions/:project_id',
                loadComponent: () => import('./admin/moderation/stopWordExceptions/stopWordExceptions.component').then(m => m.StopWordExceptions),
                canActivate: [AdminGuard]
            },
            {
                path: 'bulk_invitations/new',
                loadComponent: () => import('./admin/bulkInvitations/bulkInvitations.component').then(m => m.BulkInvitationsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'bulk_surveys/new',
                loadComponent: () => import('./admin/bulkSurveys/bulkSurveys.component').then(m => m.BulkSurveysComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'engagement_reports/new',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'review_messages/new',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'mentor_profile_report/new',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'videocall_feedbacks/new',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'platform_metrics/:tab',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'engagement_reports/:report_id',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'mentor_profile_report/:report_id',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'view_review_messages/:id',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'review_messages/:report_id',
                loadComponent: () => import('./reporting/reporting.component').then(m => m.ReportingComponent),
                canActivate: [AdminRedirectionGuard]
            },
            {
                path: 'news',
                loadComponent: () => import('./admin/news/news.component').then(m => m.NewsComponent),
                // canActivate: [AdminGuard, SuperAdminGuard]
            },
            {
                path: 'news/:action',
                loadComponent: () => import('./admin/news/news.component').then(m => m.NewsComponent),
                // canActivate: [AdminGuard, SuperAdminGuard]
            },
            {
                path: 'news/:news_id/:action',
                loadComponent: () => import('./admin/news/news.component').then(m => m.NewsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'articles/:article_id/edit',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'articles/new',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'articles/search',
                loadComponent: () => import('./knowledge/knowledgeSearchResult/knowledgeSearchResult.component').then(m => m.KnowledgeSearchResultComponent),
                canActivate: [KnowledgeGuard]
            },
            {
                path: 'articles/:article_id',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'articles',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'categories',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'featured_articles',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'categories/:action',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'categories/:category_id/:action',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'subcategories',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'subcategories/:action',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'subcategories/:sub_category_id/:action',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'external_materials',
                loadComponent: () => import('./admin/adminResources/manageResources/manageResources.component').then(m => m.ManageResourcesComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'external_materials/new',
                loadComponent: () => import('./admin/adminResources/manageResources/createExternalMaterials/createExternalMaterials.component').then(m => m.CreateExternalMaterialsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'external_materials/:external_materials_id/edit',
                loadComponent: () => import('./admin/adminResources/manageResources/editExternalMaterials/editExternalMaterials.component').then(m => m.EditExternalMaterialsComponent),
                canActivate: [AdminGuard]
            },
            {
                path: 'overview',
                loadComponent: () => import('./vle/tutorialGroupOverview/tutorialGroupOverview.component').then(m => m.TutorialGroupOverviewComponent),
            },
            {
                path: 'project_codes',
                loadComponent: () => import('./vle/tutorialGroupOverview/tutorialGroupOverview.component').then(m => m.TutorialGroupOverviewComponent),
            },
            {
                path: 'tutorials/attendances',
                loadComponent: () => import('./vle/tutorial/tutorial.component').then(m => m.TutorialComponent),
            },
            {
                path: 'tutorials/homeworks',
                loadComponent: () => import('./vle/tutorial/tutorial.component').then(m => m.TutorialComponent),
            },
            {
                path: 'baseline_assignments',
                loadComponent: () => import('./vle/vleBaselineAssignments/vleBaselineAssignments.component').then(m => m.VleBaselineAssignments),
            },
            {
                path: 'baseline_assignments/:baseline_assignments_id',
                loadComponent: () => import('./vle/vleBaselineAssignments/vleBaselineAssignments.component').then(m => m.VleBaselineAssignments),
            },
            {
                path: 'baseline_assignments/:baseline_assignments_id/:tabs',
                loadComponent: () => import('./vle/vleBaselineAssignments/vleBaselineAssignments.component').then(m => m.VleBaselineAssignments),
            },
            {
                path: 'final_assignments/new',
                loadComponent: () => import('./vle/vleTuteeFinalAssignment/vleTuteeFinalAssignment.component').then(m => m.VleTuteeFinalAssignmentComponent),
            },
            {
                path: 'final_assignments/:id',
                loadComponent: () => import('./vle/vleTuteeFinalAssignment/vleTuteeFinalAssignment.component').then(m => m.VleTuteeFinalAssignmentComponent),
            },
            {
                path: 'final_assignments',
                loadComponent: () => import('./vle/vleBaselineAssignments/vleBaselineAssignments.component').then(m => m.VleBaselineAssignments),
            },
            {
                path: 'final_assignments/:final_assignments_id/:tabs',
                loadComponent: () => import('./vle/vleBaselineAssignments/vleBaselineAssignments.component').then(m => m.VleBaselineAssignments),
            },
            {
                path: 'invitations',
                loadComponent: () => import('./vle/tutorialGroupOverview/tutorialGroupOverview.component').then(m => m.TutorialGroupOverviewComponent),
            },
            {
                path: 'knowledge-bank/:a',
                loadComponent: () => import('./knowledge/knowledgeBankRedirection/knowledge-bank-redirection.component').then(m => m.KnowledgeBankRedirectionComponent),
            },
            {
                path: 'knowledge-bank/:a/:b',
                loadComponent: () => import('./knowledge/knowledgeBankRedirection/knowledge-bank-redirection.component').then(m => m.KnowledgeBankRedirectionComponent),
            },
            {
                path: 'knowledge-bank/:a/:b/:c',
                loadComponent: () => import('./knowledge/knowledgeBankRedirection/knowledge-bank-redirection.component').then(m => m.KnowledgeBankRedirectionComponent),
            },
            {
                path: 'knowledge-bank/:a/:b/:c/:d',
                loadComponent: () => import('./knowledge/knowledgeBankRedirection/knowledge-bank-redirection.component').then(m => m.KnowledgeBankRedirectionComponent),
            },
            {
                path: 'knowledge-bank/:a/:b/:c/:d/:e',
                loadComponent: () => import('./knowledge/knowledgeBankRedirection/knowledge-bank-redirection.component').then(m => m.KnowledgeBankRedirectionComponent),
            },
            {
                path: ':category_slug/:article_slug',
                loadComponent: () => import('./knowledge/knowledgeArticle/knowledgeArticle.component').then(m => m.KnowledgeArticleComponent),
                canActivate: [KnowledgeGuard]
            },
            {
                path: 'saved_articles',
                loadComponent: () => import('./knowledge/knowledgeSavedArticles/knowledgeSavedArticles.component').then(m => m.KnowledgeSavedArticlesComponent),
                canActivate: [KnowledgeGuard]
            },
            {
                path: ':category_slug',
                loadComponent: () => import('./knowledge/viewKnowledgeCategory/viewKnowledgeCategory.component').then(m => m.ViewKnowledgeCategoryComponent),
                canActivate: [KnowledgeGuard]
            },
        ]
    }
];

export const routing = provideRouter(routes);
