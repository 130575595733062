import {Injectable,OnInit} from "@angular/core";
import {BaThemeSpinner} from '../theme/services/baThemeSpinner/baThemeSpinner.service';
import * as _ from "lodash";
import {AppConstant} from "../app.constant";
import {AbstractBaseComponent} from "../app.baseComponent";
import {IMyOptions} from "mydatepicker";
import {MentoringEvents} from "./events";
import {JoyrideService} from "ngx-joyride";
import {Router} from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import {DateAndTime} from "./dateAndTime";
import { TranslateService } from '@ngx-translate/core';
declare let window: any;
declare let UserInfo: any;
import { AES } from 'crypto-js';
import * as CryptoJS from 'crypto-js';

@Injectable({providedIn:'root'})
export class UtilityHelper extends AbstractBaseComponent {
    domainName:any;
    myDomain:any;
    fileSizeLimit: any = 10485760;
    partnerUrl: any;
    openAdmin: boolean = false;
    isScriptLoaded: boolean = false;
    isUserArchived: boolean = false;
    isProjectArchived: boolean = false;
    expiryDate: any;
    triggerTour: boolean = false;
    lastAccessedProjectId: any;
    isTypeformEnabled: boolean = false;
    mentorTypeformUrl: any = undefined;
    isSurveyEnabled: boolean = true;
    ismentorMatched: boolean = false;
    mentorUserId: any = '';
    isMentorAvailable: boolean = false;
    isTourStarted: boolean = false;
    partnerData: any;
    avatarId: string = '';
    projectUsersData:any;
    value1:any= {}


    partnershipStatus: any = [
        {name: 'None', value: 'none'},
        {name: 'Red', value: 'red'},
        {name: 'Amber', value: 'amber'},
        {name: 'Green', value: 'green'},
    ];

    contractType: any = [
        {name:'Brightside Led', value: 'brightside_led'},
        {name:'Partner Led', value: 'partner_led'},
        {name:'Brightside Led - Collaborative', value: 'brightside_led_collaborative'},
    ];
    reasonsToExcludeFromReporting: any = [
        {name: 'Demo/Test project', value: 'demo_test_project'},
        {name: 'No start/end dates', value: 'no_start_end_dates'},
        {name: 'Rolling sign-ups', value: 'rolling_sign_ups'},
        {name: 'Does not use messaging channels', value: 'does_not_use_messaging_channels'},
        {name: 'Project did not launch', value: 'project_did_not_launch'},
    ];
    deliveryModel: any = [
        {name: 'Bespoke Model', value: 'bespoke_model'},
        {name: 'HE Application Support', value: 'he_application_support'},
        {name: 'HE Exploration', value: 'he_exploration'},
        {name: 'HE Explore and Apply', value: 'he_explore_and_apply'},
        {name: 'Pre-16 Mentoring', value: 'pre_16_mentoring'},
        {name: 'Pre-Arrival Mentoring', value: 'pre_arrival_mentoring'},
        {name: 'Sector Insight', value: 'sector_insight'},
        {name: 'Virtual Work Experience', value: 'virtual_work_experience'},
    ];
    brightsideCoordinator: any = [
        {name: 'Chris', value: 'chris'},
        {name: 'Jethro', value: 'jethro'},
        {name: 'John', value: 'john'},
        {name: 'Louise', value: 'louise'},
        {name: 'Milena', value: 'milena'},
        {name: 'Natalie', value: 'natalie'},
        {name: 'Nick', value: 'nick'},
        {name: 'Sebastian', value: 'sebastian'},
        {name: 'Sophie', value: 'sophie'},
        {name: 'Tom', value: 'tom'},
    ];
    gender: any = [
        {name: 'Female', value: 'female'},
        {name: 'Male', value: 'male'},
        {name: 'Non-Binary', value: 'non-binary'},
        {name: 'I prefer to self describe', value: 'prefer to self-describe'},
        {name: 'Prefer not to say', value: 'prefer not to say'},
    ];
    genderForWls: any = [
        {name: 'Benyw', value: 'female'},
        {name: 'Gwryw', value: 'male'},
        {name: 'Anneuaidd', value: 'non-binary'},
        {name: 'Mae’n well gen i ddisgrifio fy hun', value: 'prefer to self-describe'},
        {name: 'Mae’n well gen i beidio â dweud', value: 'prefer not to say'},
    ];

    ethnicity: any = [
        {
            group: 'White',
            items: [
                {id: 1, name: 'English, Welsh, Scottish, Northern Irish or British'},
                {id: 2, name: 'Irish'},
                {id: 3, name: 'Gypsy or Irish Traveller'},
                {id: 4, name: 'Any other White background'}
            ],
        },
        {
            group: 'Mixed or Multiple ethnic groups',
            items: [
                {id: 5, name: 'White and Black Caribbean'},
                {id: 6, name: 'White and Black African'},
                {id: 7, name: 'White and Asian'},
                {id: 8, name: 'Any other Mixed or Multiple ethnic background'}
            ],
        },
        {
            group: 'Asian or Asian British',
            items: [
                {id: 9, name: 'Indian'},
                {id: 10, name: 'Pakistani'},
                {id: 11, name: 'Bangladeshi'},
                {id: 12, name: 'Chinese'},
                {id: 13, name: 'Any other Asian background'}
            ],
        },
        {
            group: 'Black, African, Caribbean or Black British',
            items: [
                {id: 14, name: 'African'},
                {id: 15, name: 'Caribbean'},
                {id: 16, name: 'Any other Black, African or Caribbean background'}
            ],
        },
        {
            group: 'Other ethnic group',
            items: [
                {id: 17, name: 'Arab'},
                {id: 18, name: 'Any other ethnic group'},
            ],
        },
        {
            group:'Prefer not to say',
            items:[
                {id: 19, name:'Prefer not to say'}
            ]
        }
    ];
   
 ethnicityForWls: any = [
        {
            group: 'Gwyn',
            items: [
                {id: 20, name: 'Cymreig, Saesnig, Albanaidd, Gogledd Iwerddon neu Brydeinig'},
                {id: 21, name: 'Gwyddelig'},
                {id: 22, name: 'Sipsi neu Deithiwr Gwyddelig'},
                {id: 23, name: 'Unrhyw gefndir Gwyn arall'}
            ],
        },
        {
            group: 'Grwpiau cymysg neu aml-ethnig',
            items: [
                {id: 24, name: 'Gwyn a Du Caribïaidd'},
                {id: 25, name: 'Gwyn a Du Affricanaidd'},
                {id: 26, name: 'Gwyn ac Asiaidd'},
                {id: 27, name: 'Unrhyw gefndir cymysg neu aml-ethnig arall'}
            ],
        },
        {
            group: 'Asiaidd neu Asiaidd Prydeinig',
            items: [
                {id: 28, name: 'Indiaidd'},
                {id: 29, name: 'Pacistanaidd'},
                {id: 30, name: 'Bangladeshaidd'},
                {id: 31, name: 'Tsieineaidd'},
                {id: 32, name: 'Unrhyw gefndir Asiaidd arall'}
            ],
        },
        {
            group: 'Du, Du Caribïaidd, Caribïaidd neu Affricanaidd',
            items: [
                {id: 33, name: 'Affricanaidd'},
                {id: 34, name: 'Caribïaidd'},
                {id: 35, name: 'Unrhyw gefndir Du, Du Prydeinig neu Garibïaidd arall'}
            ],
        },
        {
            group: 'Grŵp ethnig arall',
            items: [
                {id: 36, name: 'Arabaidd'},
                {id: 37, name: 'Unrhyw grŵp ethnig arall'},
            ],
        },
        {
            group:'Mae’n well gen i beidio â dweud',
            items:[
                {id: 38, name:'Mae’n well gen i beidio â dweud'}
            ]
        }
    ];
    taskListWls: any = [
        {name: 'Dewis cyrsiau a phynciau',value: 'Choosing courses and subjects'},
        {name: 'Technegau adolygu ac astudio',value: 'Revision and study techniques'},
        {name: 'Ysgrifennu cais i brifysgol',value: 'Writing university applications'},
        {name: 'Paratoi ar gyfer cyfweliadau' ,value: 'Preparing for interviews'},
        {name: 'Rheoli arian',value: 'Managing your finances'},
        {name: 'Paratoi ar gyfer bywyd ar ôl ysgol',value: 'Preparing for life after school'},
        {name: 'Creu CV',value: 'Building your CV'},
        {name: 'Dod o hyd i waith a phrofiad gwaith',value: 'Finding careers and work experience'},
    ];

    userDeletionReasons: string[] = [
        'Right to be forgotten',
        'Contractual requirement',
        'Archived as 6 years old',
    ];

    fileTypesArray = [
        'application/vnd.android.package-archive',
        'application/x-msdownload',
        'text/x-python',
        'application/x-ms-dos-executable',
        'application/octet-stream',
        'application/x-perl',
        'application/x-java-archive',
        'apk',
        'bat',
        'bin',
        'cgi',
        'pl',
        'com',
        'exe',
        'gadget',
        'jar',
        'py',
        'wsf',
        'exe'
    ];

    maxPaginationValue: any = 5000;

    stopWordExceptionsPageNumber: any = 1;
    stopWordExceptionsPerPage: any = 25;
    projectsPageNumber: any = 1;
    projectsPerPage: any = 25;
    stopWordProjectId: any;
    stopWordSearchContent: any = '';
    stopWordSearch: boolean = false;
    stopWordProjectSearchContent: any = '';
    stopWordProjectSearch: boolean = false;
    projectPartnersData: any = [];
    projects: any = [];
    partners: any = [];
    currentUserID: any = '';
    CustoButtonsTextsForTour:any=[]
    datePickerOptions: IMyOptions = {
        dateFormat: 'dd/mm/yyyy',
        dayLabels: {su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S'},
        monthLabels: {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'May',
            6: 'Jun',
            7: 'Jul',
            8: 'Aug',
            9: 'Sep',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec'
        },
        showTodayBtn: false,
        inline: false,
        firstDayOfWeek: 'su',
        editableDateField: false,
        showClearDateBtn: false,
        openSelectorOnInputClick: true,
        disableSince: {
            year: new Date().getFullYear(),
            month: new Date().getUTCMonth()+1,
            day: new Date().getDate() + 1
        }
    };

    loadedProfile: any = [];
    profileName: string = '';
    isUserDeletionAuthorized: boolean = false;
    dataShouldEncrypt = ['token', 'jwtToken', 'role', 'projectId']

    private debouncedStartTour = _.debounce(() => this.triggerSiteTour(),
        400, {});

    constructor(private _spinner: BaThemeSpinner,
                private cookieService: CookieService,
                private mentoringEvents: MentoringEvents,
                public joyrideService: JoyrideService,
                private router: Router,
                private dateAndTime: DateAndTime,
                public translate: TranslateService
) {
        super();
        this.partnerUrl = this.getItem('mentoringUrl') ? this.getItem('mentoringUrl') : this.MENTORING_URL;
        this.domainName = location.hostname;
        if(_.includes(this.domainName, this.REACHING_WIDER_DOMAIN)) {
            this.setItem('domainName', this.REACHING_WIDER_DOMAIN);
        } else {
            this.setItem('domainName', this.domainName);
        }
        const tomorrow = new Date();
         let formattedDate = this.dateAndTime.defaultDateAndTimeFormat(new Date(tomorrow.setDate(tomorrow.getDate() + 1)), 'llll');
        // if (typeof this.expiryDate  === 'number') {
        //     this.expiryDate  = new Date(this.expiryDate );
        // } else if (typeof this.expiryDate  === 'string') {
        //     this.expiryDate  = new Date(Date.parse(this.expiryDate ));
        // }
        this.expiryDate = new Date(formattedDate);

        if(_.includes(this.domainName, this.LOCAL_DOMAIN)) {
            this.myDomain = this.LOCAL_DOMAIN
        } else if(_.includes(this.domainName, this.ADMIN_DOMAIN)) {
            this.myDomain = this.ADMIN_DOMAIN;
        } else if(_.includes(this.domainName, this.KNOWLEDGE_DOMAIN)) {
            this.myDomain = this.KNOWLEDGE_DOMAIN;
        } else if(_.includes(this.domainName, this.BRILLIANTCLUB_DOMAIN)) {
            this.myDomain = this.BRILLIANTCLUB_DOMAIN;
        } else {
            this.myDomain = this.MENTORING_DOMAIN;
        }

        this.lastAccessedProjectId = this.getItem('projectId');
    }
        
    
    setItem(item, val) {
        if (['isBrilliantClub', 'rememberMe', 'hasCookiesInfoShown'].includes(item)
            || this.cookieService.get('rememberMe') === 'true') {
            if(this.dataShouldEncrypt.includes(item)) {
                const encryptedData = AES.encrypt(val, this.ENCRYPT_DECRYPT_CODE).toString();
                this.cookieService.set(item, encryptedData, { expires: this.expiryDate });
            } else {
                this.cookieService.set(item, val, { expires: this.expiryDate });
            }
        } else {
            if(this.dataShouldEncrypt.includes(item)) {
                const encryptedData = AES.encrypt(val, this.ENCRYPT_DECRYPT_CODE).toString();
                this.cookieService.set(item, encryptedData, { expires: this.expiryDate });
            } else {
                 this.cookieService.set(item, val, { expires: this.expiryDate });
            }
        }
    }
   

    getItem(item) {
        if (item) {
            if(this.dataShouldEncrypt.includes(item)) {
                const value = this.cookieService.get(item)
                if(value != undefined) {
                    try {
                        return AES.decrypt(value, this.ENCRYPT_DECRYPT_CODE).toString(CryptoJS.enc.Utf8);
                    } catch (error) {
                        console.log("error in catch", error)
                        return value;
                    }
                } else {
                    return this.cookieService.get(item);
                }
            } else {
                return this.cookieService.get(item);
            }
        }
    }

    removeItem(item) {
        if (item) {
            this.cookieService.delete(item);
        }
    }

    clear() {
        const hasCookiesInfoShown = this.cookieService.get('hasCookiesInfoShown');
        const translateLanguage = this.cookieService.get('currentLanguage');
        this.cookieService.deleteAll();
        this.cookieService.set('hasCookiesInfoShown', hasCookiesInfoShown, { expires: this.expiryDate });
        this.cookieService.set('currentLanguage', translateLanguage);
    }

    // iterate and set value into moderation request column
    setValueIntoColumn(moderation, channel) {
        if (moderation && moderation.length) {
                for (let i=0; i<moderation.length; i++) {
                    let moderationData: any = moderation[i];
                if (channel && channel.included && channel.included.length) {
                    channel.included.forEach((includedChannelData) => {
                        if (includedChannelData.type === 'projects' && includedChannelData.id == moderationData.project_id) {
                            moderationData.project_name = includedChannelData.attributes.name;
                            if (channel && channel.data[0] &&
                                channel.data[0].attributes &&
                                channel.data[0].attributes.project_user_id) {
                                moderationData.project_user_id = channel.data[0].attributes.project_user_id;
                            }
                        }
                    });
                }

                if (channel && channel.data && channel.data.length) {
                    channel.data.forEach((channelData) => {
                        if ((moderationData.channel_id == channelData.attributes.channel_id) &&
                            (moderationData.user_id != channelData.attributes.user_id)) {
                            if (moderationData.to && moderationData.to.length) {
                                moderationData.to = moderationData.to + ',' +
                                    channelData.attributes.display_name + ', '
                                    + channelData.attributes.role;
                            } else {
                                moderationData.to = channelData.attributes.display_name + ', ' + channelData.attributes.role;
                            }
                        }

                        if ((moderationData.channel_id == channelData.attributes.channel_id) &&
                            (moderationData.user_id == channelData.attributes.user_id)) {
                            if (moderationData.from && moderationData.from.length) {
                                moderationData.from = moderationData.from + ',' +
                                    channelData.attributes.display_name + ', ' +
                                    channelData.attributes.role;
                            } else {
                                moderationData.from = channelData.attributes.display_name + ', ' + channelData.attributes.role;
                            }
                        }
                    })
                }
            }
        }
        this._spinner.hide();
        return moderation;
    }
    setValueIntoColumnVideo(moderation, channel){
        this._spinner.hide();
        return moderation;
    }

    navigateToMentoringDomain() {
        window.location.assign(this.partnerUrl);
    }

    clearUploadControl(event){
        event.target.value = null;
    }

    validateNumbers(event){
        if (event.charCode != 0 && event.charCode != '13') {
            let regex = new RegExp("^[0-9]$");
            let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    }

    transform(bytes: any) {
        let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';
        let unit = 0;
        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }
        return bytes.toFixed(+2) + ' ' + units[unit];
    }

    /**
     * To set menu
     * @param menu
     * @param graduateText
     */
    setMenus(menu, graduateText) {
        if (menu) {
            menu.activities_enabled ? this.setItem('activities_enabled', 'true') : this.removeItem('activities_enabled');
            menu.ask_graduate_enabled ? this.setItem('graduateUrl', graduateText) : this.removeItem('graduateUrl');
            menu.community_enabled ? this.setItem('community_enabled', 'true') : this.removeItem('community_enabled');
            menu.external_materials_enabled ? this.setItem('external_materials_enabled', 'true'): this.removeItem('external_materials_enabled');
            menu.gamification_enabled ? this.setItem('gamification_enabled', 'true'): this.removeItem('gamification_enabled');
        }
    }

    /**
     * to update last active session
     */
    updateLastActiveSession(route) {
        if (this.getItem('token')) {
            let token = 'Token token=\"' + this.getItem('token') + '\"';
            $.ajax({
                url: AppConstant.BASE_URL + "v2/sessions/" + this.getItem('token'),
                headers: {
                    'AUTHORIZATION': token,
                    'Content-Type': 'application/vnd.api+json'
                },
                success: (data) => {
                    if (data && data.data &&
                        data.data.attributes && !data.data.attributes.is_slide_shown) {
                        this.triggerFirstTime = true;
                    }
                    if (data && data.included && data.included.length) {
                        if (data.included[0].type == 'users') {
                            this.lastAccessedProjectId = data.included[0].attributes.last_accessed_project_id;
                            this.isUserDeletionAuthorized = data.included[0].attributes.delete_permission;
                        }
                    }
                },
            });
        }
    }

    checkToTriggerTour() {
        if (this.triggerFirstTimeOrNot) {
            this.triggerTour = true;
            if (!this.checkMobileOrTablet() && this.triggerTour) {
                this.setItem('isTourPending', true);
                this.startFeatureTour();
            }
        }
    }

    getFileType(file) {
        if (file) {
            let fileExtension = '', fileType = '', invalidType = false;
            fileExtension = file.name ? file.name.substr((file.name.lastIndexOf('.') + 1)) : '';
            fileType = file.type ? file.type : fileExtension;
            if (fileType) {
                for (let index = 0; index < this.fileTypesArray.length; index++) {
                    if (fileType == this.fileTypesArray[index]) {
                        invalidType = true;
                        break;
                    }
                }
                return invalidType;
            }
        }
    }

    getProfileImg(img) {
        if (img && img.indexOf('brightside-assets') > -1) {
            img = this.IMG_SERVER_CDN + 'fill/60x60/' + img;
        }else{
            img = `../../../../assets/img/anonymous_user.png`;
        }
        return img;
    }

    validatePaginationParameters(param) {
        if (param && (typeof param == 'string')) {
            if (param.match(/^[0-9]*$/) != null && Number(param) && (Number(param) <= this.maxPaginationValue)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    checkIEBrowser(userAgent) {
        let ieReg = /msie|Trident.*rv[ :]*11\./gi;
        return ieReg.test(userAgent)
    }

    set triggerFirstTime(value) {
        this.setItem('isTriggerFirst', value);
    }

    get triggerFirstTimeOrNot() {
        if(this.getItem('isTriggerFirst') === 'true') {
            return true;
        } else {
            return false
        }
    }

    set connectionEnabled(value) {
        this.setItem('isConnection', value);
    }

    get connectionEnabledOrNot() {
        if (this.getItem('isConnection') && this.getItem('isConnection') == 'true') {
            return true;
        } else {
            return false;
        }
    }

    set typeformEnabled(value) {
        this.isTypeformEnabled = value;
    }

    get typeformEnabled() {
        return this.isTypeformEnabled;
    }

    set mentorTypeformId(value) {
        this.mentorTypeformUrl = value;
    }

    get mentorTypeformId() {
        return this.mentorTypeformUrl;
    }

    set isMatched(value) {
        this.ismentorMatched = value;
    }

    get isMatched() {
        return this.ismentorMatched;
    }

    set mentorUserProjectId(value) {
        this.mentorUserId = value;
    }

    get mentorUserProjectId() {
        return this.mentorUserId;
    }

    set mentorsAvailable(value) {
        this.isMentorAvailable = value;
    }

    get mentorsAvailable() {
        return this.isMentorAvailable;
    }

    checkMobileOrTablet(): boolean {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
            .test(navigator.userAgent)) {
            return true;
        }
        return false;
    }

    startFeatureTour(): void {
        this.triggerTour = true;
        this.setItem('isTourPending', true);
        this.router.navigate(['/channels']);
        let intervalId: any = setInterval(()=> {
            if (this.router.url.includes('channels')) {
                this.startJoyRide();
                clearInterval(intervalId);
            }
        }, 200);
    }

    startJoyRide(): void {
        if (this.router.url.includes('channels')) {
            let intervalId: any = setInterval( () => {
                if ($('#message-box') && $('#message-box').length) {
                    this.debouncedStartTour();
                    clearInterval(intervalId);
                }
            }, 200);
        }
    }

    triggerSiteTour() {
        this.translate.get('websiteTour').subscribe((CustomText:any)=> {
            this.CustoButtonsTextsForTour=CustomText;
           });
        this.joyrideService.startTour({
            customTexts: {
                next: this.CustoButtonsTextsForTour.next,
                prev: this.CustoButtonsTextsForTour.prev,
                done: this.CustoButtonsTextsForTour.done
              },
            steps: [`introStep@/channels`, `channelStep` , `firstStep@/channels`, `secondStep@/channels`, `attachStep`, `thirdStep`, `lastTour@/channels`],
            showCounter: false,
        });
        this.setItem('isTourPending', false);
        this._spinner.hide();
    }

    set partnerDetails(partnerData: any) {
        if(partnerData && partnerData.length) {
            partnerData.forEach(item => {
                if (item.type === 'partners') {
                    if(item && item.attributes && item.attributes.logo_id) {
                        this.setItem('partnerLogoId', item.attributes.logo_id);
                    } else {
                        this.setItem('partnerLogoId', '');
                    }
                    if(item && item.attributes && item.attributes.name) {
                        this.setItem('partnerName', item.attributes.name);
                    }
                }
            })
        }
    }

    set setPartnerDetails(partnerDetails: any) {
        this.partnerData = partnerDetails;
    }

    get getPartnerDetails() {
        return this.partnerData;
    }

    set setAvatarId(avatarId: string) {
        this.avatarId = avatarId;
    }

    get getAvatarId() {
        return this.avatarId;
    }

    checkForMobile(userAgent) {
        return this.checkMobileOrTablet();
    }

    checkOnlyForMobile() {
        if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i
                .test(navigator.userAgent)) {
            return true;
        }
        return false;
    }
    checkCameraMicrophonePermission() {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    // Permission granted, close the stream
                    stream.getTracks().forEach(track => track.stop());
                    resolve(true);
                })
                .catch(error => {
                    if (error.name === 'NotAllowedError') {
                        // Permission denied
                        resolve(false);
                    } else {
                        // Other errors
                        reject(error);
                    }
                });
        });
    }
    set surveyEnabled(value) {
        this.isSurveyEnabled = value;
    }


}
