import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {routing} from './app.routing';
import {App} from './app.component';
import {GlobalState} from './global.state';
import {NgaModule} from './theme/nga.module';
import {AuthenticationHelper} from './helpers/authenticationHelper';
import {ApplicationServices} from './appServices/application';
import {APP_BASE_HREF} from "@angular/common";
import {MentoringServices} from './appServices/mentoring';
import {UtilityHelper} from "./helpers/utilityHelper";
import {AdminServices} from "./appServices/adminService";
import {MentoringEvents} from "./helpers/events";
import {SocketService} from "./messageServices/socketService";
import {MessageComponent} from "./messageServices/components";
import {NotificationClient} from "./messageServices/notificationClient";
import {Attachments} from "./messageServices/attachment";
import {HttpClientHelper} from "./appServices/apiClient";
import {KnowledgeServices} from "./appServices/knowledgeService";
import {CkEditorHelper} from "./helpers/ckEditorHelper";
import {ReportHelper} from "./helpers/reportHelper";
import {PreLoginGuard} from './routingGuards/preLogIn.guard';
import {LoginGuard} from './routingGuards/logIn.guard';
import {AdminGuard} from './routingGuards/admin.guard';
import {AdminMentoringGuard} from './routingGuards/adminMentoring.guard';
import {SuperAdminGuard} from './routingGuards/superAdmin.guard';
import {MentoringGuard} from './routingGuards/mentoring.guard';
import {ChannelAccessGuard} from './routingGuards/channelAccess.guard';
import {BrilliantGuard} from './routingGuards/brilliant.guard';
import {KnowledgeGuard} from './routingGuards/knowledge.guard';
import {AdminRedirectionGuard} from './routingGuards/adminRedirection.guard';
import {NavigationGuard} from './routingGuards/navigation.guard';
import {AssignmentGuard} from "./routingGuards/assignment.guard";
import {MentoringMessagingComponent} from "./messageServices/mentoringMessagingComponent";
import {MentoringSocketService} from "./messageServices/mentoringSocketService";
import {JoyrideModule} from "ngx-joyride";
import {DateAndTime} from "./helpers/dateAndTime";
import {TextHelper} from "./helpers/textHelper";
import {ImageHelper} from "./helpers/imageHelper";
import {MenteeAccessGuard} from "./routingGuards/menteeAccess.guard";
import {CertificateHelper} from "./helpers/certificateHelper";
import {SignupHelper} from "./helpers/signupHelper";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {ToastrModule,
    ToastNoAnimation,
    ToastNoAnimationModule} from "ngx-toastr";
import {ToastsManager} from "./helpers/toastsManager";
import {CookieModule} from "ngx-cookie";
import {CurrentUserStateService} from "./appServices/current-user-state.service";
import {DraftMessageTextHelper} from "./helpers/draftMessageTextHelper";
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import {bugsnagClientConfiguration} from "./bugsnag";
import { environment } from '../environments/environment';
// import {ChartsModule} from "ng2-charts";
import {DynamicURLHelper} from "./helpers/dynamicURLHelper";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularDraggableModule } from 'angular2-draggable';
import { QuillModule } from 'ngx-quill'

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }

// Application wide providers
const APP_PROVIDERS = [
    GlobalState,
    LoginGuard,
    PreLoginGuard,
    AdminMentoringGuard,
    AdminGuard,
    SuperAdminGuard,
    MentoringGuard,
    SocketService,
    MessageComponent,
    NotificationClient,
    Attachments,
    ChannelAccessGuard,
    AuthenticationHelper,
    HttpClientHelper,
    ApplicationServices,
    MentoringServices,
    UtilityHelper,
    ReportHelper,
    AdminServices,
    MentoringEvents,
    BrilliantGuard,
    KnowledgeServices,
    KnowledgeGuard,
    AdminRedirectionGuard,
    NavigationGuard,
    AssignmentGuard,
    MentoringMessagingComponent,
    MentoringSocketService,
    DateAndTime,
    TextHelper,
    ImageHelper,
    MenteeAccessGuard,
    CertificateHelper,
    ToastsManager,
    CurrentUserStateService,
    SignupHelper,
    DraftMessageTextHelper,
    DynamicURLHelper,
    CookieModule,
    AngularDraggableModule,
    QuillModule.forRoot()
];

/**
 * `AppModule` is the main entry point into Angular's bootstraping process
 */


export function errorHandlerFactory() {
    return new BugsnagErrorHandler()
}

Bugsnag.start(bugsnagClientConfiguration)
