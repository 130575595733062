import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import { Router } from '@angular/router';
import { UtilityHelper } from "../helpers/utilityHelper";
import { HttpClientHelper } from "./apiClient";
import { SocketService } from "../messageServices/socketService";
import { NotificationClient } from "../messageServices/notificationClient";
import { MentoringSocketService } from "../messageServices/mentoringSocketService";
import { throwError } from "rxjs";
import { catchError, pluck } from 'rxjs/operators';


@Injectable({providedIn:'root'})
export class ApplicationServices {
    private getThreadsUrl: string = 'threads?filter[archived]=false';
    private dashboardUrl: string = 'threads?page[size]=5&filter[archived]=false';
    private threadsUrl: string = 'threads';
    private postsUrl: string = 'posts';
    private findThreadUrl: string = 'threads?filter[search]=';
    private findPostUrl: string = 'posts?filter[for_thread]=';
    private getArchivedThreadsUrl: string = 'threads?filter[archived]=true';
    private topicsUrl: string = 'topics';
    private getProjectAndPartnerUrl: string = 'v2/projects/';
    private getProjectUserUrl: string = 'v2/project_users?filter[project_id]=';
    private signOutUrl: string = 'sessions/';
    private getTopicsThreadUrl: string = 'threads?filter[for_topics]=';
    private communityReportsUrl: string = 'community_reports';
    private getSSO_TokensUrl: string = 'sso_tokens';
    private httpClient: HttpClientHelper;

    constructor(httpClient: HttpClientHelper, private router: Router,
        private utility: UtilityHelper, private socketService: SocketService,
        private notification: NotificationClient, private mentoringSocketService: MentoringSocketService) {
        this.httpClient = httpClient;
    }

    // To get thread list
    getThreads(data): Observable<any> {
        return this.httpClient.get(this.getThreadsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To create thread
    createThread(data): Observable<any> {
        return this.httpClient.post(this.threadsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To add post on thread
    addPost(data): Observable<any> {
        return this.httpClient.post(this.postsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get specific thread details
    getSpecificThread(data): Observable<any> {
        return this.httpClient.get(this.threadsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get update specific post of thread
    updateThreadPost(id, data): Observable<any> {
        return this.httpClient.put(this.postsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To like dislike post
    likeDislikePost(id, data): Observable<any> {
        return this.httpClient.post(this.postsUrl + '/' + id + '/like', data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To follw unfollw thread
    followUnfollwThread(id, data): Observable<any> {
        return this.httpClient.post(this.threadsUrl + '/' + id + '/follow', data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To delete post
    deletePost(id): Observable<any> {
        return this.httpClient.delete(this.postsUrl + '/' + id)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To Update thread name
    updateThreadName(id, data): Observable<any> {
        return this.httpClient.put(this.threadsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To delete topic
    deleteTopic(id): Observable<any> {
        return this.httpClient.delete(this.threadsUrl + '/' + id)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To find threads
    findThread(data): Observable<any> {
        return this.httpClient.get(this.findThreadUrl + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To find post
    findPost(id, data): Observable<any> {
        return this.httpClient.get(this.findPostUrl + id + '&filter[search]=' + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get archived threads
    getArchivedThreads(data): Observable<any> {
        return this.httpClient.get(this.getArchivedThreadsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To archive specific threads
    archiveThread(id, data): Observable<any> {
        return this.httpClient.put(this.threadsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get topic categories
    getTopics(filter): Observable<any> {
        return this.httpClient.get(this.topicsUrl + '?filter[project_id]=' + this.utility.getItem('projectId') + filter)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get topic categories
    createTopic(data): Observable<any> {
        return this.httpClient.post(this.topicsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get topic categories
    uploadImg(formData, options): Observable<any> {
        return this.httpClient.imgPost('', formData, options)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To like dislike thread
    likeDislikeThread(id, data): Observable<any> {
        return this.httpClient.post(this.threadsUrl + '/' + id + '/like', data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get dashboard topics
    getDashboardThreads(): Observable<any> {
        return this.httpClient.get(this.dashboardUrl)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get project and partner
    getProjectAndPartner(data): Observable<any> {
        return this.httpClient.get(this.getProjectAndPartnerUrl + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get user role
    getProjectUser(data): Observable<any> {
        return this.httpClient.get(this.getProjectUserUrl + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get specific topic
    getSpecificTopic(data): Observable<any> {
        return this.httpClient.get(this.topicsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get update topic
    updateTopic(id, data): Observable<any> {
        return this.httpClient.put(this.topicsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To signout user
    signOut(token): Observable<any> {
        return this.httpClient.delete(this.signOutUrl + token)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get threads of specific topic
    getTopicsThread(data): Observable<any> {
        return this.httpClient.get(this.getTopicsThreadUrl + data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get community reports data
    communityReports(data): Observable<any> {
        return this.httpClient.post(this.communityReportsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));;
    }

    // To get the JWT token data
    getsso_tokens(data): Observable<any> {
        return this.httpClient.post(this.getSSO_TokensUrl, data)
            .pipe(catchError(this.handleError.bind(this)));;
    }


    private extractResponse(res) { return res; }

    private handleError(error) {
        if (error.error == "Unauthorized") {
            this.socketService.disconnectSocket();
            this.mentoringSocketService.disconnectSocket();
            this.notification.disconnectSocket();
            this.utility.clear();
            this.router.navigate([''])
        } else {
            return throwError(error);
        }
    }
}
