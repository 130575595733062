import {TextHelper} from "../helpers/textHelper";
import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {AppConstant} from "../app.constant";
import {DateAndTime} from "../helpers/dateAndTime";
import { TranslateService } from "@ngx-translate/core";
import { UtilityHelper } from "app/helpers/utilityHelper";

@Injectable()
export class MessageComponent {
    currentState: any;
    imageServerCdn: string = environment.imgServerCDN;
    s3BucketOrigin: string = environment.s3BucketOriginUrl;
    // fullRedaction: boolean = true;
    updatedPayloadBody: string = ''
    messaging: any=[];

    constructor(private textHelper: TextHelper,
                private dateAndTime: DateAndTime,
                private translate: TranslateService,
                private utility: UtilityHelper) {
    }
    getTranslate() {
        this.translate.get('channnels').subscribe((value:any)=> {
            this.messaging=value;
           });
    }  
    linkifyText(text) {
        return this.textHelper.linkifyText(text);
    }

    breakifyText(text) {
        return this.textHelper.breakifyText(text);
    }

    formatDate(text) {
        let date = new Date(Date.parse(text));
        let dateTime = this.dateAndTime.defaultDateAndTimeFormat(date,'H:mm DD/MM/YYYY');
        return dateTime;
    }

    files(files) {
        if(files.length == 0) {
            return '';
        }
        else{
            let content = "<ul class=\"msg__files list--preview\">";
            files.forEach(file => {
                content = content + this.generateDownload(file);
            });
            content = content + "</ul></section>";
            return content;
        }
    }

    attachments(attachments) {
        this.getTranslate()
        if(attachments.length == 0) {
            return '';
        }
        else {
            let content = `<section class=\"msg__attachments\"><h6>${this.messaging.attachments}</h6><ul>`;
            attachments.forEach(attachment => {
                content = content + this.generateAttachment(attachment);
            });
            content = content + "</ul></section>";
            return content;
        }
    }

    updateMessage(msg, payload, chatUserId) {
        let previousState = msg.attr("data-msg-state");
        this.currentState = payload.state;
        if (payload.state == 'flagged' && payload.reported_user_id) {
            this.currentState = 'pending'
        }

        let extractedYoutubeUrls: any = this.textHelper.extractYoutubeUrls(payload.body);
        payload.body = this.textHelper.checkAndBoldOrItalicText(payload.body);
        payload.body = this.linkifyText(this.breakifyText(payload.body));

        msg.removeClass("msg--" + previousState +"").addClass("msg--"+ this.currentState);
        msg.find(".msg--" + previousState + "__message-content").removeClass("msg--"+ previousState + "__message-content").addClass("msg--"+ this.currentState + "__message-content");
        msg.find('.msg__status').html(this.messageStatus(payload.state, payload.reported_user_id,
            payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId));
        if (payload.reported_user_id == chatUserId && ['approved', 'pending'].includes(payload.state)) {
            let messagePartial = '';
            messagePartial = "<div class=\"msg__message-content msg--" + payload.state + "__message-content\">" + payload.body + "</div>"
            messagePartial = messagePartial + "" + this.files(payload.files) + this.attachments(payload.attachments) + ""
                + this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls) + "";
            msg.find('.msg__status').append(messagePartial);
        }
        msg.find('.msg__alert').remove();
        msg.attr("data-msg-state",this.currentState);
        if (this.currentState == 'flagged' || this.currentState == 'declined'){
            msg.append("<div class=\"msg__alert\">" + this.alertMessage(payload, chatUserId) + "</div>");
        }
    }

    updateUserModeratedMessage(msg) {
        this.getTranslate()
        let previousState = msg.attr('data-msg-state');
        msg.removeClass(`msg--${previousState}`).addClass('msg--flagged');
        msg.find(`.msg--${previousState}__message-content`)
            .removeClass(`msg--${previousState}__message-content`)
            .addClass(`msg--flagged__message-content`);
        msg.find('.msg__status').prepend(`<span>${this.messaging.pendingModeration} -</span>`);
        msg.find('.msg__alert').remove();
        msg.attr('data-msg-state', 'flagged');
        msg.append('<div class=\"msg__alert\">' +
            `${this.messaging.youHaveFlaggedThisMessageForModeration}</div>`);
    }

    openTextBox(msg, msgId, chatUserId) {
        msg.append('<div class=\"moderation-reason\" id=\"flagged-reason\" data-msg-id=\"' + msgId + '\" data-user-id=\"' + chatUserId + '"\>' +
            '<textarea class=\"reason-box__reason-input\" id=\"reason__input\" role=\"textbox\"></textarea>' +
            '<input type=\"submit\" class=\"btn btn--disabled .message-box__send-btn submit-reason\" disabled=\"disabled\" value=\"Submit\" aria-label=\"Submit\">' +
            '<input type=\"submit\" class=\"btn .message-box__send-btn cancel-reason\" value=\"Cancel\" aria-label=\"Submit\">' + '</div>');
    }

    updateHeader(msg, payload) {
        if (msg[0].childNodes[0].className == 'msg--header card--message') {
            if (msg.next().length && msg.next()[0] && $(msg.next()[0]).data('msg-user-id')
                && payload.user_id == $(msg.next()[0]).data('msg-user-id')) {
                msg.next().prepend(msg[0].childNodes[0]);
            }
        }
    }

    showHeader(msgUserId, lastToSpeak, newMessage){
        let msg = $("#messages");
        if (newMessage) {
            if (msg && msg[0] && msg[0].children.length) {
                if (msg[0].lastElementChild && $(msg[0].lastElementChild).data('msg-user-id')) {
                    if (msgUserId == $(msg[0].lastElementChild).data('msg-user-id')) {
                        return false
                    } else {
                        return true
                    }
                }
            }
        } else {
            if (lastToSpeak == msgUserId) {
                if (msg && msg[0] && msg[0].children.length &&
                    msg[0].children[0].children.length) {
                    msg[0].children[0].children[0].remove();
                }
            }
        }
        return true;
    }

    sentReceived(msg_user_id, chat_user_id){
        this.getTranslate
        let sentReceived;
        if(msg_user_id.toString() == chat_user_id) {
            sentReceived = `${this.messaging.sentOn}`;
        }
        else {
            sentReceived = `${this.messaging.receivedAt}`;
        }
        return sentReceived;
    }

    messageStatus(state, reportedUserId, createdAt, sentReceived, chatUserId) {
        this.getTranslate()
        if (((state === 'flagged' && reportedUserId && reportedUserId != chatUserId)
                || state !== 'flagged') && state !== 'declined') {
            return `<span class=\"small\">${sentReceived} ${this.formatDate(createdAt)}</span>`;
        } else {
            let stateMessage = '';
            if (state === 'flagged') {
                stateMessage = `${this.messaging.pendingModeration} - ${sentReceived} ${this.formatDate(createdAt)}`;
            } else if (state === 'declined' && chatUserId == reportedUserId){
                stateMessage = `${this.messaging.couldNotBeReceivedFailedModeration}`;
            } else {
                stateMessage = `${this.messaging.couldNotBeSentFailedModeration}`;
            }
            return `<span class=\"small\">${stateMessage}</span>`;
        }
    }

    alertMessage(message, chatUserId) {
        this.getTranslate()
        if (message.state == 'flagged' && message.reported_user_id == chatUserId) {
            return `${this.messaging.youHaveFlaggedThisMessageForModeration}`;
        } else if (message.state == 'flagged') {
            if(this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction'){
                return `Your message is being checked by our team. Your [mentor/mentee] knows 
                you've messaged them and will receive the full message once it's approved.`;
            } else {
                return 'Your message has been sent into the moderation queue. Once it is approved,' +
                ' it will be sent to the recipient.';
            }
        } else {
            return  `${this.messaging.thisMessageHasBeenDeclinedInModeration}`;
            // return `This message has been declined in moderation. Reason: ${message.declined_reason}`;
        }
    }

    previewIconPath(type){
        if(type == 'video'){
            return "";
        }
        else if(type == 'image') {
            return "../../assets/img/image-preview-icon.svg";
        }
        else {
            return "../../assets/img/file-preview-icon.svg";
        }
    }

    title(title) {
        if(!title) {
            return '';
        }
        else {
            return "<h4>" + this.linkifyText(title) + "</h4>";
        }
    }

    image(image) {
        if(!image) {
            return '';
        }
        else {
            return "<img src=\"" + image + "\" width=\"180\"/>";
        }
    }

    description(description) {
        if(!description) {
            return '';
        }
        else {
            return "<p class=\"small\">" + this.linkifyText(description)+ "</p>";
        }
    }

    generateAttachment(attachment) {
        return "<li>" + this.title(attachment.title) + this.image(attachment.image) +
            this.description(attachment.description) + "</li>";
    }

    transform(bytes: any) {
        let units = [ 'bytes','KB','MB','GB','TB','PB'];
        if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) return '?';

        let unit = 0;

        while ( bytes >= 1024 ) {
            bytes /= 1024;
            unit ++;
        }
        return (parseFloat(bytes).toFixed( + 2 )) + ' ' + units[ unit ];
    }

    generateDownload(file){
        this.getTranslate()
        const contentType = file.attachment_content_type || '';
        let  attachmentId: any = file.attachment_id;
        const attachmentFileName: any = file.attachment_filename;
        let imageFill: string = 'fill/240x240/';

        if (contentType && contentType.includes('image') && attachmentId
            && attachmentId.includes('brightside-assets') && !contentType.includes('gif')) {
            attachmentId = this.imageServerCdn + imageFill + attachmentId;
        } else if (contentType && attachmentId && attachmentId.includes('brightside-assets')) {
            if (contentType === 'video/mp4' && this.checkForIosDevice()) {
                attachmentId = attachmentId.replace(/\bbrightside-assets\b/g, this.s3BucketOrigin);
            } else {
                attachmentId = this.imageServerCdn + attachmentId;
            }
        } else if (contentType === 'video/mp4' && this.checkForIosDevice()) {
            attachmentId = attachmentId.replace(/\bbrightside-assets\b/g, this.s3BucketOrigin);
        }

        if (this.isAttachmentSupported(file, 'video')) {
            if (this.checkForIosOrSafariInMac()) {
                return `<li class=\"video-wrapper msg-video-container ios-video-container\" data-video-src=\"${attachmentId}\">
                       <svg class=\"video-overlay-play-button\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                        <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                        <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                      </svg>
                    </li>`;
            } else {
                return `<li class=\"video-wrapper msg-video-container\"><video width=\"100%\" height=\"240\">
                       <source src=\"${attachmentId}\" type=\"video/mp4\">
                       <source src=\"${attachmentId}\" type=\"video/ogg\">
                       <source src=\"${attachmentId}\" type=\"video/webm\">
                               ${this.messaging.yourBrowserDoesNotSupportTheVideoTag}
                       </video>
                       <svg class=\"video-overlay-play-button\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                        <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                        <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                      </svg>
                    </li>`;
            }
        } else if (this.isAttachmentSupported(file, 'image')) {
            return `<li class=\"channel-img-wrap\"> 
                        <img src=\"${attachmentId}\" alt=\"${attachmentFileName}\">
                    </li>`;
        } else {
            return "<li><div class=\"file-preview\"><div class=\"file-preview__icon\"><img src=\"" +
                this.previewIconPath(contentType) + "\"/></div><span class=\"file-preview__title\">" +
                attachmentFileName + "</span><span class=\"file-preview__info\">"+ this.transform(file.attachment_size) +
                "</span><div class=\"file-preview__action\"><a class=\"btn--file-download\" target=\"_blank\" href=\"" +
                this.imageServerCdn+ "/" + file.attachment_id+ `\"><i class=\"fa fa-cloud-download download-attachment\"></i><span class=\"btn--file-download__text\">${this.messaging.download}</span>` +
                "</a></div></div></li>";
        }
    }

    setModerationFlag(message, chatUserId, channelType) {
        if (message.user_id.toString() != chatUserId && channelType != 'support' && message.state == 'pending') {
            return this.setResetFlag(message.id, chatUserId)
        }
        return '';
    }

    setResetFlag(messageId, msgUserId) {
        this.getTranslate()
        if (messageId && msgUserId) {
            return `<i class=\"fa fa-flag moderate-msg\ tab-outline" tabindex="0" data-msg-id= ${messageId} data-user-id= ${msgUserId} 
                aria-hidden=\"true\" style=\"float: right; margin-right: 1%\" title=\"${this.messaging.reportThisMessage}\"></i>`;
        }
        return '';
    }

    generate(payload, user_data, chatUserId, last_to_speak, channelType) {
        let messageState = payload.state;
        let isSender = false, isReportedUser = false, isReported = false;

        if (payload.user_id == chatUserId) {
            isSender = true;
        }

        if (payload.reported_user_id) {
            isReported = true;
            if (payload.reported_user_id == chatUserId) {
                isReportedUser = true
            }
        }

        if (payload.state == 'flagged' && isReported && isSender) {
            messageState = 'pending'
        }

        let extractedYoutubeUrls: any = this.textHelper.extractYoutubeUrls(payload.body);
        if (payload.body && payload.body.length) {
            let reviewActivityText = this.textHelper.checkForReviewActivity(payload.body);
            if (reviewActivityText) {
                payload.body = reviewActivityText;
            }
        }
        if (!payload.automated) {
            payload.body = this.textHelper.checkAndBoldOrItalicText(payload.body);
        } 
        let messagePartial = `<div class=\"msg msg--` + messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState + " data-msg-user-id=" + payload.user_id + " tabindex='0'>";

        if (this.showHeader(payload.user_id, last_to_speak, payload.new_message)) {
            messagePartial = messagePartial + "<figure class=\"msg--header card--message\"><img src=\"" + user_data.avatar + "\" class=\"card--message__image\"/><figcaption class=\"card--message__caption\"><div class=\"info\"><h6 class=\"card__title msg__display_name\">" + user_data.display_name + '</h6></div></figcaption></figure>';
        }

        messagePartial = messagePartial + "<div class=\"msg__status\">" + this.messageStatus(payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + (payload.automated ? '' : this.setModerationFlag(payload, chatUserId, channelType)) + "</div>";

        if (isSender || ['approved', 'pending'].includes(payload.state)) {
            if(payload.body) {
                this.toCheckVideoCallsAndDyAnnouncementData(payload)
                messagePartial = messagePartial + "<div class=\"msg__message-content msg--" + messageState + "__message-content\">" + this.linkifyText(this.breakifyText(this.updatedPayloadBody)) + "</div>"
                messagePartial = messagePartial + "" + this.files(payload.files) + this.attachments(payload.attachments) + "" + this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls) + "";
            }
        }

        if ((payload.state == 'flagged' && isReported && !isSender)
            || payload.state == 'declined' || (payload.state == 'flagged' && !isReported && isSender)) {
            messagePartial = messagePartial + "<div class=\"msg__alert\">" + this.alertMessage(payload, chatUserId) + "</div>";
        }

        messagePartial = messagePartial + "</div>";
        return messagePartial;
    }

    generateAtatchedYoutubeVideosTemplate(extractedUrls) {
        let videoTemplate: any = "";
        if (extractedUrls && extractedUrls.length) {
            extractedUrls.forEach((item) => {
                let thumbnail = this.textHelper.extractYoutubeThumbnails(item, 'medium');
                videoTemplate += `<div class=\"attached-youtube-video-parent-wrap\">
                                    <div class=\"youtube-thumbnail-parent-wrap\">
                                        <img src=\"${thumbnail}\" alt=\"Video Thumbnail\" width="100%" height="240" 
                                             data-video-src=\"${item}\">
                                        <svg class=\"video-overlay-play-button\" viewBox=\"0 0 200 200\" alt=\"Play video\" width="100%" height="240">
                                            <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                                            <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                                        </svg>
                                      </div>
                                 </div>`;
            });
        }
        return videoTemplate;
    }

    isAttachmentSupported(file, type) {
        if (file) {
            const contentType = file.attachment_content_type || '';
            if (type === 'video' && contentType) {
                return AppConstant.SUPPORTED_VIDEO_TYPES.includes(contentType);
            } else if (type === 'image' && contentType && contentType.includes('image')) {
                let isImageSupported: boolean = false;
                AppConstant.SUPPORTED_IMAGE_TYPES.some((imageType)=> {
                    if (contentType.toLowerCase().includes(imageType.toLowerCase())) {
                        isImageSupported = true;
                        return true;
                    }
                });
                return isImageSupported;
            }
        }
        return false;
    }

    checkForIosDevice() {
        if ((/MacIntel|MacPPC|Mac68K|Macintosh|iPhone|iPod|iPad|iPhone Simulator|iPod Simulator |iPad Simulator|Pikev7.6 release 92|Pike v7.8 release 517/i.test(navigator.userAgent))) {
            return true;
        }
        return false;
    }

    checkForIosOrSafariInMac() {
        let isSafari: any = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if ((/iPhone|iPod|iPad/i.test(navigator.userAgent)) || isSafari ) {
            return true;
        }
        return false;
    }

    toCheckVideoCallsAndDyAnnouncementData(msgPayload) {
        this.updatedPayloadBody = msgPayload.body;
        if (msgPayload && msgPayload.automated == true && this.textHelper.regextToCheckVideoWordContains(msgPayload.body)) {
            let payloadBodyObj = JSON.parse(msgPayload.body);
            if (payloadBodyObj.is_call_scheduled && payloadBodyObj.is_call_scheduled == true) {
                if (payloadBodyObj['schedule_call_state'] == 'canceled') {
                    this.updatedPayloadBody = 'Call canclled timestamp';
                } else if (payloadBodyObj['schedule_call_state'] == 'pending') {
                    this.updatedPayloadBody = 'Call invitation sent/recieved timestamp';
                } else if (payloadBodyObj['schedule_call_state'] == 'accepted') {
                    this.updatedPayloadBody = 'Call accepted timestamp';
                } else if (payloadBodyObj['schedule_call_state'] == 'declined') {
                    this.updatedPayloadBody = 'Call declined timestamp'
                } else if (payloadBodyObj['type'] == 'video_call') {
                    this.updatedPayloadBody = 'Join call button';
                }
            } else if (payloadBodyObj.type && payloadBodyObj.type == 'video_timestamp') {
                if (payloadBodyObj.started_at) {
                    if(!payloadBodyObj.room_status){
                        this.updatedPayloadBody = 'Started at timestamp';
                    }else if(payloadBodyObj.room_status == 'room-ended'){
                        this.updatedPayloadBody = 'End call button';
                    }else if (payloadBodyObj.room_status == 'room-created'){
                        this.updatedPayloadBody = 'Join call button';
                    }else if (payloadBodyObj.room_status == 'missed_call'){
                        this.updatedPayloadBody = 'Missed video call button';
                    }
                } else if (payloadBodyObj.ended_at) {
                    this.updatedPayloadBody = 'Ended at timestamp';
                } else {
                  this.updatedPayloadBody = msgPayload.body 
                }
            } else {
                this.updatedPayloadBody = msgPayload.body  
            }
        }else if(msgPayload && msgPayload.automated == null){
            const regex = /(?=.*"enAnnBdy":).*"wlsAnnBdy":/;
            const result = regex.test(msgPayload.body );
            if (result) {
                msgPayload.body = JSON.parse(msgPayload.body)
                this.updatedPayloadBody = msgPayload.body.enAnnBdy
            }
        }
    }
}
