import {Injectable} from '@angular/core';
import * as Phoenix from './../../assets/javascript/phoenix.js';
import {AppConstant} from "../app.constant";
// import "../../assets/javascript/messagescroll.js"
import {NotificationClient} from "./notificationClient";
import {Attachments} from "./attachment";
import {UtilityHelper} from "../helpers/utilityHelper";
import {MentoringMessagingComponent} from "./mentoringMessagingComponent";
import {MentoringEvents} from "../helpers/events";
import * as loadash$ from 'lodash';
import {ToastsManager} from "../helpers/toastsManager";
import * as moment from "moment";
import { DateAndTime } from '../helpers/dateAndTime';
import {MentoringServices} from "../appServices/mentoring";
import { TranslateService } from '@ngx-translate/core';

declare var window: any;
let channelToken: any;
let channelUsers: any;
let sessionToken: any;
let myActiveChannel: any;
let channelType: any;
let userRole: any;
let badges: any;
let pageNumber: any = 0;
let limit: any = 15;
let scrolled: boolean= false;
let lastPage: boolean = false;
window.__forceSmoothScrollPolyfill__ = true;

@Injectable()
export class MentoringSocketService {

    socket: any;
    joinedChannels = {};
    browserRefresh: boolean;
    toasterMessageChannels: any=[];
    isVideoLive: boolean;
    constructor(private messageComponent: MentoringMessagingComponent, private attachments: Attachments,
                private notificationClient: NotificationClient, private utility: UtilityHelper,
                private mentoringEvents: MentoringEvents, private _toaster: ToastsManager,
                private dateAndTime: DateAndTime,
                private translate:TranslateService) {
        this.fetchData();
    }
    ngOnInit() {
        this.translate.get('channnels').subscribe((value:any)=> {
            this.toasterMessageChannels=value;
           });
    }

    getTranslate() {
        this.translate.get('channnels').subscribe((value:any)=> {
            this.toasterMessageChannels=value;
           });
    }

    fetchData() {
        if (sessionToken && channelToken) {
            if (!window.messageGenerator) {
                window.messageGenerator = this.messageComponent;
            }
        } else {
            if (this) {
                this.leaveChannels();
            }
        }

        lastPage = false;
        this.removeDefaultChatBotTemplate();
        this.mentoringEvents.getPushNewMessage().subscribe((item) => {
            if (item) {
                this.pushNewMessage();
            }
        });
    }

    linkify(text) {
        return this.messageComponent.linkifyText(text);
    }

    isLastPage() {
        return lastPage;
    }

    setData(users, channelId, token) {
        channelUsers = {};
        channelUsers = users;
        channelToken = '';
        channelToken = channelId;
        sessionToken = '';
        sessionToken = token;
        if(sessionToken && channelToken) {
            if(!window.messageGenerator) {
                window.messageGenerator = this.messageComponent;
            }
        }
        this.connect();
    }

    getMessages() {
        pageNumber = pageNumber + 1;
        scrolled = true;
        myActiveChannel.push("get_messages", { page_number: pageNumber, page_size: limit });
        if ($("#channel") && $("#channel").length) {
            $("#channel").removeClass('state--channel-loading').addClass('state--channel-loaded');
        }

        if ($('#msg-loading-indicator') && $('#msg-loading-indicator').length) {
            $('#msg-loading-indicator').css('display', 'none');
        }
    }

    connect() {
        if (!this.socket && sessionToken) {
            let socketParams = {
                heartbeatIntervalMs: 20000,
                params: {
                    session_token: sessionToken
                }
            };

            this.socket = new Phoenix.Socket(AppConstant.MSG_SERVICE_URL, socketParams, {
                logger: ((kind, msg, data) => { console.log(`${kind}: ${msg}`, data) }),
                transport: WebSocket
            });
            this.socket.connect();

            this.socket.onOpen(event => {
                this.updateConnectionState('connected', event);
            });

            this.socket.onError(error => {
                this.onError(Phoenix, error);
            });

            this.socket.onClose(event => {
                this.onClose(Phoenix, event);
            });
        }
    }

    updateConnectionState(state, event) {
        if ($('#channel') && $('#channel').length && $('#channel')[0]) {
            $('#channel')[0].className =
                $('#channel')[0].className.replace(/(^|\s)state--websocket-\S+/g, "");
            if (!$('#channel').hasClass("state--websocket-" + state)) {
                return $('#channel').addClass("state--websocket-" + state);
            }
        }
    }

    onError(data, error) {
        if (navigator.onLine) {
            if (this.socket && this.socket.transport === window.WebSocket) {
                this.socket.transport = Phoenix.LongPoll;
            } else if (this.socket && window.WebSocket) {
                this.socket.transport = window.WebSocket;
            }
        }
        if (navigator.onLine) {
            return this.updateConnectionState('error', error);
        } else {
            return this.updateConnectionState('offline', error);
        }
    }

    onClose(data, event) {
        if (navigator.onLine) {
            return this.updateConnectionState('closed', event);
        } else {
            return this.updateConnectionState('connecting', event);
        }
    }

    setChannelToNonEmpty(messagesContainer) {
        if (messagesContainer && messagesContainer.length) {
            return messagesContainer.removeClass("state--empty-channel").addClass('state--non-empty-channel');
        }
    }

    successfulJoin(channel, channelToken, resp) {
        this.updateChannelState('joined');
        this.joinedChannels[channel.topic] = channel;
        this.notificationClient.resetChannel(channelToken);
        channel.push("get_messages", {page_number: pageNumber, page_size: limit});
        if ($("#channel") && $("#channel").length) {
            $("#channel").removeClass('state--channel-loading').addClass('state--channel-loaded');
        }
    }

    failedJoin(reason, channel, error) {
        this.updateChannelState(reason);
    }

    updateChannelState(state) {
        if ($('#channel') && $('#channel').length) {
            $('#channel')[0].className = $('#channel')[0].className.replace(/(^|\s)state--channel-\S+/g, "");
            if ($('#channel').hasClass("state--channel-" + state + "")) {
                $('#channel').addClass("state--channel-" + state + "");
            }
        }
    }

    pushMessage(channel, chatInput, chatProjectId, chatSendBtn, attachment) {
        chatInput = chatInput[0];
        if (chatInput.innerText && chatInput.innerText.trim()
            && chatInput.innerText.trim().length) {
            let pushed = channel.push("shout", {
                body: chatInput.innerText.trim(),
                project_id: chatProjectId.val(),
                attachment: attachment.val(),
            });
            chatInput.innerText = '';
            attachment.val("");
            chatSendBtn.prop("disabled", true);
            chatSendBtn.addClass('btn--disabled');
            this.attachments.resetAttachments();
        }
    }

    failedPush(reason, hannel) {
        this.updateChannelState(reason);
    }

    leaveChannels() {
        let channels: any = this.joinedChannels;
        this.joinedChannels = {};
        return $.each(channels, function(topic: any, channel) {
            return channel.leave().receive("ok", (function(__this) {
                return function() {
                    return console.log("[ChannelClient] left " + topic);
                };
            })(this));
        });
    }

    appendMessage(container, holder, message, newMessage) {
        this.setChannelToNonEmpty(container);
        let messagesHolder = $("#messages");
        // Tweak this extra scroll for perfecting the lazy scrolling
        const extraScrollHeightAppended: any = 116;

        let pagerElement = '<div id="pageNum' + pageNumber + '" style="clear:both; height: 0.001px"></div>';
        if (message && messagesHolder && messagesHolder.length) {
            if (!scrolled || newMessage) {
                scrolled = true;
                holder.append(message);
            } else {
                let targetElement = "#pageNum" + pageNumber;
                if (!$(targetElement).length && pageNumber !== 0 && !newMessage) {
                    message = message + pagerElement;
                }
                holder.prepend(message);
                messagesHolder.scrollTop(extraScrollHeightAppended);
            }

            if (messagesHolder && pageNumber == 0 || newMessage) {
                messagesHolder.scrollTop(messagesHolder.prop("scrollHeight"));
            } else {
                let targetElement = "#pageNum" + pageNumber;
                if ($(targetElement) && $(targetElement).offset()) {
                    let targetOffset = $(targetElement).offset().top;
                    if (pageNumber && targetOffset) {
                        messagesHolder.scrollTop(targetOffset);
                    }
                }
            }
        }

        setTimeout( () => {
            if (newMessage) {
                this.mentoringEvents.openReasonModal(true);
            }
        }, 50);
        this.checkAndDisplayModerationFlagOnHover();
        this.addVideoPopup();
        this.addImagePopup();
        return true;
    }

    removeDefaultChatBotTemplate() {
        const mentoringChatBotTemplate: any = $($($('#messages')[0]).children()[0]);
        if (mentoringChatBotTemplate && mentoringChatBotTemplate.length) {
            if (!this.isLastPage()) {
                // Checks and remove the chat bot template if there is any message in the channel
                if (mentoringChatBotTemplate.hasClass('mentoring-chat-bot-wrap')) {
                    mentoringChatBotTemplate.css('display', 'none');
                }
            } else {
                if (mentoringChatBotTemplate.hasClass('mentoring-chat-bot-wrap')) {
                    mentoringChatBotTemplate.css('display', 'block');
                }
            }
        }
    }

    join(channelToken) {
        let topic = "channels:" + channelToken;
        if (!this.joinedChannels[topic]) {
            this.leaveChannels();
        }
        channelType = $("#channels").data('active-channel-type');
        userRole = $("#channels").data('active-channel-role');
        badges = $("#channels").data('badges');
        if(userRole && channelType) {
            myActiveChannel = '';
            pageNumber = 0;
            scrolled = false;
            let channel: any = this.socket.channel(topic, {type: channelType, user_role: userRole});
            myActiveChannel = channel;
            let chatInput: any = $("#chat__input");
            let chatSendBtn = $("#chat__send-message");
            let chatProjectId = $("#chat__project-id");
            let chatUserId = $("#chat__user-id");
            let attachment = $("#attachment");
            let messagesHolder = $("#messages-holder");
            let messagesContainer = $("#messages-container");
            let lastToSpeak = false;
            if (channel) {
                if (channel.state == 'joined') {
                    channel.push("get_messages", {page_number: pageNumber, page_size: limit});
                } else {
                    channel.join().receive("ok", (function (__this) {
                        return function (resp) {
                            return __this.successfulJoin(channel, channelToken, resp);
                        };
                    })(this)).receive("ignore", (function (__this) {
                        return function (resp) {
                            return __this.failedJoin("ignore", channel, resp);
                        };
                    })(this)).receive("error", (function (__this) {
                        return function (resp) {
                            return __this.failedJoin("errored", channel, resp);
                        };
                    })(this)).receive("timeout", (function (__this) {
                        return function (resp) {
                            return __this.failedJoin("timeout", channel, resp);
                        };
                    })(this));
                }

                if (chatInput && chatInput.length) {
                    // Paste the copied item as plain text in chat input box
                    chatInput.on('paste', (event) => {
                        const clipText: any = this.extractClipText(event);
                        if (clipText) {
                            // cancel paste
                            event.preventDefault();
                            // insert text manually
                            this.debounce(() => {
                                document.execCommand('insertText', false, clipText);
                            }, 50)();
                            // enable the send button
                            this.enableChatSendButton(chatSendBtn);
                        }
                    });

                    chatInput.on('keyup', (event) => {
                        if (!chatInput[0].innerText.trim().length) {
                            chatInput[0].innerText = '';
                        }
                        if ((chatInput[0].innerText && chatInput[0].innerText.length &&
                            channel.canPush()) || this.isAttachmentOnly()) {
                            this.enableChatSendButton(chatSendBtn);
                        } else {
                            this.disableChatSendButton(chatSendBtn);
                        }
                    });
                }

                channel.on('update', (function (__this) {
                    return function (payload) {
                        let msg, userData;
                        let isSender = false, isReported = false;
                        let chatUserIdd = chatUserId.val()
                        let responseBody
                        const regexToCheckWordVideo = /scheduled_at|video_timestamp/i;
                        if (regexToCheckWordVideo.test(payload.body)) {
                            responseBody = JSON.parse(payload.body) // Parse the body JSON
                        }
                        if (payload.user_id == chatUserIdd) {
                            isSender = true;
                        }
                        if (messagesHolder && messagesHolder.length) {
                            msg = messagesHolder.find("[data-msg-id='" + payload.id + "']");
                        }
                        if (__this.utility.getItem('moderationType') === 'default' && payload.user_id != chatUserId.val() && payload
                        && ((payload.state === 'flagged' && payload.reported_user_id != chatUserId.val())
                            || (payload.state === 'declined' && payload.reported_user_id != chatUserId.val()))) {
                        return;
                        }  else if (payload.user_id != chatUserId.val() && msg && msg.length
                            && ((payload.state == 'flagged' && payload.reported_user_id != chatUserId.val())
                                || (payload.state == 'declined' && payload.reported_user_id != chatUserId.val() && isSender))) {
                            window.messageGenerator.updateHeader(msg, payload);
                            userData = channelUsers[payload.user_id];
                            msg.remove();
                            messagesHolder.append(window.messageGenerator.generate(payload, userData, chatUserId.val(),
                            lastToSpeak, channelType));
                        }  else if( (payload.state === 'declined' && payload.reported_user_id != chatUserId.val() && !isSender)||(payload.state === 'declined' && payload.reported_user_id == chatUserIdd && !isSender)){
                            window.messageGenerator.updateHeader(msg, payload);
                            userData = channelUsers[payload.user_id];
                            msg.remove();
                            messagesHolder.append(window.messageGenerator.declinedMsgReciver(payload, userData, chatUserId.val(),
                            lastToSpeak, channelType));
                        }

                        else if (payload.user_id != chatUserId.val() && (payload.state === 'flagged'
                            || (payload.state === 'declined' && payload.reported_user_id != chatUserId.val()))) {
                            return;
                        } else if (msg && msg.length && payload.state === 'approved') {
                            userData = channelUsers[payload.user_id];
                            msg.remove();
                            messagesHolder.append(window.messageGenerator.generate(payload, userData, chatUserId.val(),
                                lastToSpeak, channelType));

                        }else if (msg && msg.length && responseBody && responseBody.room_status) {
                            userData = channelUsers[payload.user_id];
                            msg.remove();
                            messagesHolder.append(window.messageGenerator.generate(payload, userData, chatUserId.val(),
                                lastToSpeak, channelType));
                        } 
                         else if (msg && msg.length) {
                            return window.messageGenerator.updateMessage(msg, payload, chatUserId.val());
                        } else if (payload.user_id == chatUserId.val()) {
                            return;
                        } else {
                            if (!channelUsers[payload.user_id]) {
                                return;
                            }
                            userData = channelUsers[payload.user_id];
                            __this.setChannelToNonEmpty(messagesContainer);

                            if (payload) {
                                payload.new_message = true;
                            }

                            if (messagesHolder && messagesHolder.length) {
                                messagesHolder.append(window.messageGenerator.generate(payload, userData, chatUserId.val(),
                                    lastToSpeak, channelType));
                            }
                            __this.addVideoPopup();
                            __this.addImagePopup();
                            return;
                        }
                    };
                })(this));

                channel.on("shout", (function (__this) {
                    return function (payload) {
                        console.log("[ChannelClient] shout");
                        __this.utility.setItem('earnedPopUp', false)
                        payload.badges = badges;
                        if (__this.utility.getItem('moderationType') === 'default' && payload.user_id != chatUserId.val() && payload
                            && ((payload.state === 'flagged' && payload.reported_user_id != chatUserId.val())
                                || (payload.state === 'declined' && payload.reported_user_id != chatUserId.val()))) {
                            return;
                        } else if (payload && payload.new_message == false) {
                            (payload.last_page) ? lastPage = true : lastPage = false;
                            __this.removeDefaultChatBotTemplate();
                        }
                        if (payload && payload.id && messagesHolder &&
                            messagesHolder.length &&
                            messagesHolder.find("[data-msg-id='" + payload.id + "']").length) {
                            return;
                        } else {
                            if (!channelUsers[payload.user_id]) {
                                let token = 'Token token=\"' + sessionToken + '\"';
                                $.ajax({
                                    url: `${AppConstant.BASE_URL}channel_users?filter[user_id]=${payload.user_id}&filter[channel_id]=${channelToken}`,
                                    headers: {
                                        'AUTHORIZATION': token,
                                        'Content-Type': 'application/vnd.api+json'
                                    },
                                    success: (data) => {
                                        if (data && data.data && data.data.length) {
                                            let lastUserData = data.data[data.data.length - 1].attributes;
                                            let userData = {
                                                display_name: lastUserData.display_name,
                                                avatar: __this.utility.getProfileImg(lastUserData.avatar_id)
                                            };
                                            __this.appendMessage(messagesContainer, messagesHolder,
                                                window.messageGenerator.generate(payload, userData,
                                                    chatUserId.val(), lastToSpeak, channelType), payload.new_message);
                                            if (channelType === 'mentoring' && payload.automated) {
                                                const match = payload.body.match(/Congratulations you have earned the badge (.+)/);
                                                // Parse the body JSON
                                                const regexToCheckWordVideo = /scheduled_at|video_timestamp/i;
                                                if (regexToCheckWordVideo.test(payload.body)) {
                                                    const responseBody = JSON.parse(payload.body.replace(/\<i>/gi, '_').replace(/\<\/i>/gi, '_'));
                                                    if (responseBody.type === 'video_call') {
                                                        __this.videoCallJoin(responseBody);
                                                    }
                                                    if (responseBody.type === 'video_timestamp') {
                                                            __this.onDemandCallJoin(responseBody);
                                                        }
                                                }
                                                if (match && match.length && match.length > 1) {
                                                    if (userRole === 'mentee') {
                                                        __this.addBadgePopup();
                                                        if (payload.new_message === true) {
                                                            __this.mentoringEvents.fetchEarnedBadges(true);
                                                        }
                                                    }
                                                }
                                            }
                                            return lastToSpeak = payload['user_id'];
                                        } else {
                                            return
                                        }
                                    }
                                });
                            } else {
                                let user_data = channelUsers[payload.user_id];
                                let isSender = false, isReported = false;
                                let chatUserIdd = chatUserId.val()
                                if (payload.user_id == chatUserIdd) {
                                    isSender = true;
                                }

                                if ((payload.state === 'declined' && payload.reported_user_id != chatUserId.val() && !isSender)||(payload.state === 'declined' && payload.reported_user_id == chatUserIdd && !isSender)) {
                                    __this.appendMessage(messagesContainer, messagesHolder,
                                        window.messageGenerator.declinedMsgReciver(payload, user_data, chatUserId.val(), lastToSpeak, channelType),
                                        payload.new_message);
                                } else {
                                    __this.appendMessage(messagesContainer, messagesHolder,
                                        window.messageGenerator.generate(payload, user_data, chatUserId.val(), lastToSpeak, channelType),
                                        payload.new_message);
                                }
                                if (channelType === 'mentoring' && payload.automated) {
                                    const match = payload.body.match(/Congratulations you have earned the badge (.+)/);
                                    const regexToCheckWordVideo = /scheduled_at|video_timestamp/i;
                                     if (regexToCheckWordVideo.test(payload.body)) {
                                        const responseBody = JSON.parse(payload.body.replace(/\<i>/gi, '_').replace(/\<\/i>/gi, '_'));
                                        if (responseBody.type === 'video_call') {
                                            __this.videoCallJoin(responseBody);
                                        }
                                        if (responseBody.type === 'video_timestamp') {
                                            __this.onDemandCallJoin(responseBody);
                                        }
                                    }
                                    if (match && match.length && match.length > 1) {
                                        if (userRole === 'mentee') {
                                            __this.addBadgePopup();
                                            if (payload.new_message === true) {
                                                __this.mentoringEvents.fetchEarnedBadges(payload.body);
                                            }
                                        }
                                    }
                                }
                                return lastToSpeak = payload['user_id'];
                            }
                        }
                    };
                })(this));

                channel.on('auto_prompt_notification', (function(_this) {
                    return function(payload) {
                        _this.utility.setItem('displayPopup',payload.display_popup);
                        if ( _this.utility.checkOnlyForMobile() && !_this.utility.getItem('autoPromptPopUpShown') && _this.utility.getItem('displayPopup') == 'true'){
                            setTimeout(()=> {
                                if ( _this.utility.getItem('earnedPopUp') == 'false') {
                                    _this.displayAutoPromptPopup();
                                    _this.utility.setItem('displayPopup',false);
                                }
                            }, 10000);               
                        }
                    };
                })(this));
            }
        }
    }
    displayAutoPromptPopup(){
        const autoPromptModalElement: any = $('#autoPromptModal');
        autoPromptModalElement.modal('show');
        $('.modal-open').css('padding-right', '0px');
        const currentDate = moment().format();
        const autoPromptData = {
            'autoPrompt': true,
            'promptDisplayedAt': currentDate,
        }
        this.mentoringEvents.sendDisplayAutoPromptStatus(autoPromptData);
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
        }
    }

    isMobileOrTablet() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
            .test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Check if only enter key is pressed or not
     * Does not register enter key press incase of mobile
     * @param event
     */
    enterKeyTriggered(event): boolean {
        const keycode: any = (event.keyCode ? event.keyCode : event.which);
        return keycode == '13' && !event.shiftKey && !this.isMobileOrTablet();
    }

    /**
     * Extracts the copied the text as plain text and returns it
     * @param event
     */
    extractClipText(event) {
        if (window && window.clipboardData && window.clipboardData.getData) { // IE
            return window.clipboardData.getData('Text');
        } else if (event && event.originalEvent &&
            event.originalEvent.clipboardData &&
            event.originalEvent.clipboardData.getData) { // other browsers
            return event.originalEvent['clipboardData'].getData('text/plain');
        } else {
            return '';
        }
    }

    enableChatSendButton(chatSendBtn) {
        if (chatSendBtn && chatSendBtn.length) {
            chatSendBtn.prop('disabled', false);
            chatSendBtn.removeClass('btn--disabled');
        }
    }

    disableChatSendButton(chatSendBtn) {
        if (chatSendBtn && chatSendBtn.length) {
            chatSendBtn.prop('disabled', true)
                .removeClass('btn--disabled')
                .addClass('btn--disabled');
        }
    }

    /**
     * Format the date to display on the timeline
     * @param date
     */
    formatTimeLineDate(date): string {
        if (date && window && window.hasOwnProperty('messageGenerator')) {
            return window.messageGenerator.formatTimeLineDate(date);
        }
        return '';
    }

    reportMessageWithReason(targetElement, reasonText) {
        this.translate.get('channnels').subscribe((value:any)=> {
            this.toasterMessageChannels=value;
            });
        targetElement = targetElement.parentElement;
        const msgId: any = $(targetElement).data('msg-id');
        const userId: any = $(targetElement).data('user-id');
        const projectId: any = $('#chat__project-id').val();
        const msg: any = $('#messages-holder').find("[data-msg-id='" + msgId + "']");

        if (msgId && userId && projectId && reasonText.trim()) {
            myActiveChannel.push('add_to_moderation', {
                project_id: projectId,
                message_id: msgId,
                user_id: userId,
                reason: reasonText.trim(),
            });
            window.messageGenerator.updateUserModeratedMessage(msg);
            // First find and remove the reported message
            $(targetElement.parentElement).find('.msg__message-content').remove();
            $(targetElement.parentElement).find('.file-preview-wrap').remove();
            // Then remove the flag
            $(targetElement).remove();
            this._toaster.success(this.toasterMessageChannels.messageReportedSuccessfully);
        }
        return false;
    }

    checkAndDisplayModerationFlagOnHover() {
        if ($('.receiver.msg--pending') && $('.receiver.msg--pending').length) {
            $('.receiver.msg--pending').hover((inEvent) => {
                if (inEvent) {
                    this.displayOrHideModerationFlag(inEvent, true);
                }
            }, (outEvent) => {
                if (outEvent) {
                    this.displayOrHideModerationFlag(outEvent, false);
                }
            });

            $('.receiver.msg--pending').click((event) => {
                if (event) {
                    this.displayOrHideModerationFlag(event, true);
                }
            });
        }
    }

    addVideoPopup() {
        $('.video-overlay-play-button').each((index, element)=> {
            element.addEventListener("click", (event) => {
                this.videoEventListener(event);
            });
            element.addEventListener("keydown", (event) => {
                if (event['keyCode'] === 13) {
                    this.videoEventListener(event);
                }
            });
        });
    }

    videoEventListener(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
        loadash$.debounce(() => {
            let clickedElement: any = $(event.target).parent()[0];
            if ($(clickedElement).hasClass('video-overlay-play-button')) {
                clickedElement = $(clickedElement).parent()[0];
            }
            if ($(clickedElement).hasClass('youtube-thumbnail-parent-wrap')) {
                let youtubeVideoElement: any = $(clickedElement).children()[0];
                const videoType = 'youtube';
                let videoSrc = $(youtubeVideoElement).attr('data-video-src');
                if (videoSrc && videoType) {
                    this.mentoringEvents.setImageVideoDetailsForPopUp({url: videoSrc, type: videoType});
                }
            } else if($(clickedElement).hasClass('ios-video-container')) {
                let videoSrc = $(clickedElement).attr('data-video-src');
                if (videoSrc) {
                    this.mentoringEvents.setImageVideoDetailsForPopUp({url: videoSrc, type: 'video/mp4'});
                }
            } else {
                let videoElement: any = $($($(clickedElement).children()[0]).children()[0]);
                let videoSrc = videoElement.attr('src');
                let videoType = videoElement.attr('type');
                if (videoSrc && videoType) {
                    this.mentoringEvents.setImageVideoDetailsForPopUp({url: videoSrc, type: videoType});
                }
            }
        }, 500)();
    }

    addImagePopup() {
        $('.channel-img-wrap').each((index, element)=> {
            element.addEventListener("click", (event) => {
                this.imageEventListener(event);
            });
            element.addEventListener("keydown", (event) => {
                if (event['keyCode'] === 13) {
                    this.imageEventListener(event);
                }
            });
        });
    }

    imageEventListener(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
        loadash$.debounce(() => {
            let clickedElement: any = $(event.target).parent()[0];
            if ($(clickedElement).hasClass('channel-img-wrap')) {
                let imageElement = $($(clickedElement).children()[0]);
                let imageSrc = imageElement.attr('src');
                if (imageSrc) {
                    this.mentoringEvents.setImageVideoDetailsForPopUp({url: imageSrc, type: 'image'});
                }
            }
        }, 500)();
    }

    addBadgePopup() {
        if ($('.channel-badge-container')) {
            $('.channel-badge-container').each((index, element)=> {
                element.addEventListener("click", (event) => {
                    this.badgePopUpListener(event, element);
                });
                element.addEventListener("keydown", (event) => {
                    if (event['keyCode'] === 13) {
                        this.badgePopUpListener(event, element);
                    }
                });
            });
        }
    }
    onDemandCallJoin(responseBody){
        if ($('.room-created')) {
            $('.room-created').each((index, element) => {
                element.addEventListener("click", (event) => {
                    this.onDemandCallListener(event, element, responseBody)
                });
                element.addEventListener("keydown", (event) => {
                    if (event['keyCode'] === 13) {
                        this.onDemandCallListener(event, element, responseBody)
                    }
                });
            });
        }
    }
    videoCallJoin(responseBody) {
        if ($('.buttonVideobtnSender')) {
            $('.buttonVideobtnSender').each((index, element)=> {
                element.addEventListener("click", (event) => {
                    this.JoinCallListener(event,element,responseBody)
                });
                element.addEventListener("keydown", (event) => {
                    if (event['keyCode'] === 13) {
                        this.JoinCallListener(event,element,responseBody)
                    }
                });
            });
        }
    }
    onDemandCallListener(event, element,responseBody){
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
        this.mentoringEvents.setStartVideoCall(true);
        
    }
    JoinCallListener(event, element,responseBody){
        this.getTranslate();
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
        const localTime = moment.utc(responseBody.scheduled_at.started_at);
        const formattedDesiredTime = localTime.local().format("YYYY-MM-DD HH:mm:ss");
        const formattedEndTime = moment(formattedDesiredTime).add(responseBody.scheduled_at.duration, 'seconds');
        this.mentoringEvents.getOpenVideoContainer().subscribe(item => {
            if (item) {
                this.isVideoLive = true;
            } else {
                this.isVideoLive = false;
            }
        });
        if(this.isCurrentTimeEqualDesiredTime(formattedDesiredTime,formattedEndTime) && !this.isVideoLive){
            this.mentoringEvents.setStartVideoCall(true);
        }else if(this.isTimePassed(formattedDesiredTime,formattedEndTime) && !this.isVideoLive){
            this._toaster.error(this.toasterMessageChannels.theScheduledTimeIsAlreadyPassed)
        }
        else if(this.isVideoLive){
            this._toaster.info(this.toasterMessageChannels.yarAlreadyInAliveVideo)
        }
        else{
            this._toaster.info(`${this.toasterMessageChannels.pleaseWaitForTheScheduledTime}- ${localTime.format('MMM Do [at] LT')}`)
        }
        
    }
    isTimePassed(formattedDesiredTime,formattedEndTime): boolean {
        const currentTime = moment();
        return currentTime.isAfter(formattedDesiredTime)
    }
    isCurrentTimeEqualDesiredTime(formattedDesiredTime,formattedEndTime): boolean {
        const currentTime = moment();
        return currentTime.isBetween(formattedDesiredTime, formattedEndTime, 'minute', '[]');
    }
    badgePopUpListener(event, element) {
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
        const badgeId = $(element).data('badgeId');
        const createdDate = $(element).data('createdDate');
        loadash$.debounce(() => {
            this.mentoringEvents.setBadgeDetailsForPopUp({ badgeId: badgeId, createdDate: createdDate });
        }, 500)();
    }

    displayOrHideModerationFlag(event, display: boolean = true) {
        if (event && event.currentTarget && event.currentTarget.childNodes) {
            if ($(event.currentTarget).hasClass('receiver')) {
                const rightSidePanel: any = event.currentTarget.childNodes[1];
                $(rightSidePanel.children).each ((key, childElements) => {
                    if ($(childElements).hasClass('moderate-msg')) {
                        if (display) {
                            $(childElements).css('display', 'block');
                        } else {
                            $(childElements).css('display', 'none');
                        }
                        return false;
                    }
                });
            }
        }
    }

    pushNewMessage() {
        if (myActiveChannel.canPush()) {
            let chatInput: any = $("#chat__input")[0];
            let chatProjectId = $("#chat__project-id");
            let attachment = $("#attachment");
            let chatSendBtn = $("#chat__send-message");
            scrolled = false;
            // Notifies subscribers if any new messages sent
            this.mentoringEvents.setIsNewMessageSent(true);
            // Logic to check the attachment only in the message
            let messageBody: any = chatInput.innerText.trim();
            let messageAttachment: any = attachment.val();
            let isAttachmentOnly: boolean  = false;
            if (messageAttachment && !messageBody) {
             messageBody = " ";
             isAttachmentOnly = true;
            }

            if ((messageBody && messageBody.length) || isAttachmentOnly) {
                myActiveChannel.push("shout", {
                    body: messageBody,
                    project_id: chatProjectId.val(),
                    attachment: attachment.val(),
                });
                chatInput.innerText = '';
                attachment.val("");
                chatSendBtn.prop("disabled", true);
                chatSendBtn.addClass('btn--disabled');
                this.attachments.resetAttachments();
            }
        } else {
            const reason: any = myActiveChannel.socket.isConnected() ? myActiveChannel.state : "timeout";
            this.mentoringEvents.setIsNewMessageSent(false);
            this.failedPush(reason, myActiveChannel);
        }
    }

    isAttachmentOnly(): boolean {
        let isAttachmentOnly: boolean  = false;
        let chatInput: any = $("#chat__input")[0];
        let attachment = $("#attachment");
        let messageAttachment: any = attachment.val();
        let messageBody: any = chatInput.innerText.trim();
        if (messageAttachment && !messageBody) {
            isAttachmentOnly = true;
        }
        return isAttachmentOnly;
    }

    debounce(func, delay) {
        let inDebounce: any;
        return function () {
            const context: any = this;
            const args: any = arguments;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        };
    }
}
