import {Directive, Input, Output, ElementRef, EventEmitter} from '@angular/core';

import 'jquery-slimscroll';

@Directive({
    selector: '[baSlimScroll]',
    standalone: true
})
export class BaSlimScroll {

  @Input() public baSlimScrollOptions:Object;

  constructor(private _elementRef:ElementRef) {
  }

  ngOnChanges(changes) {
    this._scroll();
  }

  private _scroll() {
    this._destroy();
    this._init();
  }

  private _init() {
    let elementRef: any = jQuery(this._elementRef.nativeElement);
    elementRef.slimScroll(this.baSlimScrollOptions);
  }

  private _destroy() {
    let elementRef: any = jQuery(this._elementRef.nativeElement);
    elementRef.slimScroll({ destroy: true });
  }
}
