import {Injectable} from "@angular/core";
import * as _ from "lodash";
import {AppConstant} from "../app.constant";

@Injectable({
    providedIn:'root'
})
export class MessagingHelper {
    constructor() {
    }

    sortChannelsData(channelPayload: any[]) {
        if (channelPayload && channelPayload.length) {
            channelPayload = channelPayload.filter( (channelData) => {
                if (channelData.channelType == 'group') {
                    if (channelData.channelName) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            });
            const order: any[] = AppConstant.CHANNEL_TYPES;
            return _.sortBy(channelPayload, (items) => {
                return _.indexOf(order, items.channelType);
            });
        } else {
            return [];
        }
    }

    sortChannelsList(channelPayload: any[]) {
        if (channelPayload && channelPayload.length) {
            channelPayload = channelPayload.filter( (channelData) => {
                if (channelData.attributes.channel_type === 'group') {
                    return (channelData.attributes.group_name) ? true : false;
                }
                return true;
            });

            const channelTypesorders: any[] = AppConstant.CHANNEL_TYPES;
            return _.sortBy(channelPayload, (items) => {
                return _.indexOf(channelTypesorders, items.attributes.channel_type);
            });
        } else {
            return [];
        }
    }
}
