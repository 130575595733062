import { Injectable } from '@angular/core';
import * as Phoenix from './../../assets/javascript/phoenix.js';
import {MessageComponent} from "./components";
import {AppConstant} from "../app.constant";
// import "../../assets/javascript/messagescroll.js"
import {NotificationClient} from "./notificationClient";
import {Attachments} from "./attachment";
import {UtilityHelper} from "../helpers/utilityHelper";

declare var window: any;
let channelToken: any;
let channelUsers: any;
let sessionToken: any;
let myActiveChannel: any;
let channelType: any;
let userRole: any;
let pageNumber: any = 0;
let limit: any = 10;
let scrolled: boolean= false;
let lastPage: boolean = false;
import scrollIntoView from 'scroll-into-view-if-needed'
window.__forceSmoothScrollPolyfill__ = true;
import smoothscroll from 'smoothscroll-polyfill';
import * as loadash$ from "lodash";
import {MentoringEvents} from "../helpers/events";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SocketService {

    socket: any;
    joinedChannels = {};
    messaging: any=[];

    constructor(private messageComponent: MessageComponent, private attachments: Attachments,
                private notificationClient: NotificationClient, private utility: UtilityHelper,
                private mentoringEvents: MentoringEvents,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.fetchData();
    }
    getTranslate() {
        this.translate.get('channnels').subscribe((value:any)=> {
            this.messaging=value;
           });
    } 
    fetchData() {
        if (sessionToken && channelToken) {
            if (!window.messageGenerator) {
                window.messageGenerator = this.messageComponent;
            }
        } else {
            if (this) {
                this.leaveChannels();
            }
        }
        lastPage = false;
    }

    isLastPage() {
        return lastPage;
    }

    setData(users, channelId, token) {
        channelUsers = {};
        channelUsers = users;
        channelToken = '';
        channelToken = channelId;
        sessionToken = '';
        sessionToken = token;
        if (sessionToken && channelToken) {
            if (!window.messageGenerator) {
                window.messageGenerator = this.messageComponent;
            }
        }
        this.connect();
    }

    getMessages() {
        pageNumber = pageNumber + 1;
        scrolled = true;
        myActiveChannel.push("get_messages", {page_number: pageNumber, page_size: limit});
        $("#channel").removeClass('state--channel-loading').addClass('state--channel-loaded');
        $('#msg-loading-indicator').css('display', 'none');
    }

    connect() {
        if (!this.socket && sessionToken) {
            let socketParams = {
                heartbeatIntervalMs: 20000,
                params: {
                    session_token: sessionToken
                }
            };

            this.socket = new Phoenix.Socket(AppConstant.MSG_SERVICE_URL, socketParams, {
                logger: ((kind, msg, data) => { console.log(`${kind}: ${msg}`, data) }),
                transport: WebSocket
            });
            this.socket.connect();

            this.socket.onOpen(event => {
                this.updateConnectionState('connected', event);
            });

            this.socket.onError(error => {
                this.onError(Phoenix, error);
            });

            this.socket.onClose(event => {
                this.onClose(Phoenix, event);
            });
        }
    }

    updateConnectionState(state, event) {
        if($('#channel')[0]) {
            $('#channel')[0].className = $('#channel')[0].className.replace(/(^|\s)state--websocket-\S+/g, "");
            if (!$('#channel').hasClass("state--websocket-" + state)) {
                return $('#channel').addClass("state--websocket-" + state);
            }
        }
    }

    onError(data, error) {
        if (navigator.onLine) {
            console.error("Error connecting using " + (this.socket.transport === window.WebSocket ? 'WebSocket' : 'Phoenix.LongPoll'));
            if (this.socket && this.socket.transport === window.WebSocket) {
                this.socket.transport = Phoenix.LongPoll;
            } else if (window.WebSocket && this.socket) {
                this.socket.transport = window.WebSocket;
            }
        }
        if (navigator.onLine) {
            return this.updateConnectionState('error', error);
        } else {
            return this.updateConnectionState('offline', error);
        }
    }

    onClose(data, event) {
        if (navigator.onLine) {
            return this.updateConnectionState('closed', event);
        } else {
            return this.updateConnectionState('connecting', event);
        }
    }

    setChannelToNonEmpty(messagesContainer) {
        return messagesContainer.removeClass("state--empty-channel").addClass('state--non-empty-channel');
    }

    successfulJoin(channel, channelToken, resp) {
        this.updateChannelState('joined');
        this.joinedChannels[channel.topic] = channel;
        this.notificationClient.resetChannel(channelToken);
        channel.push("get_messages", {page_number: pageNumber, page_size: limit});
        $("#channel").removeClass('state--channel-loading').addClass('state--channel-loaded');
    }

    failedJoin(reason, channel, error) {
        this.updateChannelState(reason);
    }

    updateChannelState(state) {
        if($('#channel')[0]){
            $('#channel')[0].className = $('#channel')[0].className.replace(/(^|\s)state--channel-\S+/g, "");
            if($('#channel').hasClass("state--channel-" + state + ""))
            {
                $('#channel').addClass("state--channel-" + state + "");
            }
        }
    }

    pushMessage(channel, chatInput, chatProjectId, chatSendBtn, attachment) {
        // Logic to check the attachment only in the message
        let messageBody: any = chatInput.val().trim();
        let messageAttachment: any = attachment.val();
        let isAttachmentOnly: boolean  = false;
        if (messageAttachment && !messageBody) {
            messageBody = " ";
            isAttachmentOnly = true;
        }
        if((messageBody && messageBody.length) || isAttachmentOnly) {
            let pushed = channel.push("shout", {
                body: messageBody,
                project_id: chatProjectId.val(),
                attachment: attachment.val()
            });
            chatInput.val("");
            attachment.val("");
            chatSendBtn.prop("disabled", true);
            chatSendBtn.addClass('btn--disabled');
            this.attachments.resetAttachments();
        }
    }

    failedPush(reason, hannel) {
        this.updateChannelState(reason);
    }

    leaveChannels() {
        let channels: any = this.joinedChannels;
        this.joinedChannels = {};
        return $.each(channels, function(topic, channel) {
            return channel.leave().receive("ok", (function(_this) {
                return function() {
                    return console.log("[ChannelClient] left ");
                };
            })(this));
        });
    }

    appendMessage(container, holder, message, newMessage) {
        this.setChannelToNonEmpty(container);
        let messagesHolder = $("#messages");
        let pagerElement = '<div id="pageNum' + pageNumber + '" style="clear:both; height: 1px"></div>';
        if (message) {
            if (!scrolled || newMessage) {
                scrolled = true;
                holder.append(message);
            } else {
                let targetElement = "#pageNum" + pageNumber;
                if (!$(targetElement).length && pageNumber !== 0 && !newMessage) {
                    message = message + pagerElement;
                }
                holder.prepend(message);
                messagesHolder.scrollTop(150);
            }

            if (messagesHolder && pageNumber == 0 || newMessage) {
                messagesHolder.scrollTop(messagesHolder.prop("scrollHeight"));
            } else {
                let targetElement = "#pageNum" + pageNumber;
                if ($(targetElement) && $(targetElement).offset()) {
                    let targetOffset = $(targetElement).offset().top;
                    if (pageNumber && targetOffset) {
                        messagesHolder.scrollTop(targetOffset);
                    }
                }
            }
        }
        this.addVideoPopup();
        this.addImagePopup();
        return true;
    }

    addVideoPopup() {
        $('.video-overlay-play-button').each((index, element)=> {
            element.addEventListener("click", (event) => {
                event.stopImmediatePropagation();
                event.preventDefault();
                event.stopPropagation();
                loadash$.debounce(() => {
                    let clickedElement: any = $(event.target).parent()[0];
                    if ($(clickedElement).hasClass('video-overlay-play-button')) {
                        clickedElement = $(clickedElement).parent()[0];
                    }
                    if ($(clickedElement).hasClass('youtube-thumbnail-parent-wrap')) {
                        let youtubeVideoElement: any = $(clickedElement).children()[0];
                        const videoType = 'youtube';
                        let videoSrc = $(youtubeVideoElement).attr('data-video-src');
                        if (videoSrc && videoType) {
                            this.mentoringEvents.setImageVideoDetailsForPopUp({url: videoSrc, type: videoType});
                        }
                    } else if ($(clickedElement).hasClass('ios-video-container')) {
                        let videoSrc = $(clickedElement).attr('data-video-src');
                        if (videoSrc) {
                            this.mentoringEvents.setImageVideoDetailsForPopUp({url: videoSrc, type: 'video/mp4'});
                        }
                    } else {
                        let videoElement: any = $($($(clickedElement).children()[0]).children()[0]);
                        let videoSrc = videoElement.attr('src');
                        let videoType = videoElement.attr('type');
                        if (videoSrc && videoType) {
                            this.mentoringEvents.setImageVideoDetailsForPopUp({url: videoSrc, type: videoType});
                        }
                    }
                }, 500)();
            });
        });
    }

    addImagePopup() {
        $('.channel-img-wrap').each((index, element)=> {
            element.addEventListener("click", (event) => {
                event.stopImmediatePropagation();
                event.preventDefault();
                event.stopPropagation();
                loadash$.debounce(() => {
                    let clickedElement: any = $(event.target).parent()[0];
                    if ($(clickedElement).hasClass('channel-img-wrap')) {
                        let imageElement = $($(clickedElement).children()[0]);
                        let imageSrc = imageElement.attr('src');
                        if (imageSrc) {
                            this.mentoringEvents.setImageVideoDetailsForPopUp({url: imageSrc, type: 'image'});
                        }
                    }
                }, 500)();
            });
        });
    }

    join(channelToken) {
        this.getTranslate()
        let topic = "channels:" + channelToken;
        if(!this.joinedChannels[topic]) {
            this.leaveChannels();
        }
        channelType = $("#channels").data('active-channel-type');
        userRole = $("#channels").data('active-channel-role');
        if(userRole && channelType && this.socket) {
            myActiveChannel = '';
            pageNumber = 0;
            scrolled = false;
            let channel: any = this.socket.channel(topic, {type: channelType, user_role: userRole});
            myActiveChannel = channel;
            let chatInput: any = $("#chat__input");
            let chatSendBtn = $("#chat__send-message");
            let chatProjectId = $("#chat__project-id");
            let chatUserId = $("#chat__user-id");
            let attachment = $("#attachment");
            let messagesHolder = $("#messages");
            let messagesContainer = $("#messages-container");
            let lastToSpeak = false;
            if (channel.state == 'joined') {
                console.log("joined " + channel.state);
                channel.push("get_messages", {page_number: pageNumber, page_size: limit});
            }
            else {
                channel.join().receive("ok", (function (_this) {
                    return function (resp) {
                        return _this.successfulJoin(channel, channelToken, resp);
                    };
                })(this)).receive("ignore", (function (_this) {
                    return function (resp) {
                        return _this.failedJoin("ignore", channel, resp);
                    };
                })(this)).receive("error", (function (_this) {
                    return function (resp) {
                        return _this.failedJoin("errored", channel, resp);
                    };
                })(this)).receive("timeout", (function (_this) {
                    return function (resp) {
                        return _this.failedJoin("timeout", channel, resp);
                    };
                })(this));
            }

            chatSendBtn.on("click", (function (_this) {
                return function (event) {
                    var reason;
                    if (channel.canPush()) {
                        scrolled = false;
                        return _this.pushMessage(channel, chatInput, chatProjectId, chatSendBtn, attachment);
                    } else {
                        reason = channel.socket.isConnected() ? channel.state : "timeout";
                        return _this.failedPush(reason, channel);
                    }
                };
            })(this));

            chatInput.on("keyup", function (event) {
                if (chatInput.val().length > 0) {
                    chatSendBtn.prop("disabled", false);
                    chatSendBtn.removeClass('btn--disabled');
                }

                if (chatInput.val().length == 0 || !channel.canPush()) {
                    chatSendBtn.prop("disabled", true).removeClass('btn--disabled').addClass('btn--disabled');
                }
            });

            $('body').on('click', '.moderate-msg', (function (event) {
                let msgId = $(event.target).data('msg-id');
                let userId = $(event.target).data('user-id');
                let msg = $('#messages').find("[data-msg-id='" + msgId + "']");
                let messagesgDiv = $("#messages");
                if (msgId && userId) {
                    if ($('#flagged-reason').length) {
                        $('#flagged-reason').siblings('div').first()
                            .append(window.messageGenerator.setResetFlag($('#flagged-reason').data('msg-id'),
                                $('#flagged-reason').data('user-id')));
                        $('#flagged-reason').remove();
                    }
                    window.messageGenerator.openTextBox(msg, msgId, userId);
                    $(event.target).remove();
                    $('#reason__input').attr('placeholder',
                        `${this.messaging?.pleaseStateTheReasonYouWishToFlagThisMessageForModeration || ''} ` + 
                        `${this.messaging?.onceYouPressSubmitThisMessageWillBeSentToTheCoordinatorForModeration || ''} ` + 
                        `${this.messaging?.andBeRemovedFromYourChannel || ''}`);
                    if (msg.length) {
                        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
                                .test(navigator.userAgent)) {
                            $('#reason__input').attr('placeholder', 'Please state the reason...');
                            if (/Android/i.test(navigator.userAgent)) {
                                $('#channel').css('height', '800px');
                            }
                            var targerEle: any = $(msg.closest('.msg.msg--pending'));
                            var scrollableDiv = messagesgDiv;
                            var msgStatusDiv = targerEle.find('.msg__status');
                            var msgContentDiv = targerEle
                                .find('.msg__message-content.msg--pending__message-content')

                            var msgDivOffsetTop = targerEle[0].offsetTop;
                            var msgDivOffsetScrollHeight = targerEle[0].scrollHeight;
                            $('#reason__input').focus();
                            scrollableDiv.scrollTop(msgDivOffsetTop + msgDivOffsetScrollHeight - 280);
                        } else {
                            $('#reason__input').focus();
                            smoothscroll.polyfill();
                            scrollIntoView($('#flagged-reason')[0], { behavior: 'smooth', block: 'center' });
                        }
                    }
                    event.stopImmediatePropagation();
                }
            }));

            $('body').on('keyup', '#reason__input', function (event) {
                let reasonInput: any = $('#reason__input').val();
                if (reasonInput.trim().length) {
                    $('.submit-reason').prop('disabled', false);
                    $('.submit-reason').removeClass('btn--disabled');
                }

                if (!reasonInput.length || !channel.canPush()) {
                    $('.submit-reason').prop('disabled', true)
                        .removeClass('btn--disabled').addClass('btn--disabled');
                }
            });

            $('body').on('click', '.submit-reason', (function (event) {
                let msgId = $(event.target.parentElement).data('msg-id');
                let userId = $(event.target.parentElement).data('user-id');
                let msg = $('#messages').find("[data-msg-id='" + msgId + "']");

                if (msgId && userId) {
                    myActiveChannel.push('add_to_moderation', {
                        project_id: chatProjectId.val(),
                        message_id: msgId,
                        user_id: userId,
                        reason: $('#reason__input').val()
                    });
                    window.messageGenerator.updateUserModeratedMessage(msg);
                    $($(event.target.parentElement).siblings('.msg__message-content')).remove();
                    $(event.target.parentElement).remove();
                }
                event.stopImmediatePropagation();
                $('#channel').css('height', '');
                $('#flagged-reason').remove();
            }));

            $('body').on('click', '.cancel-reason', (function (event) {
                let msgId = $(event.target.parentElement).data('msg-id');
                let userId = $(event.target.parentElement).data('user-id');
                $($(event.target.parentElement)).siblings('div').first()
                    .append(window.messageGenerator.setResetFlag(msgId, userId));
                $('#channel').css('height', '');
                $('#flagged-reason').remove();
            }));

            $('body').on('mouseenter', '.moderate-msg', (function (event) {
                $(event.target).css('cursor', 'pointer');
            }));

            $('body').on('mouseleave', '.moderate-msg', (function (event) {
                $(event.target).css('cursor', 'default');
            }));

            channel.on('update', (function (_this) {
                return function (payload) {
                    let msg, userData;
                    msg = messagesHolder.find("[data-msg-id='" + payload.id + "']");
                    if (payload.user_id != chatUserId.val() && msg.length > 0
                        && ((payload.state == 'flagged' && payload.reported_user_id != chatUserId.val())
                            || (payload.state == 'declined' && payload.reported_user_id != chatUserId.val()))) {
                        window.messageGenerator.updateHeader(msg, payload);
                        msg.remove();
                    } else if (payload.user_id != chatUserId.val() && (payload.state == 'flagged'
                            || (payload.state == 'declined' && payload.reported_user_id != chatUserId.val()))) {
                        return
                    } else if (msg.length > 0) {
                        return window.messageGenerator.updateMessage(msg, payload, chatUserId.val());
                    } else if (payload.user_id == chatUserId.val()) {
                        return;
                    } else {
                        if (!channelUsers[payload.user_id]) {
                            return;
                        }
                        userData = channelUsers[payload.user_id];
                        _this.setChannelToNonEmpty(messagesContainer);

                        if (payload) {
                            payload.new_message = true;
                        }

                        messagesHolder.append(window.messageGenerator.generate(payload, userData, chatUserId.val(),
                            lastToSpeak, channelType));

                        _this.addVideoPopup();
                        _this.addImagePopup();
                        return
                    }
                };
            })(this));

            return channel.on("shout", (function (_this) {
                return function (payload) {
                    console.log("[ChannelClient] shout");
                    if (payload.user_id != chatUserId.val() && payload
                        && ((payload.state == 'flagged' && payload.reported_user_id != chatUserId.val())
                            || (payload.state == 'declined' && payload.reported_user_id != chatUserId.val()))) {
                        return;
                    } else if (payload && payload.new_message == false) {
                        (payload.last_page) ? lastPage = true : lastPage = false;
                    }

                    if (payload && payload.id && messagesHolder.find("[data-msg-id='" + payload.id + "']").length) {
                        return;
                    } else {
                        if (!channelUsers[payload.user_id]) {
                            let token = 'Token token=\"' + sessionToken + '\"';
                            $.ajax({
                                url: `${AppConstant.BASE_URL}channel_users?filter[user_id]=${payload.user_id}&filter[channel_id]=${channelToken}`,
                                headers: {
                                    'AUTHORIZATION': token,
                                    'Content-Type': 'application/vnd.api+json'
                                },
                                success: (data) => {
                                    if (data && data.data.length) {
                                        let lastUserData = data.data[data.data.length - 1].attributes;
                                        let userData = {
                                            display_name: lastUserData.display_name,
                                            avatar: _this.utility.getProfileImg(lastUserData.avatar_id)
                                        };

                                        _this.appendMessage(messagesContainer, messagesHolder,
                                            window.messageGenerator.generate(payload, userData,
                                                chatUserId.val(), lastToSpeak, channelType), payload.new_message);
                                        return lastToSpeak = payload['user_id'];
                                    } else {
                                        return
                                    }
                                }
                            });
                        } else {
                            let user_data = channelUsers[payload.user_id];
                            _this.appendMessage(messagesContainer, messagesHolder,
                                window.messageGenerator.generate(payload, user_data, chatUserId.val(), lastToSpeak, channelType),
                                payload.new_message);
                            return lastToSpeak = payload['user_id'];
                        }
                    }
                };
            })(this));
        }
    }

    disconnectSocket(){
        if(this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
        }
    }
}
