import {Injectable} from "@angular/core";
import {AbstractBaseComponent} from "../app.baseComponent";
import {CanActivate, Router} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {UtilityHelper} from "../helpers/utilityHelper";

@Injectable()
export class MenteeAccessGuard extends AbstractBaseComponent implements CanActivate {
    isChannelAccess: boolean = true;

    constructor(private authService: AuthenticationHelper, private utility: UtilityHelper, private route: Router) {
        super();
    }

    canActivate() {
        let hasAccess = this.utility.getItem('role');
        let isTourPending = this.utility.getItem('isTourPending');
        if (this.authService.isLoggedIn() && this.utility.getItem('role') === 'mentee') {
            return true;
        } else {
            this.route.navigate(['']);
            return false;
        }
    }
}
