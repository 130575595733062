import {Injectable, Output, EventEmitter} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable()
export class MentoringEvents {
    @Output() getThreads: EventEmitter<any> = new EventEmitter(true);
    @Output() changedProfileData: EventEmitter<any> = new EventEmitter(true);
    @Output() selectedMenu: EventEmitter<any> = new EventEmitter(true);
    @Output() removeSelectedMenu: EventEmitter<any> = new EventEmitter(true);
    @Output() openProjectSwitcher: EventEmitter<any> = new EventEmitter(true);
    @Output() projectSwitch: EventEmitter<any> = new EventEmitter(true);
    @Output() currentsubCategoryId: EventEmitter<any> = new EventEmitter(true);
    @Output() searchString: EventEmitter<any> = new EventEmitter(true);
    @Output() searchStringDraftExternalMaterial: EventEmitter<any> = new EventEmitter(true);
    @Output() searchArticleString: EventEmitter<any> = new EventEmitter(true);
    @Output() activeArticleTab: EventEmitter<any> = new EventEmitter(true);
    @Output() newsTab: EventEmitter<any> = new EventEmitter(true);
    @Output() refreshArticle: EventEmitter<any> = new EventEmitter(true);
    @Output() getCategoriesAndSubcategories: EventEmitter<any> = new EventEmitter(true);
    @Output() currentTutorialGroupId: EventEmitter<any> = new EventEmitter(true);
    @Output() resetVleTutorial: EventEmitter<any> = new EventEmitter(true);
    @Output() isKnowledgeMenu: EventEmitter<any> = new EventEmitter(true);
    @Output() isProjectSwitched: EventEmitter<any> = new EventEmitter(true);
    @Output() setNotifications: EventEmitter<any> = new EventEmitter(true);
    @Output() setUserNotifications: EventEmitter<any> = new EventEmitter(true);
    @Output() setSurveyNotifications: EventEmitter<any> = new EventEmitter(true);
    @Output() sendMatchingotifications: EventEmitter<any> = new EventEmitter(true);
    @Output() sendAnnouncementNotification: EventEmitter<any> = new EventEmitter(true);
    @Output() openKnowledgePanel: EventEmitter<any> = new EventEmitter(true);
    @Output() selectedKnowledgeMenu: EventEmitter <any> = new EventEmitter(true);
    @Output() searchedString: EventEmitter <any> = new EventEmitter(true);
    @Output() attachedUrl: EventEmitter <any> = new EventEmitter(true);
    @Output() sendSpecificModalDataEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() modalEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() sendSessionDetailsEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() bkpanelOpenOrClose: EventEmitter <any> = new EventEmitter(true);
    @Output() messageSentEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() syncAttachedLinksEvents: EventEmitter <any> = new EventEmitter(true);
    @Output() clearSearchArticleEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() replacementText: EventEmitter <any> = new EventEmitter(true);
    @Output() syncLikeAndUnlikeEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() openReasonModalEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() isClicked: EventEmitter <any> = new EventEmitter(true);
    @Output() isSlideShown: EventEmitter <any> = new EventEmitter(true);
    @Output() pushNewMessageEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() analyticsData: EventEmitter <any> = new EventEmitter(true);
    @Output() closeModal: EventEmitter <any> = new EventEmitter(true);
    @Output() openTypeformEvent: EventEmitter<any> = new EventEmitter(true);
    @Output() reasonText: EventEmitter<any> = new EventEmitter(true);
    @Output() isProjectChanged: EventEmitter<any> = new EventEmitter(true);
    @Output() switchSpecificproject: EventEmitter<any> = new EventEmitter(true);
    openTypeform: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    openTypeform$ = this.openTypeform.asObservable();
    mentorTypeformSubmittedEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    mentorTypeformSubmittedEvent$ = this.mentorTypeformSubmittedEvent.asObservable();
    @Output() imageVideoDetailsForPopUpEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() badgeDetailsForPopUpEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() imageVideoPopUpCloseEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() tabEvent: EventEmitter <any> = new EventEmitter(true);
    mentorProfileData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    mentorProfileData$ = this.mentorProfileData.asObservable();
    isMentorMatched: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isMentorMatched$ = this.isMentorMatched.asObservable();
    @Output() fetchBadges: EventEmitter <any> = new EventEmitter(true);
    @Output() certificateDownloadedEvent: EventEmitter <any> = new EventEmitter(true);
    @Output() moderationdatePickerEvent: EventEmitter <any> = new EventEmitter(true);
    announcementSentEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    announcementSentEvent$ = this.announcementSentEvent.asObservable();
    @Output() moderationRequestCount: EventEmitter <boolean> = new EventEmitter(true);
    @Output() deleteReason: EventEmitter <any> = new EventEmitter(true);
    @Output() autoPromptDisplay: EventEmitter<any> = new EventEmitter(true);
    @Output() openVideoContainer: EventEmitter<any> = new EventEmitter(true);
    @Output() openCodeOfConduct:EventEmitter<any> = new EventEmitter (true)
    @Output() videoLiveRoom: EventEmitter<any> = new EventEmitter(null);
    @Output() startVideoCall: EventEmitter<any> = new EventEmitter(null);

    @Output() MeetsDetailsDataAvailable: EventEmitter<any> = new EventEmitter(null);
    @Output() scheduledMeetingDetailsavailabe: EventEmitter<any> = new EventEmitter(null);        
    @Output() IsSchedule: EventEmitter<any> = new EventEmitter(null); 
    @Output() displayVideoIcon: EventEmitter<any> = new EventEmitter(null);    
    @Output() isPip: EventEmitter<any> = new EventEmitter(null);    
    @Output() VideoCallEnabled: EventEmitter<any> = new EventEmitter (null)
    @Output() ScheduleRefreshChannel: EventEmitter<any> = new EventEmitter (null) 
    @Output() DisconnetRoom: EventEmitter<any> = new EventEmitter (null)   
    @Output() isCocDisplayOrHide: EventEmitter<any> = new EventEmitter (null);
    private projectUsersDataSubject = new BehaviorSubject<any>(null);
    projectUsersData$ = this.projectUsersDataSubject.asObservable();

    constructor() {
    }

    setThreads(value){
        this.getThreads.emit(value);
    }

    getAllThreads(): EventEmitter<any>{
        return this.getThreads;
    }

    setChangedProfileData(value){
        this.changedProfileData.emit(value);
    }

    getChangedProfileData(): EventEmitter<any>{
        return this.changedProfileData;
    }

    setSelectedMenu(value){
        this.selectedMenu.emit(value);
    }

    getSelectedMenu(): EventEmitter<any>{
        return this.selectedMenu;
    }

    removeMenu(value){
        this.removeSelectedMenu.emit(value);
    }

    getRemovedMenu(): EventEmitter<any>{
        return this.removeSelectedMenu;
    }

    setOpenProjectSwitcher(value){
        this.openProjectSwitcher.emit(value);
    }

    getOpenProjectSwitcher(): EventEmitter<any>{
        return this.openProjectSwitcher;
    }

    setProjectSwitchedContent(value, reload?){
        value['reload'] = reload ? reload : false;
        this.projectSwitch.emit(value);
    }

    getProjectSwitchedContent(): EventEmitter<any>{
        return this.projectSwitch;
    }

    fetchNotifications (): EventEmitter<any> {
        return this.setNotifications;
    }

    setOpenRightKnowledgePanel(value) {
        this.openKnowledgePanel.emit(value);
    }

    getOpenRightKnowledgePanel(): EventEmitter<any> {
        return this.openKnowledgePanel;
    }

    setKnowledgeDropDown(value) {
        this.selectedKnowledgeMenu.emit(value);
    }

    getSelectedKnowledgeDropDown(): EventEmitter<any> {
        return this.selectedKnowledgeMenu;
    }

    sendSearchedString(value) {
        this.searchedString.emit(value);
    }

    getSearchedString(): EventEmitter<any> {
        return this.searchedString;
    }

    sendAttachedUrl(value) {
        this.attachedUrl.emit(value);
    }

    getAttachedUrl(): EventEmitter<any> {
        return this.attachedUrl;
    }

    // Event to send and get data from modal pop up to side panel

    sendDataToModal(value) {
        this.modalEvent.emit(value);
    }

    getDataToModal (): EventEmitter<any> {
        return this.modalEvent;
    }

    sendSpecificDataToSidePanel (value) {
        this.sendSpecificModalDataEvent.emit(value);
    }

    getSpecificDataFromModal (): EventEmitter<any> {
        return this.sendSpecificModalDataEvent;
    }

    sendSessionDetails(value) {
        this.sendSessionDetailsEvent.emit(value);
    }

    getSessionDetails(): EventEmitter<any> {
        return this.sendSessionDetailsEvent;
    }

    sendBrightknowledgePanelStatus(value) {
        this.bkpanelOpenOrClose.emit(value);
    }

    getBrightknowledgePanelStatus() {
        return this.bkpanelOpenOrClose;
    }

    setIsNewMessageSent(value) {
        this.messageSentEvent.emit(value);
    }

    checkIsNewMessageSent() {
        return this.messageSentEvent;
    }

    syncAttachedLinks (value) {
        this.syncAttachedLinksEvents.emit(value);
    }

    getSyncedAttachedLinks (): EventEmitter<any> {
        return this.syncAttachedLinksEvents;
    }

    clearKnowledgeSearch(value) {
        this.clearSearchArticleEvent.emit(value);
    }

    shouldClearKnowledgeSearch(): EventEmitter<any> {
        return this.clearSearchArticleEvent;
    }

    sendReplacementText(value) {
        this.replacementText.emit(value);
    }

    getReplacementText(): EventEmitter<any> {
        return this.replacementText;
    }

    syncLikeAndUnlike(value) {
        this.syncLikeAndUnlikeEvent.emit(value);
    }

    getSyncedLikeAndUnlikeData(): EventEmitter<any> {
        return this.syncLikeAndUnlikeEvent;
    }

    openReasonModal(value) {
        this.openReasonModalEvent.emit(value);
    }

    getOpenReasonModal(): EventEmitter<any> {
        return this.openReasonModalEvent;
    }

    callParent(value) {
        this.isClicked.emit(value);
    }

    getCalled(): EventEmitter<any> {
        return this.isClicked;
    }

    startTour(value) {
        this.isSlideShown.emit(value);
    }

    getstartTourStatus(): EventEmitter<any> {
        return this.isSlideShown;
    }

    setPushNewMessage(value) {
        this.pushNewMessageEvent.emit(value);
    }

    getPushNewMessage(): EventEmitter<any> {
        return this.pushNewMessageEvent;
    }

    sendAnalyticsEvents(value) {
        this.analyticsData.emit(value);
    }

    getAnalyticsEvent() {
        return this.analyticsData;
    }

    openTypeForm(value) {
        this.openTypeform.next(value);
    }

    getOpenTypeForm() {
        return this.openTypeform$;
    }

    mentorTypeformSubmitted(value) {
        this.mentorTypeformSubmittedEvent.next(value);
    }

    getMentorTypeformSubmitted() {
        return this.mentorTypeformSubmittedEvent$;
    }

    sendMentorData(value){
        this.mentorProfileData.next(value);
    }

    getMentorData(){
        return this.mentorProfileData$;
    }

    closeModalEvent(value){
        this.closeModal.emit(value);
    }

    getcloseModalEvent() {
        return this.closeModal;
    }

    setImageVideoDetailsForPopUp(value) {
        this.imageVideoDetailsForPopUpEvent.emit(value);
    }

    getImageVideoDetailsForPopUp() {
        return this.imageVideoDetailsForPopUpEvent;
    }

    setImageVideoPopUpClose(value) {
        this.imageVideoPopUpCloseEvent.emit(value);
    }

    getImageVideoPopUpClose() {
        return this.imageVideoPopUpCloseEvent;
    }

    sendReasonText(value) {
        this.reasonText.emit(value);
    }

    getReasonText() {
        return this.reasonText;
    }

    sendDisplayAutoPromptStatus(value) {
        this.autoPromptDisplay.emit(value);
    }

    getDisplayAutoPromptStatus() {
        return this.autoPromptDisplay;
    }

    projectChanged(value){
        this.isProjectChanged.emit(value);
    }

    getProjectChangedEvent() {
        return this.isProjectChanged;
    }

    sendMentorMatchedData(value) {
        this.isMentorMatched.next(value);
    }

    getMentorMatchedData() {
        return this.isMentorMatched$;
    }

    fetchUserNotifications(): EventEmitter<any> {
        return this.setUserNotifications;
    }

    setBadgeDetailsForPopUp(value) {
        this.badgeDetailsForPopUpEvent.emit(value);
    }

    getBadgeDetailsForPopUp() {
        return this.badgeDetailsForPopUpEvent;
    }

    fetchEarnedBadges(fetchBadges) {
        this.fetchBadges.emit(fetchBadges);
    }

    getLatestEarnedBadges() {
        return this.fetchBadges;
    }
    
    reviewMessageEvent(value) {
        this.tabEvent.emit(value);
    }

    getReviewMessageEvent(): EventEmitter<any> {
        return this.tabEvent;
    }

    setActionModalPopUpClose(value) {
        this.imageVideoPopUpCloseEvent.emit(value);
    }

    getActionModalPopUpClose() {
        return this.imageVideoPopUpCloseEvent;
    }

    getSwitchSpecificproject() {
        return this.switchSpecificproject
    }

    sendSwitchSpecificproject(value) {
        this.switchSpecificproject.emit(value);
    }

    fetchMatchingNotifications(): EventEmitter<any> {
        return this.sendMatchingotifications;
    }

    fetchAnnouncementNotifications(): EventEmitter<any> {
        return this.sendAnnouncementNotification;
    }

    fetchSurveyNotifications(): EventEmitter<any> {
        return this.setSurveyNotifications;
    }

    certificateDownloaded(value) {
        this.certificateDownloadedEvent.emit(value);
    }

    getCertificateDownloaded(): EventEmitter<any> {
        return this.certificateDownloadedEvent;
    }

    announcementSent(value) {
        this.announcementSentEvent.next(value);
    }

    getAnnoucementSent() {
        return this.announcementSentEvent$;
    }


    fetchModerationDatepicker(value) {
        this.moderationdatePickerEvent.emit(value);
    }

    getModerationDatepicker(): EventEmitter<any> {
        return this.moderationdatePickerEvent;
    }
    setModerationRequestCount(value) {
        this.moderationRequestCount.emit(value);
    }

    getModerationRequestCount(): EventEmitter<boolean> {
        return this.moderationRequestCount;
    }

    sendDeleteReason(value) {
        this.deleteReason.emit(value);
    }

    getDeleteReason(): EventEmitter<any> {
        return this.deleteReason;

    }
    setCodeOfConduct(value) {
        this.openCodeOfConduct.emit(value);
    }
    getCodeOfConduct(): EventEmitter<any> {
        return this.openCodeOfConduct;
    }
    setOpenVideoContainer(value) {
        this.openVideoContainer.emit(value);
    }
    getOpenVideoContainer(): EventEmitter<any> {
        return this.openVideoContainer;
    }
    setVideoLiveRoom(value){
        this.videoLiveRoom.emit(value)
    }
    getVideoLiveRomm():EventEmitter<any>{
        return this.videoLiveRoom;
    }
    setMeetsDetailsDataAvailable(value){
        this.MeetsDetailsDataAvailable.emit(value)
    }
    getMeetsDetailsDataAvailable():EventEmitter<any>{
        return this.MeetsDetailsDataAvailable;
    }
    setscheduledMeetingsData(value){
        this.scheduledMeetingDetailsavailabe.emit(value);
    }
    getscheduledMeetingsData():EventEmitter<any>{
        return this.scheduledMeetingDetailsavailabe;
    }
    setIsSchedule(value){
        this.IsSchedule.emit(value)
    }
    getIsSchedule():EventEmitter<any>{
        return this.IsSchedule
    }
    setIsdisplayVideoIcon(value){
        this.displayVideoIcon.emit(value)
    }
    getIsdisplayVideoIcon():EventEmitter<any>{
        return this.displayVideoIcon
    }
    setIsPIP(value){
        this.isPip.emit(value)
    }
    getIsPIP():EventEmitter<any>{
        return this.isPip
    }
    setStartVideoCall(value){
        this.startVideoCall.emit(value)
    }
    getStartVideoCall():EventEmitter<any>{
        return this.startVideoCall
    }
    setVideoCallEnabled(value){
        this.VideoCallEnabled.emit(value)
    }
    getVideoCallEnabled():EventEmitter<any>{
        return this.VideoCallEnabled
    }
    setScheduleRefreshChannel(value){
        this.ScheduleRefreshChannel.emit(value)
    }
    getScheduleRefreshChannel():EventEmitter<any>{
        return this.ScheduleRefreshChannel
    }
    setDisconnetRoom(value) {
        this.DisconnetRoom.emit(value);
    }
    getDisconnetRoom(): EventEmitter<any> {
        return this.DisconnetRoom;
    }
    setCocDisplayOrHide(value) {
        this.isCocDisplayOrHide.emit(value);
    }
    getCocDisplayOrHide(): EventEmitter<any>  {
        return this.isCocDisplayOrHide;
    }
    setProjectUsersData(data: any):void {
        this.projectUsersDataSubject.next(data);
    }

    getProjectUsersData(): any {
    if(this.projectUsersDataSubject != null) {
            return this.projectUsersDataSubject;
        }
    }
}
