import {Injectable} from "@angular/core";
import {AbstractBaseComponent} from "../app.baseComponent";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {UtilityHelper} from "../helpers/utilityHelper";

@Injectable()
export class AdminRedirectionGuard extends AbstractBaseComponent implements CanActivate {
    constructor(private authService: AuthenticationHelper, private utility: UtilityHelper,
                private router: Router) {
        super();
    }

    canActivate(routes: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn() && this.utility.myDomain == this.ADMIN_DOMAIN) {
            return true;
        } else {
            let url = this.MENTORING_URL + 'navigation?navigation_url=' + encodeURIComponent(decodeURIComponent(state.url));
            if (this.utility.myDomain == this.ADMIN_DOMAIN) {
                window.location.assign(url);
                return false;
            } else {
                this.router.navigate(['/']);
                return false;
            }
        }
    }
}