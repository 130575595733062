import * as moment from "moment";
import * as _ from "lodash";
import { TextHelper } from "../helpers/textHelper";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { AppConstant } from "../app.constant";
import { UtilityHelper } from "../helpers/utilityHelper";
import { DateAndTime } from "../helpers/dateAndTime";
import { TranslateService } from "@ngx-translate/core";
import { interval } from "rxjs";
import { takeWhile } from "rxjs/operators";
import 'moment-timezone';
import { MentoringEvents } from "app/helpers/events";
@Injectable()
export class MentoringMessagingComponent {
    currentState: any;
    imageServerCdn: string = environment.imgServerCDN;
    s3BucketOrigin: string = environment.s3BucketOriginUrl;
    badgesWls: any = [
        {
            "id": "1",
            "title": "Ffwrdd â ni!",
            "earnedPercentage": "45.33",
            "description": "Llwyddo i gofrestru, cael dy gysylltu â mentor ac anfon dy neges gyntaf",
            "content": {
                "achievement_introduction": "Llongyfarchiadau ar gymryd y cam cyntaf ar dy daith fentora!",
                "achievement_clause": "Mae 10 bathodyn i’w casglu – clicia ar y bathodynnau i gael mwy o wybodaeth.",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Ffwrdd â ni!’"
            }
        },
        {
            "id": "2",
            "title": "Llun llwyddiannus",
            "earnedPercentage": "21.95",
            "description": "Llwyddo i lwytho llun proffil i fyny",
            "content": {
                "achievement_introduction": "Da iawn! Mae ychwanegu llun proffil yn helpu i greu cysylltiad.",
                "achievement_clause": "Cer ati nawr i anfon negeseuon!",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Llun llwyddiannus’ "
            }
        },
        {
            "id": "3",
            "title": "Torri’r garw",
            "earnedPercentage": "17.59",
            "description": "Ysgrifennu ac anfon dy ymateb cyntaf",
            "content": {
                "achievement_introduction": "Gwych – rwyt ti wedi anfon dy ymateb cyntaf.",
                "achievement_clause": "Dal ati!",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Torri’r garw’"
            }
        },
        {
            "id": "4",
            "title": "3 chynnig i Gymro",
            "earnedPercentage": "20.92",
            "description": "Anfon 3 neges",
            "content": {
                "achievement_introduction": "Ymdrech wych – rwyt ti wedi anfon tair neges.",
                "achievement_clause": "Rwyt ti’n gwneud yn wych!",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘3 chynnig i Gymro’"
            }
        },
        {
            "id": "5",
            "title": "Pump uchel!",
            "earnedPercentage": "17.66",
            "description": "Anfon 5 neges",
            "content": {
                "achievement_introduction": "Da iawn – rwyt ti wedi anfon pum neges.",
                "achievement_clause": "Rwyt ti’n gwneud yn wych - dal ati!",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Pump uchel’"
            }
        },
        {
            "id": "6",
            "title": "Torri ac anfon",
            "earnedPercentage": "13.07",
            "description": "Rhannu 3 erthygl o Banel Erthyglau Brightside â dy fentor",
            "content": {
                "achievement_introduction": "Mae’n wych dy fod yn rhannu pethau!",
                "achievement_clause": "Da iawn am rannu erthyglau â dy fentor.",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Torri ac anfon’"
            }
        },
        {
            "id": "7",
            "title": "Deg Uchaf",
            "earnedPercentage": "12.94",
            "description": "Anfon 10 neges",
            "content": {
                "achievement_introduction": "Gwych! Rwyt ti wedi anfon 10 neges.",
                "achievement_clause": "Rwyt ti’n gwneud yn wych!",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Deg uchaf’"
            }
        },
        {
            "id": "8",
            "title": "Hoffi!",
            "earnedPercentage": "16.82",
            "description": "Hoffi 10 erthygl ddefnyddiol",
            "content": {
                "achievement_introduction": "Diolch! Bydd pawb arall sy’n cael eu mentora nawr yn gwybod pa erthyglau oedd fwyaf defnyddiol i ti.",
                "achievement_clause": "",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Hoffi!’ "
            }
        },
        {
            "id": "9",
            "title": "Dau ddeg – Da iawn",
            "earnedPercentage": "8.97",
            "description": "Anfon 20 neges",
            "content": {
                "achievement_introduction": "Da iawn – rwyt ti wedi anfon ugain o negeseuon.",
                "achievement_clause": "Waw – rwyt ti’n gwneud yn wych!",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Dau ddeg – da iawn’ "
            }
        },
        {
            "id": "10",
            "title": "Diwedd y daith!",
            "earnedPercentage": "20.45",
            "description": "Cyrraedd diwedd prosiect er mwyn ennill tystysgrif am gymryd rhan",
            "content": {
                "achievement_introduction": "Gwych – rwyt ti wedi cwblhau dy daith fentora.",
                "achievement_clause": "Rwyt ti wedi ennill tystysgrif am gymryd rhan. Da iawn ti!",
                "channel_title": "Rwyt ti wedi ennill y bathodyn ‘Diwedd y daith!’ "
            }
        }
    ]

    messaging: any = {};

    constructor(private textHelper: TextHelper, private utility: UtilityHelper,
        private dateAndTime: DateAndTime,
        private translate: TranslateService,
        private mentoringEvents: MentoringEvents) {
    }

    getTranslate() {
        this.translate.get('channnels').subscribe((value: any) => {
            this.messaging = value;
        });
    }

    linkifyText(text) {
        return this.textHelper.linkifyText(text);
    }
    breakifyText(text) {
        return this.textHelper.breakifyText(text);
    }
    files(payload, files, sentReceived) {
        if (files.length == 0) {
            return '';
        } else {
            let file = files[0];
            const contentType = file.attachment_content_type || '';
            if ((contentType && contentType.includes('image')) ||
                AppConstant.SUPPORTED_VIDEO_TYPES.includes(contentType)) {
                if (sentReceived == "received at" && payload.state == 'flagged') {
                    return "<div class=\"image-or-video-display-wrap remove-outline\">" + this.generateDownload(payload, file, sentReceived) + "</div></section>";
                } else {
                    return "<ul class=\"file-preview-wrap image-or-video-display-wrap remove-outline\">" + this.generateDownload(payload, file, sentReceived) + "</ul></section>";
                }
            } else {
                if (sentReceived == "received at" && payload.state == 'flagged') {
                    return "<div class=\"remove-outline\">" + this.generateDownload(payload, file, sentReceived) + "</div></section>";
                } else {
                    return "<ul class=\"file-preview-wrap remove-outline\">" + this.generateDownload(payload, file, sentReceived) + "</ul></section>";
                }
            }
        }
    }
    attachments(attachments) {
        if (attachments.length == 0) {
            return '';
        } else {
            let content: any = '';
            attachments.forEach(attachment => {
                if (attachment && (attachment.title || attachment.image)) {
                    content = content + this.generateAttachment(attachment);
                }
            });
            return content;
        }
    }
    updateMessage(msg, payload, chatUserId) {
        let previousState = msg.attr("data-msg-state");
        this.currentState = payload.state;
        let extractedYoutubeUrls: any = this.textHelper.extractYoutubeUrls(payload.body);
        payload.body = this.textHelper.checkAndBoldOrItalicText(payload.body);
        payload.body = this.linkifyText(this.breakifyText(payload.body));
        let isAttachmentOnly: boolean = false;
        if (!payload.body) {
            isAttachmentOnly = true;
        }
        msg.removeClass("msg--" + previousState + "").addClass("msg--" + this.currentState);
        msg.find(".msg--" + previousState + "__message-content").removeClass("msg--" + previousState + "__message-content").addClass("msg--" + this.currentState + "__message-content");
        msg.find('.msg__status').html(this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id,
            payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId));
        if (payload.reported_user_id == chatUserId && ['approved', 'pending'].includes(payload.state)) {
            let messagePartial = '';
            messagePartial = "<div class=\"msg__message-content msg--" + payload.state + `__message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">` + payload.body + "</div>"
            messagePartial = messagePartial + "" + this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId)) + this.attachments(payload.attachments) + ""
                + this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls) + "";
            msg.find('.right-side-msg-panel-wrap').append(messagePartial);
        }
        msg.find('.msg__alert').remove();
        msg.find('.msg-alert-wrap').remove();
        msg.attr("data-msg-state", this.currentState);
        if (this.currentState == 'flagged' || this.currentState == 'declined') {
            msg.find('.msg__status').removeClass('clamp-msg-status');
            msg.append("<div tabindex=\"0\" class=\"msg__alert msg-alert-wrap flagged-or-declined-msg-alert-wrap remove-outline\">" + this.alertMessage(payload, chatUserId) + "</div>");
            if (this.currentState == 'declined' && payload.reported_user_id == chatUserId) {
                msg.find('.msg__status').addClass('child-msg-status-wrap');
                msg.find('.msg_sender').remove();
                msg.find('.small').css('padding-left', '10px');
            } else {
                msg.find('.msg_sender').addClass('msg_wrap');
            }
        }
    }
    updateUserModeratedMessage(msg) {
        this.getTranslate();
        let previousState = msg.attr('data-msg-state');
        msg.removeClass(`msg--${previousState}`).addClass('msg--flagged');
        msg.find(`.msg--${previousState}__message-content`)
            .removeClass(`msg--${previousState}__message-content`)
            .addClass(`msg--flagged__message-content`);
        const msgStatusWrap: any = msg.find('.msg__status');
        $(msgStatusWrap).removeClass('clamp-msg-status');
        $(msgStatusWrap).prepend(`<span class=\"small moderation-text-color\" style="padding-left:10px;"> ${this.messaging.pendingModeration}- </span>`);
        msg.find('.msg__alert').remove();
        msg.attr('data-msg-state', 'flagged');
        msg.append('<div class=\"msg__alert moderated-alert-msg-wrap\">' +
            `${this.messaging.youHaveFlaggedThisMessageForModeration}</div>`);
    }
    updateHeader(msg, payload) {
        const prevsMsgeElement: any = msg[0].children[0];
        const sender: boolean = $(prevsMsgeElement).hasClass('sender');
        const rightSidePanel = sender ? prevsMsgeElement.children[0] : prevsMsgeElement.children[1];
        if (!sender) {
            if (msg[0].childNodes[0].childNodes[0] &&
                msg[0].childNodes[0].childNodes[0].className &&
                msg[0].childNodes[0].childNodes[0].className == 'msg--header card--message msg-header-image-wrap') {
                const leftSideMsgWraper: any = msg[0].childNodes[0];
                const headerDisplayNameWrap: any = msg[0].childNodes[1].childNodes[0];
                if (leftSideMsgWraper && headerDisplayNameWrap) {
                    if (msg.next().length && msg.next()[0] && $(msg.next()[0]).data('msg-user-id')
                        && payload.user_id == $(msg.next()[0]).data('msg-user-id')) {
                        // Append the display name to the next message
                        msg.next()[0].childNodes[1].prepend(headerDisplayNameWrap);
                        // Remove the next message header
                        msg.next()[0].childNodes[0].remove();
                        // Replace with the new one
                        msg.next().prepend(leftSideMsgWraper);
                    }
                }
            }
        }
    }
    /**
     * @param msgUserId
     * @param lastToSpeak
     * @param newMessage
     * @param clampCurrentMessage
     * @param isSender
     * flagged or declined message -->   dont remove the previous message time stamp
     * clampCurrentMessage --> true ---> then remove the previous message elements time stamp and
     * remove the previous messages time stamp class
     * clampCurrentMessage --> false ---> don't remove the previous messages time stamp
     */
    showHeader(msgUserId, lastToSpeak, newMessage, clampCurrentMessage, isSender) {
        const msg: any = $('#messages-holder');
        if (newMessage) {
            if (msg && msg[0] && msg[0].children.length) {
                const lastMessage: any = msg[0].lastElementChild
                const videoTimeStamp: boolean = $(lastMessage).hasClass('time-stamp');
                const videoCallJoinBtn: boolean = $(lastMessage).hasClass('senderVideo');
                if (msg[0].lastElementChild && $(msg[0].lastElementChild).data('msg-user-id')) {
                    if (msgUserId == $(msg[0].lastElementChild).data('msg-user-id')) {
                        // If time stamp for the new message is displayed then displays its header
                        if (!videoTimeStamp && !videoCallJoinBtn) {
                            return !clampCurrentMessage;
                        }
                    } else {
                        return true;
                    }
                }
            }
        } else {
            if (lastToSpeak == msgUserId) {
                if (msg && msg[0] && msg[0].children.length) {
                    const prevsMsgeElement: any = msg[0].children[0];
                    const sender: boolean = $(prevsMsgeElement).hasClass('sender');
                    const receiver: boolean = $(prevsMsgeElement).hasClass('receiver');
                    const videoTimeStamp: boolean = $(prevsMsgeElement).hasClass('time-stamp');
                    const rightSidePanel = sender ? prevsMsgeElement.children[0] : prevsMsgeElement.children[1];
                    if (receiver && clampCurrentMessage && !videoTimeStamp) {
                        // Remove the header image
                        $(prevsMsgeElement.children[0].children[0]).remove();
                        // Remove the header title
                        $(prevsMsgeElement.children[1].children[0]).remove();
                    }
                    if (clampCurrentMessage) {
                        if (!(['flagged', 'declined'].includes($(prevsMsgeElement).attr('data-msg-state')))) {
                            if (receiver && $(prevsMsgeElement.children[0]).hasClass('left-side-header-img-wrap')) {
                                // Remove the prev message left side header image
                                $(prevsMsgeElement.children[0].children[0]).remove();
                            }
                            if ($(rightSidePanel).hasClass('right-side-msg-panel-wrap')) {
                                // Remove the prev message left side header image
                                if (!$(rightSidePanel).hasClass('right-side-msg-panel-wrap-Ongoing-call')) {
                                    $(rightSidePanel).addClass('clamp-msg');
                                    }
                                    if ($(rightSidePanel.children[0]).hasClass('child-msg-status-wrap')) {
                                    $(rightSidePanel.children[0]).addClass('clamp-msg-status');
                                }
                            }
                        }
                    }
                }
            }
        }
        return true;
    }
    sentReceived(msgUserId, chatUserId) {
        this.getTranslate();
        let sentReceived;
        if (msgUserId.toString() == chatUserId) {
            sentReceived = 'sent on';
        } else {
            sentReceived = 'received at';
        }
        return sentReceived;
    }
    messageStatus(payload, payload_body, state, reportedUserId, createdAtTime, sentReceived, chatUserId) {
        this.getTranslate();
        if (this.utility.getItem('currentLanguage') === 'en') {
            const createdAt: any = this.dateAndTime.defaultDateAndTimeFormat(createdAtTime, 'LT');
            const fullDateTime: any = this.dateAndTime.defaultDateAndTimeFormat(createdAtTime, 'MMM Do YYYY [at] LT');
            const fullDate: any = this.dateAndTime.defaultDateAndTimeFormat(createdAtTime, 'MMM Do YYYY');
            if (payload.automated) {
                const pattern = /"type":"video_timestamp"/;
                const containsPattern = pattern.test(payload_body);
                if (containsPattern) {
                    const formattedTimestamp = moment(createdAtTime, 'YYYY-MM-DD HH:mm:ss Z').tz('Europe/London').format('LT');
                    return `<span
            title=\"${fullDateTime}\">
                <span ${fullDate} at </span>${createdAt}
            </span>`
                } else {
                    const formattedTimestamp = moment(createdAtTime).format('LT');
                    return `<span
            title=\"${fullDateTime}\">
                <span ${fullDate} at </span>${formattedTimestamp}
            </span>`
                }
            }
            if (this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction') {
                const createdAt: any = this.dateAndTime.defaultDateAndTimeFormat(createdAtTime, 'LT');
                const fullDateTime: any = this.dateAndTime.defaultDateAndTimeFormat(createdAtTime, 'MMM Do YYYY [at] LT');
                const fullDate: any = this.dateAndTime.defaultDateAndTimeFormat(createdAtTime, 'MMM Do YYYY');
                if (((state === 'flagged' && reportedUserId && reportedUserId != chatUserId)
                    || state !== 'flagged') && state !== 'declined' && state !== 'approved') {
                    if ((state === 'flagged' && reportedUserId != chatUserId) && (reportedUserId != null) && sentReceived == "received at") {
                        let stateMsg = '';
                        stateMsg = `${this.messaging.messageIsBeingChecked}`;
                        return `<span class=\"flagged-small\" title=\"${fullDateTime}\">${stateMsg} </span>${createdAt}<span>`;
                    }
                    else if ((state === 'flagged' && reportedUserId != chatUserId) && (reportedUserId != null) && sentReceived == "sent on") {
                        let stateMessage
                        stateMessage = `Pending approval - ${createdAt}`;
                        return `<span
                          class=\"small moderation-text-color small-new\"
                          title=\"${fullDateTime}\">
                          ${stateMessage}
                          </span>`;
                    }
                    else {

                        const pattern = /"type":"video<i>call"/;
                        const containsPattern = pattern.test(payload_body);
                        if (containsPattern) {
                            return `<span
                          class=\"small moderation-text-color\"
                          title=\"${fullDateTime}\">
                              <span class=\"sr-only\">${fullDate} at </span>${createdAt}
                          </span>`;
                        } else {
                            return `<span
                          class=\"small moderation-text-color small-new\"
                          title=\"${fullDateTime}\">
                              <span class=\"sr-only\">${fullDate} at </span>${createdAt}
                          </span>`;
                        }

                    }
                }
                else if (state == 'approved' && sentReceived == 'received at') {
                    let stateMsg = '';
                    stateMsg = `${this.messaging.messageHasBeenApproved}`;
                    return `<span class=\"approved-small approved-text-color\" title=\"${fullDateTime}\">${stateMsg} </span><span> ${createdAt}</span>`;
                }
                else if (state == 'approved' && sentReceived == 'sent on') {
                    return `<span
              class=\"small moderation-text-color\"
              title=\"${fullDateTime}\">
                  <span class=\"sr-only\">${fullDate} at </span>${createdAt}
              </span>`;
                }
                else {
                    let stateMessage = '';
                    if (state === 'flagged') {
                        if (sentReceived == "sent on" || reportedUserId != null) {
                            stateMessage = `${this.messaging.pendingModeration} - ${createdAt}`;
                        } else if (sentReceived == "received at") {
                            let stateMsg = '';
                            stateMsg = `${this.messaging.messageIsBeingChecked}`;
                            return `<span class=\"flagged-small\" title=\"${fullDateTime}\">${stateMsg} </span>${createdAt}<span>`;
                        }
                    }
                    else if ((state === 'declined' && reportedUserId == null && sentReceived == "received at") || (state === 'declined' && chatUserId == reportedUserId)) {
                        return `<span
                      class=\"small moderation-text-color small-new\"
                      title=\"${fullDateTime}\">
                          <span class=\"sr-only\">${fullDate} at </span>${createdAt}
                      </span>`;
                    } else {
                        if (state === 'declined' && chatUserId != reportedUserId && sentReceived == "received at") {
                            stateMessage = `<span
                            class=\"small moderation-text-color small-new\"
                            title=\"${fullDateTime}\">
                                <span class=\"sr-only\">${fullDate} at </span>${createdAt}
                            </span>`;
                        } else {
                            stateMessage = `${this.messaging.messageDidNotPassOurhecks} - ${createdAt}`;
                        }
                    }
                    return `<span class=\"small moderation-text-color\" title=\"${fullDateTime}\">${stateMessage}</span>`;
                }
            } else {
                if (((state === 'flagged' && reportedUserId && reportedUserId != chatUserId)
                    || state !== 'flagged') && state !== 'declined') {
                    if ((state === 'flagged' && reportedUserId != chatUserId) && (reportedUserId != null) && sentReceived == "sent on") {
                        let stateMessage
                        stateMessage = `Pending approval - ${createdAt}`;
                        return `<span
                                  class=\"small moderation-text-color small-new\"
                                  title=\"${fullDateTime}\">
                                  ${stateMessage}
                                  </span>`;
                    } else {
                        return `<span
                  class=\"small moderation-text-color\"
                  title=\"${fullDateTime}\">
                      <span class=\"sr-only\">${fullDate} at </span>${createdAt}
                  </span>`;
                    }
                } else {
                    let stateMessage = '';
                    if (state === 'flagged') {
                        stateMessage = `<span class=\"small moderation-text-color\" style="padding-left:10px;"> ${this.messaging.pendingModeration} - ${createdAt}</span>`;
                    }
                    else {
                        if ((state === 'declined' && chatUserId != reportedUserId && sentReceived == "received at") || (state === 'declined' && chatUserId == reportedUserId)) {
                            stateMessage = `<span
                            class=\"small moderation-text-color small-new\"
                            title=\"${fullDateTime}\">
                                <span class=\"sr-only\">${fullDate} at </span>${createdAt}
                            </span>`;
                        } else {
                            stateMessage = `${this.messaging.messageDidNotPassOurhecks} - ${createdAt}`;
                        }
                    }
                    return `<span class=\"small moderation-text-color\" title=\"${fullDateTime}\">${stateMessage}</span>`;
                }
            }
        } else {
            moment.locale('cy');
            const dateObject: any = new Date(Date.parse(createdAtTime));
            const createdAt: any = moment(dateObject);
            const fullDateTime: any = moment(dateObject);
            const fullDate: any = moment(dateObject);
            if (payload.automated) {
                const pattern = /"type":"video_timestamp"/;
                const containsPattern = pattern.test(payload_body);
                if (containsPattern) {
                    const formattedTimestamp = moment(createdAtTime).tz('Europe/London').format('LT');
                    return `<span
            title=\"${fullDateTime}\">
                <span ${fullDate} at </span>${createdAt.format('LT')}
            </span>`
                } else {
                    const formattedTimestamp = moment(createdAtTime).format('LT');
                    return `<span
            title=\"${fullDateTime}\">
                <span ${fullDate} at </span>${formattedTimestamp}
            </span>`
                }
            }

            if (this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction') {
                if (((state === 'flagged' && reportedUserId && reportedUserId != chatUserId)
                    || state !== 'flagged') && state !== 'declined' && state !== 'approved') {
                    if ((state === 'flagged' && reportedUserId != chatUserId) && (reportedUserId != null) && sentReceived == "received at") {
                        let stateMsg = '';
                        stateMsg = `${this.messaging.messageIsBeingChecked}`;
                        return `<span class=\"flagged-small\" title=\"${fullDateTime.format('lll')}\">${stateMsg} </span>${createdAt.format('LT')}<span>`;
                    } else if ((state === 'flagged' && reportedUserId != chatUserId) && (reportedUserId != null) && sentReceived == "sent on") {
                        let stateMessage
                        stateMessage = `${this.messaging.pendingModeration} - ${createdAt.format('LT')}`;
                        return `<span
                          class=\"small moderation-text-color small-new\"
                          title=\"${fullDateTime}\">
                          ${stateMessage}
                          </span>`;
                    }
                    else {
                        return `<span
                      class=\"small moderation-text-color small-new\"
                      title=\"${fullDateTime.format('lll')}\">
                          <span class=\"sr-only\">${fullDate.format('LL')} at </span>${createdAt.format('LT')}
                      </span>`;
                    }
                }
                else if (state == 'approved' && sentReceived == 'received at') {
                    let stateMsg = '';
                    stateMsg = `${this.messaging.messageHasBeenApproved}`;
                    return `<span class=\"approved-small approved-text-color\" title=\"${fullDateTime}\">${stateMsg} </span><span> ${createdAt.format('LT')}</span>`;
                }
                else if (state == 'approved' && sentReceived == 'sent on') {
                    return `<span
              class=\"small moderation-text-color\"
              title=\"${fullDateTime.format('lll')}\">
                  <span class=\"sr-only\">${fullDate.format('LL')} at </span>${createdAt.format('LT')}
              </span>`;
                }
                else {
                    let stateMessage = '';
                    if (state === 'flagged') {
                        if (sentReceived == "sent on" || reportedUserId != null) {
                            stateMessage = `${this.messaging.pendingModeration} - ${createdAt.format('LT')}`;
                        } else if (sentReceived == "received at") {
                            let stateMsg = '';
                            stateMsg = `${this.messaging.messageIsBeingChecked}`;
                            return `<span class=\"flagged-small\" title=\"${fullDateTime.format('lll')}\">${stateMsg} </span>${createdAt.format('LT')}<span>`;
                        }
                    } else if ((state === 'declined' && reportedUserId == null && sentReceived == "received at") || (state === 'declined' && chatUserId == reportedUserId)) {
                        return `<span
                      class=\"small moderation-text-color small-new\"
                      title=\"${fullDateTime.format('lll')}\">
                          <span class=\"sr-only\">${fullDate.format('LL')} at </span>${createdAt.format('LT')}
                      </span>`;
                    } else {
                        if (state === 'declined' && chatUserId != reportedUserId && sentReceived == "received at") {
                            stateMessage = `<span
                        class=\"small moderation-text-color small-new\"
                        title=\"${fullDateTime.format('lll')}\">
                            <span class=\"sr-only\">${fullDate.format('LL')} at </span>${createdAt.format('LT')}
                        </span>`;
                        } else {
                            stateMessage = `${this.messaging.messageDidNotPassOurhecks} - ${createdAt.format('LT')}`;
                        }
                    }
                    return `<span class=\"small moderation-text-color\" title=\"${fullDateTime.format('lll')}\">${stateMessage}</span>`;
                }
            } else {
                if (((state === 'flagged' && reportedUserId && reportedUserId != chatUserId)
                    || state !== 'flagged') && state !== 'declined') {
                    if ((state === 'flagged' && reportedUserId != chatUserId) && (reportedUserId != null) && sentReceived == "sent on") {
                        let stateMessage
                        stateMessage = `${this.messaging.pendingModeration} - ${createdAt.format('LT')}`;
                        return `<span
                                  class=\"small moderation-text-color small-new\"
                                  title=\"${fullDateTime}\">
                                  ${stateMessage}
                                  </span>`;
                    } else {
                        return `<span
                  class=\"small moderation-text-color\"
                  title=\"${fullDateTime.format('lll')}\">
                      <span class=\"sr-only\">${fullDate.format('LL')} </span>${createdAt.format('LT')}
                  </span>`;
                    }
                } else {
                    let stateMessage = '';
                    if (state === 'flagged') {
                        stateMessage = `<span class=\"small moderation-text-color\" style="padding-left:10px;"> ${this.messaging.pendingModeration} - ${createdAt.format('LT')}</span>`;
                    } else {
                        if ((state === 'declined' && chatUserId != reportedUserId && sentReceived == "received at") || (state === 'declined' && chatUserId == reportedUserId)) {
                            stateMessage = `<span
                            class=\"small moderation-text-color small-new\"
                            title=\"${fullDateTime.format('lll')}\">
                                <span class=\"sr-only\">${fullDate.format('LL')} at </span>${createdAt.format('LT')}
                            </span>`;
                        } else {
                            stateMessage = `${this.messaging.messageDidNotPassOurhecks} - ${createdAt.format('LT')}`;
                        }
                    }
                    return `<span class=\"small moderation-text-color\" title=\"${fullDateTime.format('lll')}\">${stateMessage}</span>`;
                }
            }
        }

    }
    alertMessage(message, chatUserId) {
        this.getTranslate();
        if (message.state == 'flagged' && message.reported_user_id == chatUserId) {
            return `${this.messaging.youHaveFlaggedThisMessageForModeration}`;
        } else if (message.state == 'flagged') {
            if (this.utility.getItem('channelRole') === 'mentee' && message.reported_user_id === null) {
                return `${this.messaging.YourMessageIsBeingCheckedByOurTeamYourMenteeKnowsYouHaveMessagedThemAndWillReceiveTheFullMessageOnceItsApproved}`
            } else if (this.utility.getItem('channelRole') === 'mentor' && message.reported_user_id === null) {
                return `${this.messaging.YourMessageIsBeingCheckedByOurTeamYourMentorKnowsYouHaveMessagedThemAndWillReceiveTheFullMessageOnceItsApproved}`
            } else if (message.reported_user_id != null) {
                return `${this.messaging.brightsideIsCheckingThisMessage}`
            }
            else {
                return `${this.messaging.YourMessageIsBeingCheckedByOurTeamYourMentorMenteeKnowsYouHaveMessagedThemAndWillReceiveTheFullMessageOnceItsApproved}`
            }
        } else {
            if (message.declined_reason) {
                return `${this.messaging.reason}: ${message.declined_reason}`;
            } else {
                return `${this.messaging.thisMessageHasBeenDeclinedInModeration}`;
            }
        }
    }
    previewIconPath(type) {
        if (type == 'video') {
            return '';
        } else if (type == 'image') {
            return '../../assets/img/image-preview-icon.svg';
        } else {
            return '../../assets/img/file-preview-icon.svg';
        }
    }
    title(title) {
        if (title) {
            return "<h4>" + this.linkifyText(title) + "</h4>";
        } else {
            return '';
        }
    }
    image(image) {
        if (image) {
            return "<img src=\"" + image + "\" width=\"180\"/>";
        } else {
            return '';
        }
    }
    description(description) {
        if (description) {
            return "<p class=\"small moderation-text-color\">" + this.linkifyText(description) + "</p>";
        } else {
            return '';
        }
    }
    generateAttachment(attachment) {
        return "<li>" + this.title(attachment.title) + this.image(attachment.image) +
            this.description(attachment.description) + "</li>";
    }
    transform(bytes: any) {
        const units: any = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';
        let unit = 0;
        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }
        return (parseFloat(bytes).toFixed(+ 2)) + ' ' + units[unit];
    }
    generateDownload(payload, file, sentReceived) {
        const contentType = file.attachment_content_type || '';
        let attachmentId: any = file.attachment_id;
        const attachmentFileName: any = file.attachment_filename;
        let imageFill: string = 'fill/240x240/';
        if (contentType && contentType.includes('image') && attachmentId
            && attachmentId.includes('brightside-assets') && !contentType.includes('gif')) {
            attachmentId = this.imageServerCdn + imageFill + attachmentId;
        } else if (contentType && attachmentId && attachmentId.includes('brightside-assets')) {
            if (contentType === 'video/mp4' && this.checkForIosDevice()) {
                attachmentId = attachmentId.replace(/\bbrightside-assets\b/g, this.s3BucketOrigin);
            } else {
                attachmentId = this.imageServerCdn + attachmentId;
            }
        } else if (contentType === 'video/mp4' && this.checkForIosDevice()) {
            attachmentId = attachmentId.replace(/\bbrightside-assets\b/g, this.s3BucketOrigin);
        }
        if (this.isAttachmentSupported(file, 'video')) {
            if (this.checkForIosOrSafariInMac()) {
                if (this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction') {
                    if (payload.state == 'flagged' || payload.state == 'declined') {
                        if (sentReceived == 'received at') {
                            let redcatMsg = this.repeatStringNumTimes(attachmentFileName, "*");
                            return `<div class=\"file-main-div"\><div class=\"file-preview redact-message-outer\">
                       <span class=\"file-preview__title\">
                         <span class=\"tab-outline\" tabindex=\"0\" role=\"group\">
                            <span class=\"tab-inline aria-label=\"something moderated message\"  redact-message\" tabindex=\"-1\">
                            ${redcatMsg}
                            </span>
                         </span>
                       </span>
                    </div>
                </div>`;
                        } else {
                            return `<li class=\"video-wrapper msg-video-container ios-video-container\" data-video-src=\"${attachmentId}\">
                       <svg class=\"video-overlay-play-button\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                        <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                        <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                      </svg>
                    </li>`;
                        }
                    } else if (payload.state == 'approved') {
                        return `<li class=\"video-wrapper msg-video-container ios-video-container\" data-video-src=\"${attachmentId}\">
                     <svg class=\"video-overlay-play-button\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                      <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                      <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                    </svg>
                  </li>`;
                    }
                } else {
                    return `<li class=\"video-wrapper msg-video-container ios-video-container\" data-video-src=\"${attachmentId}\">
                     <svg class=\"video-overlay-play-button\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                      <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                      <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                    </svg>
                  </li>`;
                }
            } else {
                if (this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction') {
                    if (payload.state == 'flagged' || payload.state == 'declined') {
                        if (sentReceived == 'received at') {
                            let redcatMsg = this.repeatStringNumTimes(attachmentFileName, "*");
                            return `<div class=\"file-main-div"\><div class=\"file-preview redact-message-outer\">
                       <span class=\"file-pending-receiver\">
                         <span class=\"tab-outline\" tabindex=\"0\" role=\"group\">
                            <span aria-label=\"something moderated message\" class=\"tab-inline redact-message\" tabindex=\"-1\">
                            ${redcatMsg}
                            </span>
                         </span>
                       </span>
                    </div>
                </div>`;
                        } else {
                            return `<li class=\"video-wrapper msg-video-container tab-outline\" tabindex=\"0\">
                       <video width=\"100%\" height=\"240\" class=\"tab-inline\" tabindex=\"-1\">
                      <source src=\"${attachmentId}\" type=\"video/mp4\" aria-hidden=\"false\">
                      <source src=\"${attachmentId}\" type=\"video/ogg\" aria-hidden=\"false\">
                      <source src=\"${attachmentId}\" type=\"video/webm\" aria-hidden=\"false\">
                              Your browser does not support the video tag.
                      </span>
                      </video>
                      <svg class=\"video-overlay-play-button tab-channels-video-image\" tabindex=\"0\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                       <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                       <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                     </svg>
                   </li>`;
                        }
                    } else if (payload.state == 'approved' || payload.state == 'pending') {
                        return `<li class=\"video-wrapper msg-video-container tab-outline\" tabindex=\"0\">
                      <video width=\"100%\" height=\"240\" class=\"tab-inline\" tabindex=\"-1\">
                     <source src=\"${attachmentId}\" type=\"video/mp4\" aria-hidden=\"false\">
                     <source src=\"${attachmentId}\" type=\"video/ogg\" aria-hidden=\"false\">
                     <source src=\"${attachmentId}\" type=\"video/webm\" aria-hidden=\"false\">
                             Your browser does not support the video tag.
                     </span>
                     </video>
                     <svg class=\"video-overlay-play-button tab-channels-video-image\" tabindex=\"0\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                      <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                      <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                    </svg>
                  </li>`;
                    }
                } else {
                    return `<li class=\"video-wrapper msg-video-container\">
                  <video width=\"100%\" height=\"240\">
                 <source src=\"${attachmentId}\" type=\"video/mp4\" aria-hidden=\"false\">
                 <source src=\"${attachmentId}\" type=\"video/ogg\" aria-hidden=\"false\">
                 <source src=\"${attachmentId}\" type=\"video/webm\" aria-hidden=\"false\">
                         Your browser does not support the video tag.
                 </span>
                 </video>
                 <svg class=\"video-overlay-play-button tab-channels-video-image\" tabindex=\"0\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                  <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                  <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                </svg>
              </li>`;
                }

            }
        } else if (this.isAttachmentSupported(file, 'image')) {
            if (this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction') {
                if (payload.state == 'flagged' || payload.state == 'declined') {
                    if (sentReceived == 'received at') {
                        let redcatMsg = this.repeatStringNumTimes(attachmentFileName, "*");
                        return `<div class=\"file-main-div\"><div class=\"file-preview redact-message-outer\">
                   <span class=\"file-pending-receiver tab-outline\">
                     <span class=\"tab-outline\" tabindex=\"0\" role=\"group\">
                        <span aria-label=\"something moderated message\" class=\"tab-inline redact-message\" tabindex=\"-1\">
                        ${redcatMsg}
                        </span>
                     </span>
                   </span>
                </div>
            </div>`;
                    } else {
                        return `<li class=\"channel-img-wrap\">
                   <img src=\"${attachmentId}\" aria-hidden=\"false\" tabindex=\"0\" class=\"tab-channels-video-image\" alt=\"${attachmentFileName}\">
               </li>`;
                    }
                } else if (payload.state == 'approved' || payload.state == 'pending') {
                    return `<li class=\"channel-img-wrap\">
                  <img src=\"${attachmentId}\" aria-hidden=\"false\" tabindex=\"0\" class=\"tab-channels-video-image\" alt=\"${attachmentFileName}\">
              </li>`;
                }
            } else {
                return `<li class=\"channel-img-wrap\">
                  <img src=\"${attachmentId}\" aria-hidden=\"false\" tabindex=\"0\" class=\"tab-channels-video-image\" alt=\"${attachmentFileName}\">
              </li>`;
            }

        } else {
            if ((this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction') && sentReceived == 'received at' && payload.state == 'flagged') {
                let redcatMsg = this.repeatStringNumTimes(attachmentFileName, "*");
                return `<div class=\"file-main-div"\><div class=\"file-preview redact-message-outer\">
           <span class=\"file-pending-receiver\">
             <span class=\"tab-outline\" tabindex=\"0\" role=\"group\">
                <span aria-label=\"something moderated message\" class=\"tab-inline redact-message\" tabindex=\"-1\">
                ${redcatMsg}
                </span>
             </span>
           </span>
        </div>
    </div>`;
            } else {
                return `<li><div class=\"file-preview\">
           <div class=\"file-preview__icon\">
                <img alt="Attach Image" src=\"${this.previewIconPath(contentType)}\">
           </div>
           <span class=\"file-preview__title\">
             <span class=\"tab-outline\" tabindex=\"0\" role=\"group\">
                <span class=\"tab-inline\" tabindex=\"-1\">
                ${attachmentFileName}
                </span>
             </span>
           </span>
           <span class=\"file-preview__info tab-outline" tabindex=\"0\">
           <span class="tab-inline" tabindex="-1">
              ${this.transform(file.attachment_size)}
            </span>
           </span>
           <span class=\"file-preview__action remove-outline\">
              <a class=\"download-btn-wrap remove-outline\"
                 target=\"_blank\"
                 role="button"
                 href=\"${this.imageServerCdn + file.attachment_id}\"
                 aria-label=\"Download Attachment\">
                 <img alt=" " src="../../assets/svg/download-icon.svg">
             </a>
           </span>
        </div>
    </li>`;
            }
        }
    }
    isAttachmentSupported(file, type) {
        if (file) {
            const contentType = file.attachment_content_type || '';
            if (type === 'video') {
                return AppConstant.SUPPORTED_VIDEO_TYPES.includes(contentType);
            } else if (type === 'image' && contentType && contentType.includes('image')) {
                let isImageSupported: boolean = false;
                AppConstant.SUPPORTED_IMAGE_TYPES.some((imageType) => {
                    if (contentType.toLowerCase().includes(imageType.toLowerCase())) {
                        isImageSupported = true;
                        return true;
                    }
                });
                return isImageSupported;
            }
        }
        return false;
    }
    setModerationFlag(message, chatUserId, channelType) {
        if (message.user_id.toString() != chatUserId && channelType != 'support' && message.state == 'pending') {
            return this.setResetFlag(message.id, chatUserId)
        }
        return '';
    }
    setResetFlag(messageId, msgUserId) {
        if (messageId && msgUserId) {
            return `<span class=\"moderate-msg\" aria-hidden=\"false\" data-msg-id= ${messageId} data-user-id= ${msgUserId} style=\"float: right;\">
                  <span class=\"visually-hidden\">Report this message</span>
                  <i aria-hidden=\"true\" class=\"fa fa-flag\"></i></span>`;
        }
        return '';
    }
    generate(payload, userData, chatUserId, lastToSpeak, channelType) {
        this.getTranslate();
        let messageId = (this.utility.getItem('messageId') == payload.id);
        if (channelType === 'mentoring' && payload.automated) {
            const match = payload.body.match(/Congratulations you have earned the badge (.+)/);
            if (match && match.length && match.length > 1) {
                return this.displayEarnedBadge(payload, match[1], userData);
            }
        }
        if (channelType == 'support' && payload.automated == null) {
            const regex = /(?=.*"enAnnBdy":).*"wlsAnnBdy":/;
            const result = regex.test(payload.body);
            if (result) {
                payload.body = JSON.parse(payload.body)
                payload.body = this.utility.getItem('currentLanguage') === 'en' ? payload.body.enAnnBdy : payload.body.wlsAnnBdy;
            }
        }
        let messageState = payload.state;
        let isSender = false, isReported = false;
        const isNewMessage: any = payload.new_message;
        const clampCurrentMessage: any = this.checkAndClampTimeStamp(payload.inserted_at, payload.user_id, isNewMessage, messageState);
        let showHeader: boolean = false
        const displayTimeLineOrNot: any = this.checkToDisplayTimeLine(payload.inserted_at, isNewMessage);
        let extractedYoutubeUrls: any = this.textHelper.extractYoutubeUrls(payload.body);
        if (payload.body && payload.body.length) {
            let reviewActivityText = this.textHelper.checkForReviewActivity(payload.body);
            if (reviewActivityText) {
                payload.body = reviewActivityText;
            }
        }
        if (!payload.automated) {
            payload.body = this.textHelper.checkAndBoldOrItalicText(payload.body);
            showHeader = this.showHeader(payload.user_id, lastToSpeak, isNewMessage, clampCurrentMessage, isSender);

        }
        let isAnySupportedAttachment: boolean = false;
        if (payload.files && payload.files.length) {
            if (this.isAttachmentSupported(payload.files[0], 'video')) {
                isAnySupportedAttachment = true;
            }
        }
        let isAttachmentOnly: boolean = false;
        if (!payload.body) {
            isAttachmentOnly = true;
        }
        if (!isNewMessage) {
            this.showTimeLine(displayTimeLineOrNot);
        }
        if (payload.user_id == chatUserId) {
            isSender = true;
        }
        if (payload.reported_user_id) {
            isReported = true;
        }
        if (payload.body) {
            payload.body = this.linkifyText(this.breakifyText(payload.body));
        }


        let messagePartial = `<div class=\"remove-outline msg msg-parent-wrap recevier_msg ${(isAnySupportedAttachment) ? 'supported-attachment-wrap' : ''} ${(isSender) ? 'sender' : 'receiver'} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''} ${(messageId == true ? 'scroll' : '')}  msg--` +
            messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState +
            " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at}` + " tabindex='-1'>";

        ///////////////// time stamp
        if (channelType === 'mentoring' && payload.automated) {
            const regexToCheckWordVideo = /scheduled_at|video_timestamp/i;
            if (regexToCheckWordVideo.test(payload.body)) {
                const responseBody = JSON.parse(payload.body) // Parse the body JSON
                let videoTimeStamp = `<div class=\"remove-outline msg ${(responseBody.room_status) ? 'msg-parent-wrap' : 'msg-parent-wrap-time-stamp' }  recevier_msg ${(isAnySupportedAttachment) ? 'supported-attachment-wrap' : ''} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''} ${(messageId == true ? 'scroll' : '')} ${(responseBody.type === 'video_timestamp' || 'schedule_call') ? 'time-stamp' : ''} msg--` +
                    messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState +
                    " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at}` + " tabindex='-1'>";
                if (responseBody.type === 'video_timestamp' && (responseBody.started_at || responseBody.ended_at)) {
                    if(responseBody.room_status){
                        return this.videoCallButtons(videoTimeStamp, payload, userData, chatUserId, isSender, messageState, responseBody, isReported)
                    }else if (responseBody.started_at) {
                        this.formatTimeLineDate(responseBody.started_at)
                        console.log("responseBody.started_at---->", responseBody.started_at)
                        let formattedTimestamp = moment(responseBody.started_at, 'YYYY-MM-DD HH:mm:ss Z').format('HH:mm');
                        const timeLine: string = `<div class = "video-call-timline""><hr><div class="video-call-time-stamp remove-outline" tabindex=\"-1\"> ${this.messaging.callStartedAt} - ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, responseBody.started_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)}</div></div>`;
                        videoTimeStamp = `${videoTimeStamp}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
                    }
                    else if (responseBody.ended_at) {
                        const durationInSeconds = parseInt(responseBody.duration, 10); // Convert to integer
                        const durationFormatted = this.formatDuration(durationInSeconds);
                        const timeLine: string = `<div class = "video-call-timline""><hr><div class="video-call-time-stamp remove-outline" tabindex=\"-1\"> ${this.messaging.callEndedAt} ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, responseBody.ended_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)} - ${durationFormatted}</div></div>`;
                        videoTimeStamp = `${videoTimeStamp}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
                        if (isNewMessage) {
                            this.mentoringEvents.setScheduleRefreshChannel(true)
                        }
                    }
                } else if (responseBody.type === 'schedule_call') {
                    if (responseBody.schedule_call_state === 'pending' && isSender) {
                        const timeLine: string = `<div class = "video-call-timline""><hr><div class="video-call-time-stamp remove-outline" tabindex=\"-1\"> ${this.messaging.invitationSent} - ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)} </div></div>`;
                        videoTimeStamp = `${videoTimeStamp}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
                    }
                    else if (responseBody.schedule_call_state === 'pending' && !isSender) {
                        const timeLine: string = `<div class = "video-call-timline""><hr><div class="video-call-time-stamp remove-outline" tabindex=\"-1\"> ${this.messaging.invitationRecieved} - ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)} </div></div>`;
                        videoTimeStamp = `${videoTimeStamp}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
                        if (isNewMessage) {
                            this.mentoringEvents.setScheduleRefreshChannel(true)
                        }
                    }
                    else if (responseBody.schedule_call_state === 'accepted') {
                        const timeLine: string = `<div class = "video-call-timline""><hr><div class="video-call-time-stamp remove-outline" tabindex=\"-1\"> ${this.messaging.invitationAccepted} - ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)} </div></div>`;
                        videoTimeStamp = `${videoTimeStamp}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
                        if (isNewMessage && isSender) {
                            this.mentoringEvents.setScheduleRefreshChannel(true)
                        }
                    }
                    else if (responseBody.schedule_call_state === 'declined') {
                        const timeLine: string = `<div class = "video-call-timline""><hr><div class="video-call-time-stamp remove-outline" tabindex=\"-1\"> ${this.messaging.invitationDeclined} - ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)} </div></div>`;
                        videoTimeStamp = `${videoTimeStamp}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
                        if (isNewMessage && isSender) {
                            this.mentoringEvents.setScheduleRefreshChannel(true)
                        }
                    }
                    else if (responseBody.schedule_call_state === 'canceled') {
                        const timeLine: string = `<div class = "video-call-timline""><hr><div class="video-call-time-stamp remove-outline" tabindex=\"-1\"> ${this.messaging.meetingCancelled} - ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)}</div></div>`;
                        videoTimeStamp = `${videoTimeStamp}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
                        if (isNewMessage) {
                            this.mentoringEvents.setScheduleRefreshChannel(true)
                        }
                    }
                    else if (responseBody.schedule_call_state === 'missed_call') {
                        const timeLine: string = `<div class = "video-call-timline""><hr><div class="video-call-time-stamp remove-outline" tabindex=\"-1\"> Missed call - ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)} </div></div>`;
                        videoTimeStamp = `${videoTimeStamp}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
                    }
                    ///////////time stamp end
                } else if (responseBody.type === 'video_call' && responseBody.scheduled_at.started_at) {
                    /////////////////////////video call join button
                    if (isSender) {
                        return
                    } else {
                        return `${this.joinCallButton(payload, userData, chatUserId, lastToSpeak, channelType, responseBody)}`
                    }
                }
                return videoTimeStamp
            }
        }
        ///////////////// join call end

        if (showHeader && !isSender) {
            if (this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction') {
                if (clampCurrentMessage && isNewMessage) {
                    messagePartial = messagePartial + "<div class= \"left-side-header-img-wrap\" >" +
                        "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                        userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                        "<div class= \"right-side-msg-panel-wrap clamp-msg\" >" +
                        "<figcaption class=\"card--message__caption tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                        + userData.display_name + "</h4></div></figcaption>";
                } else {
                    if (payload.state == 'pending') {
                        messagePartial = messagePartial + "<div class= \"left-side-header-img-wrap\" >" +
                            "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                            userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                            "<div class= \"right-side-msg-panel-wrap\" >" +
                            "<figcaption class=\"card--message__caption_support tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                            + userData.display_name + "</h4></div></figcaption>";
                    } else {
                        messagePartial = messagePartial + "<div class= \"left-side-header-img-wrap\" >" +
                            "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                            userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                            "<div class= \"right-side-msg-panel-wrap\" >" +
                            "<figcaption class=\"card--message__caption tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                            + userData.display_name + "</h4></div></figcaption>";
                    }
                }
            } else {
                if (clampCurrentMessage && isNewMessage) {
                    messagePartial = messagePartial + "<div class= \"left-side-header-img-wrap\" >" +
                        "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                        userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                        "<div class= \"right-side-msg-panel-wrap clamp-msg\" >" +
                        "<figcaption class=\"card--message__caption-default tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                        + userData.display_name + "</h4></div></figcaption>";
                } else {
                    messagePartial = messagePartial + "<div class= \"left-side-header-img-wrap\" >" +
                        "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                        userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                        "<div class= \"right-side-msg-panel-wrap\" >" +
                        "<figcaption class=\"card--message__caption-default tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                        + userData.display_name + "</h4></div></figcaption>";
                }
            }
        } else {
            if (clampCurrentMessage && isNewMessage) {
                if (isSender) {
                    messagePartial = messagePartial + "<div class= \"right-side-msg-panel-wrap clamp-msg\" >";
                } else {
                    messagePartial = messagePartial + "<div class= \"left-side-header-img-wrap\" >" + "</div>" +
                        "<div class= \"right-side-msg-panel-wrap clamp-msg\" >";
                }
            } else {
                if (isSender) {
                    messagePartial = messagePartial + "<div class= \"right-side-msg-panel-wrap\" >";
                } else {
                    messagePartial = messagePartial + "<div class= \"left-side-header-img-wrap\" >" +
                        "<figure class=\"msg--header card--message msg-header-image-wrap\"></figure>" + "</div>" +
                        "<div class= \"right-side-msg-panel-wrap\" >";
                }
            }
        }
        if (this.utility.getItem('moderationType') == 'full_redaction' || this.utility.getItem('moderationType') == 'partial_redaction') {
            if (this.utility.getItem('messageId')) {
                setTimeout(() => {
                    this.scrolltomessage();
                }, 1000);
            }
            if ((payload.reported_user_id == null && (payload.state == 'flagged') && !isSender) ||
                ((payload.state == 'approved') && (payload.reported_user_id != null) && !isSender)) {
                messagePartial = messagePartial + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} sample\">` +
                    "<span class=\"tab-outline\" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
            } else if (payload.reported_user_id == null && (payload.state == 'approved') && !isSender) {
                messagePartial = messagePartial + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} sample\">` +
                    "<span class=\"tab-outline\" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
            } else if ((payload.user_id != chatUserId && payload && payload.state === 'flagged'
                && ((payload.reported_user_id != chatUserId)))
                && (payload.reported_user_id != null)) {
                messagePartial = messagePartial + `<div class=\"msg__status  sample\">` +
                    "<span class=\"tab-outline\" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
            } else {
                messagePartial = messagePartial + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
                    "<span  class=\"tab-outline is-reported-not-null\" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
            }
        } else {
            if (this.utility.getItem('messageId')) {
                setTimeout(() => {
                    this.scrolltomessage();
                }, 1000);
            }
            messagePartial = messagePartial + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
                "<span class=\"tab-outline\" tabindex=\"0\" role=\"group\">" +
                "<span class=\"tab-inline\" tabindex=\"-1\">" +
                this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
        }
        // Render Attachments if the message is being sent or it has been approved or in pending state
        if (this.utility.getItem('moderationType') == 'full_redaction') {
            if ((isSender && !isReported) || ['approved', 'pending'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body} </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;

            } else if ((isSender && isReported) && ['declined'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body}  </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
            else if ((isSender && isReported) && ['flagged'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body} </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
            else if ((isSender && isReported) && ['declined'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body}  </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
            else if ((isSender && isReported) && ['flagged'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body} </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
            if (!isSender && ['flagged'].includes(payload.state) && isReported == false) {
                let redcatMsg = this.repeatStringNumTimes(payload.body, "*");
                if (payload.files.length === 0) {
                    messagePartial = `${messagePartial}  <div class=\"msg_sender msg__message-content msg--
               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
  <div tabindex=\"0\" class=\"tab-outline redact-message-outer\" role=\"group\">
               <div tabindex=\"-1\" class=\"tab-inline\" aria-label=\"something moderated message\"> <span class=\"redact-message\"> ${redcatMsg} </span>  </div> </div> </div>`;
                    messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
                } else {
                    messagePartial = `${messagePartial}  <div class=\"msg_sender msg__message-content msg--
               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
  <div tabindex=\"0\" class=\"tab-outline redact-message-outer\" role=\"group\">
               <div tabindex=\"-1\" class=\"tab-inline\" aria-label=\"something moderated message\"> <span class=\"redact-message\"> ${redcatMsg} </span>  </div> </div> </div>`;
                    messagePartial = ` ${messagePartial}  <div class=\"attached_file\">${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                                ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)}</div> `;
                }
            }
            if ((payload.user_id != chatUserId && payload
                && ((payload.state === 'flagged' && payload.reported_user_id != chatUserId)))
                && !(payload.reported_user_id == null)) {
                let redcatMsg = this.repeatStringNumTimes(payload.body, "*");
                if (payload.files.length === 0) {
                    messagePartial = `${messagePartial}  <div class=\"msg_sender msg__message-content msg--
                       ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                       <div tabindex=\"0\" class=\"tab-outline redact-message-outer\" role=\"group\">
                       <div tabindex=\"-1\" class=\"tab-inline\" aria-label=\"something moderated message\"> <span class=\"redact-message\"> ${redcatMsg} </span>  </div> </div> </div>`;
                }
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                                   ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
        } else if (this.utility.getItem('moderationType') == 'partial_redaction') {
            if ((isSender && !isReported) || ['approved', 'pending'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body} </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
            else if ((isSender && isReported) && ['declined'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body}  </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
            else if ((isSender && isReported) && ['flagged'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body} </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
            if (!isSender && ['flagged'].includes(payload.state) && isReported == false) {
                if (payload.files.length === 0) {
                    let body: any = this.getContent(payload.body)
                    messagePartial = `${messagePartial}  <div class=\"msg_sender msg__message-content redact-message-outer msg--
                   ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                   <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                   <div tabindex=\"-1\" class=\"tab-inline\" aria-label=\"something moderated message\"> ${body} </div> </div> </div>`;
                    messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                                ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
                } else {
                    let body: any = this.getContent(payload.body)
                    messagePartial = `${messagePartial}  <div class=\"msg_sender msg__message-content redact-message-outer msg--
                   ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                   <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                   <div tabindex=\"-1\" class=\"tab-inline\" aria-label=\"something moderated message\"> ${body} </div> </div> </div>`;
                    messagePartial = ` ${messagePartial} <div class=\"attached_file\">${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} </div>`;
                }
            }
            if ((payload.user_id != chatUserId && payload
                && ((payload.state === 'flagged' && payload.reported_user_id != chatUserId)))
                && !(payload.reported_user_id == null)) {
                let redcatMsg = this.repeatStringNumTimes(payload.body, "*");
                if (payload.files.length === 0) {
                    messagePartial = `${messagePartial}  <div class=\"msg_sender msg__message-content msg--
                       ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                       <div tabindex=\"0\" class=\"tab-outline redact-message-outer\" role=\"group\">
                       <div tabindex=\"-1\" class=\"tab-inline\" aria-label=\"something moderated message\"> <span class=\"redact-message\"> ${redcatMsg} </span>  </div> </div> </div>`;
                }
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                                   ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
        } else {
            if ((isSender && !isReported) || ['approved', 'pending'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body}  </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            } else if ((isSender && isReported) && ['declined'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body}  </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }
            else if ((isSender && isReported) && ['flagged'].includes(payload.state)) {
                messagePartial = `${messagePartial}  <div class=\"${((payload.state == 'flagged' && isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                               ${messageState} __message-content inner-message-content-wrap ${(isAttachmentOnly) ? 'display-none' : ''}\">
                  <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                               <div tabindex=\"-1\" class=\"tab-inline\">  ${payload.body} </div> </div> </div>`;
                messagePartial = ` ${messagePartial}  ${this.files(payload, payload.files, this.sentReceived(payload.user_id, chatUserId))}
                               ${this.attachments(payload.attachments)}    ${this.generateAtatchedYoutubeVideosTemplate(extractedYoutubeUrls)} `;
            }

        }

        if ((payload.state == 'flagged' && isReported && !isSender)
            || payload.state == 'declined' || (payload.state == 'flagged' && !isReported && isSender) || (payload.state == 'flagged' && isReported && isSender)) {
            if (!(payload.state == 'flagged' && payload.user_id != chatUserId && payload
                && ((payload.state === 'flagged' && payload.reported_user_id != chatUserId)))) {
                return messagePartial = messagePartial + "<div class=\"msg__alert msg-alert-wrap\">" + "<div class=\'tab-outline\' tabindex=\"0\" role=\"group\">" + "<div class=\'tab-inline\' tabindex=\'-1\'>" + this.alertMessage(payload, chatUserId) + "</div>" + "</div>" + "</div>";
            }
        }
        if ((isNewMessage || payload.state === 'approved') && !displayTimeLineOrNot) {
            messagePartial = `${messagePartial}${this.setModerationFlag(payload, chatUserId, channelType)}</div>`;
        } else {
            const insertedTime: string = this.formatTimeLineDate(payload.inserted_at);
            const timeLine: string = `<div class="time-line""><hr><div class="time-line-time-stamp remove-outline" tabindex=\"-1\">${insertedTime}</div></div>`;
            messagePartial = `${messagePartial}${this.setModerationFlag(payload, chatUserId, channelType)}${timeLine}</div>`;
        }
        if (channelType == 'support' && (isNewMessage || $('#messages-holder .sender').length == $('#messages-holder .msg').length)) {
            $('.auto-respond-msg').remove();
            $('.mentoring-chat-bot-wrap').remove();
            if (isSender) {
                messagePartial = `${messagePartial} </div><div class="remove-outline auto-respond-msg msg msg-parent-wrap receiver time-line-parent msg--pending">
              <div class= "left-side-header-img-wrap">
                 <figure class="msg--header card--message tab-outline" tabindex="0">
                    <img tabindex="-1" alt="Profile image of brightside mentoring image"
                       src="../../../assets/img/mentoring.ico"
                       class="card--message__image tab-inline">
                  </figure>
              </div>
            <div class="right-side-msg-panel-wrap">
              <figcaption class="card--message__caption tab-outline">
                  <div class="info"  tabindex="0"><h4
                                        class="card__title msg__display_name tab-inline"  tabindex="-1">
                      ${this.messaging.brightsideTeam}</h4></div>
              </figcaption>
                    <div class="msg__message-content msg--pending__message-content inner-message-content-wrap ">
                        <div tabindex="0" class="tab-outline" role="group">
                              <div tabindex="-1" class="tab-inline">
                              ${this.messaging.thanksForGettingInTouch}
                              </div>
                         </div>
                    </div>
              </div>
            </div>`;
            }
        }
        return messagePartial;
    }
    videoCallButtons(videoTimeStamp, payload, userData, chatUserId, isSender, messageState, responseBody, isReported) {
        this.getTranslate();
        const isNewMessage: any = payload.new_message;
        const clampCurrentMessage: any = this.checkAndClampTimeStamp(payload.inserted_at, payload.user_id, isNewMessage, messageState);
        const displayTimeLineOrNot: any = this.checkToDisplayTimeLine(payload.inserted_at, isNewMessage);
        if (responseBody.room_status == 'room-created') {
            if (isSender) {
                let missedCallMessage = `<div class=\"remove-outline msg msg-parent-wrap recevier_msg ${(isSender) ? 'sender' : 'reciver'} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''} msg--` +
                    messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState +
                    " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at}` + " tabindex='-1'>";
                missedCallMessage = missedCallMessage + `<div class="right-side-msg-panel-wrap">`
                missedCallMessage = missedCallMessage + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
                    "<span  class=\"tab-outline is-reported-not-null\" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
                missedCallMessage = `${missedCallMessage}  <div class=\"${((payload.state == 'flagged') || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                    ${messageState} __message-content inner-message-content-wrap ongoing-call-inner-message-content-wrap room-created\">
                    <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                    <div tabindex=\"-1\" class=\ "ButtonSenderOnDemandCall ${(this.utility.getItem('currentLanguage') === 'wls' ) ? 'welshButtonResize': ''} tab-inline\"><div><img class= "missedEndButton" src="../../../assets/svg/Video call solid icon.svg" alt="" >
                    </div><span aria-label="${this.messaging.joinCall} button">${this.messaging.joinCall}</span></div> </div> </div>`
                return missedCallMessage
            }
            else {
                videoTimeStamp = videoTimeStamp + "<div class= \"left-side-header-img-wrap \" style=\"padding-left: 1.5%; margin-right: 1%;\">" +
                    "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                    userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                    "<div class= \"right-side-msg-panel-wrap  right-side-msg-panel-wrap-Ongoing-call\" >" +
                    "<div><figcaption class=\"card--message__caption-default tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                    + userData.display_name + "</h4></div></figcaption>"

                videoTimeStamp = videoTimeStamp + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
                    "<span  class=\"tab-outline \" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div></div>";
                videoTimeStamp = videoTimeStamp + `<div class="channel-badge-container channel-VideoCall-content">
                    <div class = "onDemandCallButton">
                        <div class = \"onGoingCallButton room-created ${(this.utility.getItem('currentLanguage') === 'wls' ) ? 'welshButtonResize': ''} tab-outline \" tabindex=\"0\">
                            <div><img class= "missedEndButton" src="../../../assets/svg/Video call solid icon.svg" alt="" >
                            </div> 
                        <span style="font-size:15px;" aria-label="${this.messaging.joinCall} button">${this.messaging.joinCall}</span>
                        </div>
                    </div>
                    </div>`
            }
        } else if (responseBody.room_status == "missed-call") {
            let missedCallMessage = `<div class=\"remove-outline msg msg-parent-wrap recevier_msg  ${(isSender) ? 'sender' : 'reciver'} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''}  msg--` +
                messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState +
                " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at}` + " tabindex='-1'>";
            if (isSender) {
                missedCallMessage = missedCallMessage + `<div class="right-side-msg-panel-wrap">`
                missedCallMessage = missedCallMessage + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
                    "<span  class=\"tab-outline is-reported-not-null\" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
                missedCallMessage = `${missedCallMessage}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                    ${messageState} __message-content inner-message-content-wrap missedCall-inner-message-content-wrap \">
                    <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                    <div tabindex=\"-1\" class=\ "missedCallButtonSender ${(this.utility.getItem('currentLanguage') === 'wls' ) ? 'welshButtonResize': ''}  tab-inline\"><div> <img class= "missedEndButton" src="../../../assets/svg/missed-call.svg" alt="" >
                    </div> <span style="font-size:14px;" aria-label="${this.messaging.missedVideoCall} button"> ${this.messaging.missedVideoCall} </span></div> </div> </div>`
                return missedCallMessage
            } else {
                videoTimeStamp = videoTimeStamp + "<div class= \"left-side-header-img-wrap \" style=\"padding-left: 1.5%; margin-right: 1%;\">" +
                    "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                    userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                    "<div class= \"right-side-msg-panel-wrap  right-side-msg-panel-wrap-Ongoing-call\" >" +
                    "<div><figcaption class=\"card--message__caption-default tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                    + userData.display_name + "</h4></div></figcaption>"

                videoTimeStamp = videoTimeStamp + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
                    "<span  class=\"tab-outline \" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div></div>";
                videoTimeStamp = videoTimeStamp + `<div class="channel-badge-container channel-VideoCall-content">
                        <div class = "onDemandCallButton">
                            <div class = \"endCallButton ${(this.utility.getItem('currentLanguage') === 'wls' ) ? 'welshButtonResize': ''} tab-outline \" tabindex=\"0\">
                                <div> <img class= "missedEndButton" src="../../../assets/svg/missed-call.svg" alt="" >
                                </div> 
                               <span aria-label="${this.messaging.missedVideoCall} button"> ${this.messaging.missedVideoCall}</span> 
                            </div>
                        </div>
                    </div>`
            }
        }
        else if (responseBody.room_status == "room-ended") {
            const durationInSeconds = parseInt(responseBody.duration, 10); // Convert to integer
            const durationFormatted = this.formatDuration(durationInSeconds);
            let missedCallMessage = `<div class=\"remove-outline msg msg-parent-wrap recevier_msg ${(isSender) ? 'sender' : 'reciver'} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''}  msg--` +
                messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState +
                " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at}` + " tabindex='-1'>";
            if (isSender) {
                missedCallMessage = missedCallMessage + `<div class="right-side-msg-panel-wrap">`
                missedCallMessage = missedCallMessage + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
                    "<span  class=\"tab-outline is-reported-not-null\" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
                missedCallMessage = `${missedCallMessage}  <div class=\"${((payload.state == 'flagged' && !isReported) || payload.state == 'declined') ? 'msg_wrap' : ''} msg_sender msg__message-content msg--
                    ${messageState} __message-content inner-message-content-wrap endCall-inner-message-content-wrap \">
                    <div tabindex=\"0\" class=\"tab-outline\" role=\"group\">
                    <div tabindex=\"-1\" class=\ "ButtonSenderOnDemandCall ${(this.utility.getItem('currentLanguage') === 'wls' ) ? 'welshButtonResize': ''} tab-inline\"><div><img class= "missedEndButton" src="../../../assets/svg/complete-video-call.svg" alt="" >
                    </div> <div style="line-height: normal; font-size:14px;" aria-label=" ${this.messaging.videoCall} end button">${this.messaging.videoCall} <br>
                    <span style="font-weight: normal;">${durationFormatted}</span> </div> </div> </div> </div>`
                return missedCallMessage
            } else {
                videoTimeStamp = videoTimeStamp + "<div class= \"left-side-header-img-wrap \" style=\"padding-left: 1.5%; margin-right: 1%;\">" +
                    "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                    userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                    "<div class= \"right-side-msg-panel-wrap  right-side-msg-panel-wrap-Ongoing-call\" >" +
                    "<div><figcaption class=\"card--message__caption-default tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                    + userData.display_name + "</h4></div></figcaption>"

                videoTimeStamp = videoTimeStamp + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
                    "<span  class=\"tab-outline \" tabindex=\"0\" role=\"group\">" +
                    "<span class=\"tab-inline\" tabindex=\"-1\">" +
                    this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div></div>";
                videoTimeStamp = videoTimeStamp + `<div class="channel-badge-container channel-VideoCall-content">
            <div class = "onDemandCallButton">
                <div class = \"endCallButton ${(this.utility.getItem('currentLanguage') === 'wls' ) ? 'welshButtonResize': ''} tab-outline \" tabindex=\"0\">
                    <div><img class= "missedEndButton" src="../../../assets/svg/complete-video-call.svg" alt="" >
                    </div>
                    <div class="endCallButtonText">
                        <span style="font-size:14px;" aria-label=" ${this.messaging.videoCall} end button"> ${this.messaging.videoCall}</span> 
                        <span style="font-weight: normal;">${durationFormatted}</span>
                   </div> 
                </div>
            </div>
        </div>`
            }
        }
        return videoTimeStamp
    }

    declinedMsgReciver(payload, userData, chatUserId, lastToSpeak, channelType) {
        this.getTranslate();
        let messageId = (this.utility.getItem('messageId') == payload.id);
        let messageState = payload.state;
        let isSender = false, isReported = false;
        const isNewMessage: any = payload.new_message;
        const clampCurrentMessage: any = this.checkAndClampTimeStamp(payload.inserted_at, payload.user_id, isNewMessage, messageState);
        const showHeader: boolean = this.showHeader(payload.user_id, lastToSpeak, isNewMessage, clampCurrentMessage, isSender);
        const displayTimeLineOrNot: any = this.checkToDisplayTimeLine(payload.inserted_at, isNewMessage);
        payload.body = this.textHelper.checkAndBoldOrItalicText(payload.body);
        let isAnySupportedAttachment: boolean = false;
        if (payload.files && payload.files.length) {
            if (this.isAttachmentSupported(payload.files[0], 'video')) {
                isAnySupportedAttachment = true;
            }
        }
        let isAttachmentOnly: boolean = false;
        if (!payload.body) {
            isAttachmentOnly = true;
        }
        if (!isNewMessage) {
            this.showTimeLine(displayTimeLineOrNot);
        }
        if (payload.user_id == chatUserId) {
            isSender = true;
        }
        if (payload.reported_user_id) {
            isReported = true;
        }
        if (payload.state === 'flagged' && isReported && isSender) {
            messageState = 'pending';
        }


        if (payload.state == 'flagged' && !isSender) {
            payload.body = this.breakifyText(payload.body);
        } else {
            payload.body = this.linkifyText(this.breakifyText(payload.body));
        }

        let declinedmessagePartial = `<div class=\"remove-outline msg msg-parent-wrap recevier_msg ${(isAnySupportedAttachment) ? 'supported-attachment-wrap' : ''} ${(isSender) ? 'sender' : 'receiver'} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''} ${(messageId == true ? 'scroll' : '')}  msg--` +
            "pending" + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + "pending" +
            " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at}` + " tabindex='-1'>";
        if (showHeader && !isSender) {
            declinedmessagePartial = declinedmessagePartial + "<div class= \"left-side-header-img-wrap\" >" +
                "<figure class=\"msg--header card--message msg-header-image-wrap tab-outline\" tabindex=\"0\"><img tabindex=\"-1\" alt=\"Profile image of " + userData.display_name + "\" src=\"" +
                userData.avatar + "\" class=\"card--message__image tab-inline\"/></figure>" + "</div>" +
                "<div class= \"right-side-msg-panel-wrap\" >" +
                "<figcaption class=\"card--message__caption_support tab-outline\"><div class=\"info\" tabindex=\"0\"><h4 tabindex=\"-1\" class=\"card__title msg__display_name tab-inline\">"
                + userData.display_name + "</h4></div></figcaption>";
        } else {
            if (clampCurrentMessage && isNewMessage) {
                declinedmessagePartial = declinedmessagePartial + "<div class= \"left-side-header-img-wrap\" >" + "</div>" +
                    "<div class= \"right-side-msg-panel-wrap clamp-msg\" >";
            } else {
                declinedmessagePartial = declinedmessagePartial + "<div class= \"left-side-header-img-wrap\" >" +
                    "<figure class=\"msg--header card--message msg-header-image-wrap\"></figure>" + "</div>" +
                    "<div class= \"right-side-msg-panel-wrap\" >";
            }

        }
        declinedmessagePartial = declinedmessagePartial + `<div class=\"msg__status ${(isNewMessage && clampCurrentMessage) ? 'clamp-msg-status' : ''} child-msg-status-wrap\">` +
            "<span  class=\"tab-outline is-reported-not-null\" tabindex=\"0\" role=\"group\">" +
            "<span class=\"tab-inline\" tabindex=\"-1\">" +
            this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId) + "</span>" + "</span>" + "</div>";
        if ((payload.reported_user_id == chatUserId) || (payload.reported_user_id != chatUserId && !isSender && isReported)) {
            declinedmessagePartial = declinedmessagePartial + `<div class=" msg_sender msg__message-content msg--
                                   pending __message-content inner-message-content-wrap ">
                      <div tabindex="0" class="tab-outline" role="group">
                                   <div tabindex="-1" class="tab-inline" style="font-style: italic; opacity: 0.5;">  ${this.messaging.flaggedMessageDidNotPassBrightsidesChecks} </div> </div> </div>`
        }
        else {
            declinedmessagePartial = declinedmessagePartial + `<div class=" msg_sender msg__message-content msg--
                               pending __message-content inner-message-content-wrap ">
                  <div tabindex="0" class="tab-outline" role="group">
                               <div tabindex="-1" class="tab-inline" style="font-style: italic; opacity: 0.5;">  ${this.messaging.thisMessageDidNotPassTheChecks}</div> </div> </div>`
        }
        return declinedmessagePartial
    }
    joinCallButton(payload, userData, chatUserId, lastToSpeak, channelType, responseBody) {
        let messageState = payload.state;
        let isSender = false, isReported = false;
        const isNewMessage: any = payload.new_message;
        const displayTimeLineOrNot: any = this.checkToDisplayTimeLine(payload.inserted_at, isNewMessage);
        let extractedYoutubeUrls: any = this.textHelper.extractYoutubeUrls(payload.body);
        payload.body = this.textHelper.checkAndBoldOrItalicText(payload.body);
        let isAnySupportedAttachment: boolean = false;
        let isTimePassed = false
        let isBetween = false
        if (payload.files && payload.files.length) {
            if (this.isAttachmentSupported(payload.files[0], 'video')) {
                isAnySupportedAttachment = true;
            }
        }
        let isAttachmentOnly: boolean = false;
        if (!payload.body) {
            isAttachmentOnly = true;
        }
        if (!isNewMessage) {
            this.showTimeLine(displayTimeLineOrNot);
        }
        if (payload.user_id == chatUserId) {
            isSender = true;
        }
        if (payload.reported_user_id) {
            isReported = true;
        }
        if (responseBody.scheduled_at.started_at) {
            const localTime = moment.utc(responseBody.scheduled_at.started_at);
            const formattedDesiredTime = localTime.local().format("YYYY-MM-DD HH:mm:ss");
            const formattedEndTime = moment(formattedDesiredTime).add(responseBody.scheduled_at.duration, 'seconds');
            const currentTime = moment();
            isBetween = currentTime.isBetween(formattedDesiredTime, formattedEndTime, 'minute', '[]')
            isTimePassed = currentTime.isAfter(formattedDesiredTime)
        }

        if (payload.state == 'flagged' && !isSender) {
            payload.body = this.breakifyText(payload.body);
        } else {
            payload.body = this.linkifyText(this.breakifyText(payload.body));
        }

        let newMessagePartial = `<div class=\"remove-outline msg msg-parent-wrap recevier_msg ${(isAnySupportedAttachment) ? 'supported-attachment-wrap' : ''} ${(isSender) ? 'receiver' : 'receiver'} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''} msg--` +
            messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState +
            " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at}` + " tabindex='-1'>";
        if (isSender) {
            return true
        } else if (!isBetween && isTimePassed) {
            return `<div class=\"remove-outline msg msg-parent-wrap recevier_msg ${(isAnySupportedAttachment) ? 'supported-attachment-wrap' : ''} ${(isSender) ? 'senderVideo' : 'senderVideo'} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''} msg--` +
                messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState +
                " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at} tabindex='-1'>
        <div class="left-side-header-img-wrap">
            <figure class="msg--header card--message tab-outline" tabindex="0">
                <img class="tab-inline" tabindex="-1" alt="Profile image of brightside team icon"
                src="../../../assets/img/mentoring.ico" class="card--message__image remove-outline">
            </figure>
        </div>
        <div class="right-side-msg-panel-wrap-video-call-join-btn">
            <figcaption class="bot--message__caption remove-outline">
                <div class="info">
                    <h4 tabindex="0" class="card__title msg__display_name remove-outline">Brightside Team</h4>
                </div>
            </figcaption>
            <div class=\"msg__status child-msg-status-wrap\">
            <span  class=\"tab-outline \" tabindex=\"0\" role=\"group\">
            <span class=\"tab-inline\" tabindex=\"-1\">
            ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)}</span></span></div>
              <div class="channel-badge-container channel-VideoCall-content">
                <div class = "TimePassedbuttonVideobtnContainerSender">
                       <div class = \"TimePassedbuttonVideobtnSender tab-outline \" tabindex=\"0\">
                          <div class="TimePassedvideo-icon-button"><i class="fa fa-video-camera" style="font-size:20px;"></i>
                          </div> 
                          ${this.messaging.callExpired}
                       </div>
                </div>
            </div>  
        </div>
    </div>`;
        } else {
            return `<div class=\"remove-outline msg msg-parent-wrap recevier_msg ${(isAnySupportedAttachment) ? 'supported-attachment-wrap' : ''} ${(isSender) ? 'senderVideo' : 'senderVideo'} ${((!isNewMessage || (isNewMessage && displayTimeLineOrNot))) ? 'time-line-parent' : ''} msg--` +
                messageState + "\"" + " data-msg-id=" + payload.id + " data-msg-state=" + messageState +
                " data-msg-user-id=" + payload.user_id + ` inserted-at=${payload.inserted_at} + " tabindex='-1'>
        <div class="left-side-header-img-wrap">
              <figure class="msg--header card--message tab-outline" tabindex="0">
                  <img class="tab-inline" tabindex="-1" alt="Profile image of brightside team icon"
                  src="../../../assets/img/mentoring.ico" class="card--message__image remove-outline">
              </figure>
          </div>
          <div class="right-side-msg-panel-wrap-video-call-join-btn">
              <figcaption class="bot--message__caption remove-outline">
                  <div class="info">
                      <h4 tabindex="0" class="card__title msg__display_name remove-outline">Brightside Team</h4>
                     
                  </div>
              </figcaption>
              <div class=\"msg__status child-msg-status-wrap\">
              <span  class=\"tab-outline \" tabindex=\"0\" role=\"group\">
              <span class=\"tab-inline\" tabindex=\"-1\">
              ${this.messageStatus(payload, payload.body, payload.state, payload.reported_user_id, payload.inserted_at, this.sentReceived(payload.user_id, chatUserId), chatUserId)}</span></span></div>
                <div class="channel-badge-container channel-VideoCall-content">
                  <div class = "buttonVideobtnContainerSender">
                         <div class = \"buttonVideobtnSender tab-outline \" tabindex=\"0\">
                            <div class="video-icon-button"><i class="fa fa-video-camera" style="font-size:20px;"></i>
                            </div> 
                            ${this.messaging.joinCallWith} ${userData.display_name.split(' ')[0]}
                         </div>
                  </div>
              </div>  
          </div>
      </div>`;
        }

    }

    formatDuration(seconds: number): string {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        if (hours == 0) {
            const formattedDuration = `${minutes} min ${remainingSeconds} sec`;
            return formattedDuration;
        } else {
            const formattedDuration = `${hours} hr ${minutes} min ${remainingSeconds} sec`;
            return formattedDuration;
        }
    }

    generateAtatchedYoutubeVideosTemplate(extractedUrls) {
        let videoTemplate: any = "";
        if (extractedUrls && extractedUrls.length) {
            extractedUrls.forEach((item) => {
                let thumbnail = this.textHelper.extractYoutubeThumbnails(item, 'medium');
                videoTemplate += `<div class=\"attached-youtube-video-parent-wrap\">
                                  <div class=\"youtube-thumbnail-parent-wrap\">
                                      <img src=\"${thumbnail}\" alt=\"Video Thumbnail\" width=\"319\" height=\"164\"
                                           data-video-src=\"${item}\">
                                      <svg class=\"video-overlay-play-button\" tabindex=\"0\" class=\"tab-channels-video-image\" viewBox=\"0 0 200 200\" alt=\"Play video\">
                                          <circle cx=\"100\" cy=\"100\" r=\"90\" fill=\"none\" stroke-width=\"15\" stroke=\"#fff\"/>
                                          <polygon points=\"70, 55 70, 145 145, 100\" fill=\"#fff\"/>
                                      </svg>
                                    </div>
                               </div>`;
            });
        }
        return videoTemplate;
    }
    checkAndClampTimeStamp(currentTime, userId, forNewMsg: boolean = false, messageState) {
        if (forNewMsg && ['flagged', 'declined'].includes(messageState)) {
            return false;
        }
        const msg = $('#messages-holder');
        let prevTime: any;
        let prevUserId: any = '';
        // Clamps all the messages with in 5 minutes
        const clampTime: number = 5;
        currentTime = this.dateAndTime.momentDate(new Date(Date.parse(currentTime)), 'DD/MM/YYYY HH:mm:ss');
        if (msg && msg[0] && msg[0].lastElementChild) {
            // checks for if new message and retrieve the previous message element
            const prvsMsgElement: any = forNewMsg ? $($('#messages-holder').find('.msg').not('.auto-respond-msg')).last() : $(msg[0].firstElementChild);
            prevUserId = prvsMsgElement.data('msg-user-id');
            if (prevUserId && prevUserId.toString() == userId.toString()) {
                const retrievedPrevData: any = new Date(Date.parse(prvsMsgElement.attr('inserted-at')));
                prevTime = this.dateAndTime.momentDate(retrievedPrevData, 'DD/MM/YYYY HH:mm:ss');
                const timeDifferenceDuration: any = moment.duration(currentTime.diff(prevTime));
                // If the last message was of today
                if (timeDifferenceDuration.days() === 0) {
                    // Checks if the last message sent was in 5 minutes
                    if (_.inRange(timeDifferenceDuration.minutes(), -clampTime, clampTime + 1)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    /**
     * To check whether to display the time line for the current message payload
     * @param currentPayloadTime
     * @param isNewMsg
     */
    checkToDisplayTimeLine(currentPayloadTime, isNewMsg: boolean = false): boolean {
        const msg = $('#messages-holder');
        let prevTime: any;
        currentPayloadTime = this.dateAndTime.momentDate(new Date(Date.parse(currentPayloadTime)), 'DD/MM/YYYY');
        if (msg && msg[0] && msg[0].children.length) {
            // checks for if new message and retrieve the previous message element
            const prvsMsgElement: any = isNewMsg ? $($('#messages-holder').find('.msg').not('.auto-respond-msg')).last() : $(msg[0].firstElementChild);
            const retrievedPrevData: any = new Date(Date.parse(prvsMsgElement.attr('inserted-at')));
            prevTime = this.dateAndTime.momentDate(retrievedPrevData, 'DD/MM/YYYY');
            if (currentPayloadTime.isSame(prevTime, 'day')) {
                return !isNewMsg;
            }
        }
        return isNewMsg;
    }
    /**
     * Run function for each message payload to remove the previous time line
     * @param displayTimeLine
     */
    showTimeLine(displayTimeLine): void {
        const msg: any = $('#messages-holder');
        if (msg && msg[0] && msg[0].children.length && displayTimeLine) {
            const prvsMsgElement = msg[0].children[0];
            const sender: boolean = $(prvsMsgElement).hasClass('sender');
            const rightSidePanel = sender ? prvsMsgElement.children[0] : prvsMsgElement.children[1];
            if ($(prvsMsgElement).hasClass('time-line-parent')) {
                $(prvsMsgElement).removeClass('time-line-parent');
            }
            if ($(rightSidePanel).hasClass('right-side-msg-panel-wrap')) {
                // let elmnt: any = $(msg[0].children[0].children[1].children);
                $(rightSidePanel.children).each((key, Childelmnt) => {
                    if ($(Childelmnt).hasClass('time-line')) {
                        $(Childelmnt).remove();
                        return false;
                    }
                });
            }
        }
    }
    /**
     * Format the date to display on the timeline
     * @param date
     */
    formatTimeLineDate(date): string {
        this.getTranslate();
        const dateObject: any = new Date(Date.parse(date));
        const currentDate: any = moment();
        const createdDate: any = moment(dateObject);
        const toDay = currentDate.clone().startOf('day');
        const yesterDay = currentDate.clone().subtract(1, 'days').startOf('day');
        if (createdDate.isSame(toDay, 'd')) {
            return `${this.messaging.today}`;
        } else if (createdDate.isSame(yesterDay, 'd')) {
            return `${this.messaging.yesterday}`;
        } else if (createdDate['_isValid']) {
            if (this.utility.getItem('currentLanguage') === 'en') {
                return createdDate.format('MMM Do YYYY');
            } else {
                return createdDate.format('ll');
            }
        } else {
            return '';
        }
    }
    checkForIosDevice() {
        if ((/MacIntel|MacPPC|Mac68K|Macintosh|iPhone|iPod|iPad|iPhone Simulator|iPod Simulator |iPad Simulator|Pikev7.6 release 92|Pike v7.8 release 517/i.test(navigator.userAgent))) {
            return true;
        }
        return false;
    }
    checkForIosOrSafariInMac() {
        let isSafari: any = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if ((/iPhone|iPod|iPad/i.test(navigator.userAgent)) || isSafari) {
            return true;
        }
        return false;
    }
    displayEarnedBadge(message, badgeId, userData) {
        this.getTranslate();
        let badges: any = $("#channels").data('badges');
        let badge = null;
        let badgewls = null;
        let mentorMessage = '';
        let nextBadgeMessage = '';

        if (this.utility.getItem('currentLanguage') === 'en') {
            if (badges && badges.length) {
                badges.forEach(item => {
                    if (item.id === badgeId) {
                        badge = item;
                        if ($("#channels").data('active-channel-role') === 'mentor') {
                            mentorMessage = `<div class="badege-msg-mentor tab-outline" tabindex="0" role="group"><div class="tab-inline" tabindex="-1">Awarded to ${userData.display_name} on ${this.dateAndTime.defaultDateAndTimeFormat(message.inserted_at, 'D MMMM, YYYY')}</div></div>`;
                        }
                    }
                });
            }
        } else if (this.utility.getItem('currentLanguage') === 'wls') {
            if (this.badgesWls && this.badgesWls.length) {
                this.badgesWls.forEach(item => {
                    if (item.id === badgeId) {
                        badgewls = item;
                        if ($("#channels").data('active-channel-role') === 'mentor') {
                            mentorMessage = `<div class="badege-msg-mentor tab-outline" tabindex="0" role="group"><div class="tab-inline" tabindex="-1">${this.messaging.awardedTo} ${userData.display_name}  ${this.dateAndTime.defaultDateAndTimeFormat(message.inserted_at, 'LL')}</div></div>`;
                        }
                    }
                });
            }
        }
        if (this.utility.getItem('currentLanguage') === 'en') {
            if (!badge) {
                return true;
            }
        } else if (this.utility.getItem('currentLanguage') === 'wls') {
            if (!badgewls) {
                return true;
            }
        }
        if (this.utility.checkOnlyForMobile()) {
            return `<div class="msg msg-parent-wrap badge-parent receiver msg--pending chatbot-margin"
              data-msg-id=${message.id} data-badge-id=${badge.id} data-created-date=${message.inserted_at}>
          <div class="left-side-header-img-wrap">
              <figure class="msg--header card--message msg-header-image-wrap remove-outline">
                  <img tabindex="0" alt="Profile image of brightside mentoring image"
                  src="../../../assets/img/mentoring.ico" class="card--message__image remove-outline">
              </figure>
          </div>
          <div class="right-side-msg-panel-wrap">
              <figcaption class="bot--message__caption remove-outline">
                  <div class="info">
                      <h4 tabindex="0" class="card__title msg__display_name remove-outline">${this.messaging.brightsideTeam}</h4>
                      ${mentorMessage}
                  </div>
              </figcaption>
              <div class="channel-mobile-badge-container channel-badge-container" data-badge-id=${badge.id} data-created-date=${message.inserted_at}>
                  <div class="badge-header-container">
                      <div class="badge-title">
                          ${badge.content.channel_title}
                      </div>
                  </div>
                  <div class="left-badge-inner-container">
                      <div class="badge-icon">
                          <img src="../../../assets/img/badges/earnedBadge${badge.id}-static.png" alt="${badge.title} badge icon" class="badge-img">
                      </div>
                  </div>
                  <div class="badge-text-container">
                      <div class="congratulations-text">
                          ${badge.content.achievement_introduction}
                      </div>
                      <div class="remaining-badges-text">
                          ${badge.content.achievement_clause}
                      </div>
                  </div>
              </div>
          </div>
      </div>`;
        }

        if (this.utility.getItem('currentLanguage') === 'en') {
            return `<div class="msg msg-parent-wrap badge-parent receiver msg--pending chatbot-margin"
            data-msg-id=${message.id}>
        <div class="left-side-header-img-wrap">
            <figure class="msg--header card--message tab-outline" tabindex="0">
                <img class="tab-inline" tabindex="-1" alt="Profile image of brightside team icon"
                src="../../../assets/img/mentoring.ico" class="card--message__image remove-outline">
            </figure>
        </div>
        <div class="right-side-msg-panel-wrap">
            <figcaption class="bot--message__caption remove-outline">
                <div class="info">
                    <h4 tabindex="0" class="card__title msg__display_name remove-outline">${this.messaging.brightsideTeam}</h4>
                    ${mentorMessage}
                </div>
            </figcaption>

            <div class="channel-badge-container channel-badge-content" 
                data-badge-id=${badge.id} 
                data-created-date=${message.inserted_at}
                onmouseover="document.getElementById(\`badge${badge.id}\`).src = \`../../../assets/img/badges/earnedBadge${badge.id}-animated.gif\`;"
                onmouseout="document.getElementById(\`badge${badge.id}\`).src = \`../../../assets/img/badges/earnedBadge${badge.id}-static.png\`;">
                <div class="left-badge-inner-container">
                    <div class="badge-icon tab-outline" tabindex="0">
                        <img id="badge${badge.id}" src="../../../assets/img/badges/earnedBadge${badge.id}-static.png"
                        alt="${badge.title} badge icon" class="badge-img tab-inline" tabindex="-1">  
                    </div>
                </div>
                <div class="right-badge-inner-container">
                    <div class="badge-title tab-outline" role="group" tabindex="0">
                        <div class="tab-inline" tabindex="-1">
                            ${badge.content.channel_title}
                        </div>
                    </div>
                    <div class="congratulations-text tab-outline" role="group" tabindex="0">
                        <div class="tab-inline" tabindex="-1">
                            ${badge.content.achievement_introduction}
                        </div>
                    </div>
                    <div class="remaining-badges-text tab-outline" role="group" tabindex="0">
                        <div class="tab-inline" tabindex="-1">
                            ${badge.content.achievement_clause}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>`;
        }
        else if (this.utility.getItem('currentLanguage') === 'wls') {
            return `<div class="msg msg-parent-wrap badge-parent receiver msg--pending chatbot-margin"
            data-msg-id=${message.id}>
        <div class="left-side-header-img-wrap">
            <figure class="msg--header card--message tab-outline" tabindex="0">
                <img class="tab-inline" tabindex="-1" alt="Profile image of brightside team icon"
                src="../../../assets/img/mentoring.ico" class="card--message__image remove-outline">
            </figure>
        </div>
        <div class="right-side-msg-panel-wrap">
            <figcaption class="bot--message__caption remove-outline">
                <div class="info">
                    <h4 tabindex="0" class="card__title msg__display_name remove-outline">${this.messaging.brightsideTeam}</h4>
                    ${mentorMessage}
                </div>
            </figcaption>

            <div class="channel-badge-container channel-badge-content" 
                data-badge-id=${badgewls.id} 
                data-created-date=${message.inserted_at}
                onmouseover="document.getElementById(\`badge${badgewls.id}\`).src = \`../../../assets/img/badges/earnedBadge${badgewls.id}-animated.gif\`;"
                onmouseout="document.getElementById(\`badge${badgewls.id}\`).src = \`../../../assets/img/badges/earnedBadge${badgewls.id}-static.png\`;">
                <div class="left-badge-inner-container">
                    <div class="badge-icon tab-outline" tabindex="0">
                        <img id="badge${badgewls.id}" src="../../../assets/img/badges/earnedBadge${badgewls.id}-static.png"
                        alt="${badgewls.title} badge icon" class="badge-img tab-inline" tabindex="-1">  
                    </div>
                </div>
                <div class="right-badge-inner-container">
                    <div class="badge-title tab-outline" role="group" tabindex="0">
                        <div class="tab-inline" tabindex="-1">
                            ${badgewls.content.channel_title}
                        </div>
                    </div>
                    <div class="congratulations-text tab-outline" role="group" tabindex="0">
                        <div class="tab-inline" tabindex="-1">
                            ${badgewls.content.achievement_introduction}
                        </div>
                    </div>
                    <div class="remaining-badges-text tab-outline" role="group" tabindex="0">
                        <div class="tab-inline" tabindex="-1">
                            ${badgewls.content.achievement_clause}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>`;
        }
    }
    onHover(badgeId, isPopUp) {
        let element = isPopUp ? document.getElementById(`popUpBadge${badgeId}`) as HTMLImageElement :
            document.getElementById(`badge${badgeId}`) as HTMLImageElement;
        element.src = `../../../assets/img/badges/earnedBadge${badgeId}-animated.gif`;
    }
    unHover(badgeId, isPopUp) {
        let element = isPopUp ? document.getElementById(`popUpBadge${badgeId}`) as HTMLImageElement :
            document.getElementById(`badge${badgeId}`) as HTMLImageElement;
        element.src = `../../../assets/img/badges/earnedBadge${badgeId}-static.png`;
    }
    check(val) {
        console.log('vale', val);
    }


    repeatStringNumTimes(msgBody, string) {
        var countMsgBodyLength = msgBody.length
        const regex = /(<br \/>|[\s\S])/g;
        const result = msgBody.replace(regex, (match, group) => group === '<br />' ? match : '*');
        return result;
    }

    scrolltomessage() {
        let element = document.querySelector('.scroll');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setTimeout(() => {
            this.utility.removeItem('messageId');
        }, 2000);
    }

    getContent(msgBody) {
        const regex = /(¶)/g;
        const result = msgBody.replace(regex, (match, group) => {
            const lengthGroup = '*'.repeat(group.length);
            const coloredSpan = `<span style="background-color: #002b39 !important;color: #002b39">${lengthGroup}</span>`;
            return coloredSpan;
        });
        return result;
    }


}

