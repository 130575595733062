import { Component, OnInit } from '@angular/core';
import { AppConstant } from '../../../app.constant';
import { UtilityHelper } from '../../../helpers/utilityHelper';
import {Router, NavigationEnd} from "@angular/router";
import { filter, tap } from 'rxjs/operators';
import {AbstractBaseComponent} from '../../../app.baseComponent';
import {MentoringSocketService} from '../../../messageServices/mentoringSocketService'
@Component({
    selector: 'app-pop-up-screen',
    templateUrl: './popUpScreen.component.html',
    styleUrls: ['./popUpScreen.component.scss'],
    standalone: true
})
export class PopUpScreenComponent extends AbstractBaseComponent implements OnInit {
  constructor(private utility: UtilityHelper, public router: Router, private socketService: MentoringSocketService,) {
    super();
  }

  ngOnInit() {}
  closeModal() {
    let autoPromptModalElement: any = $('#autoPromptModal');
    autoPromptModalElement.modal('hide');
  }

  openStore() {
    if (!this.utility.getItem('autoPromptPopUpShown')) {
      window.open(AppConstant.APP_DOWNLOAD_LINK, '_blank');
      this.utility.setItem('autoPromptPopUpShown', true);
      let autoPromptModalElement: any = $('#autoPromptModal');
      autoPromptModalElement.modal('hide');
    }
  }

}
