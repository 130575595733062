import {Injectable} from '@angular/core';
import {BaThemeSpinner} from '../theme/services/baThemeSpinner/baThemeSpinner.service';
import * as _ from 'lodash';
import {AppConstant} from '../app.constant';
import {IMyOptions} from 'mydatepicker';
import {UtilityHelper} from './utilityHelper';
declare let window: any;
declare let UserInfo: any;

@Injectable()
export class ReportHelper {
    isScriptLoaded: boolean = false;
    lineChartOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        scales: {
          x: {
            grid: {
              drawBorder: false,
              display: false
            },
            ticks: {
              display: false
            }
          },
          y: {
            grid: {
              drawBorder: false,
              display: false
            },
            ticks: {
              beginAtZero: false,
              display: false
            }
          }
        },
        elements: {
            point: {
              radius: 0,          
              hoverRadius: 0      
            }
          }
      };
      
      
    lineChartColors:Array<any> = [
        {
            backgroundColor: '#00A1E5',
            borderColor: '#00A1E5',
            pointBackgroundColor: '#00A1E5',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#00A1E5',
            pointRadius: 0
        }
    ];
    usersInformation: any = [];
    projectInformation: any = [];
    userEngagementPlatform: any = [];
    userEngagementProject: any = [];
    userEngagementMatch: any = [];
    groupMessaging: any = [];
    categories: any = [];
    engagementReportingFields: any = [];
    projectPartnersData: any = [];
    projects: any = [];
    partners: any = [];
    selectedProjectName: any = '';
    selectedPartnerName: any = '';
    selectedToDate: any = '';
    selectedFormDate: any = '';
    originalCategories: any = [];
    reviewRole: any = '';
    keywordSearch: any = '';
    userActivity: boolean = false;
    financialYear: boolean = false;

    constructor(private _spinner: BaThemeSpinner, private utility: UtilityHelper){
        this.setReportingFields();
    }

    clearUploadControl(event){
        event.target.value = null;
    }

    getPartnersData() {
        this._spinner.show();
        let partnersUrl = 'projects?filter[adminable]=true&fields[projects]=name,partner_id&fields[partners]=name&page[number]=1&page[size]=5000';
        if (this.utility.getItem('token')) {
            let token = 'Token token=\"' + this.utility.getItem('token') + '\"';
            $.ajax({
                url: AppConstant.BASE_URL + partnersUrl,
                headers: {
                    'AUTHORIZATION': token,
                    'Content-Type': 'application/vnd.api+json'
                },
                success: (data) => {
                    if (data && data.data) {
                        this.projectPartnersData = data;
                        this.setProjectAndPartnerData();
                    } else {
                        this._spinner.hide();
                    }
                },
                error: (error) => {
                    this._spinner.hide();
                }
            });
        }
    }

    setProjectAndPartnerData() {
        if(this.projectPartnersData.data && this.projectPartnersData.data.length) {
            this.partners = [];
            this.projectPartnersData.included.forEach((item)=> {
                if (item.type == 'partners') {
                    this.partners.push(item);
                }
            });
            this.projects = [];
            this.projectPartnersData.data.map((project) => {
                if(project.type == "projects") {
                    this.projects.push(project);
                }
            });
            this.projects = _.orderBy(this.projects, ['attributes.name'],['asc']);
            this.partners = _.orderBy(this.partners, ['attributes.name'],['asc']);
            this._spinner.hide();
        } else {
            this._spinner.hide();
        }
    }

    getTagCategories() {
        if (this.utility.getItem('token')) {
            let token = 'Token token=\"' + this.utility.getItem('token') + '\"';
            $.ajax({
                url: AppConstant.BASE_URL + "tag_categories",
                headers: {
                    'AUTHORIZATION': token,
                    'Content-Type': 'application/vnd.api+json'
                },
                success: (data) => {
                    if (data) {
                        this.categories = [];
                        data.data.forEach(item => {
                            let slugname = _.replace(item.attributes.slug, /-/g, "_");
                            let data = {
                                name: item.attributes.title,
                                value: slugname + '_tags_list',
                                isChecked: false
                            };
                            this.categories.push(data);
                            this.originalCategories = _.cloneDeep(this.categories);
                        });
                    }
                },
            });
        }
    }

    setReportingFields() {
        this.projectInformation = [
            {
                name: 'Date Invited to Project',
                value: 'primary_project_invitation.created_at',
                isChecked: false,
                index: 5
            },
            { name: 'No. of Matches', value: 'match_count', isChecked: false, index: 16 },
            { name: 'Joining Mechanisms', value: 'joining_mechanisms', isChecked: false, index: 13 },
            {
                name: 'Bulk Upload Date',
                value: 'bulk_uploaded_at',
                isChecked: false,
                index: 18,
                type: 'userEngagementProject'
            },
            { name: 'Project Joined Date', value: 'joined_at', isChecked: false, index: 14 },
            {
                name: 'Receive Message Notifications',
                value: 'receive_message_notifications',
                isChecked: false,
                index: 15
            },
            { name: 'Roles', value: 'role_list', isChecked: false, index: 17 },
            { name: 'Project Name', value: 'project.name', isChecked: true, index: 1 },
            { name: 'Match Status', value: 'state', isChecked: true, index: 0 },
            { name: 'Project User Status', value: 'project_user_status', isChecked: false, index: 19 },
            { name: 'Project Start Date', value: 'project.starts_on', isChecked: false, index: 2 },
            { name: 'Project End Date', value: 'project.ends_on', isChecked: false, index: 3},        
            { name: 'Archived Project', value: 'project.is_archived', isChecked: false, index: 12 },
            {
                name: 'Engagement Reminder Enabled',
                value: 'project.engagement_reminder_status',
                isChecked: false,
                index: 21
            },
            { name: 'Domain/URL', value: 'project.partner.domain', isChecked: false, index: 20 },
            { name: 'Mentor Matching Tool Enabled', value: 'project.typeform_enabled', isChecked: false, index: 23 },
            { name: 'Interval In Days', value: 'project.engagement_reminder_days', isChecked: false, index: 22 },
            { name: 'Mentor Matching Tool - Profiles Only', value: 'project.mentor_profile_status', isChecked: false, index: 26 },
            { name: 'Project Code', value: 'project_user_code', isChecked: false, index: 24 },
            { name: 'Gamification Enabled', value: 'project.gamification_enabled', isChecked: false, index: 25 },
            { name: 'Partner Name', value: 'project.partner.name', isChecked: true, index: 4 }, 
            { name: 'Moderation Type', value: 'project.moderation_type', isChecked: false, index: 27},   
        ];
        this.engagementReportingFields = [
            { name: 'Project Contract Type', value: 'project.contract_type', isChecked: false, index: 6 },
            { name: 'Project Delivery Model', value: 'project.delivery_model', isChecked: false, index: 7 },
            {
                name: 'Message Expectation for Entire Project ',
                value: 'project.message_expectation',
                isChecked: false,
                index: 8
            },
            { name: 'Brightside Coordinator', value: 'project.coordinator_id', isChecked: false, index: 9 },
            {
                name: 'Reasons to Exclude from Reporting',
                value: 'project.list_of_reasons',
                isChecked: false,
                index: 10
            },
            {
                name: 'Notes Affecting Engagement',
                value: 'project.notes_affecting_engagement',
                isChecked: false,
                index: 11
            },
            { name: 'Project User Id', value: 'id', isChecked: false, index: 12 },
            { name: 'Project Id', value: 'project.id', isChecked: false, index: 13 },
            { name: 'Partnership Status', value: 'project.partnership_status', isChecked: false, index: 14 },
        ];
        this.usersInformation = [
            { name: 'First Name', value: 'first_name', isChecked: true, isDisabled: true, index: 0 },
            { name: 'Verification Status', value: 'verification_status', isChecked: true, isDisabled: true, index: 6 },
            { name: 'Last Name', value: 'last_name', isChecked: true, isDisabled: true, index: 1 },
            { name: 'Created At', value: 'created_at', isChecked: true, isDisabled: true, index: 8 },
            { name: 'Email Address', value: 'email', isChecked: true, isDisabled: true, index: 2 },
            { name: 'Verified On', value: 'verified_at', isChecked: false, index: 7 },
            { name: 'Phone Number', value: 'phone_number', isChecked: true, isDisabled: true, index: 3 },
            { name: 'No. of Projects', value: 'projects.count', isChecked: false, index: 9 },
            { name: 'Date of birth', value: 'dob', isChecked: false, index: 4 },
            { name: 'No. of Groups Joined', value: 'group_channel_users.count', isChecked: false, index: 10 },
            { name: 'Postcode', value: 'postcode', isChecked: false, index: 5 },
            { name: 'User Id', value: 'id', isChecked: false, index: 11 },
            { name: 'App Installed', value: 'device', isChecked: false, index: 12 },
            { name: 'Active App User', value: 'active_app_user', isChecked: false, index: 13 },
            { name: 'Gender', value: 'gender', isChecked: false, index: 14 },
            { name: 'Ethnicity', value: 'user_ethnicity', isChecked: false, index: 15 },
        ];
        this.userEngagementPlatform = [
            {
                name: 'Total No. of Sent Messages',
                value: 'messages.sent.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 0
            },
            {
                name: 'No. Messages Received in Groups',
                value: 'messages.received.in_groups.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 9
            },
            {
                name: 'No. Messages Sent to Mentors',
                value: 'messages.sent.to_mentors.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 1
            },
            {
                name: 'Date Last Message Received',
                value: 'messages.last_received_at',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 10
            },
            {
                name: 'No. Messages Sent to Mentees',
                value: 'messages.sent.to_mentees.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 2
            },
            {
                name: 'Last Active',
                value: 'last_active_at',
                isChecked: true,
                type: 'userEngagementPlatform',
                index: 12
            },
            {
                name: 'No. Messages Sent in Groups',
                value: 'messages.sent.in_groups.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 3
            },
            {
                name: 'Days Active',
                value: 'days_active',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 13
            },
            {
                name: 'No. Days Messages Sent On',
                value: 'messages.days_sent.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 11
            },
            {
                name: 'Available Activities',
                value: 'activities.available.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 14
            },
            {
                name: 'Date First Message Sent',
                value: 'messages.first_sent_at',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 4
            },
            {
                name: 'Viewed Activities',
                value: 'activities.viewed.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 16
            },
            {
                name: 'Date Last Message Sent',
                value: 'messages.last_sent_at',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 5
            },
            {
                name: 'Unstarted Activities',
                value: 'activities.unstarted.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 15
            },
            {
                name: 'Total No. of Received Messages',
                value: 'messages.received.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 6
            },
            {
                name: 'Started Activities',
                value: 'activities.started.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 17
            },
            {
                name: 'No. Messages Received from Mentors',
                value: 'messages.received.from_mentors.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 7
            },
            {
                name: 'Completed Activities',
                value: 'activities.completed.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 18
            },
            {
                name: 'No. Messages Received from Mentees',
                value: 'messages.received.from_mentees.count',
                isChecked: false,
                type: 'userEngagementPlatform',
                index: 8
            }
        ];
        this.userEngagementProject = [
            {
                name: 'Total No. of Sent Messages (project level)',
                value: 'messages.sent.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 0
            },
            {
                name: 'No. Messages Received in Groups (project level)',
                value: 'messages.received.in_groups.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 9
            },
            {
                name: 'No. Messages Sent to Mentors (project level)',
                value: 'messages.sent.to_mentors.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 1
            },
            {
                name: 'Date Last Message Received (project level)',
                value: 'messages.last_received_at',
                isChecked: false,
                type: 'userEngagementProject',
                index: 10
            },
            {
                name: 'No. Messages Sent to Mentees (project level)',
                value: 'messages.sent.to_mentees.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 2
            },
            {
                name: 'Available Activities (project level)',
                value: 'activities.available.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 11
            },
            {
                name: 'No. Messages Sent in Groups (project level)',
                value: 'messages.sent.in_groups.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 3
            },
            {
                name: 'Viewed Activities (project level)',
                value: 'activities.viewed.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 13
            },
            {
                name: 'Date First Message Sent (project level)',
                value: 'messages.first_sent_at',
                isChecked: false,
                type: 'userEngagementProject',
                index: 4
            },
            {
                name: 'Unstarted Activities (project level)',
                value: 'activities.unstarted.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 12
            },
            {
                name: 'Date Last Message Sent (project level)',
                value: 'messages.last_sent_at',
                isChecked: false,
                type: 'userEngagementProject',
                index: 5
            },
            {
                name: 'Started Activities (project level)',
                value: 'activities.started.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 14
            },
            {
                name: 'Total No. of Received Messages (project level)',
                value: 'messages.received.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 6
            },
            {
                name: 'Completed Activities (project level)',
                value: 'activities.completed.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 15
            },
            {
                name: 'No. Messages Received from Mentors (project level)',
                value: 'messages.received.from_mentors.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 7
            },
            {
                name: 'Entry Survey State',
                value: 'entry_survey_state',
                isChecked: false,
                type: 'userEngagementProject',
                index: 16,
            },
            {
                name: 'No. Messages Received from Mentees (project level)',
                value: 'messages.received.from_mentees.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 8
            },
            {
                name: 'Exit Survey State',
                value: 'exit_survey_state',
                isChecked: false,
                type: 'userEngagementProject',
                index: 17,
            },
            {
                name: 'Total No. of Video Calls (project level)',
                value: 'room_recordings.count',
                isChecked: false,
                type: 'userEngagementProject',
                index: 18,
            },
            {
                name: 'Total No. of Video Call Minutes (project level)',
                value: 'room_recordings.minutes',
                isChecked: false,
                type: 'userEngagementProject',
                index: 19,
            },
        ];
        this.userEngagementMatch = [
            { name: 'Mentoring Role', value: 'role', isChecked: false, index: 0 },
            { name: 'Mentor/Mentee Matched with', value: 'matched_with', isChecked: false, index: 1 },
            {
                name: 'Mentor/Mentee Previously Matched with',
                value: 'previously_matched_with',
                isChecked: false,
                index: 2
            },
            {
                name: 'Date of Last Match',
                value: 'last_match_date',
                isChecked: false,
                index: 3
            },
            { name: 'No. of Sent Messages (match level)', value: 'messages.sent.count', isChecked: false, index: 4 },
            {
                name: 'No. of Received Messages (match level)',
                value: 'messages.received.count',
                isChecked: false,
                index: 6
            },
            {
                name: 'Date Last Message Sent (match level)',
                value: 'messages.last_sent_at',
                isChecked: false,
                index: 5
            },
            {
                name: 'Date Last Message Received (match level)',
                value: 'messages.last_received_at',
                isChecked: false,
                index: 7
            },
        ];
        this.groupMessaging = [
            { name: 'Role', value: 'group.role', isChecked: false, index: 0 },
            {
                name: 'No. of Messages sent by Individual User (group level)',
                value: 'group.messages.sent.count',
                isChecked: false,
                index: 4
            },
            { name: 'Group Name', value: 'group.name', isChecked: false, index: 1 },
            {
                name: 'Date Last Message Sent By Individual User (group level)',
                value: 'group.messages.sent.last_sent_at',
                isChecked: false,
                index: 5
            },
            { name: 'No. of Members in Group', value: 'group.users.count', isChecked: false, index: 2 },
            { name: 'Group Created Date', value: 'group.created_at', isChecked: false, index: 3 },
            {
                name: 'Total No. of Messages sent by all Users in Group (group level)',
                value: 'group.messages.count',
                isChecked: false,
                index: 6
            },
            {
                name: 'Date Last Message Sent by any User in Group (group level)',
                value: 'group.messages.last_sent_at',
                isChecked: false,
                index: 7
            },
        ];
    }

    resetReportingFields(){
        this.usersInformation = [];
        this.projectInformation = [];
        this.userEngagementPlatform = [];
        this.userEngagementProject = [];
        this.userEngagementMatch = [];
        this.groupMessaging = [];
        this.categories = [];
        this.engagementReportingFields = [];
        this.setReportingFields();
        this.userActivity = false;
    }
}
