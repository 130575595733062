import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MentoringEvents } from 'app/helpers/events';
import { VideoChatService } from 'app/messageServices/video-chat-service';
import { NgaModule } from 'app/theme/nga.module';
import { JoyrideModule } from 'ngx-joyride';

declare global {
  interface Document {
    pictureInPictureElement: HTMLVideoElement | null;
    exitPictureInPicture: () => Promise<void>;
  }
}
@Component({
    selector: 'app-video-call-modal',
    templateUrl: './video-call-modal.component.html',
    styleUrls: ['./video-call-modal.component.scss'],
    standalone: true,
    imports: [NgIf,CommonModule,NgaModule,RouterModule,JoyrideModule,]
})
export class VideoCallModalComponent implements OnInit {

  showVideModalPopUp: any;
  isVideoLive: boolean;
  videoCallTrack: any;

  constructor(private videoChatService:VideoChatService,private mentoringEvents:MentoringEvents) { }

  ngOnInit() {
    this.showVideModalPopUp = $('#showVideModalPopUp');
    this.showVideModalPopUp.modal('hide');
  }

  ngAfterViewInit() {
    this.videoChatService.showVideoModalPopUp.subscribe(message => {
      if(message == true) {
        this.showVideModalPopUp.modal('show');
      }
    })
    this.mentoringEvents.getOpenVideoContainer().subscribe(item => {
      if (item) {
          this.isVideoLive = true;
      } else {
          this.isVideoLive = false;
      }
  });

  this.mentoringEvents.getVideoLiveRomm().subscribe(data => {
      if (data) {
          this.videoCallTrack = data
      }
      else {
          this.videoCallTrack = null
      }
  })
  }
}
