import {Component} from '@angular/core';
import {GlobalState} from '../../../global.state';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterModule } from '@angular/router';
import {UtilityHelper} from "../../../helpers/utilityHelper";
import {MentoringEvents} from "../../../helpers/events";
import {MentoringServices} from "../../../appServices/mentoring";
import {AbstractBaseComponent} from "../../../app.baseComponent";
import {filter} from "rxjs/operators";
import { KnowledgeSearchComponent } from '../../../pages/knowledge/knowledgeSearch/knowledgeSearch.component';
import { CommonModule, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'knowledge-header',
    templateUrl: 'knowledgeHeader.html',
    styleUrls: ['knowledgeHeader.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, KnowledgeSearchComponent,CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,]
})

export class KnowledgeHeader extends AbstractBaseComponent {
    public isScrolled: boolean = false;
    public isMenuCollapsed: boolean = false;
    public isMenu: boolean = false;
    displaySearch: boolean = false;
    routerEvents: any;
    isHome: boolean = false;
    baseUrl: any = '';

    constructor(private _state: GlobalState, public router: Router,
                public utility: UtilityHelper,
                private mentoringEvents: MentoringEvents,
                private routes: ActivatedRoute,  private mentoringService: MentoringServices) {
        super();
    }

    ngOnInit() {
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
        this.getData();
        this.listenToRouterEvents();
        if (this.utility.myDomain == this.KNOWLEDGE_DOMAIN &&
            this.utility.getItem('token') && this.utility.getItem('userId')) {
            this.getMyAccountDetails(this.utility.getItem('userId'));
        }
    }

    listenToRouterEvents() {
        this.routerEvents = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(event => {
           this.getData();
        });
    }

    getData() {
        if (this.routes.snapshot) {
            let routeData = this.routes.snapshot;
            if (this.router.url == '/' || this.router.url == '/#categories') {
                this.isHome = true;
            } else {
                this.displaySearch = false;
            }

            if (!this.isHome) {
                this.resetMenu();
            }
        }
    }

    resetMenu() {
        this.isMenu = false;
        this.mentoringEvents.isKnowledgeMenu.emit(this.isMenu);
    }

    ngOnDestroy() {
        this.routerEvents.unsubscribe();
    }

    public toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
        return false;
    }

    public scrolledChanged(isScrolled) {
        this.isScrolled = isScrolled;
    }

    menuClicked() {
        this.isMenu = !this.isMenu;
        this.mentoringEvents.isKnowledgeMenu.emit(this.isMenu);
    }

    displaySearchBox() {
        if (!this.isHome) {
            this.displaySearch = !this.displaySearch;
        }
    }

    ngAfterViewInit() {
        this.mentoringEvents.isKnowledgeMenu.subscribe(item => {
            this.isMenu = item;
        });
    }

    getMyAccountDetails(user_id) {
        this.mentoringService.getMyAccountDetails(user_id).subscribe(
            data => this.getMyAccountDetailsSuccess(data),
            error => this.getMyAccountDetailsFail(error),
        );
    }

    getMyAccountDetailsSuccess(data) {
        if(data && data.data) {
            if (data.data.attributes.first_name) {
                if(this.utility.getItem('firstName') && this.utility.getItem('firstName') != data.data.attributes.first_name) {
                    this.utility.setItem('firstName', data.data.attributes.first_name);
                }
            }
        }
    }

    getMyAccountDetailsFail(error) { }
}
