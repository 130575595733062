import {Component} from '@angular/core';
import {UtilityHelper} from "../../../helpers/utilityHelper";
import {Router} from "@angular/router";
import {GlobalState} from "../../../global.state";
import {AdminServices} from "../../../appServices/adminService";
import {MentoringServices} from "../../../appServices/mentoring";
import {BaThemeSpinner} from "../../services/baThemeSpinner/baThemeSpinner.service";
import {MentoringEvents} from "../../../helpers/events";
import {AbstractBaseComponent} from "../../../app.baseComponent";
import {animate, keyframes, style, transition, trigger} from "@angular/animations";
import * as _ from "lodash";
import { AuthenticationHelper } from '../../../helpers/authenticationHelper';
import {CurrentUserStateService} from "../../../appServices/current-user-state.service";
import { Pages } from '../../../pages/pages.component';
import { NgIf, NgStyle, NgFor } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'project-switcher',
    styleUrls: ['./projectSwitcher.scss'],
    templateUrl: './projectSwitcher.html',
    animations: [
        trigger('toggleProjectSwitcherTrigger', [
            transition(':enter', [
                animate('0.37s', keyframes([
                    style({ transform: 'rotate(0) scale(1) translateX(0px)', zIndex: '-1000',
                        offset: 0 }),
                    style({ transform: 'rotate(0) scale(1) translateX(0px)', zIndex: '1000',
                        offset: 1 }),
                ])),
            ]),
            transition(':leave', [
                animate('0.37s', keyframes([
                    style({ transform: 'rotate(0) scale(1) translateX(0px)', zIndex: '1000',
                        offset: 0 }),
                    style({ transform: 'rotate(0) scale(1) translateX(0px)', zIndex: '-1000',
                        offset: 1 }),
                ])),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        NgFor,
        NgxPaginationModule,
        TranslateModule
    ],
})

export class ProjectSwitcher extends AbstractBaseComponent {

    role: string = '';
    openAdmin: boolean = false;
    isMenuCollapsed: boolean = false;
    openProjectSwitcher: boolean = false;
    projectList: any = [];
    graduateUrl: string = '';
    projectsCount: any= '';
    isArchived: boolean = false;
    pageNumber: any = 1;
    perPage: any = 4;
    defaultPageNumber: any = 1;
    defaultPerPage: any = 4;
    displayViewMore: boolean = false;
    isLoaded: boolean = false;
    activeTab: any = {};
    activeCloseICon: any = {};
    closeIconHovered: boolean = false;
    debounceTimeOut: any = 250;
    isConnection: boolean = false;
    isProjectArchive: boolean = false;

    private debouncedToggleProjectType = _.debounce((isArchived) => this.toggleProjectType(isArchived),
        this.debounceTimeOut, {});

    constructor(private utility: UtilityHelper, private router: Router,private _state: GlobalState,
                private adminServices: AdminServices, private mentoringService: MentoringServices,
                private _spinner: BaThemeSpinner, private mentoringEvents: MentoringEvents,
                private auth: AuthenticationHelper, private currentUserStateService: CurrentUserStateService,
                private page: Pages) {
        super();

        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });

        if (this.utility.getItem('open_admin')) {
            this.openAdmin = true;
        } else {
            this.openAdmin = false;
        }
        this.fetchProjectsList();
        this.setActiveTabColor();
    }

    setActiveTabColor() {
        const primaryColor: any = this.utility.getItem('primaryBgColor') ? this.utility.getItem('primaryBgColor') : 'blue';
        this.activeTab = {
            'color' : primaryColor,
            'border-bottom' : `2px solid ${primaryColor}`,
        };

        this.activeCloseICon = {
            'color' : primaryColor,
        };
    }

    ngAfterViewInit() {
        this.mentoringEvents.getOpenProjectSwitcher().subscribe(item => {
            this.isArchived = false;
            this.resetSwitcher();
            if (item) {
                this.openProjectSwitcher = true;
                this.setActiveTabColor();
                this.fetchProjectsList();
            } else {
                this.openProjectSwitcher = false;
            }
        })

        this.mentoringEvents.isProjectSwitched.subscribe(item => {
            if (item) {
                this.isArchived = false;
                this.resetSwitcher();
                if (this.openProjectSwitcher) {
                    this.fetchProjectsList();
                }
            }
        });

        this.mentoringEvents.getSwitchSpecificproject().subscribe(item => {
            if (item) {
                this.switchToOtherProject(item)
            }
        });
    }

    closeSwitcher() {
        this.openProjectSwitcher = false;
        this.mentoringEvents.setOpenProjectSwitcher(false);
    }

    getProjectsListService(pageUrl) {
        this.isLoaded = false;
        this.role = '';
        this.role = this.utility.getItem('role');
        this.adminServices.getUsersProjectList(pageUrl).subscribe(
            data => this.getUsersProjectListSuccess(data),
            error => this.getUsersProjectListFail(error)
        );
    }

    getUsersProjectListSuccess(data) {
        this.displayViewMore = false;
        if (data && data.data.length) {
            data.data.forEach( (item) => {
               this.projectList.push(item);
            });
            this.projectList.forEach(item => {
                data.included.some( (includedData) => {
                    if (item.attributes.partner_id == includedData.id && includedData.type == 'partners') {
                        item.partner = includedData;
                        return true;
                    }
                });

            });
        }
        this.projectsCount = data.meta.record_count;
        if (this.projectList && this.projectList.length < this.projectsCount) {
            this.displayViewMore = true;
        } else {
            this.displayViewMore = false;
        }
        this.isLoaded = true;
    }

    getUsersProjectListFail(error) {
        this.isLoaded = false;
    }

    getProject(event) {
        if (event) {
            this.switchToOtherProject({
                channelId: null,
                projectId: event.id
            })
        }
    }

    switchToOtherProject(switchData) {
        this.mentoringEvents.projectChanged(true);
        this._spinner.show();

        if ([this.MENTORING_DOMAIN, this.LOCAL_DOMAIN, this.BRILLIANTCLUB_DOMAIN].includes(this.utility.myDomain)) {
            this.adminServices.getSpecificProject(switchData.projectId).subscribe(
                data => this.getSpecificProjectSuccess(data, switchData.channelId),
                error => this.getSpecificProjectFail(error)
            );
        } else {
            let url = this.utility.getItem('mentoringUrl') ? this.utility.getItem('mentoringUrl')
                : this.MENTORING_URL;
            window.location.assign(`${url}session?feeds_id=${switchData.projectid}`);
        }
    }

    getSpecificProjectSuccess(data, channelId) {
        this.page.projectDetails = data;
        if (data) {
            this.mentoringEvents.mentorTypeformSubmitted(false);
            this.isProjectArchive = data.data.attributes.is_archived;
            if (this.isProjectArchive) {
                const archiveObject = {
                    'isArchive': this.isProjectArchive,
                };
                this.currentUserStateService.setCurrentUserState(archiveObject);
            }
            if(data.included){
                this.utility.partnerDetails = data.included;
            }
            let isConnectionEnabled: boolean = false;
            let isTypeformEnabled: boolean = false;
            if (data.data &&
                data.data.attributes &&
                data.data.attributes.is_connection_project) {
                isConnectionEnabled = true;
            }

            if (data.data &&
                data.data.attributes &&
                data.data.attributes.typeform_enabled) {
                isTypeformEnabled = true;
            }

            this.utility.connectionEnabled = isConnectionEnabled;
            this.utility.typeformEnabled = isTypeformEnabled;
            this.utility.mentorsAvailable = data.data.attributes.matching_enabled;
            if (data.included.length && data.included[0]) {
                this.utility.setItem('primaryBgColor', data.included[0].attributes.primary_color);
                this.utility.setItem('secondaryBgColor', data.included[0].attributes.secondary_color);
                this.utility.setItem('mentoringUrl', data.included[0].attributes.url + '/');
                this.utility.isProjectArchived = data.data.attributes.is_archived;
            }

            if (data.included.length && data.included[1]) {
                if (data.included[1].attributes && data.included[1].attributes.replacement_text_enabled) {
                    this.graduateUrl = 'Ask the ' + data.included[1].attributes.replacement_text;
                } else {
                    this.graduateUrl = 'Ask the Graduate';
                }
            }

            if (data.data) {
                this.utility.setItem('projectName', data.data.attributes.name);
                this.utility.setItem('moderationType', data.data.attributes.moderation_type);
                this.utility.setItem('projectId', data.data.id);
                this.utility.setMenus(data.data.attributes, this.graduateUrl);
                this.mentoringService.getUserProjectRole(data.data.id).subscribe(
                    data => this.getUserProjectRoleSuccess(data, channelId),
                    error => this.getUserProjectRoleFail(error)
                )
            }
            this.openProjectSwitcher = false;
            this.mentoringEvents.setOpenProjectSwitcher(false);
        } else {
            this._spinner.hide();
        }
    }

    getSpecificProjectFail(error) {
        this._spinner.hide();
    }

    viewMore() {
        this.pageNumber++;
        this.fetchProjectsList(true);
    }

    getUserProjectRoleSuccess(data, channelId) {
        if (data) {
            if (data.included[0]) {
                const superAdminObj = {
                    'isSuperAdmin': data.included[0].attributes.super_admin,
                };
                this.currentUserStateService.setSuperAdminState(superAdminObj);
                this.utility.setAvatarId = data.included[0].attributes.avatar_id;
                this.utility.setItem('firstName', data.included[0].attributes.first_name);
                this.utility.setItem('lastName', data.included[0].attributes.last_name);
            }
            if (data.included[1]) {
                const roleObj = {
                    'role': data.included[1].attributes.name,
                };
                this.currentUserStateService.setUserRoleState(roleObj);
                this.utility.setItem('role', data.included[1].attributes.name);
            }
            if (data.data[0]) {
                this.utility.setItem('userId', data.data[0].relationships.user.data.id);
                this.utility.setItem('projectUserId', data.data[0].id);
                this.utility.mentorTypeformId = data.data[0].attributes.typeform_link;
                this.auth.updateLastAccessedProject(data.data[0].attributes.project_id);
                if (!this.isProjectArchive) {
                    const archiveObject = {
                        'isArchive': data.data[0].attributes.is_archived,
                    }
                    this.currentUserStateService.setCurrentUserState(archiveObject);
                }
                this.mentoringEvents.isProjectSwitched.emit(true);
            }
            this.mentoringEvents.setProjectSwitchedContent(data);
            if (channelId) {
                this.router.navigate(['feeds'], { queryParams : { 'channel_id': channelId } });
            } else {
                this.router.navigate(['feeds']);
            }
            this._spinner.hide();
        } else {
            this._spinner.hide();
        }
    }

    getUserProjectRoleFail(error) {
        this._spinner.hide();
    }

    toggleProjectType(isArchived) {
        this.isLoaded = false;
        this.isArchived = isArchived;
        this.resetSwitcher();
        this.fetchProjectsList();
    }

    resetSwitcher() {
        this.closeIconHovered = false;
        this.displayViewMore = false;
        this.projectList = [];
        this.pageNumber = 1;
        this.perPage = 4;
    }

    fetchProjectsList(viewMoreProjects: boolean = false) {
        let pageUrl: any = '';
        const pageParams: string = [
            `filter[is_authorized]=true`,
            `filter[is_archived]=${this.isArchived}`,
            `page[number]=${viewMoreProjects ? this.pageNumber : this.defaultPageNumber}`,
            `page[size]=${viewMoreProjects ? this.perPage : this.defaultPerPage}`,
            `sort=-starts_on`,
        ].join('&');
        pageUrl = `?${pageParams}`;
        this.getProjectsListService(pageUrl);
    }
}
