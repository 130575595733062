import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import {Router} from "@angular/router";
import {UtilityHelper} from "../helpers/utilityHelper";
import {HttpClientHelper} from "./apiClient";
import {SocketService} from "../messageServices/socketService";
import {NotificationClient} from "../messageServices/notificationClient";
import {MentoringSocketService} from "../messageServices/mentoringSocketService";
import {throwError} from "rxjs";
import { data } from 'jquery';
import { catchError, map, pluck } from 'rxjs/operators';

@Injectable()
export class MentoringServices {
    private httpClient: HttpClientHelper;
    private getPartnerDetailsUrl = 'partners';
    private getUserIpAddressUrl = 'ip_address_request';
    private usersUrl = 'users';
    private validateProjectCodeUrl = 'project_codes';
    private signInUserUrl = 'sessions';
    private getUserProjectUrl = 'v2/projects';
    private getUserProjectRoleUrl = 'v2/project_users?filter[project_id]=';
    private validateInvitationCodeUrl = 'project_invitations';
    private getLandingPageDetailsUrl = 'landing_pages';
    private resetPasswordUrl = 'password_resets';
    private getAskGraduateDetailsUrl = 'ask_graduates';
    private getGraduateDetailsUrl = 'graduates';
    private updateNotificationUrl = 'project_users';
    private getProjectUserPermissionsUrl = 'project_users';
    private askQuestionUrl = 'graduate_questions';
    private getProjectResourcesDetailsUrl = 'attached_external_materials';
    private assignmentsUrl = 'assignments';
    private getAttachedActivitiesUrl = 'attached_activities';
    private getProjectUsersActivitiesUrl = 'project_user_activities';
    private getActivityAttemptedQuestionUrl = 'attempted_questions';
    private surveyUrl = 'v2/project_users';
    private getProjectChannelsUrl = 'v2/channels';
    private getSupportRequestChannelUsersUrl = 'support_requests/';
    private channelsUrl = 'channels';
    private getProjectGroupChatDetailsUrl = 'project_groups/';
    private getBrilliantClubTutorialgroupUrl = 'brilliant_club_tutorials';
    private getBaselineAssignmentsUrl = 'baseline_assignment_submissions';
    private getGradedBaselineAssignmentsUrl = 'graded_assignment_submissions';
    private getGradedAssignmentStatusUrl = 'graded_assignments';
    private baselineAssignmentSubmissionUrl = 'brilliant_club_baseline_assignment_submissions';
    private finalGradedAssignmentSubmissionUrl = 'brilliant_club_final_assignment_submissions';
    private validateEmailVerificationCodeUrl = 'email_addresses';
    private sendBrilliantClubTutorialDetailsUrl = 'brilliant_club_tutorial_registers';
    private getChannelUserUrl = 'v3/channel_users';
    private update3SlidePopUpUrl = 'users';
    private getTrendingPostUrl = 'posts';
    private upcomingEventsUrl = 'project_user_events';
    private submittedArticleUrl = 'news_articles';
    private connectionLandingUrl = 'connection_landing_pages';
    private notificationUrl = 'user_notifications';
    private getMatchedMentorsUrl = 'matching_users';
    private matchMentorUrl = 'project_matches';
    private mentorProfilesUrl = 'mentor_profiles';
    private getTypeformUrl = 'get_form/';
    private badgesURL = 'badges';
    private messageStreakURL = 'message_streaks';
    private gamificationCertificateURL = 'gamification_certificates';
    private getEthnicityURL = 'ethnicities';
    private getGenericLandingPageURL = 'generic_landing_pages';
    private getTwilioVideoAuthentication ='tokens';
    private scheduleMeetURL = 'schedule_calls';
    private postVideoCallFeedback = 'video_call_feedbacks'
    private roomUpdateForMissedCallUrl = 'rooms'
    constructor(httpClient: HttpClientHelper, private router: Router,
        private utility: UtilityHelper, private socketService: SocketService,
        private notification: NotificationClient, private mentoringSocketService: MentoringSocketService) {
        this.httpClient = httpClient;
    }

    // To get partner details
    getPartnerDetails(data): Observable<any> {
        return this.httpClient.get(this.getPartnerDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get requesting user ip address
    getUserIpAddress(data): Observable<any> {
        return this.httpClient.get(this.getUserIpAddressUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create User
    createUser(localeLang,data): Observable<any> {
        return this.httpClient.post(this.usersUrl+'?locale='+localeLang, data)
        .pipe(catchError(this.handleError.bind(this)));
    }

    createUserProfile(data): Observable<any> {
        return this.httpClient.post(this.mentorProfilesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    updateUserProfile(id, data): Observable<any> {
        return this.httpClient.put(`${this.mentorProfilesUrl}/${id}`, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    updateMaxiumMatches(id, data): Observable<any> {
        return this.httpClient.put(`${this.mentorProfilesUrl}/${id}`, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To check and get possible matches
    getMatchUsers(data): Observable<any> {
        return this.httpClient.post(this.getMatchedMentorsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To match mentor and mentee
    matchMentor(data): Observable<any> {
        return this.httpClient.post(this.matchMentorUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To validate project code
    validateProjectCode(data): Observable<any> {
        return this.httpClient.get(this.validateProjectCodeUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To sign in user
    signInUser(localeLang,data): Observable<any> {
        return this.httpClient.post(this.signInUserUrl+'?locale='+localeLang, data)
        .pipe(catchError(this.handleError.bind(this)));

    }

    // To send reset password link
    sendResetPasswordLink(data): Observable<any> {
        return this.httpClient.post(this.resetPasswordUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get user's project
    getUserProject(data): Observable<any> {
        return this.httpClient.get(this.getUserProjectUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get user project role
    getUserProjectRole(data): Observable<any> {
        return this.httpClient.get(this.getUserProjectRoleUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To validate invitation code
    validateInvitationCode(data): Observable<any> {
        return this.httpClient.get(this.validateInvitationCodeUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get landing page details
    getLandingPageDetails(data): Observable<any> {
        return this.httpClient.get(this.getLandingPageDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get my-account details
    getMyAccountDetails(data): Observable<any> {
        return this.httpClient.get(this.usersUrl + '/' + data)
.pipe( catchError(this.handleError.bind(this)));
    }

    // To get ask graduate details
    getAskGraduateDetails(data): Observable<any> {
        return this.httpClient.get(this.getAskGraduateDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get graduates details
    getGraduateDetails(data): Observable<any> {
        return this.httpClient.get(this.getGraduateDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get graduates answer details
    getGraduateAnswers(data): Observable<any> {
        return this.httpClient.get(this.getGraduateDetailsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get project access permissions
    getProjectUserPermissions(data): Observable<any> {
        return this.httpClient.get(this.getProjectUserPermissionsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update my-account details
    updateMyAccount(id, data): Observable<any> {
        return this.httpClient.put(this.usersUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    updateProfilePicture(id, data): Observable<any> {
        return this.httpClient.patch(this.usersUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update current project for a user
    updateLastAccessedProject(id, data): Observable<any> {
        return this.httpClient.patch(`${this.usersUrl}/${id}`, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    updateAutoPromtFlag(id, data): Observable<any> {
        return this.httpClient.patch(`${this.usersUrl}/${id}`, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update my-account details
    updateNotification(id, data): Observable<any> {
        return this.httpClient.put(this.updateNotificationUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update Typeform submission details
    updateTypeformSubmission(id, data): Observable<any> {
        return this.httpClient.put(this.updateNotificationUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get active graduates details
    getActiveGraduatesDetails(data): Observable<any> {
        return this.httpClient.get(this.getGraduateDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getConnectionDetails(data): Observable<any> {
        return this.httpClient.get(this.connectionLandingUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To ask graduate a question
    askQuestion(data): Observable<any> {
        return this.httpClient.post(this.askQuestionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get notification status
    getNotificationStatus(data): Observable<any> {
        return this.httpClient.get(this.updateNotificationUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get project resources details
    getProjectResourcesDetails(data): Observable<any> {
        return this.httpClient.get(this.getProjectResourcesDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get assignments

    getAssignments(data): Observable<any> {
        return this.httpClient.get(this.assignmentsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To create assignment

    createAssignment(data): Observable<any> {
        return this.httpClient.post(this.assignmentsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get attached activities

    getAttachedActivities(data): Observable<any> {
        return this.httpClient.get(this.getAttachedActivitiesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get project user activities

    getProjectUsersActivities(data): Observable<any> {
        return this.httpClient.get(this.getProjectUsersActivitiesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get specific activity details
    getSpecificActivityDetails(data): Observable<any> {
        return this.httpClient.get(this.getProjectUsersActivitiesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get activity attempted question
    getActivityAttemptedQuestion(data): Observable<any> {
        return this.httpClient.get(this.getActivityAttemptedQuestionUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To post view activity
    viewActivity(data): Observable<any> {
        return this.httpClient.post(this.getProjectUsersActivitiesUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get question details
    getQuestionDetails(data): Observable<any> {
        return this.httpClient.get(this.getActivityAttemptedQuestionUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update question state

    updateQuestionState(data): Observable<any> {
        return this.httpClient.post(this.getActivityAttemptedQuestionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To submit activity answer
    submitActivityAnswer(data, id): Observable<any> {
        return this.httpClient.patch(this.getActivityAttemptedQuestionUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To set next question progress
    setProgress(data, id): Observable<any> {
        return this.httpClient.patch(this.getProjectUsersActivitiesUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get reviewed activities

    getReviewedActivities(data): Observable<any> {
        return this.httpClient.get(this.getProjectUsersActivitiesUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getProjectChannels(data): Observable<any> {
        return this.httpClient.get(this.getProjectChannelsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getProjectUserChannel(data): Observable<any> {
        return this.httpClient.get(this.getChannelUserUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get the survey information
    getSurvey(data): Observable<any> {
        return this.httpClient.get(this.surveyUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getProjectUserChannels(data): Observable<any> {
        return this.httpClient.get(this.channelsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getSupportRequestChannelUsers(data): Observable<any> {
        return this.httpClient.get(this.getSupportRequestChannelUsersUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getChannel(data): Observable<any> {
        return this.httpClient.get(this.channelsUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getProjectGroupChatDetails(data): Observable<any> {
        return this.httpClient.get(this.getProjectGroupChatDetailsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    sendValidationCode(data): Observable<any> {
        return this.httpClient.get(this.resetPasswordUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    sendNewPassword(id, data): Observable<any> {
        return this.httpClient.patch(this.resetPasswordUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getTokenId(data): Observable<any> {
        return this.httpClient.get(this.resetPasswordUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getTutorialGroups(data): Observable<any> {
        return this.httpClient.get(this.getBrilliantClubTutorialgroupUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To send the tutee attendance and homework submission
    sendTuteeData(data): Observable<any> {
        return this.httpClient.post(this.sendBrilliantClubTutorialDetailsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To update the tutee attendance and homework submission
    updateTuteeData(data, id): Observable<any> {
        return this.httpClient.patch(this.sendBrilliantClubTutorialDetailsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getBaselineAssignments(data): Observable<any> {
        return this.httpClient.get(this.getBaselineAssignmentsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getSubmittedAssignments(data): Observable<any> {
        return this.httpClient.get(this.updateNotificationUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    // To get invited users for brillaint club coordinator
    invitedUsers(data): Observable<any> {
        return this.httpClient.get(this.validateInvitationCodeUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
    * To get graded submitted assignments
    * */
    getGradedSubmittedAssignments(data): Observable<any> {
        return this.httpClient.get(this.getGradedBaselineAssignmentsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
     * To get graded submitted assignments
     * */
    getHomeworkAssignments(data): Observable<any> {
        return this.httpClient.get(this.assignmentsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
    * To submit assignment marks
    * */
    sendAssignmentMarks(id, data): Observable<any> {
        return this.httpClient.put(this.getBaselineAssignmentsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
     * To submit final assignment marks
     * */
    sendFinalAssignmentMarks(id, data): Observable<any> {
        return this.httpClient.put(this.getGradedBaselineAssignmentsUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
   * To get graded final assignments status
   * */
    getFinalAssignmentStatus(data): Observable<any> {
        return this.httpClient.get(this.getGradedAssignmentStatusUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
   * To submit final assignment marks for tutee
   * */
    submitFinalAssignmentMarks(data): Observable<any> {
        return this.httpClient.post(this.getGradedBaselineAssignmentsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
     * To submit baseline assignment
     * */
    submitBaselineAssignment(data): Observable<any> {
        return this.httpClient.post(this.getBaselineAssignmentsUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
    * To confirm submission of the baseline assignment.
    * */
    confirmBaselineAssignmentPost(data): Observable<any> {
        return this.httpClient.post(this.baselineAssignmentSubmissionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
    * To post final graded assignment for tutee
    * */
    postFinalGradedAssignment(data): Observable<any> {
        return this.httpClient.post(this.finalGradedAssignmentSubmissionUrl, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    /*
    * to validate email verification code
    * */
    validateEmailVerificationCode(data): Observable<any> {
        return this.httpClient.get(this.validateEmailVerificationCodeUrl + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }
    /*
    * to verify email addresses
    * */
    verifyEmailAddresses(id, data): Observable<any> {
        return this.httpClient.patch(this.validateEmailVerificationCodeUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    updateSlidePopUp(id, data): Observable<any> {
        return this.httpClient.patch(this.update3SlidePopUpUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getTendingPostsDetails(data): Observable<any> {
        return this.httpClient.get(this.getTrendingPostUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getupcomingEvents(data): Observable<any> {
        return this.httpClient.get(this.upcomingEventsUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getUserArticles(data): Observable<any> {
        return this.httpClient.get(this.submittedArticleUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getUserNotification(data): Observable<any> {
        return this.httpClient.get(this.notificationUrl + data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    updateReadUserNotification(id, data): Observable<any> {
        return this.httpClient.put(this.notificationUrl + '/' + id, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getMentorTypeform(formID): Observable<any> {
        return this.httpClient.get(this.getTypeformUrl + formID)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getMentorProfile(profileId): Observable<any> {
        return this.httpClient.get(`${this.mentorProfilesUrl}/${profileId}`)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getSpecificMentorProfileResponse(url): Observable<any> {
        return this.httpClient.get(`${this.updateNotificationUrl}/${url}`)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getBadgesData(url): Observable<any> {
        return this.httpClient.get(`${this.badgesURL}${url}`)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getMessageStreakData(data): Observable<any> {
        return this.httpClient.post(this.messageStreakURL, data)
            .pipe(catchError(this.handleError.bind(this)));
    }

    getCertificateData(data): Observable<any> {
        return this.httpClient.post(this.gamificationCertificateURL, data)
            .pipe(catchError(this.handleError.bind(this)));
    }
    getEthnicityDetails(data): Observable<any> {
        return this.httpClient.get(this.getEthnicityURL + '/' + data)
            .pipe(catchError(this.handleError.bind(this)));
    }
    getGenericLandingPageDetails(link): Observable<any> {
        return this.httpClient.get(this.getGenericLandingPageURL + link)
            .pipe(catchError(this.handleError.bind(this)));
    }
    getTwilioVideoAuth(data) {
        return this.httpClient.post(this.getTwilioVideoAuthentication,data).pipe(
            map(this.extractResponse),
            catchError(this.handleError.bind(this))
        )
    }
    scheduleMeeting(data) {
        return this.httpClient.post(this.scheduleMeetURL,data)
        .pipe(
            map(this.extractResponse),
            catchError(this.handleError.bind(this))
        )
    }
    updateMeeting(data) {
        return this.httpClient.patch(`${this.scheduleMeetURL}/${data.data.id}`,data)
        .pipe(
            map(this.extractResponse),
            catchError(this.handleError.bind(this))
        )
    }
    getMeetDetails(link): Observable<any> {
        return this.httpClient.get(this.scheduleMeetURL + link)
        .pipe(
          map(this.extractResponse),
          catchError(this.handleError.bind(this))
        );
      }
    cancelScheduledMeeting(data) {
        return this.httpClient.put(`${this.scheduleMeetURL}/${data.data.id}`,data)
        .pipe(
            map(this.extractResponse),
            catchError(this.handleError.bind(this))
        )
    }

    declineScheduledMeet(data) {
        return this.httpClient.put(`${this.scheduleMeetURL}/${data.data.id}/decline_reason`,data)
        .pipe(
            map(this.extractResponse),
            catchError(this.handleError.bind(this))
        )
    }

    acceptScheduledMeet(data) {
        return this.httpClient.put(`${this.scheduleMeetURL}/${data.data.id}/accept`, data)
        .pipe(
            map(this.extractResponse),
            catchError(this.handleError.bind(this))
        )
    }
    postVideoCall(data) {
        return this.httpClient.post(this.postVideoCallFeedback,data)
        .pipe(
            map(this.extractResponse),
            catchError(this.handleError.bind(this))
        )
    }
    updateRoomForMissedCall(data) {
        return this.httpClient.patch(`${this.roomUpdateForMissedCallUrl}/${data.data.id}`,data)
        .map(this.extractResponse)
        .catch(this.handleError.bind(this))
    }

    private extractResponse(res) {
        return res;
    }

    private handleError(error) {
        if (error.error == "Unauthorized") {
            this.socketService.disconnectSocket();
            this.mentoringSocketService.disconnectSocket();
            this.notification.disconnectSocket();
            this.utility.clear();
            this.router.navigate([''])
        } else {
            return throwError(error.error);
        }
    }
}
