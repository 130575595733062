import {Injectable} from "@angular/core";
import {AbstractBaseComponent} from "../app.baseComponent";
import {CanActivate, Router} from "@angular/router";
import {AuthenticationHelper} from "../helpers/authenticationHelper";
import {UtilityHelper} from "../helpers/utilityHelper";

@Injectable()
export class AssignmentGuard extends AbstractBaseComponent implements CanActivate {
    isArchive: string = '';

    constructor(private authService: AuthenticationHelper,
                private utility: UtilityHelper,
                private route: Router) {
        super();
    }

    canActivate() {
        this.isArchive = this.utility.getItem('isProjectUserArchived');
        if ((this.authService.isLoggedIn() && (this.utility.myDomain == this.LOCAL_DOMAIN ||
            this.utility.myDomain == this.MENTORING_DOMAIN ||
            this.utility.myDomain == this.BRILLIANTCLUB_DOMAIN) &&
            (this.utility.getItem('isChannelAccess') == 'True')) || this.isArchive === 'true') {
            return true;
        } else {
            this.route.navigate(['']);
            return false;
        }
    }
}
